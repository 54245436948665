import React, { Component, createContext } from 'react';
import { withRouter } from 'react-router-dom';
import TabService from '../AvainiaTools/TabService.js';

export const TopbarContext = createContext();

class TopbarContextProvider extends Component {
  state = {
    tab: null,
    tabs: [],
    loading: false,
    userManagementTabKey: null,
    searchTriggered: false,
    searchText: '',
    searchTextChangedCallback: () => {},
    documentTypeId: null,
    searchDocumenttype: null,
    mapViewToggleEnabled: false,
    mapViewActive: this.props.children.props.configuration.infraMapVisibleByDefault,
  }

  showUserManagement = () => {
    const tab = this.state.userManagementTabKey;
    this.setTab(tab);
  }

  setTab = (tab) => {
    TabService.updateUrl(tab);
    this.setState({ tab });
  }

  mapViewToggle = () => {
    this.setState((prevState) => ({
      mapViewActive: !prevState.mapViewActive,
    }),
    () => {
      if (!this.state.mapViewHasChangedCallback) { return; }
      this.state.mapViewHasChangedCallback(this.state.mapViewActive);
    });
  }

  mapViewHasChangedCallback = (mapView) => {
    this.setState({ mapViewHasChangedCallback: mapView });
  }

  /* TODO: Convert to oldschool config object with default params */
  setTopbar = (
    tabs,
    userManagementTabKey,
    mapViewToggleEnabled = false,
    callback = () => {},
    searchTextChangedCallback = () => {},
  ) => {
    const tab = TabService.getTab() || (tabs && tabs.length ? tabs[0].key : null);

    this.setState({
      userManagementTabKey,
      tabs,
      tab,
      mapViewToggleEnabled,
      searchTextChangedCallback,
    }, callback);
  }

  resetTopbar = () => {
    this.setState({
      tabs: [],
      tab: null,
      userManagement: null,
      mapViewToggleEnabled: false,
      searchTriggered: false,
      mapViewActive: this.props.children.props.configuration.infraMapVisibleByDefault,
      searchTextChangedCallback: () => {},
    });
  }

  timeout = false;

  searchChangeHandler = (searchText) => {
    this.setState({ searchText, loading: true }, () => {
      if (this.timeout) { clearTimeout(this.timeout); }
      this.timeout = setTimeout(() => {
        if (!this.state.searchTriggered) {
          this.setState({ searchTriggered: true }, this.props.history.push('/search'));
        } else {
          this.props.history.replace('/search');
        }
        this.setState({ loading: false }, () => {
          this.timeout = false;
          if (this.state.searchTextChangedCallback) {
            this.state.searchTextChangedCallback();
          }
        });
      }, 1500);
    });
  }

  searchDocumentTypeHandler = (documentTypeId) => {
    this.setState({ documentTypeId }, () => {
      if (this.state.searchText) {
        this.searchChangeHandler(this.state.searchText);
      }
    });
  }

  render() {
    const value = {
      ...this.state,
      setTab: this.setTab,
      setTopbar: this.setTopbar,
      resetTopbar: this.resetTopbar,
      showUserManagement: this.showUserManagement,
      mapViewToggle: this.mapViewToggle,
      searchChangeHandler: this.searchChangeHandler,
      mapViewHasChangedCallback: this.mapViewHasChangedCallback,
      searchDocumentTypeHandler: this.searchDocumentTypeHandler,
    };

    return (
      <TopbarContext.Provider value={value}>
        {this.props.children}
      </TopbarContext.Provider>
    );
  }
}

export default withRouter(TopbarContextProvider);
