// The unnecessary ones are here on purpose, they will be needed soon enough
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import AvainiaPanel from './AvainiaPanel.js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import AvainiaTableHeading from '../AvainiaTable/AvainiaTableHeading.js';
import './PanelProjectStatuses.scss';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import ModalProjectStatusEdit from '../Modals/ModalProjectStatusEdit.js';
import ModalProjectStatusAdd from '../Modals/ModalProjectStatusAdd.js';
import { Check, Close } from '../Icon/Icon.js';
import { Button } from 'react-bootstrap';


const Modals = {
  editProjectStatus: 1,
  addProjectStatus: 2,
};

class PanelProjectStatuses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeProjectStatus: null,
      modal: false,
      loading: true,
      error: false,
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.projectStatusesGet().then((projectStatuses) => {
      if (projectStatuses.error) { return; }
      this.setState({ projectStatuses, loading: false });
    });
  };

  // ? note: this is copied Inframarineproject -- // TODO:  dedupe?
  updateUrl = (newView) => {
    const urlAppendix = `/defaultfolders/${encodeURIComponent(JSON.stringify(newView))}`;
    // TODO: This works, but seems suboptimal
    this.props.history.replace(urlAppendix);
  }

  hideModal = () => {
    this.setState({ modal: false, activeProjectStatus: null });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  editProjectStatus = (e, projectStatus) => {
    e.stopPropagation();

    this.setState({
      modal: Modals.editProjectStatus,
      activeProjectStatus: projectStatus,
    });
  }

  projectStatusCallback = () => {
    this.setState({ modal: null, activeProjectStatus: null })
    window.location.reload(); // TODO: improve
  }

  renderProjectStatuses = (projectStatus, level = 0) => {
    const children = this.state.projectStatuses.filter((df) => df.parent_id === projectStatus.id);
    const renderedChildren = children.map((folder) => this.renderProjectStatuses(folder, level + 1));

    return (
      <div
        key={projectStatus.id}
        onClick={(e) => this.editProjectStatus(e, projectStatus)}
        className={`tree-${level}`}
      >
        {projectStatus.is_active ? <Check className="icon clickable active" /> : <Close className="icon clickable inactive" />}
        {projectStatus.name}
        <div className="children">
          {renderedChildren}
        </div>
      </div>
    );
  }

  render() {
    const { error, loading, projectStatuses, modal } = this.state;
    const rootStatuses = projectStatuses && projectStatuses.filter((df) => df.parent_id === 0);
    const renderedStatuses = rootStatuses && rootStatuses.map((df) => this.renderProjectStatuses(df));

    return (
      <AvainiaPanel icon={null} heading={null}>
        <AvainiaTableHeading
          title={I18n.t('projectStatuses.project-statuses')}
          button={
            <>
              <Button onClick={() => this.setState({ modal: Modals.addProjectStatus })} size="m" style={{ marginBottom: 5 }}>
                {I18n.t('projectStatuses.create-new-status')}
              </Button>
            </>
          }
        />

        <Error inline error={error} />
        <Loading inline loading={loading} />

        {!loading &&
          <div>
            <div className='tree-menu-list' style={{ border: '1px solid #eee', padding: 10, marginTop: 40, marginBottom: 10 }}>
              <h4>{I18n.t('projectStatuses.project-status-tree')} </h4>
              {renderedStatuses}
            </div>
            <hr />
          </div>
        }

        {modal === Modals.editProjectStatus &&
          <ModalProjectStatusEdit
            show
            onHide={this.hideModal}
            projectStatusCallback={this.projectStatusCallback}
            activeProjectStatus={this.state.activeProjectStatus}
            projectStatuses={this.state.projectStatuses}
            invalidParents={this.state.descendents}
          />
        }

        { modal === Modals.addProjectStatus && 
          <ModalProjectStatusAdd 
            show
            onHide={this.hideModal}
            projectStatusCallback={this.projectStatusCallback}
            projectStatuses={this.state.projectStatuses}
          />
        }

      </AvainiaPanel>
    )
  }
}

export default withRouter(PanelProjectStatuses);
