import React from 'react';
import logo from '../../../images/logo.png';
import './Footer.scss';

const Footer = (props) => {
  const { configuration: cfg } = props;

  const secondaryLogo = process.env.REACT_APP_SECONDARY_FOOTER_LOGO;

  return <footer className="Footer">
    <p className="Footer-text">
      {cfg.dev && <>Dev</>}
      {cfg.test && <>Test</>}
      {cfg.demo && <>Demo</>}
    </p>
    <div className="Footer-logo-wrapper">
      {secondaryLogo && <img className="Footer-logo" src={secondaryLogo} alt="" />}
      <img className="Footer-logo" src={logo} alt="" />
    </div>
  </footer>;
};

export default Footer;
