import React, { Component } from 'react';
import AvainiaCore from 'avainia-core-api';
import { Edit, Trash } from '../../multiview/Icon/Icon.js';
import { ListGroup } from 'react-bootstrap';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import ApartmentTemplateEditModal from './ApartmentTemplateEditModal.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

const Modals = {
  edit: 1,
};

class ApartmentTemplatesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      modal: false,
      error: false,
      loading: false,
    };
  }

  delete = (id) => {
    if (this.state.loading) { return; }

    const api = new AvainiaCore(LocalStorageService.getToken);
    // TODO: Confirm
    this.setState({ loading: 1 }, () => {
      api.condominiumApartmentTemplateDelete(this.props.condominium.id, id).then((result) => {
        if (result.error) { return this.setState({ loading: false, error: result.error }); }

        return this.setState({ loading: false, error: false }, () => { this.props.deleteCallback(result); });
      });
    });
  }

  edit = (id) => {
    if (this.state.loading) { return; }
    // TODO: Confirm
    const editingTarget = this.props.list.find((x) => x.id === id);
    this.setState({ editing: editingTarget, modal: Modals.edit });
  }

  hideModal = (e) => { this.setState({ modal: false }); }

  render() {
    return <>
      {this.state.error && <Error inline error={this.state.error} />}
      {!this.state.error && this.state.loading && <Loading inline />}
      {!this.state.error && !this.state.loading &&
        <ListGroup className="listing">
          {this.props.list.map((at) => <ListGroup.Item key={at.id}>
            {at.name}
            <Trash onClick={() => { this.delete(at.id); }} />
            <Edit onClick={() => { this.edit(at.id); }} />
          </ListGroup.Item>)}
        </ListGroup>
      }

      {this.state.modal === Modals.edit &&
        <ApartmentTemplateEditModal apartmentTemplate={this.state.editing} onHide={this.hideModal} editCallback={this.props.editCallback} />
      }
    </>;
  }
}

export default ApartmentTemplatesList;
