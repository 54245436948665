import React, { Component } from 'react';
import { Form, Button, Row, Modal } from 'react-bootstrap';
import I18n from 'i18n-js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import CustomSelect from '../Select/CustomSelect.js';
import AvainiaCore from 'avainia-core-api';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

export default class ModalCompanyCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      code: '',
      type: '',
      loading: false,
      error: false,
    };
  }

  handleSelectChange = (name, value) => { this.setState({ type: value.value }); }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  save = (e) => {
    const company = {
      name: this.state.name,
      code: this.state.code,
      type: this.state.type,
    };

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.companyCreate(company).then((result) => {
      if (result.error) { return this.setState({ error: result.error }); }

      this.props.addCompanyCallback(result);
      this.props.onHide();
    });
  }

  render() {
    const CompanyTypes = window.Avainia.Globals.CompanyTypes.map((x) => {
      return {
        label: I18n.t(`constants.companyTypes.${x}`),
        value: x,
      };
    });

    return <Modal show={true} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.companies.add-company')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.error && <Error inline error={this.state.error} />}
        {!this.state.error && this.state.loading && <Loading inline />}
        {!this.state.error && !this.state.loading && <div className="tinyform">
          <Form.Group as={Row}>
            <CustomSelect
              name="type"
              placeholder={I18n.t('views.companies.type')}
              handleChange={this.handleSelectChange}
              selectOptions={CompanyTypes}
            />
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Control placeholder={I18n.t('views.companies.name')} type="text" onChange={this.onChange} name="name" value={this.state.name} />
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Control placeholder={I18n.t('views.companies.code')} type="text" onChange={this.onChange} name="code" value={this.state.code} />
          </Form.Group>
        </div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" disabled={this.state.loading} onClick={this.save}>{I18n.t('general.modal-form-save')}</Button>
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('general.modal-close')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}
