import React, { Component, Fragment } from 'react';
import { Button, Modal, Form, Tab, Tabs } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import AvainiaTable from '../../AvainiaTable/AvainiaTable.js';
import AvainiaTagInput from '../../AvainiaTagInput/AvainiaTagInput.js';
import { Trash, Edit } from '../../Icon/Icon.js';
import './DocumentTypeManageModal.scss';
import LocalStorageService from '../../../../AvainiaTools/LocalStorageService.js';

const FieldTypes = {
  keyword: 'keywords',
  text: 'text',
  date: 'date',
};

class FieldsManageModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: [],
      tags: false,
      newField: {
        fieldName: '',
        fieldType: '',
        keywords: [],
        showInDocumentsTable: false,
      },
      editMode: false, // id or false
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.fieldsGet().then((fields) => {
      if (fields.error) { return this.setState({ error: fields.error }); }
      this.setState({ fields });
    });
  }

  newFieldOnChange = (e) => {
    e.persist();
    this.setState((prevState) => ({ newField: { ...prevState.newField, [e.target.name]: e.target.value } }));
  }

  newFieldOnChangeCheckbox = (e) => {
    e.persist();
    this.setState((prevState) => ({ newField: { ...prevState.newField, [e.target.name]: e.target.checked } }));
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  addKeyword = (keyword) => {
    const keywords = [ ...this.state.newField.keywords, [ keyword, false ]];
    this.setState((prevState) => ({ newField: { ...prevState.newField, keywords } }));
  }

  removeKeyword = (keyword) => {
    const keywords = this.state.newField.keywords.filter((kw) => kw !== keyword);
    this.setState((prevState) => ({ newField: { ...prevState.newField, keywords } }));
  }

  deleteField = (id) => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.fieldDelete(id).then((fields) => {
      if (fields.error) { return this.setState({ error: fields.error }); }
      this.setState((prevState) => ({
        fields: prevState.fields.filter((field) => field.id !== id),
      }));
    });
  }

  renderActions = (cell, row) => {
    if (row.is_default) {
      return <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {row.type === 'keywords'
          ? <Edit className="clickable" onClick={() => this.setEditMode(row.id)} />
          : '-'
        }
      </div>;
    }

    return <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Edit className="clickable" onClick={() => this.setEditMode(row.id)} />
        <Trash className="clickable" onClick={() => this.deleteField(row.id)} />
    </div>;
  }

  setEditMode = (id) => {
    const editMode = id ? this.state.fields.find((field) => field.id === id) : false;
    this.setState({ editMode });

    if (editMode) {
      const keywords = editMode.keywords.map((keyword) => [ keyword.text, keyword.deactivated, keyword.id ] );

      this.setState((prevState) => ({
        newField: {
          fieldName: editMode.key,
          fieldType: editMode.type,
          keywords,
          showInDocumentsTable: editMode.show_in_documents_table,
        },
      }));
    }
  }

  createNewField = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.fieldCreate(this.state.newField).then((result) => {
      if (!result.error) {
        this.setState({
          fields: [ ...this.state.fields, result ],
        });
        window.location.reload(); // TODO: Improve
      }
    });
  }

  updateField = (id) => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.fieldUpdate(id, this.state.newField).then((result) => {
      if (!result.error) {
        this.setState({ fields: result.data });
        window.location.reload(); // TODO: Improve
      }
    });
  }

  render() {
    const { show, hideModal } = this.props;
    const { editMode } = this.state;

    return <Modal size="lg" show={show} onHide={hideModal} onExit={() => this.setEditMode(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('documentType.manage-fields')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey="viewFields">
          <Tab eventKey="viewFields" className="document-type-fields" title={I18n.t('documentType.view')}>
              { editMode
                ? <Fragment>
                    <Form.Group>
                      <Form.Control
                        disabled={editMode.is_default}
                        as="select"
                        onChange={this.newFieldOnChange}
                        name="fieldType"
                        ref={editMode.type}
                        value={this.state.newField.fieldType}
                      >
                        <option>{I18n.t('documentType.select-type')}</option>
                        <option value={FieldTypes.keyword}>{I18n.t('documentType.keywords')}</option>
                        <option value={FieldTypes.text}>{I18n.t('documentType.text')}</option>
                        <option value={FieldTypes.date}>{I18n.t('documentType.date')}</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group>
                      <Form.Control 
                        readOnly={editMode.is_default}
                        type="text"
                        name="fieldName"
                        placeholder="Name"
                        value={this.state.newField.fieldName}
                        onChange={this.newFieldOnChange}
                      />
                    </Form.Group>
                    {this.state.newField.fieldType === FieldTypes.keyword &&
                      <Form.Group>
                        <AvainiaTagInput
                          keywords={editMode.keywords}
                          addKeyword={this.addKeyword}
                          removeKeyword={this.removeKeyword}
                          editKeyword={console.log} // TODO
                          targetField={editMode}
                        />
                      </Form.Group>
                    }

                    {/*<Form.Group>
                      <Form.Check
                        name="showInDocumentsTable"
                        type="checkbox"
                        checked={this.state.newField.showInDocumentsTable}
                        onChange={this.newFieldOnChangeCheckbox}
                        label={I18n.t('documentType.show-in-documents')}
                      />
                    </Form.Group>*/}
                    <Button variant="primary" onClick={() => this.updateField(editMode.id)}>
                      {I18n.t('general.save')}
                    </Button>
                    <Button variant="primary" style={{ marginLeft: '10px' }} onClick={() => this.setEditMode(false)}>
                      {I18n.t('general.cancel')}
                    </Button>
                  </Fragment>
                : <AvainiaTable
                    keyField="id"
                    data={this.state.fields}
                    rowClickHandler={null}
                    columns={[
                      {
                        dataField: 'key',
                        text: I18n.t('documentType.field-name'),
                        formatter: (cell, row) => {
                          return I18n.t(`fields.${cell}`, { defaultValue: cell });
                        },
                      },
                      {
                        dataField: 'type',
                        text: I18n.t('documentType.field-type'),
                        formatter: (cell, row) => {
                          return I18n.t(`documentType.${cell}`);
                        },
                      },
                      {
                        dataField: 'is_default',
                        text: I18n.t('documentType.default'),
                        formatter: (cell, row) => {
                          if (cell) {
                            return I18n.t('documentType.true');
                          }
                          return I18n.t('documentType.false');
                        },
                      },
                      {
                        dataField: 'actions',
                        text: I18n.t('documentType.actions'),
                        headerStyle: { width: '100px' },
                        formatter: this.renderActions,
                        headerAlign: 'center',
                      },
                    ]}
                  />
                }
          </Tab>

          <Tab eventKey="createField" title={I18n.t('documentType.create')}>
            <Form.Group>
              <Form.Control 
                as="select" 
                onChange={this.newFieldOnChange} 
                name="fieldType" 
                value={this.state.newField.fieldType}
              >
                <option>{I18n.t('documentType.select-type')}</option>
                <option value={FieldTypes.keyword}>{I18n.t('documentType.keywords')}</option>
                <option value={FieldTypes.text}>{I18n.t('documentType.text')}</option>
                <option value={FieldTypes.date}>{I18n.t('documentType.date')}</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Control 
                type="text" 
                name="fieldName" 
                placeholder={I18n.t('documentType.name')} 
                onChange={this.newFieldOnChange} 
              />
            </Form.Group>
            {this.state.newField.fieldType === FieldTypes.keyword &&
              <Form.Group>
                <AvainiaTagInput
                  keywords={false}
                  addKeyword={this.addKeyword}
                  removeKeyword={this.removeKeyword}
                />
              </Form.Group>
            }
            {/*<Form.Group>
              <Form.Check
                type="checkbox"
                name="showInDocumentsTable"
                checked={this.state.newField.showInDocumentsTable}
                onChange={this.newFieldOnChangeCheckbox}
                label={I18n.t('documentType.show-in-documents')}
              />
            </Form.Group>*/}
            <Button variant="primary" onClick={this.createNewField}>{I18n.t('general.save')}</Button>
          </Tab>

        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.hideModal}>{I18n.t('general.cancel')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default FieldsManageModal;
