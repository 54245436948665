import React, { Component } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import Error from '../../Error/Error.js';
import Loading from '../../Loading/Loading.js';
import LocalStorageService from '../../../../AvainiaTools/LocalStorageService.js';

class DocumentTypesDeleteModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: false,
    };
  }

  deleteDocumentType = (e) => {
    if (this.state.loading) { return; }
    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.documentTypesDelete(this.props.documentTypeId).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }
        this.props.deleteCallback();
        this.props.hideModal();
        this.setState({ error: false, loading: false });
      });
    });
  }

  clearModal = () => {
    this.setState({ error: false, loading: false });
    this.props.hideModal();
  }

  render() {
    return <Modal show={this.props.show} onHide={this.props.hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('documentType.delete-type')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {this.state.loading && <Loading inline />}
        {this.state.error && <Error inline error={this.state.error} />}
        {!this.state.error &&
          <Form.Group>
            <Form.Label>{I18n.t('documentType.confirm-delete')}</Form.Label>
          </Form.Group>
        }
      </Modal.Body>

      <Modal.Footer>
        {!this.state.error &&
          <Button variant="primary" onClick={this.deleteDocumentType}>{I18n.t('general.delete')}</Button>
        }
        <Button variant="secondary" onClick={this.clearModal}>{I18n.t('general.cancel')}</Button>
      </Modal.Footer>

    </Modal>;
  }
}

export default DocumentTypesDeleteModal;
