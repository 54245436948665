import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import MaterialSelectionsPanel from '../../multiview/Panels/MaterialSelectionsPanel.js';
import ProjectListPanel from '../../multiview/Panels/ProjectListPanel.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class Apartment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apartment: {},
      condominium: {},
      condominiumProjects: [],
      apartmenttemplates: [],
      deadlines: [],
      quotes: [],
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    const { condominiumId } = this.props.match.params;
    const { apartmentId } = this.props.match.params;
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.condominiumApartmentTemplatesGet(condominiumId).then((apartmenttemplates) => {
      if (apartmenttemplates.error) { return this.setState({ error: apartmenttemplates.error }); }

      api.condominiumApartmentGet(condominiumId, apartmentId).then((apartment) => {
        if (apartment.error) { return this.setState({ error: apartment.error }); }

        api.apartmentMaterialquotesGet(apartmentId).then((quotes) => {
          // eslint-disable-next-line no-param-reassign
          if (quotes.error) { quotes = []; /* this is because no quotes === 404 error. */ }

          api.condominiumGet(condominiumId).then((condominium) => {
            if (condominium.error) { return this.setState({ error: condominium.error }); }

            api.condominiumProjectsGet(condominiumId).then((condominiumProjects) => {
              if (condominiumProjects.error) { return this.setState({ error: condominiumProjects.error }); }

              // TODO: Performance improvement - create API endpoint - getDeadlinesForApartment(apartmentId)
              const promises = condominiumProjects.map((cp) => api.materialSelectionDeadlinesGet(cp.project.id));

              Promise.all(promises).then((results) => {
                let error = false;
                let deadlines = [];

                results.forEach((result) => {
                  if (result.error) { error = result.error; }
                  if (error) { return; }
                  deadlines = deadlines.concat(result); // Each deadline retains all relevant data, thankfully!
                });

                if (error) { return this.setState({ error }); }

                this.setState({
                  quotes,
                  apartmenttemplates,
                  apartment,
                  condominiumProjects,
                  condominium,
                  deadlines,
                  loading: false,
                }, this.context.resetTopbar);
              });
            });
          });
        });
      });
    });
  }

  getTemplate = (apt) => {
    return this.state.apartmenttemplates.find((tmpl) => tmpl.id === apt.apartment_template_id);
  }

  render() {
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    return (
      <div className="App-container AvainiaPanel">
        <div className="AvainiaPanel-panel AvainiaPanel-panel--header">
          <div className="AvainiaPanel-content">
            <div className="text">
              <h2>{I18n.t('views.condominiums.apartments.apartment')} {this.state.apartment.stairwell}{this.state.apartment.apartment_number}</h2>
              {/* TODO! This kind of finnish formatting might not work for international projects! */}
              <p>
                {I18n.t('views.condominiums.apartmenttemplate')}: {this.getTemplate(this.state.apartment).name}
              </p>
              <p>
                {this.state.condominium.street_address} {this.state.apartment.stairwell}{this.state.apartment.apartment_number}<br/>
                {this.state.condominium.postcode} {this.state.condominium.post_office}
              </p>
            </div>
          </div>
        </div>

        <ProjectListPanel projects={this.state.condominiumProjects.map((x) => x.project)} />

        <MaterialSelectionsPanel
          double
          quotes={this.state.quotes}
          apartment={this.state.apartment}
          projects={this.state.condominiumProjects.map((cp) => cp.project)}
          deadlines={this.state.deadlines}
        />

        <hr />
      </div>
    );
  }
}

export default withRouter(Apartment);
