import React, { Component } from "react";
import Sidebar from "../Sidebar/Sidebar.js";
import MobileMenu from "../Mobilemenu/MobileMenu.js";
import LocalStorageService from "../../../AvainiaTools/LocalStorageService.js";
import AvainiaLinks from "./AvainiaLinks.js";
import AvainiaCore from "avainia-core-api";

class AvainiaNav extends Component {
  render() {
    const config = this.props.configuration;
    const links = <AvainiaLinks cfg={config} />;
    const user = LocalStorageService.getUser();

    return (
      <>
        <Sidebar cfg={config} user={user} AvainiaLinks={links} />
        <MobileMenu configuration={config} AvainiaLinks={links} />
      </>
    );
  }
}

export default AvainiaNav;
