import React, { Component } from "react";
import { Button, Modal, Form, ListGroup } from "react-bootstrap";
import I18n from "i18n-js";
import DatePicker from "react-datepicker";
import AvainiaCore from "avainia-core-api";
import LocalStorageService from "../../../AvainiaTools/LocalStorageService";
import moment from "moment";
import { Trash } from "../Icon/Icon";

class ModalProgressReportAdd extends Component {
  constructor(props) {
    super(props);
    let selectTasks = [
      { name: "cabletrenchdig", unit: "m" },
      { name: "laidcable", unit: "m" },
      { name: "cablecoverinstalled", unit: "m" },
      { name: "eleccabinetinstalled", unit: "kpl" },
      { name: "transinstalled", unit: "kpl" },
      { name: "wedtileinstalled", unit: "kpl" },
      { name: "polesinstalled", unit: "kpl" },
      { name: "directdrilling", unit: "kpl" },
      { name: "asphaltinstalled", unit: "m2" },
      { name: "usedpetrol", unit: "l" },
    ];

    this.state = {
      date: new Date(),
      selectTasks: selectTasks,
      selectedTask: "cabletrenchdig",
      amount: "",
      unit: "m",
      tasks: [],
      id: 0,
    };
  }

  addProgressReport = () => {
    const payload = {
      date: moment(this.state.date).format("DD.MM.YYYY HH:mm"),
      numbers: JSON.stringify(this.state.tasks),
    };

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.progressNumbersAdd(this.props.project.id, payload).then((result) => {
      if (result.error) {
        return this.setState({ error: result.error });
      }
      this.props.onHide();
      this.props.onRefresh();
      this.setState({ tasks: [], amount: "", date: new Date() });
    });
  };

  addWorkProgress = () => {
    let tasks = this.state.tasks;

    if (this.state.amount !== "" && this.state.selectedTask !== "" && this.state.unit !== "") {
      tasks.push({
        id: this.state.id,
        slug: this.state.selectedTask,
        unit: this.state.unit,
        value: this.state.amount,
      });
    } else {
      if (!window.confirm("Täytä kaikki kentät")) {
        return;
      }
    }
    this.setState({ tasks: tasks });
    this.setState({ amount: "" });
    this.setState({ id: this.state.id + 1 });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSelectedTaskChange = (e) => {
    this.setState({ selectedTask: e.target.value });
    this.setState({ unit: this.state.selectTasks.find((x) => x.name === e.target.value).unit });
  };

  delete = (task) => {
    const tasks = this.state.tasks.filter((x) => x.id !== task.id);
    this.setState({ tasks: tasks });
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header>
          <Modal.Title>{I18n.t("views.progressnumbers.addreport")}</Modal.Title>
          <Button onClick={this.addProgressReport} style={{ float: "right" }}>
            {I18n.t(`views.progressnumbers.addreport`)}
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>{I18n.t(`views.infraproject.date`)}</Form.Label>
              <DatePicker
                className="datepicker-input"
                selected={this.state.date}
                onChange={(date) => this.setState({ date })}
                dateFormat="dd.MM.yyyy HH:mm"
                popperPlacement="auto-left"
              />

              <div style={{ display: "flex" }}>
                <div style={{ width: "1000px", marginRight: "1rem" }}>
                  <Form.Label>{I18n.t(`views.progressnumbers.tasks`)}</Form.Label>
                  <Form.Control as="select" onChange={this.onSelectedTaskChange}>
                    {this.state.selectTasks.map((task) => (
                      <option key={task.name} value={task.name}>
                        {I18n.t(`views.progressnumbers.${task.name}`)} ({I18n.t(`views.progressnumbers.${task.unit}`)})
                      </option>
                    ))}
                  </Form.Control>
                </div>
                <div style={{ flexGrow: "1" }}>
                  <Form.Label>{I18n.t(`views.progressnumbers.amount`)}</Form.Label>
                  <Form.Control type="text" onChange={this.onChange} name="amount" value={this.state.amount} />
                </div>
              </div>
              <Button onClick={this.addWorkProgress}>{I18n.t(`views.progressnumbers.addtasks`)}</Button>
            </Form.Group>
          </Form>
          <ListGroup>
            {this.state.tasks.map((task) => (
              <ListGroup.Item
                key={task.slug}
                style={{
                  display: "block",
                  padding: "0.5rem",
                  borderRadius: "0.5rem",
                  border: "3px solid #e7ebf2",
                  margin: "0.25rem",
                  backgroundColor: "#e7ebf2",
                }}
              >
                {I18n.t(`views.progressnumbers.${task.slug}`)}{" "}
                <b>
                  {task.value} {I18n.t(`views.progressnumbers.${task.unit}`)}
                </b>
                <Trash
                  className="trash-button"
                  onClick={() => {
                    this.delete(task);
                  }}
                />
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
      </Modal>
    );
  }
}
export default ModalProgressReportAdd;
