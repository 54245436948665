import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import I18n from 'i18n-js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import { SignOut } from '../Icon/Icon.js';
import { NavLink } from 'react-router-dom';

class MobileMenu extends Component {
  logout = (e) => {
    LocalStorageService.clearUser();
    window.location.reload(); // TODO: Improve
  }

  render() {
    return <Navbar bg="light" expand="lg" id="mobilemenu">
      <Navbar.Brand>{I18n.t('general.appname')}</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
        {this.props.AvainiaLinks}
        <NavLink to="/" className="Sidebar-user-logOut" onClick={this.logout}><SignOut />{I18n.t('views.sidebar.link-logout')}</NavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>;
  }
}

export default MobileMenu;
