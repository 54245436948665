import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Pusher from 'pusher-js';
import { pdfjs } from 'react-pdf';
import AvainiaNav from './multiview/AvainiaNav/AvainiaNav.js';
import Login from './mainviews/Login/Login.js';
import PrivacyPolicy from './mainviews/PrivacyPolicy/PrivacyPolicy.js';
import Error from './multiview/Error/Error.js';
import Footer from './multiview/Footer/Footer.js';
import Loading from './multiview/Loading/Loading.js';
import LoginStatusToast from './multiview/Toasts/LoginStatusToast.js';
import Utils from '../AvainiaTools/Utils.js';
import TopbarContextProvider from '../contexts/TopbarContext.js';
import AppMain from './AppMain.js';
import './App.scss';
import LocalStorageService from '../AvainiaTools/LocalStorageService.js';
import AvainiaCore from 'avainia-core-api';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      configuration: false,
      sessionToast: false,
      timeout: false,
      loginCheckInterval: false,
      interval: null,
      pageTitle: '',
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    const loginCheckInterval = this.createLoginChecker();
    window.Avainia.loginCheckInterval = loginCheckInterval;

    this.setState({ loginCheckInterval }, () => {
      // this.pusherSubscriber();
      Utils.Globals.getGlobals().then(() => {
        // Ensure that the configuration exists and works
        Utils.Configuration.getConfiguration().then((configuration) => {
          if (!configuration || configuration.error) { return this.setState({ loading: false, error: 1000 }); }
          this.setState({
            configuration,
            loading: false,
            pageTitle: document.title,
          });
        });
      });
    });
  }

  createLoginChecker = () => {
    return window.setInterval(() => {
      if (window.triggerLoginCheck) {
        window.clearInterval(this.state.loginCheckInterval);

        const localToken = LocalStorageService.getToken();
        const api = new AvainiaCore(localToken);
        api.loginCheck().then((result) => {
          if (result.error) {
            this.handleSessionTimeout(localToken);
          } else {
            // TODO: Replace with real log
            console.error('This message should not be visible ever. Check why this is visible');
          }
        });
      }
    }, 1000);
  }

  loginCallback = () => {
    this.setState({
      timeout: false,
      sessionToast: false,
    }, this.stopBlink);
  }

  handleSessionTimeout = (token) => {
    const localToken = Utils.LocalStorage.getToken();
    if (token === localToken) {
      Utils.LocalStorage.clearUser();
      this.setState({ timeout: true }, this.stopBlink);
    }
  }

  handlePusherEvent = (event) => {
    if (event.message === 'SESSION_WILL_EXPIRE') {
      const interval = setInterval(this.blinkTitle, 1000);
      this.setState({ sessionToast: true, interval });
    } else if (event.message === 'SESSION_HAS_EXPIRED') {
      this.handleSessionTimeout(event.token);
    }
  }

  pusherSubscriber = () => {
    Pusher.logToConsole = false;
    const user = Utils.LocalStorage.getUser();

    if (user) {
      const userChannel = `private-App.User.${user.id}`;

      const pusher = new Pusher('67e6fecb53b597ce117a', {
        cluster: 'eu',
        authEndpoint: `${process.env.REACT_APP_API_HOST}/api/v1/auth/pusher`,
        auth: {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        },
      });

      const channel = pusher.subscribe(userChannel);
      channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', this.handlePusherEvent);
    }
  }

  blinkTitle = () => {
    const { pageTitle } = this.state;
    const attentionMsg = `\u26A0 ${pageTitle}`;

    if (document.title === attentionMsg) {
      document.title = pageTitle;
    } else {
      document.title = attentionMsg;
    }
  }

  stopBlink = () => {
    clearInterval(this.state.interval);
    document.title = this.state.pageTitle;
  }

  render() {
    const { configuration, loading, error, timeout, sessionToast } = this.state;
    const user = Utils.LocalStorage.getUser();

    if (error || loading) {
      return <div className="App">
        <Error inline error={error} />
        <Loading inline hide={!loading} />
      </div>;
    }

    if (!user || timeout) {
      return (
        <div className="App">
          <Switch>
            <Route path="/privacy" render={() => <PrivacyPolicy configuration={configuration} />} />
            <Route path="/activate/:token/:email" render={() => <Login configuration={configuration} activate />} />
            <Route path="/forgot/:token/:email" render={() => <Login configuration={configuration} forgot />} />
            <Route render={() => <Login configuration={configuration} timeout={timeout} />} />
          </Switch>
        </div>
      );
    }

    return (
      <div className="App">
        <AvainiaNav configuration={configuration} />

        <TopbarContextProvider>
          <AppMain configuration={configuration} />
        </TopbarContextProvider>

        <Footer configuration={configuration} />
        {sessionToast && <LoginStatusToast loginCallback={this.loginCallback} />}
      </div>
    );
  }
}

export default App;
