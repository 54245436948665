import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Form, FormControl, InputGroup, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

class EditableField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      changed: false,
    };
  }

  setValue = (value) => {
    this.setState({ value, changed: true });
  }

  formatDate = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    return formattedDate;
  }

  handleChange = (id, date) => {
    const { updateDateFieldData } = this.props;

    this.setState({
      startDate: date,
      changed: true,
    }, () => {
      if (date === null) {
        updateDateFieldData(id, "");
      } else {
        updateDateFieldData(id, this.formatDate(date));
      }
    });
  };

  getFieldValue = (field, document) => {
    if (field.type === 'keywords') {
      const fieldValue = document.keywords.find((f) => f.field_id === field.id);
      return fieldValue;
    }

    if (field.type === 'text') {
      return document.fields.find((f) => f.field_id === field.id);
    }
  };

  render() {
    const { field, updateFieldData, editCoords, newPosition, document } = this.props;
    const fieldValue = this.getFieldValue(field, document);
    const readOnly = field.is_automatic;

    if (field.type === 'keywords') {
      const options = field.keywords
        .sort((a, b) => { return a.text > b.text ? 1 : -1; })
        .map((kw) => kw.text !== "-" && !kw.deactivated &&
          <option disabled={kw.deactivated} key={kw.id} value={kw.text}>{kw.text}</option>
        );
      options.push(<option key="default" value=""></option>);

      return <Form.Group>
        <Form.Label>{I18n.t(`fields.${field.key}`, { defaultValue: field.key })}</Form.Label>
        <FormControl
          as="select"
          defaultValue={fieldValue ? fieldValue.text : ''}
          onChange={(e) => {
            this.setValue(e.target.value);
            updateFieldData(field.id, e.target.value);
          }}
        >
          { options }
        </FormControl>
      </Form.Group>;
    }

    if (field.type === 'text') {
      const fieldIsGps = field.key === 'gps_coordinates';
      const fieldIsAccuracy = field.key === 'gps_accuracy';

      let inputValue = this.state.value;

      if (newPosition && fieldIsGps) {
        inputValue = newPosition;
      }
      if (newPosition && fieldIsAccuracy) {
        inputValue = 0;
      }

      if (!inputValue && fieldValue && !this.state.changed) {
        inputValue = fieldValue.text;
      }

      if (fieldIsGps) {
        return <Form.Group>
          <Form.Label>{I18n.t(`fields.${field.key}`, { defaultValue: field.key })}</Form.Label>
          <InputGroup>
            <FormControl
              type="text"
              value={inputValue}
              readOnly={readOnly}
              onChange={(e) => {
                this.setValue(e.target.value);
                updateFieldData(field.id, e.target.value);
              }}
            />
            <InputGroup.Append>
              <Button
                variant="outline-secondary"
                onClick={editCoords}
              >
                {I18n.t('general.edit')}
              </Button>
            </InputGroup.Append>
          </InputGroup>
          {this.props.isEditCoords && fieldIsGps && <div id="editCoordsModalMap"></div>}
        </Form.Group>;
      }

      return <Form.Group>
        <Form.Label>{I18n.t(`fields.${field.key}`, { defaultValue: field.key })}</Form.Label>
        <FormControl
          type="text"
          value={inputValue}
          readOnly={readOnly}
          onChange={(e) => {
            this.setValue(e.target.value);
            updateFieldData(field.id, e.target.value);
          }}
        />
        {this.props.isEditCoords && fieldIsGps && <div id="editCoordsModalMap"></div>}
      </Form.Group>;
    }

    if (field.type === 'date') {
      // Get a previously saved date from db
      const date = document.fields.find((f) => f.field_id === field.id);

      let inputValue = this.state.value;

      if (this.state.startDate) {
        inputValue = moment(this.state.startDate).format('YYYY.MM.DD HH:mm');
      } else if (date && date.date !== null) {

        // if found, format date as follows and set it as inputValue
        inputValue = moment(date.date);
      }
      else {
        inputValue = null;
      }

      let selectedDate = null;

      if (this.state.startDate) {
        selectedDate = this.state.startDate;
      }
      if (!this.state.startDate && this.state.changed) {
        selectedDate = null;
      }
      else {
        if (inputValue === null) {
          selectedDate = null;
        }
        else {
          selectedDate = moment(inputValue)._d;
        }
      }

      return <Form.Group>
        <Form.Label>{I18n.t(`fields.${field.key}`, { defaultValue: field.key })}</Form.Label>
        <DatePicker
          className="form-control"
          readOnly={readOnly}
          todayButton={I18n.t('general.today')}
          selected={selectedDate}
          onChange={(newDate) => this.handleChange(field.id, newDate) }
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="yyyy.MM.dd HH:mm"
        />
      </Form.Group>;
    }
    return false;
  }
}

export default EditableField;
