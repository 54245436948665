import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Admin from "./mainviews/Admin/Admin.js";
import Apartment from "./mainviews/Condominiums/Apartment.js";
import Companies from "./mainviews/Companies/Companies.js";
import SearchResults from "./mainviews/SearchResults/SearchResults.js";
import CompanyManagement from "./mainviews/CompanyManagement/CompanyManagement.js";
import Condominium from "./mainviews/Condominiums/Condominium.js";
import Condominiums from "./mainviews/Condominiums/Condominiums.js";
import PrivacyPolicy from "./mainviews/PrivacyPolicy/PrivacyPolicy.js";
import Products from "./mainviews/Products/Products.js";
import Permissions from "./mainviews/Permissions/Permissions.js";
import Project from "./mainviews/Projects/Project/Project.js";
import Projects from "./mainviews/Projects/Projects.js";
import Translations from "./mainviews/Translations/Translations.js";
import DocumentTypes from "./mainviews/DocumentTypes/DocumentTypes.js";
import Notifications from "./mainviews/Notifications/NotificationsAdmin.js";
import NotificationsArchive from "./multiview/Notifications/NotificationsArchive.js";
import Mobilelinks from "./mainviews/Mobilelinks/Mobilelinks.js";
import Import from "./mainviews/Import/Import.js";
import SummaryViews from "./mainviews/Summary/SummaryViews.js";
import SummaryView from "./mainviews/Summary/SummaryView.js";
import SummaryCompanies from "./mainviews/Summary/SummaryCompanies.js";

import Topbar from "./multiview/Topbar/Topbar.js";

import Utils from "../AvainiaTools/Utils.js";
import AvainiaPermissions from "../AvainiaTools/AvainiaPermissions.js";
import { TopbarContext } from "../contexts/TopbarContext.js";

export default class AppMain extends Component {
  static contextType = TopbarContext;

  render() {
    const user = Utils.LocalStorage.getUser();

    //! Note: these need to match the ones in AvainiaNav!
    const companiesManage = user.hasPermission(AvainiaPermissions.CompaniesManage);
    const admin = user && (user.hasRole("owner") || user.hasRole("super"));
    const myCompany = user && (user.hasRole("manager") || user.hasRole("super"));
    const ownerManager = user && user.isOwnerManager();
    //const notificationsManager = user.hasPermission(AvainiaPermissions.NotificationsAccess);

    const { configuration } = this.props;

    return (
      <div className="App-main">
        <Topbar configuration={configuration} />
        <Switch>
          <Route path="/import" render={() => <Import configuration={configuration} />} />
          <Route path="/search" render={() => <SearchResults configuration={configuration} />} />
          <Route path="/privacy" render={() => <PrivacyPolicy configuration={configuration} />} />
          <Route
            path="/project-archive"
            render={() => <Projects key="projects-archive" inactive="1" configuration={configuration} />}
          />
          <Route
            path="/projects"
            render={() => <Projects key="projects" inactive="0" configuration={configuration} />}
          />
          <Route path="/project/:id/:view" render={() => <Project configuration={configuration} />} />
          <Route path="/project/:id" render={() => <Project />} />
          <Route path="/products" render={() => <Products />} />
          <Route path="/condominiums" render={() => <Condominiums />} />
          <Route path="/condominium/:condominiumId/apartments/:apartmentId" render={() => <Apartment />} />
          <Route path="/condominium/:id" render={() => <Condominium />} />
          {admin && <Route path="/translations" render={() => <Translations />} />}
          {ownerManager && (
            <Route path="/defaultfolders/:view" render={() => <Admin configuration={configuration} />} />
          )}
          {ownerManager && <Route path="/defaultfolders/" render={() => <Admin configuration={configuration} />} />}
          {ownerManager && <Route path="/admin" render={() => <Redirect to="/defaultfolders" />} />}
          {ownerManager && <Route path="/document-types" render={() => <DocumentTypes />} />}
          {ownerManager && <Route path="/permissions" render={() => <Permissions />} />}
          {ownerManager && <Route path="/summaryviews" render={() => <SummaryViews />} />}
          {ownerManager && <Route path="/summaryview/:id" render={() => <SummaryView  configuration={configuration} />} />}
          {ownerManager && <Route path="/summarycompanies" render={() => <SummaryCompanies />} />}
          {companiesManage && <Route path="/companies" render={() => <Companies configuration={configuration} />} />}
          {myCompany && (
            <Route path="/company-management/:id" render={() => <CompanyManagement configuration={configuration} />} />
          )}
          {ownerManager && <Route path="/notifications" render={() => <Notifications />} />}
          <Route path="/view-notifications" render={() => <NotificationsArchive />} />
          {ownerManager && <Route path="/mobilelinks" render={() => <Mobilelinks />} />}
          <Route path="/" render={() => <Redirect to="/projects" />} />
        </Switch>
      </div>
    );
  }
}
