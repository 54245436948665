import React, { Component } from 'react';
import { Button, Modal, InputGroup, FormControl } from 'react-bootstrap';
import I18n from 'i18n-js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import AvainiaCore from 'avainia-core-api';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import { Check, Close, Trash } from '../Icon/Icon.js';
import './ModalProjectStatusEdit.scss';
import { Popover } from 'react-bootstrap';

export default class ModalProjectStatusEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payload: {
        id: this.props.activeProjectStatus.id,
        name: this.props.activeProjectStatus.name,
        slug: this.props.activeProjectStatus.slug,
        is_active: this.props.activeProjectStatus.is_active,
        parent_id: this.props.activeProjectStatus.parent_id,
      },
      projectStatuses: this.props.projectStatuses,
      projects: {},
      loading: false,
      error: false,
    };
  }

  componentDidMount = () => {
    const { activeProjectStatus } = this.props;
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.projectsWithStatusGet(this.state.payload.id).then((projects) => {
      if (projects.error) { return this.setState({ error: projects.error }); }

      this.setState({activeProjectStatus, loading: false, projects },  () => {
        this.listPossibleParents(activeProjectStatus.id)
      })
    });
  }

  onChange = (e) => {
    let value = e.target.value;
    if (e.target.name === "parent_id") {
      parseInt(value);
    }

    this.setState({
      payload:  {...this.state.payload, [e.target.name]: value },
    })
  }

  toggleActiveStatus = (value) => {
    this.setState({
      payload:  {...this.state.payload, is_active: value },
    })
  }

  save = () => {
    if (this.state.loading) { return; }

    const payload = this.state.payload;

    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.projectStatusEdit(payload, this.state.payload.id).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }

        this.props.projectStatusCallback(result);
      });
     });
  }

  listPossibleParents = (id) => {
    const children = this.state.projectStatuses.filter((x) => x.parent_id === id);
    let descendents = children;

    children.forEach((x) => {
      descendents = descendents.concat(this.listPossibleParents(x.id))
    })

    this.setState({invalidParents: descendents})
    return descendents;
  }

  deleteStatus = () => {
    const {projects, invalidParents} = this.state;


    // check if in use
    if ( projects && Object.keys(projects).length !== 0) {
      this.setState({ error: 41 });
      return;
    }

    // check if has children
    if (invalidParents && invalidParents.length !== 0) {
      this.setState({ error: 42 });
      return;
    }

    if (this.state.activeProjectStatus.is_default) {
      this.setState({ error: 43 });
      return;
    }

    if (!window.confirm(I18n.t('projectStatuses.confirm-delete'))) { return; }

    // if ok
    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.projectStatusDelete(this.state.payload.id).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }
        this.props.projectStatusCallback(result);
      });
     });
  }

  render() {
    const { error, loading, payload, invalidParents } = this.state;
    const { onHide, show, projectStatuses } = this.props;
    const possibleParents = [];

    const popover = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">{I18n.t('projectStatuses.status-active-in-projects')}</Popover.Title>
        <Popover.Content>
          {this.state.projects && Object.keys(this.state.projects).length !== 0 && Object.keys(this.state.projects).map((key) => { return <li>{this.state.projects[key]}</li>})}
        </Popover.Content>
      </Popover>
    );

      /* eslint-disable-next-line array-callback-return */
    invalidParents && projectStatuses.filter((pp) => {
      if (!this.state.invalidParents.find(el => el.id === pp.id)){
       possibleParents.push(pp)
    }
  });

    return (
      <Modal className="modal-project-statuses" show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('projectStatuses.edit-status')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Error inline error={error} info={error && error === 41} popoverInfo={popover}/>
          <Loading inline loading={loading} />

          {!loading && (
            <>
              <label htmlFor="slug" className="status-label">{I18n.t('projectStatuses.status-slug')}</label>
              <InputGroup>
                <FormControl
                  type="text"
                  onChange={this.onChange}
                  name="slug"
                  value={payload.slug}
                  disabled
                />
              </InputGroup>
              <label htmlFor="name" className="status-label">{I18n.t('projectStatuses.status-name')}</label>
              <InputGroup>
                <FormControl
                  type="text"
                  onChange={this.onChange}
                  name="name"
                  value={payload.name}
                />
              </InputGroup>

              <label htmlFor="parent_id" className="status-label">{I18n.t('projectStatuses.status-parent')}</label>
              <InputGroup>
                <FormControl
                  as="select"
                  onChange={this.onChange}
                  name="parent_id"
                  value={payload.parent_id}
                >
                <option key="0" value="0"> - </option>
                {possibleParents && possibleParents.map((parent) => {
                  if (parent.id !== this.state.activeProjectStatus.id) {
                    return <option key={parent.id} value={parent.id}> {parent.name} </option>;
                  } return null;
                })}
                </FormControl>
              </InputGroup>

              <label htmlFor="is_active" className="status-label">{I18n.t('projectStatuses.status-active')}</label>
              <InputGroup>
              {payload.is_active &&
                <Check
                  className="project-status icon clickable active"
                  onClick={() => this.toggleActiveStatus(0)}
                  title={I18n.t('projectStatuses.click-to-deactivate')}
                />
              }
              {!payload.is_active &&
                <Close
                  className="role-not-assigned icon clickable inactive"
                  onClick={() => this.toggleActiveStatus(1)}
                  title={I18n.t('projectStatuses.click-to-activate')}
                />
              }
              </InputGroup>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" disabled={loading} onClick={this.save}>{I18n.t('general.modal-form-save')}</Button>
          <Button className="trash" variant="danger" onClick={() => { this.deleteStatus(); }}>
            <Trash />
          </Button>
          <Button variant="secondary" onClick={onHide}>{I18n.t('general.modal-close')}</Button>
        </Modal.Footer>
      </Modal>
    );
    }
  }
