import React from 'react';
import { InputGroup, FormControl, Row, Col } from 'react-bootstrap';
import I18n from 'i18n-js';
import { Search } from '../Icon/Icon.js';

function AvainiaTableHeading(props) {
  return (
    <Row className="project-heading">
      <Col lg="5">
        <h1>{props.title}</h1>
      </Col>
      <Col lg="7">
        <Row>
          <Col xl="6" lg="4">
          {props.onChange ?
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <Search style={{ fontSize: '22px' }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="text"
                placeholder={I18n.t('views.projects.search-projects')}
                name="search"
                onChange={props.onChange}
              />
            </InputGroup>
            : '' }
          </Col>
          <Col xl="6" lg="8">
            {props.button ? props.button : ''}
          </Col>
        </Row>
        {props.secondRow && 
          <Row>
            <Col xl={{ span: 6, offset: 6 }} lg={{ span: 4, offset: 4 }} >
              {props.secondRow ? props.secondRow : ''}
            </Col>
          </Row>
        }
      </Col>
    </Row>
  );
}

export default AvainiaTableHeading;
