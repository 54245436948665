import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Button, InputGroup, Form, Row, Col } from 'react-bootstrap';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import AvainiaTable from '../../multiview/AvainiaTable/AvainiaTable.js';
import CondominiumUsersModal from './CondominiumUsersModal.js';

const Modals = {
  editUser: 1,
};

const DemoUsers = [
  {
    id: 1,
    name: 'Test user',
    email: 'test@test.fi',
    phone: '+358 000 000 00',
    type: 'Tenant',
    specifier: 'A1',
  },
  {
    id: 2,
    name: 'Test user 2',
    email: 'test2@test.fi',
    phone: '+358 100 000 00',
    type: 'Stakeholder',
    specifier: 'A1',
  },
];

class CondominiumUsersTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      usersFiltered: [],
      userToEdit: null,
      search: '',
      modal: 0,
      loading: false,
      error: false,
    };
  }

  componentDidMount() {
    this.setState({ users: DemoUsers, usersFiltered: DemoUsers, loading: false });
  }

  search = (e) => {
    const { users } = this.state;
    const term = e.target.value;
    const searchable = ['name', 'email', 'type'];

    const searchResults = users.filter((obj) => Object.keys(obj).filter((key) => searchable.includes(key))
      .some((key) => {
        return obj[key].toLowerCase().includes(term);
      }));

    this.setState({ usersFiltered: (searchResults || []) });
  }

  closeModal = (e) => {
    this.setState({ modal: 0, userToEdit: null });
  }

  addCallback = (user) => {
    const { users } = this.state;
    users.push(user);
    this.setState({ users });
  }

  editCallback = (user) => {
    const users = this.state.users.filter((x) => x.id !== this.state.userToEdit.id);

    users.push(user);
    users.sort((a, b) => { return a.id > b.id ? 1 : -1; });
    this.setState({ users, usersFiltered: users });
  }

  removeCallback = (id) => {
    const users = this.state.users.filter((x) => x.id !== id);
    this.setState({ users, usersFiltered: users });
  }

  render() {
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    return <div className="App-container">
      <Row className="align-items-center mb-5">
        <Col xs={6}>
          <h1 className="App-pageTitle mb-0">{I18n.t('views.condominiums.users.title')}</h1>
        </Col>
        <Col xs={4}>
          <InputGroup>
            <Form.Control
              placeholder={I18n.t('views.condominiums.users.search')}
              name="Search"
              type="text"
              onChange={this.search}
            />
          </InputGroup>
        </Col>
        <Col xs={2}>
          <Button
            block
            variant="primary"
            onClick={() => { this.setState({ modal: Modals.editUser, userToEdit: { id: Date.now(), new: true } }); }}>
            {I18n.t('views.condominiums.users.add')}
          </Button>
        </Col>
      </Row>

      <AvainiaTable
        keyField='email'
        data={this.state.usersFiltered}
        rowClickHandler={(rowData) => { this.setState({ userToEdit: rowData, modal: Modals.editUser }); }}
        columns={[
          { dataField: 'name', text: I18n.t('views.condominiums.users.name') },
          { dataField: 'email', text: I18n.t('views.condominiums.users.name') },
          { dataField: 'type', text: I18n.t('views.condominiums.users.type') },
          { dataField: 'specifier', text: I18n.t('views.condominiums.users.specifier') },
        ]}
      />

      {this.state.modal === Modals.editUser &&
        <CondominiumUsersModal
          addCallback={this.addCallback}
          editCallback={this.editCallback}
          removeCallback={this.removeCallback}
          userToEdit={this.state.userToEdit} onHide={this.closeModal}
        />
      }
    </div>;
  }
}

export default CondominiumUsersTab;
