import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Rnd } from 'react-rnd';
import './ModalViewDocument.scss';
import ModalViewDocument from './ModalViewDocument.js';

class ModalViewGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      x: 10,
      y: 10,
      viewportWidth: 100,
      viewportHeight: 100,
      width: 380,
      height: 780,
    };
  }

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    if (!this.rnd) {
      return;
    }

    const defaultValues = this.getRndDefaultValues();
    this.rnd.updateSize({
      width: defaultValues.width,
      height: defaultValues.height,
    });

    this.rnd.updatePosition({ x: defaultValues.x, y: defaultValues.y });
    this.setState({
      x: defaultValues.x,
      y: defaultValues.y,
      width: defaultValues.width,
      height: defaultValues.height,
    });
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateWindowDimensions);
  };

  getRndDefaultValues = () => {
    const defaultValues = {
      width: 380,
      height: 780,
      x: 10,
      y: 10,
    };

    if (window[`AvainiaDetailSize_${this.props.parent}`]) {
      defaultValues.width =
        window[`AvainiaDetailSize_${this.props.parent}`].width;
      defaultValues.height =
        window[`AvainiaDetailSize_${this.props.parent}`].height;
    }

    if (window[`AvainiaDetailPosition_${this.props.parent}`]) {
      defaultValues.x = window[`AvainiaDetailPosition_${this.props.parent}`].x;
      defaultValues.y = window[`AvainiaDetailPosition_${this.props.parent}`].y;
    }

    this.setState({ width: defaultValues.width, height: defaultValues.height });

    return defaultValues;
  };

  deactivateEditMode = (e) => {
    console.log('group deactive');
    this.setState(
      {
        editMode: false,
      },
      () => {
        window.editmap = null;
      }
    );
  };

  activateEditMode = (id) => {
    console.log('group active, id: ', id);

    this.setState({
      editMode: id,
    });
  };

  updateWindowDimensions = () => {
    this.setState({
      viewportWidth: window.innerWidth,
      viewportHeight: window.innerHeight,
    });
  };

  render() {
    const { editMode } = this.state;
    const {
      activeGroupImages,
      isMobile,
      activeImage,
      parent,
      hideModal,
      fields,
      phases,
      project,
      subjects,
      states,
      configuration,
    } = this.props;

    return (
      <div
        id="RndWrapper"
        style={{
          position: 'fixed',
          display: (isMobile && !editMode) || editMode ? 'none' : 'contents',
          top: 1,
          left: 1,
          zIndex: 9220,
          overflowY: 'hidden',
        }}
      >
        <Rnd
          id="ModalViewDocument"
          ref={(c) => {
            this.rnd = c;
          }}
          position={{ x: this.state.x, y: this.state.y }}
          onResizeStop={(event, direction, element, delta, position) => {
            window[`AvainiaDetailSize_${parent}`] = {
              width: element.style.width,
              height: element.style.height,
            };
            window[`AvainiaDetailPosition_${parent}`] = {
              x: position.x,
              y: position.y,
            };
            this.setState({
              width: parseInt(element.style.width, 10),
              height: parseInt(element.style.height, 10),
            });
          }}
          onDragStop={(event, dataObject) => {
            let posX = dataObject.x;
            let posY = dataObject.y;

            if (dataObject.x < 0) {
              posX = 0;
            }
            if (dataObject.y < 0) {
              posY = 0;
            }
            if (
              dataObject.x >
              this.state.viewportWidth -
                parseInt(dataObject.node.style.width, 10)
            ) {
              posX =
                this.state.viewportWidth -
                parseInt(dataObject.node.style.width, 10);
            }
            if (
              dataObject.y >
              this.state.viewportHeight -
                parseInt(dataObject.node.style.height, 10)
            ) {
              posY =
                this.state.viewportHeight -
                parseInt(dataObject.node.style.height, 10);
            }
            window[`AvainiaDetailSize_${this.props.parent}`] = {
              width: dataObject.node.style.width,
              height: dataObject.node.style.height,
            };
            window[`AvainiaDetailPosition_${this.props.parent}`] = {
              x: posX,
              y: posY,
            };
            this.setState({ x: posX, y: posY });
          }}
          minWidth="110"
          minHeight="120"
          maxHeight="95vw"
          maxWidth="95vw"
          style={{
            border: '3px solid #ccc',
            borderRadius: '3px',
            zIndex: 9221,
            margin: 10,
            position: 'fixed',
          }}
          size={{ width: this.state.width, height: this.state.height }}
        >
          <div className="group-wrapper">
            {activeImage && isMobile && (
              <Modal
                className="mobile-document-view"
                show={editMode ? false : true}
                onHide={this.props.onHide}
              >
                <ModalViewDocument
                  isMobile={isMobile}
                  parent="infraproject"
                  key={activeImage.id}
                  documentActive={activeImage}
                  isGroup={false}
                  documentEditedCallback={() => {
                    document.location.reload();
                  }}
                  documentDeletedCallback={() => {
                    document.location.reload();
                  }}
                  deactivateEditMode={() => this.deactivateEditMode()}
                  activateEditMode={(id) => this.activateEditMode(id)}
                  hideModal={hideModal}
                  fields={fields}
                  phases={phases}
                  project={project}
                  subjects={subjects}
                  states={states}
                  show={true}
                  configuration={configuration}
                  editMode={this.state.editMode}
                />
              </Modal>
            )}

            {activeImage && (
              <ModalViewDocument
                isMobile={isMobile}
                parent="infraproject"
                key={activeImage.id}
                documentActive={activeImage}
                isGroup={false}
                documentEditedCallback={() => {
                  document.location.reload();
                }}
                documentDeletedCallback={() => {
                  document.location.reload();
                }}
                deactivateEditMode={() => this.deactivateEditMode()}
                activateEditMode={(id) => this.activateEditMode(id)}
                hideModal={hideModal}
                fields={fields}
                phases={phases}
                project={project}
                subjects={subjects}
                states={states}
                show={true}
                configuration={configuration}
                editMode={this.state.editMode}
              />
            )}

            {activeGroupImages && isMobile && (
              <Modal
                className="mobile-document-view"
                show={editMode ? false : true}
                onHide={this.props.onHide}
              >
                {activeGroupImages.map((image) => {
                  if (editMode && editMode === image.id) {
                    return (
                      <ModalViewDocument
                        isMobile={isMobile}
                        parent="infraproject"
                        key={image.id}
                        documentActive={image}
                        isGroup={true}
                        documentEditedCallback={() => {
                          document.location.reload();
                        }}
                        documentDeletedCallback={() => {
                          document.location.reload();
                        }}
                        deactivateEditMode={() => this.deactivateEditMode()}
                        activateEditMode={(id) => this.activateEditMode(id)}
                        hideModal={hideModal}
                        fields={fields}
                        phases={phases}
                        project={project}
                        subjects={subjects}
                        states={states}
                        show={true}
                        configuration={configuration}
                        editMode={this.state.editMode}
                      />
                    );
                  }

                  if (!editMode) {
                    return (
                      <ModalViewDocument
                        isMobile={isMobile}
                        parent="infraproject"
                        key={image.id}
                        documentActive={image}
                        isGroup={true}
                        documentEditedCallback={() => {
                          document.location.reload();
                        }}
                        documentDeletedCallback={() => {
                          document.location.reload();
                        }}
                        deactivateEditMode={() => this.deactivateEditMode()}
                        activateEditMode={(id) => this.activateEditMode(id)}
                        hideModal={hideModal}
                        fields={fields}
                        phases={phases}
                        project={project}
                        subjects={subjects}
                        states={states}
                        show={true}
                        configuration={configuration}
                        editMode={this.state.editMode}
                      />
                    );
                  }
                })}
              </Modal>
            )}
            {activeGroupImages &&
              activeGroupImages.map((image) => {
                if (editMode && editMode === image.id) {
                  return (
                    <ModalViewDocument
                      isMobile={isMobile}
                      parent="infraproject"
                      key={image.id}
                      documentActive={image}
                      isGroup={true}
                      documentEditedCallback={() => {
                        document.location.reload();
                      }}
                      documentDeletedCallback={() => {
                        document.location.reload();
                      }}
                      deactivateEditMode={() => this.deactivateEditMode()}
                      activateEditMode={(id) => this.activateEditMode(id)}
                      hideModal={hideModal}
                      fields={fields}
                      phases={phases}
                      project={project}
                      subjects={subjects}
                      states={states}
                      show={true}
                      configuration={configuration}
                      editMode={this.state.editMode}
                    />
                  );
                }

                if (!editMode) {
                  return (
                    <ModalViewDocument
                      isMobile={isMobile}
                      parent="infraproject"
                      key={image.id}
                      documentActive={image}
                      isGroup={true}
                      documentEditedCallback={() => {
                        document.location.reload();
                      }}
                      documentDeletedCallback={() => {
                        document.location.reload();
                      }}
                      deactivateEditMode={() => this.deactivateEditMode()}
                      activateEditMode={(id) => this.activateEditMode(id)}
                      hideModal={hideModal}
                      fields={fields}
                      phases={phases}
                      project={project}
                      subjects={subjects}
                      states={states}
                      show={true}
                      configuration={configuration}
                      editMode={this.state.editMode}
                    />
                  );
                }
              })}
          </div>
        </Rnd>
      </div>
    );
  }
}

export default ModalViewGroup;
