import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import I18n from 'i18n-js';
import { Form, Button, Tab, Row } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import AvainiaTable from '../../multiview/AvainiaTable/AvainiaTable.js';
import ModalCompanyCreate from '../../multiview/Modals/ModalCompanyCreate.js';
import ModalCompanyEdit from '../../multiview/Modals/ModalCompanyEdit.js';
import PanelUserCategories from '../../multiview/Panels/PanelUserCategories.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import { TopbarContext } from '../../../contexts/TopbarContext.js';
import AvainiaTableHeading from '../../multiview/AvainiaTable/AvainiaTableHeading.js';
import { AngleLeft, Trash } from '../../multiview/Icon/Icon.js';

const Modals = {
  editCompany: 1,
  createCompany: 2,
};

const Tabs = {
  companies: "companies",
  usertypes: "usertypes",
  allusers: "allusers",
  inactiveusers: "inactiveusers",
};

class Companies extends Component {
  static contextType = TopbarContext;

  constructor(props) {
    super(props);

    this.state = {
      users: [],
      inactiveusers: [],
      companies: [],
      companiesFiltered: [],
      userCategories: [],
      companyToEdit: false,
      search: '',
      modal: false,
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.companiesGet().then((companies) => {
      if (companies.error) {
        return this.setState({ error: companies.error });
      }

      api.userCategoriesGet().then((userCategories) => {
        if (userCategories.error) { return this.setState({ error: userCategories.error }); }
      });

      this.getAllUsers();

      this.setState({
        companies,
        companiesFiltered: companies,
        loading: false,
      }, () => {
        const tabs = [
          <Tab key={Tabs.companies} eventKey={Tabs.companies} title={I18n.t('views.companies.companies')} />,
          <Tab key={Tabs.usertypes} eventKey={Tabs.usertypes} title={I18n.t('usercategories.heading')} />,
          <Tab key={Tabs.allusers} eventKey={Tabs.allusers} title={I18n.t('general.users')} />,
          <Tab key={Tabs.inactiveusers} eventKey={Tabs.inactiveusers} title={I18n.t('general.inactiveusers')} />,
        ];
        this.context.setTopbar(tabs, false);
      });
    });
  }

  getAllUsers = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    this.setState({loading: true});

    api.usersGet().then((users) => {
      if (users.error) { return this.setState({ error: users.error }); }

      let enabled_users = users.filter((usr) => {
        return usr.enabled ? true : false;
      });

      let disabled_users = users.filter((usr) => {
        return usr.enabled ? false : true;
      });

      this.setState({users: enabled_users, inactiveusers: disabled_users, loading: false});
    });
  }

  search = (e) => {
    const { companies } = this.state;
    const term = e.target.value;
    const searchableFields = ['name', 'code', 'type'];

    const companiesFiltered = companies.filter((obj) => {
      return Object.keys(obj)
        .filter((key) => searchableFields.includes(key))
        .some((key) => {
          return obj[key].toLowerCase().includes(term.toLowerCase());
        });
    });

    this.setState({ companiesFiltered });
  };

  hideModal = (e) => {
    this.setState({ modal: 0, companyToEdit: null });
  };

  addCompanyCallback = (company) => {
    const { companies } = this.state;
    companies.push(company);
    this.setState({ companies });
  };

  editCompanyCallback = (company) => {
    const companies = this.state.companies.filter(
      (x) => x.id !== this.state.companyToEdit.id,
    );

    companies.push(company);
    companies.sort((a, b) => {
      return a.id > b.id ? 1 : -1;
    });

    this.setState({ companies, companiesFiltered: companies });
  };

  removeCompanyCallback = (id) => {
    const companies = this.state.companies.filter((x) => x.id !== id);
    this.setState({ companies, companiesFiltered: companies });
  };

  handleCompanyRowClick = (e, rowData) => {
    const companyToEdit = this.state.companies.find((x) => x.id === rowData.id);
    this.setState({ companyToEdit, modal: Modals.editCompany });
  };

  createCompany = (e) => {
    this.setState({ modal: Modals.createCompany });
  };

  reEnableUser = (user) => {
    
    let payload = {
      id: user.id,
      email: user.email,
      name: user.name,
      enabled: 1
    };

    let company = user.companies[0];

    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.companyUsersUpdate(company, payload).then((result) => {

        let disabled_users = this.state.inactiveusers.filter((usr) => {
          return usr.id != user.id ? true : false;
        });

        this.setState({inactiveusers: disabled_users, loading: false});

      });
    });
  };

  removeUser = (user) => {
    if (this.state.loading) { return; }

    let company = null;

    if(user.companies && user.companies[0]){
      // Use Company User delete endpoint

      company = user.companies[0];

      this.setState({ loading: true }, () => {
        const api = new AvainiaCore(LocalStorageService.getToken);
        api.companyUsersDelete( company, user).then((result) => {
          if (result.error) { return this.setState({ error: result.error, loading: false }); }
          
          let enabled_users = this.state.users.filter((usr) => {
            return usr.id != user.id ? true : false;
          });
    
          let disabled_users = this.state.inactiveusers.filter((usr) => {
            return usr.id != user.id ? true : false;
          });
  
          this.setState({users: enabled_users, inactiveusers: disabled_users, loading: false});
  
        });
      });

      return;
    } else {
      // Use common User delete endpoint
      console.log("no company detected");

      this.setState({ loading: true }, () => {
        const api = new AvainiaCore(LocalStorageService.getToken);
        api.userDelete(user.id).then((result) => {
          if (result.error) { return this.setState({ error: result.error, loading: false }); }
          
          let enabled_users = this.state.users.filter((usr) => {
            return usr.id != user.id ? true : false;
          });
    
          let disabled_users = this.state.inactiveusers.filter((usr) => {
            return usr.id != user.id ? true : false;
          });
  
          this.setState({users: enabled_users, inactiveusers: disabled_users, loading: false});
  
        });
      });

      return;
    }
  }

  render() {
    if (this.state.error) {
      return <Error error={this.state.error} />;
    }
    if (this.state.loading) {
      return <Loading />;
    }

    const user = LocalStorageService.getUser();
    const ownerManager = user && user.isOwnerManager(); // TODO: Replace with real checks

    if (!ownerManager) { return <Redirect to="/projects" push={false} />; }

    return (
      <div className="App-container">
        {this.context.tab === Tabs.companies && <div>
          <h1>{I18n.t('views.companies.companies')}</h1>

          <div style={{display:"flex",justifyContent:"space-between"}}>
            <Button onClick={this.createCompany} variant="primary">
              {I18n.t('views.companies.add-company')}
            </Button>

            <Button onClick={() => {window.location = '/import'}} variant="primary">
              {I18n.t('views.companies.csv-import')}
            </Button>
          </div>

          <Form.Control
            type="text"
            onChange={this.search}
            placeholder={I18n.t('views.companies.search')}
          />

          <AvainiaTable
            keyField="id"
            data={this.state.companiesFiltered}
            rowClickHandler={this.handleCompanyRowClick}
            defaultSorted={[{ dataField: 'name', order: 'asc' }]}
            columns={[
              { dataField: 'id', text: I18n.t('general.id') },
              {
                dataField: 'name',
                text: I18n.t('views.companies.name'),
                sort: true,
              },
              { dataField: 'code', text: I18n.t('views.companies.code') },
              {
                dataField: 'type',
                text: I18n.t('views.companies.type'),
                formatter: (cell, row) => I18n.t(`constants.companyTypes.${cell}`),
              },
            ]}
          />

          {this.state.modal === Modals.createCompany && (
            <ModalCompanyCreate
              userCategories={this.state.userCategories}
              addCompanyCallback={this.addCompanyCallback}
              onHide={this.hideModal}
            />
          )}

          {this.state.modal === Modals.editCompany && (
            <ModalCompanyEdit
              userCategories={this.state.userCategories}
              editCompanyCallback={this.editCompanyCallback}
              removeCompanyCallback={this.removeCompanyCallback}
              companyToEdit={this.state.companyToEdit}
              onHide={this.hideModal}
            />
          )}
        </div>}

        {this.context.tab === Tabs.usertypes && <PanelUserCategories />}

        {this.context.tab === Tabs.allusers && <div>
          <AvainiaTableHeading title={I18n.t('general.users')} />

          {!this.state.users.length > 0 &&
            <div>
              <Loading inline />
            </div>
          }

          {this.state.users.length > 0 &&
            <AvainiaTable
              keyField='id'
              data={this.state.users}
              columns={[
                { dataField: 'id', text: I18n.t('general.id') },
                { dataField: 'name', text: I18n.t('general.user') },
                { dataField: 'document_amount', text: I18n.t('general.document-amount') },
                { dataField: 'latest_document', text: I18n.t('general.document-created') },
                { dataField: 'email', text: I18n.t('general.email') },
                {
                  dataField: 'companies',
                  text: I18n.t('general.companies'),
                  formatter: (cell, row) => {
                    return <>
                      {row.companies[0] && row.companies[0].name && row.companies[0].name}
                    </>;
                  },
                },
              ]}
            />
          }
        </div>}

        {this.context.tab === Tabs.inactiveusers && <div>
          <AvainiaTableHeading title={I18n.t('general.inactiveusers')} />

          {!this.state.users.length > 0 &&
            <div>
              <Loading inline />
            </div>
          }

          {this.state.users.length > 0 &&
            <AvainiaTable
              keyField='id'
              data={this.state.inactiveusers}
              columns={[
                { dataField: 'id', text: I18n.t('general.id') },
                { dataField: 'name', text: I18n.t('general.user') },
                { dataField: 'document_amount', text: I18n.t('general.document-amount'), headerStyle: { width: '150px' } },
                { dataField: 'latest_document', text: I18n.t('general.document-created') },
                { dataField: 'email', text: I18n.t('general.email') },
                {
                  dataField: 'companies',
                  text: I18n.t('general.companies'),
                  formatter: (cell, row) => {
                    return <>
                      {row.companies[0] && row.companies[0].name && row.companies[0].name}
                    </>;
                  },
                },
                {
                  dataField: 'id',
                  headerStyle: { width: '200px' },
                  text: "",
                  formatter: (cell, row) => {
                    return <>
                      <AngleLeft style={{cursor:"pointer"}} onClick={() => { this.reEnableUser(row); }} />
                      <Trash style={{cursor:"pointer"}} onClick={() => { this.removeUser(row); }} />
                    </>;
                  },
                },
              ]}
            />
          }
        </div>}
      </div>
    );
  }
}

export default Companies;
