import React, { Component } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import I18n from 'i18n-js';
import Error from '../Error/Error.js';
import AvainiaCore from 'avainia-core-api';
import Loading from '../Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

export default class ModalUsercategoryCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      default: false,
      loading: false,
      error: false,
    };
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  save = () => {
    if (this.state.loading) { return; }

    this.setState({ loading: true }, () => {
      const payload = {
        default: this.state.default,
        name: this.state.name,
      };

      const api = new AvainiaCore(LocalStorageService.getToken);
      api.userCategoryCreate(payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('usercategories.button-create-new')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.error && <Error inline error={this.state.error} />}
          {this.state.loading && <Loading inline />}

          <Form.Group>
            <Form.Label>{I18n.t('usercategories.name')}</Form.Label>
            <Form.Control type="text" onChange={this.onChange} name="name" value={this.state.name} />
          </Form.Group>
          <Form.Group>
            <Form.Check
              onChange={this.onChange}
              name="default"
              checked={this.state.default}
              label={I18n.t('usercategories.default')}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {!this.state.loading && <>
            <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('general.cancel')}</Button>
            <Button variant="primary" onClick={this.save}>{I18n.t('general.save')}</Button>
          </>}
        </Modal.Footer>
      </Modal>
    );
  }
}
