import React, { Component } from 'react';
import { Form, Button, Modal, Badge, InputGroup, FormControl } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class ModalProjectCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      code: '',
      type: '',
      status: '',
      coordinates: '',
      editCoords: false,
      requiredError: '',
      projectSubtype: null,
      projectTypes: [],
      loading: true,
      error: false,
    };
  }

  componentDidMount = () => {
    const projectTypes = this.getAllowedProjectTypes();
    if (!projectTypes.length) { return this.setState({ error: 9 }); }

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.projectStatusesGet().then((projectStatuses) => {
      if (projectStatuses.error) { return; }
      this.setState({
        loading: false,
        projectSubtype: this.props.projectSubtypes.find(x => x.default),
        projectTypes,
        projectStatuses,
        type: projectTypes[0],
        coordinates: this.props.configuration.coordinates.lat + ", " + this.props.configuration.coordinates.lng,
      });
    });
  }

  componentDidUpdate = () => {
    if (!this.state.editCoords) { return; }
    if (window.editmap) { return; }

    if (window.google) {
      return this.initializeEditMap();
    }

    const scriptNode = document.createElement('script');
    scriptNode.type = 'text/javascript';
    scriptNode.id = 'googleMapsInsert';
    scriptNode.src = 'https://maps.google.com/maps/api/js?key=AIzaSyCn6T7i_CYc8AFNuT3idK-M0PjLG07anN4'; // TODO! DEDUPLICATE

    document.head.appendChild(scriptNode);

    // Wait for map to load
    scriptNode.addEventListener('load', this.initializeEditMap);
  }

  editCoords = () => {
    this.setState({ editCoords: true });
  }

  componentDidUpdate = () => {
    if (!this.state.editCoords) { return; }
    if (window.editmap) { return; }

    if (window.google) {
      return this.initializeEditMap();
    }

    const scriptNode = document.createElement('script');
    scriptNode.type = 'text/javascript';
    scriptNode.id = 'googleMapsInsert';
    scriptNode.src = 'https://maps.google.com/maps/api/js?key=AIzaSyCn6T7i_CYc8AFNuT3idK-M0PjLG07anN4'; // TODO! DEDUPLICATE

    document.head.appendChild(scriptNode);

    // Wait for map to load
    scriptNode.addEventListener('load', this.initializeEditMap);
  }


  editCoords = () => {
    this.setState({ editCoords: true });
  }

  handleStatusChange = (changeEvent) => {
    this.setState({
      status: changeEvent.target.value,
    });
  };

  formatDateDB = (date) => {
    const d = new Date(date);
    const formatted = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;

    return formatted;
  }

  createProject = () => {
    if (this.state.loading) { return; }

    if (!this.state.name
      || !/\S/.test(this.state.name)
      || !this.state.code
      || !/\S/.test(this.state.code)
      || !this.state.type
      || !this.state.projectSubtype
      || !this.state.status
    ) {
      return this.setState({ requiredError: I18n.t('general.required') });
    }

    this.setState({ loading: true, error: false }, () => {
      // set coords to default from core, unless user has input their own.
      let projectDefaultCoordinates = this.props.configuration.coordinates.lat + ", " + this.props.configuration.coordinates.lng;
      if (this.state.coordinates) {
        projectDefaultCoordinates = this.state.coordinates;
      }

      const payload = {
        project_subtype_id: this.state.projectSubtype.id,
        type: this.state.type,
        code: this.state.code,
        name: this.state.name,
        status: this.state.status,
        coordinates: projectDefaultCoordinates,
      };

      const api = new AvainiaCore(LocalStorageService.getToken);
      api.projectsCreate(payload).then((project) => {
        if (project.errorCode) { return this.setState({ error: 29, loading: false }); }
        if (project.error) { return this.setState({ error: project.error, loading: false }); }

        const date = new Date();
        const start = this.formatDateDB(new Date());
        const end = this.formatDateDB(new Date(date.setDate(date.getDate() + 1)));

        const schedulePayload = {
          description: I18n.t('views.projects.project-statuses'),
          start_at: start,
          end_at: end,
          completion: 0,
          automatical: true
        };

        api.projectSchedulesCreate(project.id, schedulePayload).then((schedule) => {
          if (schedule.error) { this.setState({ error: schedule.error }); }

          this.setState({ error: false, loading: false, description: "", start_at: "", end_at: "", parent_id: 0, completion: null }, () => {
            this.props.projectAddedCallback(project);
          });
        }); 
      });
    });
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  getAllowedProjectTypes = () => {
    const allowedProjectTypes = [];

    if (this.props.configuration.condominiums) { allowedProjectTypes.push("condominium"); }
    if (this.props.configuration.infraprojects) { allowedProjectTypes.push("infrastructure"); }
    if (this.props.configuration.marineprojects) { allowedProjectTypes.push("marine"); }

    return allowedProjectTypes;
  }

  hide = () => {
    window.editmap = null;
    this.props.onHide();
  }

  // Stolen from ModalViewDocument, TODO: DEDUPE?
  initializeEditMap = () => {
    const defaultCoords = { lat: this.props.configuration.coordinates.lat, lng: this.props.configuration.coordinates.lng };
    const center = defaultCoords;
    const options = { // TODO: DEDUPE, Map.js and below
      center,
      zoom: 10,
      fullscreenControl: false,
      zoomControl: true,
      scaleControl: true,
      mapTypeControl: true,
      gestureHandling: 'cooperative'
    };

    window.editmap = new window.google.maps.Map(document.getElementById("editCoordsModalMap"), options);

    if (window.editmarker) { window.editmarker.setMap(null); }

    window.editmap.addListener('click', (e) => {
      if (window.editmarker) {
        window.editmarker.setMap(null);
      }

      const newPosition = e.latLng;

      window.editmarker = new window.google.maps.Marker({
        position: newPosition,
        map: window.editmap,
      });

      window.editmap.panTo(newPosition);

      const fixedNewPosition = `${newPosition}`.replace(' ', '').replace('(', '').replace(')', '');

      this.setState({ coordinates: fixedNewPosition });
    });
  }

  onSelectChange = (e) => {
    let value = parseInt(e.target.value);

    this.setState({
      status: value,
    })
  }

  render() {
    const { projectSubtypes } = this.props;
    const { loading, error, projectSubtype, requiredError, projectStatuses } = this.state;

    return (
      <Modal show={true} onHide={this.hide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('views.projects.create-new-project')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Error error={error} inline />}
          {loading && <Loading inline />}

          <Form.Group>
            <Form.Label>{I18n.t('views.projects.project-name')}</Form.Label>
            <Form.Control type="text" onChange={this.onChange} name="name" value={this.state.name} />
            { !this.state.name && <Badge variant="danger">{requiredError}</Badge> }
          </Form.Group>

          <Form.Group>
            <Form.Label>{I18n.t('views.projects.project-code')}</Form.Label>
            <Form.Control type="text" onChange={this.onChange} name="code" value={this.state.code} />
            { !this.state.code && <Badge variant="danger">{requiredError}</Badge> }
          </Form.Group>

          {this.state.type === "infrastructure" && this.props.configuration.setProjectLocationCoordinates && <>
            <Form.Group>
              <Form.Label>{I18n.t('views.projects.project-coordinates')}</Form.Label>
              <InputGroup>
                <Form.Control readOnly type="text" onChange={this.onChange} name="coordinates" value={this.state.coordinates} />
                <InputGroup.Append>
                  <Button
                    variant="outline-secondary"
                    onClick={this.editCoords}
                  >
                    {I18n.t('general.edit')}
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
            {this.state.editCoords &&  <div id="editCoordsModalMap"></div>}
          </>}

          <Form.Group>
            <Form.Label>{I18n.t('projectSubtypes.project-subtype')}</Form.Label>
            {projectSubtypes.map((st) =>
              <Form.Check
                key={st.id}
                type="radio"
                onChange={() => { this.setState({ projectSubtype: st }); }}
                value={st.id}
                checked={projectSubtype && st.id === projectSubtype.id}
                label={st.name}
              />
            )}
            { !this.state.projectSubtype && <Badge variant="danger">{requiredError}</Badge> }
          </Form.Group>

          {this.state.projectTypes.length > 1 && <Form.Group>
            <Form.Label>{I18n.t('views.projects.type')}</Form.Label>
            {this.state.projectTypes.map((type) =>
              <Form.Check
                key={type}
                type="radio"
                value={type}
                onChange={() => { this.setState({ type }); }}
                checked={this.state.type === type}
                label={I18n.t(`views.projects.${type}`)}
              />
            )}
           { !this.state.type && <Badge variant="danger">{requiredError}</Badge> }
          </Form.Group>}

          <Form.Group>
            <Form.Label>{I18n.t('views.projects.status')}</Form.Label>
            <FormControl
              as="select"
              onChange={ this.onSelectChange }
              name="status"
              placeholder={I18n.t('projectStatuses.status-parent')}
            >
              <option key="0" value="0"> - </option>
            { 
              projectStatuses && projectStatuses.map((parent) => {
                if (parent.is_active) {
                  return <option key={parent.id} value={parent.id}> {parent.is_default ? I18n.t(`views.projects.statuses.${parent.slug}`) : parent.name} </option>;
                } return null;
              })
            }
            </FormControl>
            { !this.state.status && <Badge variant="danger">{requiredError}</Badge> }
          </Form.Group>

          <Button variant="primary" disabled={loading} onClick={this.createProject}>
            {I18n.t('views.projects.button-create')}
          </Button>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalProjectCreate;
