import React, { Component } from 'react';
import { Badge, Button, ListGroup } from 'react-bootstrap';
import moment from 'moment';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import RenderedMaterialformModal from '../Modals/RenderedMaterialformModal.js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import AvainiaPanel from './AvainiaPanel.js';
import { File } from '../Icon/Icon.js';
import QuoteModal from '../Modals/QuoteModal.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

const Modals = {
  quote: 1,
  materialform: 2,
};

class MaterialSelectionsPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      materialforms: [],
      deadlines: [],
      targetQuote: false,
      targetMaterialform: false,
      modal: false,
      keySeed: 1,
      loading: true,
      error: false,
    };
  }

  activate = (materialform) => {
    this.setState({
      modal: Modals.materialform,
      targetMaterialform: materialform,
    });
  }

  onHide = () => {
    this.setState({ modal: false, keySeed: this.state.keySeed + 1 });
  }

  refreshMultiprojectMatforms = (projects) => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    const promises = projects.map((project) => api.projectMaterialFormsGet(project.id, 'matforms'));

    Promise.all(promises).then((data) => {
      let allMaterialforms = [];
      let error = false;
      data.forEach((x) => {
        if (x.error) { error = x.error; }
        if (error) { return; }
        if (x.matforms) { allMaterialforms = allMaterialforms.concat(x.matforms); }
      });

      if (error) { return this.setState({ error }); }

      this.setState({ materialforms: allMaterialforms, loading: false });
    });
  }

  componentDidMount = () => {
    if (!!this.props.deadlines && !this.props.apartment) { return this.setState({ error: 20 }); }

    if (this.props.project) {
      this.refreshMultiprojectMatforms([this.props.project]);
    } else if (this.props.projects) {
      this.refreshMultiprojectMatforms(this.props.projects);
    }
  }

  getProjectForMf = (mf) => {
    let projects = [];
    if (this.props.project) {
      projects = [this.props.project];
    } else if (this.props.projects) {
      projects = this.props.projects;
    }

    return projects.find((p) => p.id === mf.project_id);
  }

  getActiveDeadlineFor = (project) => {
    // {"id":3,"project_id":1,"stairwell":"A","apartment_number":"","deadline_at":"2022-01-01T00:00:00+02:00"}
    let found = false;

    // Go through each DL, if an exact matchi is found, use that
    found = this.props.deadlines.find((dl) => {
      return dl.project_id === project.id
              && dl.stairwell === this.props.apartment.stairwell
              && dl.apartment_number === this.props.apartment.apartment_number;
    });
    if (found) { return <span data-id={found.id}>{moment(found.deadline_at).format('DD.MM.YYYY')}</span>; }

    // Go through each DL, if a stairwell match without apt setting is found, use that
    found = this.props.deadlines.find((dl) => {
      return dl.project_id === project.id
             && dl.stairwell === this.props.apartment.stairwell
             && !dl.apartment_number;
    });
    if (found) { return <span data-id={found.id}>{moment(found.deadline_at).format('DD.MM.YYYY')}</span>; }

    // Go through each DL, if a project match without stairwell or apt setting is found, use that
    found = this.props.deadlines.find((dl) => {
      return dl.project_id === project.id
             && !dl.stairwell
             && !dl.apartment_number;
    });
    if (found) { return <span data-id={found.id}>{moment(found.deadline_at).format('DD.MM.YYYY')}</span>; }

    return false;
  }

  openQuote = (targetQuote) => {
    this.setState({
      modal: Modals.quote,
      targetQuote,
    });
  }

  getQuotes = () => {
    if (this.props.apartment && this.props.quotes) {
      return this.props.quotes.filter((x) => x.status === 'sent');
    }

    return [];
  }

  render() {
    const deadlines = !!this.props.deadlines;
    const quotes = this.getQuotes();

    return <AvainiaPanel heading={I18n.t('views.materialforms.materialforms')} icon={<File />} double={this.props.double}>
        {this.state.error && <Error inline error={this.state.error} />}
        {!this.state.error && this.state.loading && <Loading inline />}
        {!this.state.error && !this.state.loading && <>
          {this.props.projects && <>
            <ListGroup className="listing">
              {this.props.projects.map((project) => <ListGroup.Item key={project.name}>
                  {project.name}
                  {/* TODO: Performance */}
                  {deadlines && !!this.getActiveDeadlineFor(project) && <>
                    <Badge variant="warning" className="floatright">{I18n.t('views.materialforms.deadline')} {this.getActiveDeadlineFor(project)}</Badge>
                  </>}
                  <ul>
                    {this.state.materialforms.filter((x) => x.project_id === project.id).map((mf) => <li key={mf.id}>
                      <Badge variant="primary" onClick={() => { this.activate(mf); }} className="clickable">{mf.name}</Badge>
                    </li>)}
                  </ul>
                </ListGroup.Item>)}
            </ListGroup>
          </>}
          {this.props.project &&
            <ListGroup className="listing">
              {this.state.materialforms.map((mf) => <ListGroup.Item key={mf.id}>
                  <Badge variant="primary" onClick={() => { this.activate(mf); }} className="clickable">{mf.name}</Badge>
                </ListGroup.Item>)}
            </ListGroup>}
        </>}

        {!!quotes.length && <h3>{I18n.t('views.materialforms.quotes')}</h3>}
        {quotes.map((quote) =>
          <Button
            key={quote.id}
            variant="primary"
            className="clickable"
            onClick={() => { this.openQuote(quote); }}
            style={{ width: '100%' }}
          >
          {I18n.t('views.materialforms.button-view-quote')} <i>{quote.name}</i>
        </Button>)}

      {this.state.modal === Modals.materialform && <RenderedMaterialformModal
        apartment={this.props.apartment}
        key={this.state.keySeed}
        project={this.getProjectForMf(this.state.targetMaterialform)}
        materialformId={this.state.targetMaterialform.id}
        onHide={this.onHide}
        />}

      {this.state.modal === Modals.quote &&
        <QuoteModal
          key={this.state.keySeed}
          onHide={this.hideModal}
          apartment={this.props.apartment}
          quote={this.state.targetQuote}
        />
      }
    </AvainiaPanel>;
  }
}

export default MaterialSelectionsPanel;
