import React, { Component } from 'react';
import I18n from 'i18n-js';
import { TopbarContext } from '../../../contexts/TopbarContext';

class PrivacyPolicy extends Component {
  static contextType = TopbarContext;

  componentDidMount = () => {
    if (this.context) {
      this.context.resetTopbar();
    }
  }

  render() {
    return (

      <div className="App-container">
        <iframe
          title={I18n.t('general.terms')}
          src={this.props.configuration.privacy}
          style={{ width: '90%', height: '90vh', margin: '0px auto', paddingTop: '10px', display: 'block', border: 0 }}
        />
      </div>

    );
  }
}

export default PrivacyPolicy;
