import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Modal, Button } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import Error from '../../Error/Error.js';
import Loading from '../../Loading/Loading.js';
import LocalStorageService from '../../../../AvainiaTools/LocalStorageService.js';

export default class ModalDocumentReplace extends Component {
  constructor(props) {
    super(props);

    this.state = {
      replacedFile: false,
      loading: false,
      error: false,
    };
  }

  replaceFile = (e) => {
    const replacedFile = { file: e.target.files[0] };
    this.setState({ replacedFile: replacedFile });
  }

  saveReplacedFile = () => {
    if (this.state.loading) { return; }
    if (!this.state.replacedFile) { return; }

    this.setState({ loading: true }, () => {
      const projectId = this.props.project.id;
      const documentId = this.props.document.id;
      const fileId = this.props.document.files[0].id;
      const payload = this.state.replacedFile;
      const token = LocalStorageService.getToken();

      const api = new AvainiaCore(LocalStorageService.getToken);
      api.documentReplace(projectId, documentId, fileId, payload, token)
        .then((result) => {
          if (result.error) { return this.setState({ error: result.error, loading: false }); }
          window.location.reload(); // TODO: Improve
        })
        .catch(() => {
          this.setState({ error: 6, loading: false }); // TODO: Improve
        });
    });
  }

  closeModal = () => {
    if (this.state.loading) { return; }
    this.props.onHide();
  }

  render() {
    const { document } = this.props;
    const { error, loading, replacedFile } = this.state;

    return (
      <Modal show={true} onHide={this.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {I18n.t('views.documents.replace-file')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Error inline error={error} />
          <Loading inline hide={!loading} />

          {!replacedFile && <>
            {I18n.t('views.documents.current-file')}{': '}
            {document.files[0].name}<br />
          </>}

          {replacedFile && <>
            {I18n.t('views.documents.new-file')}{': '}
            {replacedFile.file.name}<br />
          </>}

          {/* The id-attribute is mandatory because of the htmlFor */}
          {!loading && <>
            <input style={{ display: 'none' }} type="file" id="files" name="addNewImage" onChange={this.replaceFile} />
            <label className="replace-file label-select-files" htmlFor="files">
              {I18n.t('views.documents.choose-new-file')}
            </label>
          </>}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.closeModal} disabled={loading}>
            {I18n.t('general.button-cancel')}
          </Button>
          <Button variant="primary" onClick={this.saveReplacedFile} disabled={loading}>
            {I18n.t('general.button-save')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
