import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import I18n from 'i18n-js';
import { Button, Form } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import AvainiaPanel from './AvainiaPanel.js';
import { Folder } from '../Icon/Icon.js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import AvainiaTableHeading from '../AvainiaTable/AvainiaTableHeading.js';
import ModalDefaultfolderAdd from '../Modals/ModalDefaultfolderAdd.js';
import CustomSelect from '../Select/CustomSelect.js';
import ModalDefaultfolderManage from '../Modals/ModalDefaultfolderManage.js';
import ModalProjectSubtypeCreate from '../Modals/ModalProjectSubtypeCreate.js';
import './PanelDefaultfolders.scss';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

const Modals = {
  createDefaultfolder: 1,
  manageDefaultfolder: 2,
  createProjectSubtype: 3,
};

class PanelDefaultfolders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: {
        selectedProjectTypeId: '',
      },
      name: '',
      companies: [],
      allFolders: [],
      defaultFolders: [],
      contentPermissions: [],
      activeDefaultfolder: false,
      activeProjectSubtype: null,
      projectSubtypes: [],
      modal: false,
      loading: true,
      error: false,
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    const promises = [
      // TODO: Go through all of them and add the data attribute
      api.defaultFoldersGet('defaultFolders'),
      api.contentPermissionsGet('App\\Defaultfolder', 'contentPermissions'), // TODO! Hardcoding the contentPermission type, should it be done?
      api.companiesGet('companies'),
      api.projectSubtypesGet('projectSubtypes'),
    ];

    Promise.all(promises).then((data) => {
      let error = false;
      let companies;
      let contentPermissions;
      let defaultFolders;
      let projectSubtypes;
      let activeProjectSubtype;

      const view = this.getInitialView(this.props);

      data.forEach((x) => {
        if (error) { return; }
        if (x.error) { error = x.error; return; }
        if (x.companies) { companies = x.companies; }
        if (x.contentPermissions) { contentPermissions = x.contentPermissions; }
        if (x.defaultFolders) { defaultFolders = x.defaultFolders; }
        if (x.projectSubtypes) {
          projectSubtypes = x.projectSubtypes;

          if (projectSubtypes.find((s) => s.id === view.selectedProjectTypeId)) {
            activeProjectSubtype = projectSubtypes.find((s) => s.id === view.selectedProjectTypeId);
          } else {
            activeProjectSubtype = projectSubtypes.find((s) => s.default);
            const newView = { ...this.state.view };
            newView.selectedProjectTypeId = activeProjectSubtype.id;
            this.setState({ view: newView });
            this.updateUrl(newView);
          }
        }
      });

      if (error) { return this.setState({ loading: false, error }); }

      this.setState({
        view,
        contentPermissions,
        allFolders: defaultFolders,
        companies,
        projectSubtypes,
        activeProjectSubtype,
        loading: false,
      });
    });
  };

  updatePermissionsCallback = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    const promises = [
      // TODO: Go through all of them and add the data attribute
      api.contentPermissionsGet('App\\Defaultfolder', 'contentPermissions'), // TODO! Hardcoding the contentPermission type, should it be done?
    ];

    Promise.all(promises).then((data) => {
      let error = false;
      let contentPermissions;

      data.forEach((x) => {
        if (error) { return; }
        if (x.error) { error = x.error; return; }
        if (x.contentPermissions) { contentPermissions = x.contentPermissions; }
      });

      if (error) { return this.setState({ loading: false, error }); }

      this.setState({
        contentPermissions,
        loading: false,
      });
    });

  }

  // ? note: this is copied Inframarineproject -- // TODO:  dedupe?
  getInitialView = (props) => {
    const viewInitString = props.match.params.view;

    if (viewInitString) {
      try {
        // TODO: sanitize and validate phases etc for outdated or removed
        return JSON.parse(decodeURIComponent(viewInitString));
      } catch (e) {
        console.error(`JSON parse fail ${e.message}`); // TODO: Actual error reporting
      }
    }

    const view = { ...this.state.view };

    this.updateUrl(view);
    return view;
  }

  // ? note: this is copied Inframarineproject -- // TODO:  dedupe?
  handleSelectedProjectChange = (projectType) => {
    const view = { ...this.state.view };
    view.selectedProjectTypeId = projectType.id;
    this.setState({ view });
    this.updateUrl(view);
  }

  // ? note: this is copied Inframarineproject -- // TODO:  dedupe?
  updateUrl = (newView) => {
    const urlAppendix = `/defaultfolders/${encodeURIComponent(JSON.stringify(newView))}`;
    // TODO: This works, but seems suboptimal
    this.props.history.replace(urlAppendix);
  }

  showCreateModal = () => {
    this.setState({ modal: Modals.createDefaultfolder });
  };

  hideModal = () => {
    this.setState({ modal: false });
  };

  editDefaultfolder = (e, defaultFolder) => {
    e.stopPropagation();
    this.setState({
      modal: Modals.manageDefaultfolder,
      activeDefaultfolder: defaultFolder,
    });
  };

  renderDefaultfolder = (defaultFolder, level = 0) => {
    // this check limits the rendered output to defaultfolders that exist within the current project subtype
    if (defaultFolder.project_subtype_id !== this.state.activeProjectSubtype.id) { return; }

    const children = this.state.allFolders.filter((df) => df.parent_id === defaultFolder.id);
    const renderedChildren = children.map((folder) => this.renderDefaultfolder(folder, level + 1));

    return (
      <div
        key={defaultFolder.id}
        onClick={(e) => this.editDefaultfolder(e, defaultFolder)}
        className={`tree-${level}`}
      >
        <Folder />
        {defaultFolder.name}
        <div className="children">
          {renderedChildren}
        </div>
      </div>
    );
  }

  handleProjectSubtypeSelectChange = (customSelectName, selectedValue) => {
    const projectSubtype = this.state.projectSubtypes.find((x) => x.id === selectedValue.value);

    this.handleSelectedProjectChange(projectSubtype);
    this.setState({ activeProjectSubtype: projectSubtype });
  }

  addProjectSubtype = () => {
    this.setState({ modal: Modals.createProjectSubtype });
  }

  projectSubtypeAdded = () => {
    window.location.reload(); // TODO: improve
  }

  saveActiveSubtype = () => {
    if (this.state.loading || !this.state.name) { return; }

    this.setState({ loading: true }, () => {
      const payload = this.state.activeProjectSubtype;
      payload.name = this.state.name;
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.projectSubtypesSave(payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        window.location.reload(); // TODO: improve
      });
    });
  }

  deleteActiveSubtype = () => {
    if (this.state.loading) { return; }
    if (this.state.activeProjectSubtype.default === 1) {
      return this.setState({ error: 36 });
    }
    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.projectSubtypesDelete(this.state.activeProjectSubtype.id).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        window.location.reload(); // TODO: improve
      });
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { error, loading, allFolders, projectSubtypes, modal, activeProjectSubtype } = this.state;
    const rootDefaultfolders = allFolders.filter((df) => df.parent_id === null);
    const renderedFolders = rootDefaultfolders.map((df) => this.renderDefaultfolder(df));

    // This type mapping is infuriating
    const customSelectValue = activeProjectSubtype ? { label: activeProjectSubtype.name, value: activeProjectSubtype.id } : null;
    const customSelectValues = projectSubtypes.map((c) => { return { label: c.name, value: c.id }; });

    return (
      <AvainiaPanel icon={null} heading={null}>
        <AvainiaTableHeading
          title={I18n.t('defaultfolders.project-subtypes-and-default-folders')}
          button={
            <Button onClick={this.addProjectSubtype} size="m" style={{ marginBottom: 5 }}>
              {I18n.t('projectSubtypes.project-subtypes-create')}
            </Button>
          }
        />

        <Error inline error={error} />
        <Loading inline loading={loading} />

        {!loading && activeProjectSubtype && (
          <>
            <p>{I18n.t('defaultfolders.explanation')}</p>

            <div style={{ border: '1px solid #eee', backgroundColor: '#fafafa', padding: 10, marginTop: 40, marginBottom: 10 }}>
              <h4>{I18n.t('projectSubtypes.project-subtypes-select')} </h4>
              <div className='project-subtypes-select'>
                <CustomSelect
                  name='project-subtypes'
                  placeholder={I18n.t('projectSubtypes.project-subtypes-select')}
                  handleChange={this.handleProjectSubtypeSelectChange}
                  selectOptions={customSelectValues}
                  value={customSelectValue}
                />
              </div>
            </div>

            <div style={{ border: '1px solid #eee', padding: 10, marginTop: 40, marginBottom: 10 }}>
              <h4>{I18n.t('projectSubtypes.project-subtypes-edit')} </h4>
              <div style={{ display: 'flex' }}>

              <Form.Control type="text" onChange={this.onChange} name="name" value={this.state.name || activeProjectSubtype.name} />
              <Button style={{ marginBottom: 10 }} variant="primary" onClick={this.saveActiveSubtype}>
                {I18n.t('general.save')}
              </Button>
              <Button style={{ marginBottom: 10 }} variant="danger" onClick={this.deleteActiveSubtype}>
                {I18n.t('general.delete')}
              </Button>
              </div>
              <Form.Check type="checkbox" label={I18n.t('general.default')} checked={activeProjectSubtype.default} readOnly />
            </div>

            <div className='tree-menu-list' style={{ border: '1px solid #eee', padding: 10, marginTop: 40, marginBottom: 10 }}>
              <h4>{I18n.t('folders.defaultfolders')} </h4>
              {renderedFolders}
            </div>

            <hr />
            <Button onClick={this.showCreateModal}>
              {I18n.t('defaultfolders.button-create-new')}
            </Button>
          </>
        )}

        {modal === Modals.createDefaultfolder &&
          <ModalDefaultfolderAdd
            show
            onHide={this.hideModal}
            projectSubtype={activeProjectSubtype}
          />
        }

        {modal === Modals.manageDefaultfolder &&
          <ModalDefaultfolderManage
            show
            onHide={this.hideModal}
            projectSubtype={activeProjectSubtype}
            contentPermissions={this.state.contentPermissions}
            companies={this.state.companies}
            activeDefaultfolder={this.state.activeDefaultfolder}
            updatePermissionsCallback={this.updatePermissionsCallback}
          />
        }

        {modal === Modals.createProjectSubtype &&
          <ModalProjectSubtypeCreate
            show
            onHide={this.hideModal}
            projectSubtypeAdded={this.projectSubtypeAdded}
          />
        }

      </AvainiaPanel>
    );
  }
}

export default withRouter(PanelDefaultfolders);
