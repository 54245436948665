import React, { Component } from "react";
import "./SummaryViews.scss";
import AvainiaTable from "../../multiview/AvainiaTable/AvainiaTable";
import I18n from "i18n-js";
import { Button } from "react-bootstrap";
import AvainiaCore from "avainia-core-api";
import LocalStorageService from "../../../AvainiaTools/LocalStorageService.js";
import Loading from "../../multiview/Loading/Loading";
import ModalsummaryViewAdd from "../../multiview/Modals/ModalsummaryViewAdd";
import { Trash } from "../../multiview/Icon/Icon";
import { TopbarContext } from "../../../contexts/TopbarContext";

const Modals = {
  summaryViewAdd: 1,
};

class SummaryViews extends Component {
  static contextType = TopbarContext;

  constructor(props) {
    super(props);

    this.state = {
      summaryViews: [],
      defaultFolders: [],
      loading: true,
      modal: false,
    };
  }
  componentDidMount = () => {
    this.getSummaryViews();
    this.context.resetTopbar();
  };
  getSummaryViews = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.defaultFoldersGet().then((defaultFolders) => {
      if (defaultFolders.error) {
        return this.setState({ error: defaultFolders.error });
      }
      this.setState({ defaultFolders: defaultFolders });

      api.summaryViewsGet().then((summaryViews) => {
        if (summaryViews.error) {
          return this.setState({ error: summaryViews.error });
        }
        this.setState({ summaryViews: summaryViews, loading: false });
      });
    });
  };

  deleteSummaryView = (id) => {
    if (!window.confirm(I18n.t("views.progressnumbers.confirmreportdelete"))) {
      return;
    }

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.summaryViewDelete(id).then((result) => {
      if (result.error) {
        return this.setState({ error: result.error });
      }
      window.location.reload();
    });
  };

  //Add default folder name to summaryViews
  getFolderName = (defaultFolderId) => {
    const defaultFolder = this.state.defaultFolders.find((folder) => folder.id === defaultFolderId);
    return defaultFolder ? defaultFolder.name : "";
  };

  addSummaryView = () => {
    this.setState({ modal: Modals.summaryViewAdd });
  };

  hideModal = (e) => {
    this.setState({ modal: false });
  };

  refreshView = () => {
    this.getSummaryViews();
  };

  renderActions = (cell, row) => {
    return (
      <div className="documenttype-actions">
        <Trash onClick={() => this.deleteSummaryView(row.id)} className="clickable" />
      </div>
    );
  };

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    this.state.summaryViews.map((summaryView) => {
      summaryView.defaultfolder_name = this.getFolderName(summaryView.defaultfolder_id);
      return summaryView;
    });

    return (
      <div className="App-container">
        <div className="summaryviews-top">
          <h1>{I18n.t("views.sidebar.sharedsummaries")}</h1>
          <div className="add-summary-button" onClick={this.addSummaryView}>
            <Button>{I18n.t("views.summaryviews.addsummaryview")}</Button>
          </div>
        </div>

        <div>
          <AvainiaTable
            keyField="id"
            data={this.state.summaryViews}
            //rowClickIdRedirect="summaryview"
            columns={[
              {
                dataField: "name",
                text: I18n.t("documentType.name"),
                formatter: (cell, row) => {
                  return (
                    <a href={`/summaryview/${row.id}`} className="clickable">
                      {cell}
                    </a>
                  );
                },
              },
              {
                dataField: "defaultfolder_name",
                text: I18n.t("folders.folder"),
              },
              {
                dataField: "actions",
                text: I18n.t("general.table-actions"),
                headerStyle: { width: "100px" },
                formatter: this.renderActions,
              },
            ]}
          />
        </div>
        <ModalsummaryViewAdd
          show={this.state.modal === Modals.summaryViewAdd}
          onHide={this.hideModal}
          onRefresh={this.refreshView}
          defaultFolders={this.state.defaultFolders}
        />
      </div>
    );
  }
}
export default SummaryViews;
