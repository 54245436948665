import React, { Component } from 'react';
import {  Button, Modal, Col, Row } from 'react-bootstrap';
import I18n from 'i18n-js';
import moment from 'moment';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import { Download } from '../Icon/Icon.js';


class ModalNotificationShow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      code: '',
      type: '',
      status: '',
      loading: false,
      error: false,
      requiredError: '',
    };
  }

  downloadAttachment = async (e) => {
    try {
      const obj = { headers: { Authorization: `Bearer ${LocalStorageService.getToken()}`} };
      const res = await fetch(process.env.REACT_APP_API_HOST + "/api/v1/notifications/" + this.props.notification.id + "/download", obj);
      const fileName = this.props.notification.attachment.name;

      if (res.ok) {
        const binary = await res.blob();

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(binary, fileName);
        } else {
          const src = window.URL.createObjectURL(binary);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style.cssText = 'display: none';
          a.href = src;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(src);
          setTimeout((x) => { document.body.removeChild(a); }, 1000);
        }
      }
      else {
        this.setState({error: 30});
      }
    } catch (ex) {
      console.error(ex); // TODO! Fatal error, do actual logging
    }
  }

  render() {
    const notification = this.props.notification;
    const edited = notification.created_at !== notification.updated_at;

    return (
      <Modal show={true} onHide={this.props.hide}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.notification.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.error && <Error error={this.state.error} inline />}
          {this.state.loading && <Loading inline />}
          <p style={{ whiteSpace: 'pre-line' }}>{this.props.notification.body}</p>
          {this.props.notification.attachment && <>
            <hr />
            <Button className="notification-download-link" variant="link" onClick={this.downloadAttachment}>
              <Download />
              {this.props.notification.attachment.name}
            </Button>
          </>}
          <hr />
          <Row style={{ fontSize: '80%' }}>
            <Col xs={3}>{I18n.t('views.notifications.created-at')} </Col>
            <Col xs={4}>{moment(this.props.notification.created_at).format('DD.MM.YYYY HH:mm')}</Col>
            <Col xs={5} style={{ textAlign: 'right' }}>{this.props.notification.creator.name}</Col>
          </Row>
          {edited && <Row style={{ fontSize: '80%' }}>
            <Col xs={3}>{I18n.t('views.notifications.updated-at')}</Col>
            <Col xs={4}>{moment(this.props.notification.updated_at).format('DD.MM.YYYY HH:mm')}</Col>
            <Col xs={5} style={{ textAlign: 'right' }}>{this.props.notification.editor.name}</Col>
          </Row>}
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalNotificationShow;
