import React, { Component } from 'react';
import { Button, Modal, InputGroup, FormControl } from 'react-bootstrap';
import I18n from 'i18n-js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import AvainiaCore from 'avainia-core-api';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

export default class ModalProjectSubtypeCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      loading: false,
      error: false,
    };
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  save = () => {
    if (this.state.loading) { return; }

    const payload = {
      name: this.state.name,
    };

    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.projectSubtypesCreate(payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }

        this.props.projectSubtypeAdded(result);
      });
    });
  }

  render() {
    const { error, loading, name } = this.state;
    const { onHide, show } = this.props;

    return (
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('projectSubtypes.project-subtypes-create')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Error inline error={error} />
          <Loading inline loading={loading} />
          {!loading && (
            <InputGroup>
              <FormControl
                type="text"
                placeholder={I18n.t('projectSubtypes.project-subtypes-name')}
                value={name}
                name="name"
                onChange={this.onChange}
              />
            </InputGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" disabled={loading} onClick={this.save}>{I18n.t('general.modal-form-save')}</Button>
          <Button variant="secondary" onClick={onHide}>{I18n.t('general.modal-close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
