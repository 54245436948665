import React, { Component } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import Error from '../../Error/Error.js';
import Loading from '../../Loading/Loading.js';
import LocalStorageService from '../../../../AvainiaTools/LocalStorageService.js';

class DocumentTypesCreateModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validated: false,
      newDocumentType: '',
      is_default: false,
      is_immutable: false,
      is_active: true,
      error: false,
      loading: false,
    };
  }

  createDocumentType = (e) => {
    if (this.state.loading) { return; }

    return this.setState({ loading: true, validated: e.currentTarget.checkValidity }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.documentTypesCreate(this.state.newDocumentType).then((result) => {
        if (result.error) {
          return this.setState({ loading: false, error: result.error });
        }

        return this.setState({ loading: false }, () => {
          this.props.createCallback(result);
          this.props.hideModal();
        });
      });
    });
  }

  onChange = (e) => {
    this.setState({ error: false, [e.target.name]: e.target.value });
  }

  render() {
    return <Modal show={this.props.show} onHide={this.props.hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('documentType.create-document-type')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.error && <Error inline error={this.state.error} />}
        {this.state.loading && <Loading inline />}
        <Form noValidate validated={this.state.validated}>
          <Form.Group>
            <Form.Label>{I18n.t('documentType.name')}</Form.Label>
            <Form.Control type="text" name="newDocumentType" onChange={this.onChange} required />
            <Form.Control.Feedback type="invalid">
              {I18n.t('errors.code-8')}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={this.createDocumentType}>{I18n.t('general.create')}</Button>
        <Button variant="secondary" onClick={this.props.hideModal}>{I18n.t('general.cancel')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default DocumentTypesCreateModal;
