import React, { Component } from 'react';
import { Button, Col, Row, Form, Tab, Tabs, Modal } from 'react-bootstrap';
import I18n from 'i18n-js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import UtilService from '../../../AvainiaTools/UtilService.js';
import AvainiaCore from 'avainia-core-api';
import Lightbox from '../Lightbox/Lightbox.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class RenderedMaterialformModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selections: [],
      apiFetchedSelections: [],
      materialform: false,
      lightboxImages: [],
      editing: false,
      loading: true,
    };
  }

  componentDidMount = () => {
    if (!this.props.materialformId) { return; /* TODO: Log error */ }
    if (!this.props.project) { return; /* TODO: Log error */ }

    const projectId = this.props.project.id;
    const { materialformId } = this.props;
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.projectMaterialFormGet(projectId, materialformId).then((materialform) => {
      if (materialform.error) { return this.setState({ error: materialform.error }); }

      UtilService.convertMaterialform(materialform).then((converted) => {
        if (materialform.error) { return this.setState({ error: converted.error }); }

        if (!materialform.groups || materialform.groups.length === 0) { return this.setState({ error: 19 }); }

        if (this.props.apartment) {
          const apartmentId = this.props.apartment.id;

          api.materialSelectionsGet(projectId, apartmentId).then((selections) => {
            if (selections.error) { return this.setState({ error: selections.error }); }

            this.setState({
              materialform: converted,
              apiFetchedSelections: selections,
              selections,
              editing: selections.length > 0,
            }, this.makeSureEachSelectionHasAnOptionSelected);
          });
        } else {
          this.setState({
            materialform: converted,
            loading: false,
          }, this.makeSureEachSelectionHasAnOptionSelected);
        }
      });
    });
  }

  makeSureEachSelectionHasAnOptionSelected = () => {
    // Go through materialform and selections. Loop through all groups -> selections,

    let selections = [];

    this.state.materialform.groups.forEach((group) => {
      group.selects.forEach((select) => {
        // Check a selection exists in selections for this select
        // eslint-disable-next-line eqeqeq
        const selection = this.state.selections.find((s) => s.material_form_select_id == select.id);

        // If a selection for this select is not found, use the default.
        if (!selection) {
          let breaker = false;
          select.options.forEach((option) => {
            if (breaker) { return; }
            if (option.isDefault) {
              selections.push({
                material_form_select_id: select.id,
                material_form_option_id: option.id,
                name: option.name,
                price: option.price,
              });
              breaker = 1;
            }
          });
        }
      });
    });

    selections = selections.concat(this.state.selections);
    this.setState({ selections, loading: false });
  }

  isSelected = (select, option) => {
    // eslint-disable-next-line eqeqeq
    const selection = this.state.selections.find((x) => select.id == x.material_form_select_id);

    // eslint-disable-next-line eqeqeq
    return selection && selection.material_form_option_id == option.id;
  }

  selectOption = (groupTarget, selectTarget, optionTarget) => {
    const materialform = { ...this.state.materialform };
    const group = materialform.groups.find((g) => g.id === groupTarget.id);
    const select = group.selects.find((s) => s.id === selectTarget.id);

    const selections = this.state.selections.filter((selection) => {
      if (selectTarget.id !== selection.material_form_select_id) { return true; }
      return false;
    });

    select.options.forEach((option) => {
      option.selected = false;
      if (option.id === optionTarget.id) {
        option.selected = true;
        selections.push({
          material_form_select_id: select.id,
          material_form_option_id: option.id,
          name: option.name,
          price: option.price,
          product_image: option.product_image,
          product_context_image: option.product_context_image,
        });
      }
    });

    this.setState({ selections, materialform });
  }

  save = () => {
    if (this.state.loading) { return; }
    if (!this.props.apartment) { return; }

    this.setState({ loading: true }, () => {
      if (this.state.editing) {
        // Delete all old saves
        const projectId = this.props.project.id;
        const apartmentId = this.props.apartment.id;
        const api = new AvainiaCore(LocalStorageService.getToken);
        const promises = this.state.apiFetchedSelections.map((selection) =>
          api.materialSelectionsDelete(projectId, apartmentId, selection.id)
        );
        Promise.all(promises).then((results) => {
          let error = false;
          results.forEach((result) => {
            if (result.error) { error = result.error; }
          });

          if (error) { return this.setState({ error }); } // TODO: Properly log the error

          this.actuallySave();
        });
      }

      this.actuallySave();
    });
  }

  actuallySave = () => {
    const payloads = [];
    this.state.materialform.groups.forEach((group) => {
      group.selects.forEach((select) => {
        select.options.forEach((option) => {
          if (option.selected) {
            payloads.push({
              material_form_select_id: select.id,
              material_form_option_id: option.id,
              name: option.name,
              price: option.price,
            });
          }
        });
      });
    });

    // TODO! How do we delete the old selections?
    // TODO! How do we update the old selections?

    const projectId = this.props.project.id;
    const apartmentId = this.props.apartment.id;
    const api = new AvainiaCore(LocalStorageService.getToken);
    const promises = payloads.map((x) =>
      api.materialSelectionsCreate(projectId, apartmentId, x)
    );

    Promise.all(promises).then((data) => {
      let error = false;

      data.forEach((result) => {
        if (result.error) { error = result.error; }
        if (error) { }
      });

      if (error) { return this.setState({ error, loading: false }); }

      window.location.reload(); // TODO: Improve
    });
  }

  onSelect = (tab) => { this.setState({ tab }); }

  getTotalPrice = () => {
    let accumulator = 0;

    this.state.selections.forEach((selection) => {
      let select = false;

      this.state.materialform.groups.forEach((g) => {
        if (select) { return; }

        // eslint-disable-next-line eqeqeq
        select = g.selects.find((s) => s.id == selection.material_form_select_id);
      });

      if (!select) { return; /* Probably, hopefully, this means that this selection is for some other materialform */ }

      const price = Number(selection.price);
      const area = Number(select.area);

      accumulator += area ? area * price : price;
    });

    return Number(accumulator).toFixed(2);
  }

  getMappedSelections = () => {
    const mapped = [];

    this.state.selections.forEach((selection) => {
      let select = false;
      this.state.materialform.groups.forEach((g) => {
        if (select) { return; }
        select = g.selects.find((s) => s.id === selection.material_form_select_id);
      });

      if (!select) { return; /* Probably, hopefully, this means that this selection is for some other materialform */ }

      mapped.push({
        select,
        ...selection,
      });
    });

    return mapped;
  }

  render() {
    if (this.state.error) {
      return <Modal show onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('views.materialforms.materialform')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Error inline error={this.state.error} />
        </Modal.Body>
      </Modal>;
    }

    if (this.state.loading) {
      return <Modal show onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('views.materialforms.materialform')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loading inline />
        </Modal.Body>
      </Modal>;
    }

    const mapped = this.getMappedSelections();
    const mappedError = mapped.error;

    return <Modal onHide={this.props.onHide} show size="lg" className="selectionform">
      <Modal.Header closeButton>
        <Modal.Title>{this.state.materialform.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {mappedError && <Error inline error={mappedError} />}
        {!this.state.loading && <div>
          <Tabs onSelect={this.onSelect} className="materialform">
            {(this.state.materialform.groups || []).map((group) => <Tab eventKey={group.id} key={group.id} className="" title={group.name}>

              {(group.selects || []).map((select) => <div key={select.id} className="select">
                <h3>{select.name} <span>{!!select.area && <span>{select.area} m&sup2;</span>}</span></h3>

                {(select.options || []).map((option) => <Row key={option.id} className={`${this.isSelected(select, option) ? 'selected' : ''} ${select.default_option_id === option.id ? 'option default' : 'option'}`}>
                  <Col sm={1} className="vcentre radiobox">
                    {/* TODO! This thinks it is an uncontrolled input. Why? */}
                    <Form.Check checked={this.isSelected(select, option)} onChange={() => { this.selectOption(group, select, option); }} type="radio" />
                  </Col>
                  <Col sm={4} className="vcentre">
                    <div>{option.name}</div>
                    <div>{option.price}</div>
                  </Col>
                  <Col sm={7}>
                    <span className="lightbox-trigger" onClick={() => { this.setState({ lightboxImages: [option.product_photo, option.product_context_photo] }); }}>
                      <img src={option.product_photo} style={{ width: '30%', margin: '0 0 0 auto', display: 'block' }} alt="" data-todo="Implement" />
                    </span>
                  </Col>
                </Row>)}
              </div>)}
            </Tab>)}
          </Tabs>

          <hr />

          {/* TODO: Better yhteenveto */}
          {mapped.map((selection) => <Row key={selection.material_form_select_id} className="forceRight">
              <Col sm={8} className="forceRight">{selection.select.name} / {selection.name}</Col>
              <Col sm={2} className="forceRight">{Number(selection.price).toFixed(2)}</Col>
              <Col sm={2} className="forceLeft">{!!selection.select.area && <>x {selection.select.area}</>}</Col>
            </Row>)}

          <Row>
            <Col sm={8} className="forceRight"><b>{I18n.t('general.price')}</b></Col>
            <Col sm={2} className="forceRight"><b>{this.getTotalPrice()}</b></Col>
            <Col sm={2}></Col>
          </Row>

          <pre style={{ display: 'none' }}>{JSON.stringify(this.state.materialform, undefined, 2)}</pre>
        </div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('views.materialforms.button-cancel')}</Button>
        {this.props.apartment && <Button variant="primary" onClick={this.save}>{I18n.t('views.materialforms.button-save')}</Button>}
      </Modal.Footer>
      <Lightbox close={() => { this.setState({ lightboxImages: [] }); }} images={this.state.lightboxImages}></Lightbox>
    </Modal>;
  }
}

export default RenderedMaterialformModal;
