import { objectToFormData } from 'object-to-formdata';
import NewRequestService from './NewRequestService.js'

function _catcherInTheCode(e, allow401And403) {
  if (e.status > 199 && e.status < 300) {
    return console.log('Well, this is unexpected. Is this an error? I am ignoring it.', e);
  };
  if (e.status === 400) { return { error: 6, errorCode: e.errorCode }; }
  if (e.status === 401) {
    if (!allow401And403) {
      if (window) { window.triggerLoginCheck = true; }
    }
    return { error: 4, unauthorized: allow401And403, errorCode: e.errorCode };
  };
  
  if (e.message === "User has been disabled.") { return { error: 44, unauthorized: allow401And403, errorCode: e.errorCode }; }
  if (e.status === 403) { return { error: 5, unauthorized: allow401And403, errorCode: e.errorCode }; }
  if (e.status === 404) { return { error: 3, errorCode: e.errorCode }; }
  if (e.status === 405) { return { error: 10, errorCode: e.errorCode }; }
  if (e.status === 409) { return { error: 14, errorCode: e.errorCode }; }
  if (e.status === 419) { return { error: 27, errorCode: e.errorCode }; }
  if (e.status === 422) { return { error: -1, errorCode: e.errorCode }; }
  if (e.status === 500) { return { error: 500, errorCode: e.errorCode }; } // TODO! This is always a fatal error that should be logged && reported

  console.error('Unexpected error during API call!', e);
  return { error: -3, e };
}

function _logicError(desc, result) {
  console.error(`Invalid return value for ${desc}`, result);
  return { error: -2 };
}

function _getResultError(result, emptyAllowed = false) {
  if (emptyAllowed && !result) { return; }
  if (!emptyAllowed && !result) { return { error: 9 }; }
  if (result.errors) { return { error: 6 }; }
  if (result.exception) { return { error: 7 }; }
  if (result.error) { return { error: 8 }; }

  return false;
}

export default class AvainiaCore {
  constructor(tokenReference) {
    let token;
    if (!tokenReference) {
      // Allow explicit usage without a token, the 401 catcher _should_ take care of it
      this.requestService = new NewRequestService(token);
      return;
    }

    if (typeof tokenReference === "function") {
      token = tokenReference();
    } else if (typeof tokenReference === "string") { // Stupid JS primitive typing, why can't I use instanceof String :(
      token = tokenReference;
    } else {
      throw Error("Invalid token argument, expected synchronous callback function or string");
    }

    if (token instanceof Promise) {
      throw Error("Token function must not be async");
    }

    this.token = token;
    this.requestService = new NewRequestService(token);
  }

  async getCompanyUsers(id) {
    return this.requestService
      .get(`companies/${id}/users`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('getCompanyUsers', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async getProject(id) {
    return this.projectGet(id); // TODO: Refactor this away
  }

  async getPhaseCompanies(phase) {
    // api/v1/fields/{fieldId}/keywords/{keywordId}/companies
    return this.requestService
      .get(`fields/2/keywords/${phase.id}/companies?per_page=9999`) // TODO: Let's hope the field ID never changes :skull:
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('getPhaseCompanies', result);
        }

        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async removePhaseFromCompany(phase, company) {
    // api/v1/fields/{fieldId}/keywords/{keywordId}/companies/{companyId}
    return this.requestService
      .delete(`fields/2/keywords/${phase.id}/companies/${company.id}`) // TODO: Let's hope the ID never changes :skull:
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('addPhaseToCompany', result);
        }

        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async addPhaseToCompany(phase, company) {
    // api/v1/fields/{fieldId}/keywords/{keywordId}/companies
    return this.requestService
      .post(`fields/2/keywords/${phase.id}/companies`, company) // TODO: Let's hope the ID never changes :skull:
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('addPhaseToCompany', result);
        }

        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async createPhase(name) {
    if (!name) {
      return { error: 'Invalid input, need name!' };
    }

    const payload = {
      field_id: 2, // TODO: Let's hope the ID never changes :skull:
      text: name,
    };

    return this.requestService
      .post('fields/2/keywords', payload) // TODO: Let's hope the ID never changes :skull:
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('createPhase', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async savePhase(phase) {
    if (!phase) {
      return { error: 'Invalid input, need phase!' };
    }

    const payload = {
      text: phase.name,
      field_d: 2, // TODO: Let's hope the ID never changes :skull:
      deactivated: phase.deactivated,
    };

    return this.requestService
      .put(`fields/2/keywords/${phase.id}`, payload) // TODO: Let's hope the ID never changes :skull:
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('savePhase', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async deletePhase(phase) {
    if (!phase) {
      return { error: 'Invalid input, need phase!' };
    }

    return this.requestService
      .delete(`fields/2/keywords/${phase.id}`) // TODO: Let's hope the ID never changes :skull:
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('deletePhase', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async getAllPhases(dataAttribute = 'phases') {
    return this.requestService
      .get('fields/2/keywords?per_page=9999') // TODO: Let's hope the ID never changes :skull:
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('getAllPhases', result);
        }

        result.data.sort((a, b) => {
          return a.text > b.text ? 1 : -1;
        });

        const phases = result.data.map((x) => {
          return { id: x.id, name: x.text, deactivated: x.deactivated };
        });

        return dataAttribute ? { [dataAttribute]: phases } : phases;
      })
      .catch(_catcherInTheCode);
  }

  async getAllSubjects(dataAttribute = 'subjects') {
    // Let's hope the ID never changes :skull:
    return this.requestService
      .get('fields/3/keywords?per_page=9999')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('getAllSubjects', result);
        }

        result.data.sort((a, b) => {
          return a.text > b.text ? 1 : -1;
        });

        const subjects = result.data.map((x) => {
          return { id: x.id, name: x.text };
        });
        return dataAttribute ? { [dataAttribute]: subjects } : subjects;
      })
      .catch(_catcherInTheCode);
  }

  async getAllStates(dataAttribute = 'states') {
    // THIS IS WRONG!!!! THE FIELD ID IN ENERKE PRODUCTION IS 10!!!!
    return this.requestService
      .get('fields/4/keywords?per_page=9999') // TODO: Let's hope the ID never changes :skull:
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('getAllStates', result);
        }

        result.data.sort((a, b) => {
          return a.text > b.text ? 1 : -1;
        });

        const states = result.data.map((x) => {
          return { id: x.id, name: x.text };
        });

        return dataAttribute ? { [dataAttribute]: states } : states;
      })
      .catch(_catcherInTheCode);
  }

  async getFields(dataAttribute = 'fields') {
    return this.requestService
      .get('fields?per_page=9999')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          _logicError('getFields', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async deleteUser(user) {
    if (!user) {
      return { error: 'Invalid input, need user!' };
    } //! TODO: Translate

    // /api/v1/users/{userId}
    return this.requestService
      .delete(`users/${user.id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('deleteUser', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async documentReplace(projectId, documentId, fileId, payload) {
    if (!payload || !payload.file) {
      return { error: 'Guru Meditation: Payload must contain a file' };
    }

    const BaseURL = `${process.env.REACT_APP_API_HOST}/api/v1/`;
    const endpoint = `${BaseURL}projects/${projectId}/documents/${documentId}/files/${fileId}/replace`;
    const options = {
      indices: true,
      nullsAsUndefineds: false,
      booleansAsIntegers: false,
    };

    const data = {
      method: 'POST',
      body: objectToFormData(payload, options),
      headers: {
        Accept: 'application/json',
      },
    };

    if (this.token) {
      data.headers.Authorization = `Bearer ${this.token}`;
    }

    return fetch(endpoint, data)
      .then(async (response) => {
        if (response.status > 299 || response.status < 199) {
          return _catcherInTheCode(response);
        }

        let json;
        try {
          json = response.json ? await response.json() : null;
        } catch (e) {
          console.warn('Unable to parse JSON from API response', e);
        }

        return json.data ? json.data : json;
      })
      .catch(_catcherInTheCode);
  }

  async documentAdd(payload) {
    const BaseURL = `${process.env.REACT_APP_API_HOST}/api/v1/`; //TODO! EXTRACT OUT!
    const endpoint = `${BaseURL}projects/${payload.projectId}/documents`;

    const options = {
      indices: true,
      nullsAsUndefineds: false,
      booleansAsIntegers: false,
    };

    const data = {
      method: 'POST',
      body: objectToFormData(payload, options),
      headers: {
        Accept: 'application/json',
      },
    };

    if (this.token) {
      data.headers.Authorization = `Bearer ${this.token}`;
    }

    return fetch(endpoint, data)
      .then(async (response) => {
        if (response.status > 299 || response.status < 199) {
          return _catcherInTheCode(response);
        }

        let json;
        try {
          json = response.json ? await response.json() : null;
        } catch (e) {
          console.warn('Unable to parse JSON from API response', e);
        }

        return json.data ? json.data : json;
      })
      .catch(_catcherInTheCode);
  }

  async createFileExportJob(payload) {
    return this.requestService
      .post(`projects/${payload.projectId}/files-zip`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (result.code !== 200) {
          return _logicError('createFileExportJob');
        }
        return result;
      })
      .catch((e) => {
        return _catcherInTheCode(e);
      });
  }

  async acceptTerms() {
    return this.requestService
      .post('acceptedprivacyterms', {})
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('acceptTerms');
        }
        return true;
      })
      .catch((e) => {
        return _catcherInTheCode(e, true);
      });
  }

  async doUpdateFolder(project, docId, newFolderId, newProjectId = null) {
    return await this.requestService
      .updateFolder(
        `projects/${project.id}/documents/${docId}/updateFolder`,
        newFolderId,
        newProjectId
      )
      .then((result) => {
        if (_getResultError(result, false)) {
          return _getResultError(result, false);
        }

        return true;
      })
      .catch(_catcherInTheCode);
  }

  async doSaveEditedImage(project, docId, payload) {
    return await this.requestService
      .put(`projects/${project.id}/documents/${docId}`, payload)
      .then((result) => {
        if (_getResultError(result, false)) {
          return _getResultError(result, false);
        }

        return true;
      })
      .catch(_catcherInTheCode);
  }

  async doDeleteDocument(project, document) {
    return await this.requestService
      .delete(`projects/${project.id}/documents/${document.id}`, document)
      .then((result) => {
        if (_getResultError(result, false)) {
          return _getResultError(result, false);
        }

        return true;
      })
      .catch(_catcherInTheCode);
  }

  async doImportData(data) {
    return await this.requestService
      .post('import', data)
      .then((result) => {
        return result;
      })
      .catch(_catcherInTheCode);
  }

  /* Rows below this comment have updated naming */

  async login(email, password, isPortal) {
    if (!email || !password) {
      return { error: 'Input error' };
    }

    return this.requestService
      .post('authenticate', { email, password, isPortal })
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data || !result.data.user) {
          return _logicError('doLogin');
        }

        const { user } = result.data;
        user.token = result.data.token;

        return user;
      })
      .catch((e) => {
        return _catcherInTheCode(e, true);
      });
  }

  async loginCheck() {
    return this.requestService
      .get('user')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('loginCheck', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumsInfoGet() {
    return this.requestService.get('condominiumsInfo')
      .then((result) => {
        if (_getResultError(result)) { return _getResultError(result); }
        if (!result) { return _logicError('condominiumsInfo', result); }
        return result;
      }).catch(_catcherInTheCode);
  }

  async apartmentMaterialquotesGet(id) {
    return this.requestService
      .get(`apartments/${id}/materialquotes`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('apartmentMaterialquotesGet', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async getOpenQuotesByApartment(id, dataAttribute) {
    return this.requestService
      .get(`apartments/${id}/openquotesbyapartment`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('apartmentMaterialquotesGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async materialQuoteMarkAsRead(id, payload, dataAttribute) {
    return this.requestService
      .post(`materialquotes/${id}/mark-as-read`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('materialQuoteMarkAsRead', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async apartmentMaterialquoteSendCorrections(id, payload) {
    return this.requestService
      .post(`materialquotes/${id}/send-corrections`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('apartmentMaterialquoteSendCorrections', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async apartmentMaterialquoteApprove(id, payload) {
    return this.requestService
      .post(`materialquotes/${id}/approve`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('apartmentMaterialquoteApprove', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async materialQuoteCommentAdd(quoteId, payload, dataAttribute) {
    return this.requestService
      .post(`material-quote-comments/quote/${quoteId}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('materialQuoteCommentAdd', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async materialQuoteCommentsGet(quoteId, dataAttribute) {
    return this.requestService
      .get(`material-quote-comments/quote/${quoteId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('materialQuoteCommentsGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumsGet(dataAttribute) {
    return this.requestService
      .get('condominiums?per_page=9999')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumsGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumProjectsGet(id) {
    return this.requestService
      .get(`condominiums/${id}/projects`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumsGet', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumEdit(payload) {
    return this.requestService
      .put(`condominiums/${payload.id}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumEdit', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumCreate(payload) {
    return this.requestService
      .post('condominiums', payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumsCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumDelete(payload) {
    return this.requestService
      .delete(`condominiums/${payload.id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumDelete', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumGet(id, dataAttribute) {
    return this.requestService
      .get(`condominiums/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumsGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumMainPhotoGet(id) {
    return this.requestService
      .getImage(`condominium/photo/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('userPhotoGet', result);
        }

        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(result);
          reader.onloadend = () => {
            resolve(reader.result);
          };
        });
      })
      .catch(() => {
        return { error: true };
      });
  }

  async condominiumMainPhotoCreate(condominiumId, photo) {
    return this.requestService
      .postPhoto(`condominium/photo/${condominiumId}`, photo)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('userPhotoUpdate', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumMainPhotoDelete(id) {
    return this.requestService
      .delete(`condominium/photo/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('userPhotoDelete', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumApartmentsGet(id, dataAttribute) {
    return this.requestService
      .get(`condominiums/${id}/apartments`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumApartmentsGet', result);
        }

        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumApartmentGet(condominiumId, apartmentId, dataAttribute) {
    return this.requestService
      .get(`condominiums/${condominiumId}/apartments/${apartmentId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumApartmentGet', result);
        }

        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumApartmentCreate(id, payload) {
    return this.requestService
      .post(`condominiums/${id}/apartments`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumApartmentCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumApartmentDelete(id, aptid) {
    return this.requestService
      .delete(`condominiums/${id}/apartments/${aptid}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumApartmentDelete', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumApartmentTemplateMaterialformsGet(id, dataAttribute) {
    return this.requestService
      .get(`apartmenttemplates/${id}/materialforms`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError(
            'condominiumApartmentTemplateMaterialformsGet',
            result
          );
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumApartmentTemplateMaterialformsWithSubmissionFormsGet(
    id,
    apartmentId,
    dataAttribute
  ) {
    return this.requestService
      .get(
        `apartmenttemplates/${id}/materialforms-with-submissions/${apartmentId}`
      )
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError(
            'condominiumApartmentTemplateMaterialformsWithSubmissionFormsGet',
            result
          );
        }
        return dataAttribute ? { [dataAttribute]: result } : result;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumApartmentTemplateMaterialformsCreate(id, payload) {
    return this.requestService
      .post(`apartmenttemplates/${id}/materialforms`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError(
            'condominiumApartmentTemplateMaterialformsCreate',
            result
          );
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumApartmentTemplateMaterialformsDelete(
    apartmentTemplateId,
    materialformId
  ) {
    return this.requestService
      .delete(
        `apartmenttemplates/${apartmentTemplateId}/materialforms/${materialformId}`
      )
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError(
            'condominiumApartmentTemplateMaterialformsDelete',
            result
          );
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumApartmentTemplatesGet(id, dataAttribute) {
    return this.requestService
      .get(`condominiums/${id}/apartmenttemplates`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumApartmentTemplatesGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumApartmentTemplateCreate(id, name) {
    const payload = { name };
    return this.requestService
      .post(`condominiums/${id}/apartmenttemplates`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumApartmentTemplateCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumApartmentTemplateGet(condoid, templateid, dataAttribute) {
    return this.requestService
      .get(`condominiums/${condoid}/apartmenttemplates/${templateid}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumApartmentTemplateGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumApartmentTemplateDelete(condoid, templateid) {
    return this.requestService
      .delete(`condominiums/${condoid}/apartmenttemplates/${templateid}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumApartmentTemplateDelete', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumApartmentTemplateEdit(condoid, templateid, payload) {
    return this.requestService
      .put(`condominiums/${condoid}/apartmenttemplates/${templateid}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumApartmentTemplateEdit', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumBasedOnApartmentGet(apartmentId, dataAttribute) {
    return this.requestService
      .get(`apartments/condominium/${apartmentId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumBasedOnApartmentGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumApartmentsGetByApartmentId(apartmentId, dataAttribute) {
    return this.requestService
      .get(`apartment/${apartmentId}/condominium/`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumApartmentsGetByApartmentId', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async defaultFoldersGet(dataAttribute) {
    return this.requestService
      .get('defaultfolders')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('defaultFoldersGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async defaultFoldersCreate(defaultfolder, dataAttribute) {
    return this.requestService
      .post('defaultfolders', defaultfolder)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('defaultFoldersCreate', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async defaultFoldersDelete(defaultfolderId, dataAttribute) {
    return this.requestService
      .delete(`defaultfolders/${defaultfolderId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('defaultFoldersDelete', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async defaultFoldersEdit(defaultfolder, dataAttribute) {
    return this.requestService
      .put(`defaultfolders/${defaultfolder.id}`, defaultfolder)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('defaultFoldersEdit', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async materialQuotePhotosGet(projectId, materialQuoteId, dataAttribute) {
    return this.requestService
      .get(`projects/${projectId}/materialquotes/${materialQuoteId}/photos`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('materialQuotePhotosGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async materialQuotePhotoGet(
    projectId,
    materialQuoteId,
    photoId,
    dataAttribute
  ) {
    return this.requestService
      .getImage(
        `projects/${projectId}/materialquotes/${materialQuoteId}/photo/${photoId}`
      )
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('materialQuotePhotoGet', result);
        }

        return new Promise((resolve, reject) => {
            resolve(result);
        });
      })
      .catch(_catcherInTheCode);
  }

  async apartmentMaterialQuotePhotosSave(projectId, payload) {
    return this.requestService
      .put(
        `projects/${projectId}/materialquotes/${payload.id}/photos`,
        payload,
        true
      )
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialquotesCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async apartmentMaterialQuotePhotosDelete(projectId, payload) {
    return this.requestService
      .delete(
        `projects/${projectId}/materialquotes/${payload.quote_id}/photos/${payload.photo_id}`,
        payload
      )
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialquotesCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async apartmentMaterialQuoteSave(projectId, payload) {
    return this.requestService
      .put(`projects/${projectId}/materialquotes/${payload.id}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialquotesCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async apartmentMaterialQuoteRoomCardDeadlineSave(projectId, payload) {
    return this.requestService.post(`projects/${projectId}/materialquotes/${payload.id}/room-card-deadline`, payload)
      .then((result) => {
        if (_getResultError(result)) { return _getResultError(result); }
        if (!result.data) { return _logicError('apartmentMaterialQuoteRoomCardDeadlineSave', result); }
        return result.data;
      }).catch(_catcherInTheCode);
  }

  async apartmentMaterialQuotePublish(projectId, payload) {
    return this.requestService
      .put(
        `projects/${projectId}/materialquotes/${payload.id}/publish`,
        payload
      )
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialquotesPublish', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async apartmentMaterialQuoteRowCommentsAdd(quoteId, payload) {
    return this.requestService
      .post(`material-quote-comments/quote/${quoteId}/rows/comments`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('apartmentMaterialQuoteCommentsSave', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async productCategoriesGet() {
    return this.requestService.get('productcategories?per_page=9999')
      .then((result) => {
        if (_getResultError(result)) { return _getResultError(result); }
        if (!result.data) { return _logicError('productCategoriesGet', result); }
        return result.data;
      }).catch(_catcherInTheCode);
  }

  async productCategoriesCreate(payload) {
    return this.requestService
      .post('productcategories', payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('productCategoriesGetCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async productCategoriesDelete(id) {
    return this.requestService
      .delete(`productcategories/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('productCategoriesDelete', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async productCategoriesEdit(id, payload) {
    return this.requestService
      .put(`productcategories/${id}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('productCategoriesEdit', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async productGet(id) {
    return this.requestService
      .get(`products/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('productGet', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async productsGet(id) {
    return this.requestService
      .get(`productcategories/${id}/products?per_page=9999`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('productsGet', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async productsDelete(id) {
    return this.requestService
      .delete(`products/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('productsDelete', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async productsEdit(id, payload) {
    return this.requestService
      .put(`products/${id}`, payload, true)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('productsEdit', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async productsCreate(payload) {
    return this.requestService
      .post('products', payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('productsCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async contentPermissionAdd(role, contentType, contentId) {
    const payload = {
      role_id: role.id,
      content_type: contentType,
      content_id: contentId,
    };
    return this.requestService
      .post('content-permissions', payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('contentPermissionAdd', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async contentPermissionRemove(contentPermission) {
    return this.requestService
      .delete(`content-permissions/${contentPermission.id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('contentPermissionRemove', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async contentPermissionsGet(type, dataAttribute) {
    let url = 'content-permissions';
    if (type) {
      url = `${url}?content_type=${type}`;
    }

    return this.requestService
      .get(url)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('contentPermissionsGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async permissionsGet() {
    return this.requestService
      .get('rolepermissions')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('permissionsGet', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async permissionsForOwnerGet() {
    return this.requestService
      .get('rolepermissions_owner')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('permissionsForOwnerGet', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async permissionsSave(role, permission) {
    const payload = {
      role,
      permission: permission.name,
    };
    return this.requestService
      .post('rolepermissions', payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('permissionsSave', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async permissionsDelete(role, permission) {
    return this.requestService
      .delete(`rolepermissions/${role}/permission/${permission.name}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('permissionsDelete', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectGet(id) {
    return this.requestService
      .get(`projects/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('getProject', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectsEdit(id, payload) {
    return this.requestService
      .put(`projects/${id}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectsEdit', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectsDelete(id) {
    return this.requestService
      .delete(`projects/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectsDelete', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectsCreate(payload) {
    return this.requestService
      .post('projects', payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectsCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectsGet(dataAttribute, limit = null, onlyactive = null, onlyinactive = null) {
    let params = 'per_page=9999';
    if(limit){
      params += '&limit=' + limit
    }
    if(onlyactive == 1){
      params += '&onlyactive=1';
    }
    if(onlyinactive == 1){
      params += '&onlyinactive=1';
    }
    return this.requestService.get('projects?' + params)
      .then((result) => {
        if (_getResultError(result)) { return _getResultError(result); }
        if (!result.data) { return _logicError('projectsGet', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(_catcherInTheCode);
  }

  async projectSubtypesGet(dataAttribute) {
    return this.requestService
      .get('projectSubtypes?per_page=9999')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectSubtypesGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectSubtypesCreate(payload, dataAttribute) {
    return this.requestService
      .post('projectSubtypes', payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectSubtypesCreate', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectSubtypesDelete(id, dataAttribute) {
    return this.requestService
      .delete('projectSubtypes/' + id)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectSubtypesCreate', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectSubtypesSave(payload, dataAttribute) {
    return this.requestService
      .put('projectSubtypes/' + payload.id, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectSubtypesCreate', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectContactsGet(id, dataAttribute) {
    return this.requestService
      .get(`projects/${id}/contacts?per_page=9999`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectContactsGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectContactsCreate(id, payload) {
    return this.requestService
      .post(`projects/${id}/contacts`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectsContactsCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectContactsDelete(projectId, contactId) {
    return this.requestService
      .delete(`projects/${projectId}/contacts/${contactId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('productContactsDelete', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectContactsEdit(projectId, contactId, payload) {
    return this.requestService
      .put(`projects/${projectId}/contacts/${contactId}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('productContactsEdit', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

   async projectNotificationsGet(id, dataAttribute) {
    return this.requestService.get(`projects/${id}/notifications?per_page=9999`)
      .then((result) => {
        if (_getResultError(result)) { return _getResultError(result); }
        if (!result.data) { return _logicError('projectNotificationsGet', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(_catcherInTheCode);
  }

  async projectNotificationsCreate(id, payload) {
    return this.requestService.post(`projects/${id}/notifications`, payload)
      .then((result) => {
        if (_getResultError(result)) { return _getResultError(result); }
        if (!result.data) { return _logicError('projectNotificationsCreate', result); }
        return result.data;
      }).catch(_catcherInTheCode);
  }

  async projectNotificationsDelete(projectId, notificationId) {
    return this.requestService.delete(`projects/${projectId}/notifications/${notificationId}`)
      .then((result) => {
        if (_getResultError(result)) { return _getResultError(result); }
        if (!result.data) { return _logicError('projectNotificationsDelete', result); }
        return result.data;
      }).catch(_catcherInTheCode);
  }

  async materialFormDeadlineGet(id) {
    return this.requestService
      .get(`materialFormDeadlines`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async materialFormDeadlineCreate(payload) {
    return this.requestService
      .post(`materialFormDeadlines`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async materialFormDeadlineDelete(deadlineId) {
    return this.requestService
      .delete(`materialFormDeadlines/${deadlineId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async materialFormDeadlineEdit(deadlineId, payload) {
    return this.requestService
      .put(`materialFormDeadlines/${deadlineId}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async quoteDeadlineCreate(payload) {
    return this.requestService.post(`quoteDeadlines`, payload)
      .then((result) => {
        if (_getResultError(result)) { return _getResultError(result); }
        return result;
      }).catch(_catcherInTheCode);
  }

  async quoteDeadlineDelete(deadlineId) {
    return this.requestService.delete(`quoteDeadlines/${deadlineId}`)
      .then((result) => {
        if (_getResultError(result)) { return _getResultError(result); }
        return result;
      }).catch(_catcherInTheCode);
  }

  async quoteDeadlineEdit(deadlineId, payload) {
    return this.requestService.put(`quoteDeadlines/${deadlineId}`, payload)
      .then((result) => {
        if (_getResultError(result)) { return _getResultError(result); }
        return result;
      }).catch(_catcherInTheCode);
  }

  async projectCondominiumsGet(id, dataAttribute) {
    return this.requestService
      .get(`projects/${id}/contacts?per_page=9999`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectCondominiumsGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectFoldersGet(id, dataAttribute) {
    return this.requestService
      .get(`projects/${id}/folders?per_page=9999`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectFoldersGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumFoldersGet(projectId, dataAttribute) {
    return this.requestService
      .get(`condominium/project/${projectId}/folders`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumFoldersGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectFolderCreate(project, payload) {
    return this.requestService
      .post(`projects/${project.id}/folders`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectFolderCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectFolderDelete(folder) {
    return this.requestService
      .delete(`folders/${folder.id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectFolderDelete', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectFolderEdit(folder, payload) {
    return this.requestService
      .put(`folders/${folder.id}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectFolderEdit', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumProjectDocumentsGet(
    id,
    fields,
    converterFunc,
    dataAttribute
  ) {
    return this.requestService
      .get(`projects/${id}/condominiumdocuments?per_page=9999`)
      .then((result) => {
        if (result.error) {
          return { error: result.error };
        }
        const results = result.data.map((item, index) => {
          return converterFunc(item, id, fields, index);
        });
        return dataAttribute ? { [dataAttribute]: results } : results;
      })
      .catch(_catcherInTheCode);
  }

  async projectSchedulesGet(id, dataAttribute) {
    return this.requestService
      .get(`projects/${id}/schedules?per_page=9999`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectSchedulesGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectSchedulesCreate(projectId, payload) {
    return this.requestService
      .post(`projects/${projectId}/schedules`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectSchedulesCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectSchedulesDelete(projectId, scheduleId) {
    return this.requestService
      .delete(`projects/${projectId}/schedules/${scheduleId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('productSchedulesDelete', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectSchedulesEdit(projectId, scheduleId, payload) {
    return this.requestService
      .put(`projects/${projectId}/schedules/${scheduleId}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('productSchedulesEdit', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async materialFormsGet() {
    return this.requestService
      .get(`materialforms?per_page=9999`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('MaterialFormsGet', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectMaterialFormsGet(id, dataAttribute) {
    return this.requestService
      .get(`projects/${id}/materialforms?per_page=9999`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialFormsGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectMaterialFormGet(projectid, materialformid, dataAttribute) {
    return this.requestService
      .get(`projects/${projectid}/materialforms/${materialformid}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialFormsGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectMaterialFormsForThisApartmentGet(
    id,
    apartmentId,
    dataAttribute
  ) {
    return this.requestService
      .get(`projects/${id}/materialforms/apartment/${apartmentId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialFormsGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectMaterialFormsCreate(projectId, payload) {
    return this.requestService
      .post(`projects/${projectId}/materialforms`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialFormsCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectMaterialFormsDuplicate(
    projectid,
    materialformid,
    dataAttribute
  ) {
    return this.requestService
      .get(`projects/${projectid}/materialforms/${materialformid}/duplicate`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialFormsDuplicate', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectMaterialFormsUpdate(projectId, materialformId, payload) {
    return this.requestService
      .put(`projects/${projectId}/materialforms/${materialformId}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialFormsUpdate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectMaterialFormsDelete(projectId, id) {
    return this.requestService
      .delete(`projects/${projectId}/materialforms/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialFormsDelete', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectMaterialFormsGroupsCreate(materialformId, payload) {
    return this.requestService
      .post(`materialforms/${materialformId}/groups`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialFormsGroupsCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectMaterialFormsGroupsUpdate(materialformId, payload) {
    return this.requestService
      .put(`materialforms/${materialformId}/groups/${payload.id}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialFormsGroupsUpdate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectMaterialFormsGroupsDelete(materialformId, groupId) {
    return this.requestService
      .delete(`materialforms/${materialformId}/groups/${groupId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialFormsGroupsDelete', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectMaterialFormsSelectCreate(groupId, payload) {
    return this.requestService
      .post(`materialformgroups/${groupId}/selects`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialFormsSelectCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectMaterialFormsSelectUpdate(groupId, payload) {
    return this.requestService
      .put(`materialformgroups/${groupId}/selects/${payload.id}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialFormsSelectUpdate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectMaterialFormsSelectDelete(groupId, selectId) {
    return this.requestService
      .delete(`materialformgroups/${groupId}/selects/${selectId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialFormsSelectDelete', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectMaterialFormsOptionCreate(selectId, payload) {
    return this.requestService
      .post(`materialformselects/${selectId}/options`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialFormsOptionCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectMaterialFormsOptionUpdate(selectId, payload) {
    return this.requestService
      .put(`materialformselects/${selectId}/options/${payload.id}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialFormsOptionUpdate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectMaterialFormsOptionsDelete(selectId, optionId) {
    return this.requestService
      .delete(`materialformselects/${selectId}/options/${optionId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialFormsOptionUpdate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectMaterialquotesGet(projectId) {
    return this.requestService
      .get(`projects/${projectId}/materialquotes`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialquotesGet', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectMaterialquotesCreate(projectId, payload) {
    return this.requestService
      .post(`projects/${projectId}/materialquotes`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialquotesCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectMaterialQuotesGet(id, dataAttribute) {
    return this.requestService
      .get(`projects/${id}/materialquotes?per_page=9999`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectMaterialQuotesGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectDocumentsGet(id, fields, converterFunc, dataAttribute) {
    return this.requestService
      .get(`projects/${id}/documents?per_page=9999`)
      .then((result) => {
        if (result.error) {
          return { error: result.error };
        }
        const results = result.data.map((item, index) => {
          return converterFunc(item, id, fields, index);
        });
        return dataAttribute ? { [dataAttribute]: results } : results;
      })
      .catch(_catcherInTheCode);
  }

  async allDocumentsFromPicturesFolderGet(projectId, dataAttribute) {
    return this.requestService
      .get(`projects/${projectId}/pictures`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          _logicError('allDocumentsFromPicturesFolderGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectCondominiumSet(projectId, condominiumId) {
    const payload = {
      condominium_id: condominiumId,
      project_id: projectId,
    };
    return this.requestService
      .post('condominiumprojects', payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('projectCondominiumSet', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async materialSelectionDeadlinesGet(id, dataAttribute) {
    return this.requestService
      .get(`projects/${id}/materialdeadlines?per_page=9999`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('materialSelectionDeadlinesGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async materialFormsDeadlineGet(id, dataAttribute) {
    return this.requestService
      .get(`project/${id}/materialformdeadlines`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('materialFormDeadlineGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async materialSelectionDeadlinesCreate(id, payload, dataAttribute) {
    return this.requestService
      .post(`projects/${id}/materialdeadlines`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('materialSelectionDeadlinesCreate', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async materialSelectionDeadlinesDelete(projectId, deadlineId, dataAttribute) {
    return this.requestService
      .delete(`projects/${projectId}/materialdeadlines/${deadlineId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('materialSelectionDeadlinesDelete', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async materialSelectionsGet(projectId, apartmentId, dataAttribute) {
    return this.requestService
      .get(`projects/${projectId}/apartments/${apartmentId}/materialselections`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('materialSelectionsGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async materialSelectionsPerSubmissionFormGet(
    projectId,
    apartmentId,
    submissionID,
    dataAttribute
  ) {
    return this.requestService
      .get(
        `projects/${projectId}/apartments/${apartmentId}/submissionForm/${submissionID}/materialselections`
      )
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('materialSelectionsGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async materialSelectionsCreate(payload) {
    return this.requestService
      .post(`materialFormSubmission`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('materialSelectionsCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async materialSelectionsUpdate(materialFormSubmissionId, payload) {
    return this.requestService
      .put(`materialFormSubmission/${materialFormSubmissionId}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('materialSelectionsUpdate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async materialSelectionsDelete(materialFormSubmissionId) {
    return this.requestService
      .delete(`materialFormSubmission/${materialFormSubmissionId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('materialSelectionsDelete', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async materialFormSubmissionGet(apartmentId, materialFormId, dataAttribute) {
    return this.requestService
      .get(`materialFormSubmission/${apartmentId}/${materialFormId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('materialFormSubmissionGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async getMaterialFormSubmissionsByApartment(apartmentId) {
    return this.requestService
      .get(`getMaterialFormSubmissionsByApartment/${apartmentId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('getMaterialFormSubmissionsByApartment', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async materialFormSubmissionSend(materialFormId, payload) {
    return this.requestService
      .post(`materialFormSubmissionStatus/${materialFormId}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('materialFormSubmissionGet', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async configurationGet() {
    return this.requestService
      .rawGet('instance-configuration')
      .then((config) => {
        if (_getResultError(config)) {
          return _getResultError(config);
        }

        return config;
      })
      .catch(_catcherInTheCode);
  }

  async reportForProducts(projectId, stairwell) {
    // /api/v1/projects/{projectId}/productreport/{stairwell}
    return this.requestService
      .rawGet(`api/v1/projects/${projectId}/productreport/${stairwell}`, {
        tokenify: true,
        text: true,
      })
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async reportForProject(projectId) {
    return this.requestService
      .get(`projects/${projectId}/productreportforproject/`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async termsGet() {
    return this.requestService
      .rawGet('api/v1/privacyterms')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('termsGet', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async userGet(id) {
    return this.requestService
      .get(`users/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('userGet');
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async usersGet() {
    return this.requestService
      .get('users?per_page=9999')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('usersGet');
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async userTypesGet(dataAttribute) {
    return this.requestService
      .get('userTypes?per_page=9999')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          _logicError('userTypesGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async userPhotoGet(id) {
    return this.requestService
      .getImage(`userphoto/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('userPhotoGet', result);
        }

        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(result);
          reader.onloadend = () => {
            resolve(reader.result);
          };
        });
      })
      .catch(() => {
        return { error: true };
      });
  }

  async userPhotoCreate(id, photo) {
    return this.requestService
      .postUserPhoto(`userphoto/${id}`, photo)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('userPhotoUpdate', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async userPhotoDelete(id) {
    return this.requestService
      .delete(`userphoto/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('userPhotoDelete', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async userPasswordChange(payload) {
    return this.requestService
      .post('user/change-password', payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }

        return result;
      })
      .catch(_catcherInTheCode);
  }

  async userPasswordManualChange(payload) {
    return this.requestService.post('user/manual-password', payload)
      .then((result) => {
        if (_getResultError(result)) { return _getResultError(result); }

        return result;
      }).catch(_catcherInTheCode);
  }

  async userPasswordReset(email) {
    const payload = { email };
    return this.requestService
      .post('user/reset-password', payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }

        return result;
      })
      .catch(_catcherInTheCode);
  }

  async userCategoriesGet() {
    return this.requestService
      .get('usercategories')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async userCategoryCreate(payload) {
    return this.requestService
      .post('usercategories', payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async userCategoryDelete(id) {
    return this.requestService
      .delete(`usercategories/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async companiesGet(dataAttribute) {
    return this.requestService
      .get('companies?per_page=9999')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('companiesGet');
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async companyGet(id) {
    return this.requestService
      .get(`companies/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('companyGet');
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async companyCreate(company) {
    return this.requestService
      .post('companies', company)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('companyCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async companyUpdate(company) {
    return this.requestService
      .put(`companies/${company.id}`, company)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('companyDelete', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async companyDelete(company) {
    return this.requestService
      .delete(`companies/${company.id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('companyDelete', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async companyUsersCreate(company, user) {
    return this.requestService
      .post(`companies/${company.id}/users`, user)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('companyUsersCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async companyUsersUpdate(company, user) {
    return this.requestService
      .put(`companies/${company.id}/users/${user.id}`, user)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('companyUsersUpdate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async companyUsersSetPassword(user, company, newPassword) {
    return this.requestService
      .put(
        `companies/${company.id}/users/${user.id}/resetpassword`,
        newPassword
      )
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('companyUsersSetPassword', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async companyUsersDelete(company, user) {
    if (!company || !user) {
      return { error: 'Invalid input, need ID and user!' };
    }
    return this.requestService
      .delete(`companies/${company.id}/users/${user.id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('companyUsersDelete', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async companyUsersGet(companyId) {
    return this.requestService
      .get(`companies/${companyId}/users`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('companyUsersGet', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async companyUserRoleAdd(companyId, userId, roleId) {
    const payload = {
      id: roleId,
    };

    return this.requestService
      .post(`companies/${companyId}/users/${userId}/roles`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('companyUserRoleAdd', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async companyUserRoleRemove(companyId, userId, roleId) {
    return this.requestService
      .delete(`companies/${companyId}/users/${userId}/roles/${roleId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('companyUserRoleRemove', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async fieldsGet(dataAttribute) {
    return this.requestService
      .get('fields?per_page=9999')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          _logicError('fieldsGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async keywordsGet(dataAttribute) {
    return this.requestService
      .get('allKeywords?per_page=9999')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          _logicError('keywordsGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async companyTypesGet(dataAttribute) {
    return this.requestService
      .get('companyTypes?per_page=9999')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          _logicError('companyTypesGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async rolesGet() {
    return this.requestService
      .get('roles?per_page=9999')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          _logicError('rolesGet', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async rolesTypedGet(dataAttribute) {
    return this.requestService
      .get('roles_typed')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          _logicError('rolesTypedGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async documentGet(id, dataAttribute) {
    return this.requestService.get('documents/' + id).then((result) => {
      if (_getResultError(result)) {
        return _getResultError(result);
      }
      if (!result.data) {
        _logicError('documentGet', result);
      }
      return dataAttribute ? { [dataAttribute]: result.data } : result.data;
    });
  }

  async documentDeleteRequestsGet() {
    return this.requestService
      .get('document-delete-requests?per_page=9999')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('documentDeleteRequestsGet');
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async documentDeleteRequestCreate(id) {
    const payload = { document_id: id };
    return this.requestService
      .post('document-delete-requests', payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('documentDeleteRequestCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async documentDeleteRequestAccept(payload) {
    if (!payload) {
      return { error: 'Invalid input, need d!' };
    } // TODO: Improve

    return this.requestService
      .post(`document-delete-requests/${payload.id}/accept`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('documentDeleteRequestAccept', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async documentDeleteRequestReject(payload) {
    if (!payload) {
      return { error: 'Invalid input, need payload!' };
    } // TODO: Improve

    return this.requestService
      .delete(`document-delete-requests/${payload.id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('documentDeleteRequestReject', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async documentTypesGet(dataAttribute) {
    return this.requestService
      .get('document-types')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('getDocumentTypes', result);
        }

        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async documentTypesCreate(name) {
    const payload = {
      name,
      is_immutable: false, // TODO
      is_default: false, // TODO
      is_active: true, // TODO
    };

    return this.requestService
      .post('document-types', payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('documentTypesCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async documentTypesDelete(id) {
    return this.requestService
      .delete(`document-types/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('userPhotoDelete', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async documentTypesUpdate(id, payload) {
    return this.requestService
      .put(`document-types/${id}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('documentTypesUpdate', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async documentTypeFieldsUpdate(id, payload) {
    return this.requestService
      .put(`document-types/${id}/fields`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('documentTypesUpdate', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async fieldCreate({ fieldName, fieldType, keywords, showInDocumentsTable }) {
    const payload = {
      key: fieldName,
      type: fieldType,
      keywords,
      show_in_documents_table: false, // Will this be used or not?
    };

    return this.requestService
      .post('fields', payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('fieldCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async fieldUpdate(id, { fieldName, fieldType, keywords }) {
    const payload = {
      key: fieldName,
      type: fieldType,
      keywords,
      show_in_documents_table: true,
    };

    return this.requestService
      .put(`fields/${id}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('fieldUpdate', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async fieldDelete(id) {
    return this.requestService
      .delete(`fields/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('fieldDelete', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async keywordUpdate(payload) {
    // It seems that core only checks the keyword id - field id is irrelevant
    return this.requestService
      .put(`fields/0/keywords/${payload.keyword}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('keywordUpdate', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async keywordDelete(id) {
    // It seems that core only checks the keyword id - field id is irrelevant
    return this.requestService
      .delete(`fields/0/keywords/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('keywordDelete', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async notificationGet() {
    return this.requestService
      .get('notifications?per_page=9999')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('notificationGet', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async notificationGetLatest() {
    return this.requestService
      .get('notifications/latest')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('notificationGet', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async apartmentNotificationsGet(apartmentId, dataAttribute) {
    return this.requestService
      .get(`apartment/${apartmentId}/notifications`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('apartmentNotificationsGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumNotificationsGet(condominiumId, dataAttribute) {
    return this.requestService
      .get(`condominium/${condominiumId}/notifications`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumNotificationsGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async globalNotificationsGet(condominiumId, dataAttribute) {
    return this.requestService
      .get(`notifications/global`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('globalNotificationsGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async notificationCreate(payload) {
    const options = {
      indices: true,
      nullsAsUndefineds: false,
      booleansAsIntegers: true,
    };

    return this.requestService
      .post('notifications', objectToFormData(payload, options))
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('notificationsCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async notificationEdit(payload, id) {
    let isPublished = payload.is_published;
    if (isPublished === true || isPublished === 1) {
      payload.is_published = 1;
    } else {
      payload.is_published = 0;
    }

    return this.requestService
      .put(`notifications/${id}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('notificationsCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async notificationDelete(id) {
    return this.requestService
      .delete(`notifications/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('notificationDelete', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async deleteDocuments(ids, projectId) {
    const payload = { ids };

    return this.requestService
      .post(`projects/${projectId}/documents/delete`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('deleteDocuments', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async search(payload) {
    if (!payload) {
      return { error: 'payload is mandatory' }; // TODO: Convert to error code
    }
    if (!payload.query) {
      return { error: 'payload must contain query' }; // TODO: Convert to error code
    }
    return this.requestService
      .post('search', payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('search', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async searchResultDetails(documentId, query) {
    if (!documentId) {
      return { error: 'documentId is mandatory' }; // TODO: Convert to error code
    }

    return this.requestService
      .get('searchResultDetails/' + documentId + '/' + query)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('searchResultDetails', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectStatusesGet(dataAttribute) {
    return this.requestService
      .get('project-statuses')
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          _logicError('projectStatusesGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectStatusEdit(payload, id) {
    return this.requestService
      .put(`project-statuses/${id}/edit`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          _logicError('projectStatusEdit', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectStatusAdd(payload) {
    return this.requestService
      .post(`project-statuses/add`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          _logicError('projectStatusAdd', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectsWithStatusGet(id) {
    return this.requestService
      .get(`project-statuses/projects/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          _logicError('projectsWithStatusGet', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectStatusDelete(id) {
    return this.requestService
      .delete(`project-statuses/${id}/delete`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          _logicError('projectStatusDelete', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async projectStatusGet(id) {
    return this.requestService
      .get(`project-statuses/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          _logicError('projectStatusGet', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async getCondominiumUsers(condominiumId) {
    return this.requestService
      .get(`condominium/${condominiumId}/users`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          _logicError('projectStatusGet', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async modifyCondominiumUsersRole(condominiumId, userId) {
    return this.requestService
      .get(`condominium/${condominiumId}/user/${userId}/modifyRole`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          _logicError('projectStatusGet', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumUserCreate(id, payload) {
    return this.requestService
      .post(`condominium/${id}/users`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumUserCreate', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumUserDelete(id, userId) {
    return this.requestService
      .delete(`condominium/${id}/users/${userId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumUserDelete', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async condominiumUserEdit(id, userId, payload) {
    return this.requestService
      .put(`condominium/${id}/users/${userId}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('condominiumUserEdit', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async getApartmentUsers(condoId, apartmentId, dataAttribute) {
    return this.requestService
      .get(`condominium/${condoId}/apartments/${apartmentId}/users`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('getApartmentUsers', result);
        }
        return dataAttribute ? { [dataAttribute]: result } : result;
      })
      .catch(_catcherInTheCode);
  }

  async modifyApartmentRole(condoId, apartmentId, userId, payload) {
    return this.requestService
      .post(
        `condominium/${condoId}/apartments/${apartmentId}/user/${userId}/modifyRole`,
        payload
      )
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('modifyApartmentRole', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async getApartmentAllowedRoles(condoId, apartmentId) {
    return this.requestService
      .get(`condominium/${condoId}/apartments/${apartmentId}/getAllowedRoles`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('getApartmentAllowedRoles', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async removeAptUserRole(condoId, apartmentId, userId, payload) {
    return this.requestService
      .delete(
        `condominium/${condoId}/apartments/${apartmentId}/user/${userId}/destroy`,
        payload
      )
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('removeAptUserRole', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async quoteForRoomCardGet(quoteId, materialFormId, dataAttribute) {
    return this.requestService
      .get(`materialquotes/${quoteId}/${materialFormId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('quoteForRoomCardGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result } : result;
      })
      .catch(_catcherInTheCode);
  }

  async roomCardSelectionsGet(quoteId, dataAttribute) {
    return this.requestService
      .get(`materialquotes/${quoteId}/selections`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('roomCardSelectionsGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async roomCardUpload(quoteId, file) {
    return this.requestService.put(`room-card/${quoteId}/upload`, file, true)
      .then((result) => {
        if (_getResultError(result)) { return _getResultError(result); }
        if (!result) { return _logicError('roomCardUpload', result); }
        return result.data;
      }).catch(_catcherInTheCode);
  }

  async roomCardDownload(quoteId) {
    return this.requestService.get(`room-card/${quoteId}/download`)
      .then((result) => {
        if (_getResultError(result)) { return _getResultError(result); }
        if (!result) { return _logicError('roomCardDownload', result); }
        return result.data;
      }).catch(_catcherInTheCode);
  }

  async roomCardDelete(quoteId) {
    return this.requestService.delete(`room-card/${quoteId}/delete`)
      .then((result) => {
        if (_getResultError(result)) { return _getResultError(result); }
        if (!result) { return _logicError('roomCardDelete', result); }
        return result.data;
      }).catch(_catcherInTheCode);
  }

  async roomCardApprove(quoteId) {
    return this.requestService.post(`room-card/${quoteId}/approve`)
      .then((result) => {
        if (_getResultError(result)) { return _getResultError(result); }
        if (!result) { return _logicError('roomCardApprove', result); }
        return result.data;
      }).catch(_catcherInTheCode);
  }

  async newUserRequestCreate(payload, dataAttribute) {
    return this.requestService
      .post(`portal/user`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('newUserRequestCreate', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async additionalProductsGet(dataAttribute) {
    return this.requestService
      .get(`additional-products`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('additionalProductsGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async additionalProductAdd(formData) {
    return this.requestService
      .post(`additional-products/add`, formData)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('additionalProductAdd', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async additionalProductEdit(id, payload) {
    return this.requestService
      .put(`additional-products/${id}`, payload, true)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          return _logicError('productsEdit', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async additionalProductDelete(id) {
    return this.requestService
      .delete(`additional-products/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('additionalProductDelete', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async additionalProductsForMaterialFormGet(formId, dataAttribute) {
    return this.requestService.get(`additional-products/form/${formId}`)
    .then((result) => {
      if (_getResultError(result)) { return _getResultError(result); }
      if (!result) { return _logicError('additionalProductDelete', result); }
      return dataAttribute ? { [dataAttribute]: result.data } : result.data;
    }).catch(_catcherInTheCode);
  }

  async materialQuoteAdditionalRowAdd(quoteId, payload) {
    return this.requestService
      .post(`quote-additional-rows/quote/${quoteId}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('materialQuoteAdditionalRowAdd', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }

  async materialQuoteAdditionalRowsGet(quoteId, dataAttribute) {
    return this.requestService
      .get(`quote-additional-rows/quote/${quoteId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('materialQuoteAdditionalRowsGet', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async materialQuoteAdditionalRowsDelete(quoteId, rowId, dataAttribute) {
    return this.requestService
      .delete(`quote-additional-rows/quote/${quoteId}/row/${rowId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('materialQuoteAdditionalRowsDelete', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async materialQuoteAdditionalRowEdit(quoteId, rowId, payload, dataAttribute) {
    return this.requestService
      .put(`quote-additional-rows/quote/${quoteId}/row/${rowId}`, payload, true)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('materialQuoteAdditionalRowEdit', result);
        }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      })
      .catch(_catcherInTheCode);
  }

  async materialQuoteRowsGet(quoteId, dataAttribute) {
    return this.requestService.get(`materialquotes/${quoteId}/rows`)
    .then((result) => {
      if (_getResultError(result)) { return _getResultError(result); }
      if (!result) { return _logicError('materialQuoteRowsGet', result); }
      return dataAttribute ? { [dataAttribute]: result.data } : result.data;
    }).catch(_catcherInTheCode);
  }

  async tagsGet() {
    return this.requestService
      .get(`tags`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('tagsGet', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  // gets products belonging to tag
  async tagProductsGet(id) {
    return this.requestService
      .get(`producttags/${id}/products`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('tagProductsGet', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async categoryProductTagsGet(id) {
    return this.requestService
      .get(`productcategories/${id}/tags`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          _logicError('categoryProductTagsGet', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async tagCreate(payload) {
    return this.requestService
      .post(`tags`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          _logicError('tagCreate', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async tagDelete(id) {
    return this.requestService
      .delete(`tags/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('tagDelete', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async tagEdit(id, payload) {
    return this.requestService
      .put(`tags/${id}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          _logicError('tagEdit', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async mobilelinkGet() {
    return this.requestService
      .get(`mobilelinks`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('mobilelinkGet', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async mobilelinkCreate(payload) {
    return this.requestService
      .post(`mobilelinks`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          _logicError('mobilelinkCreate', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async mobilelinkDelete(id) {
    return this.requestService
      .delete(`mobilelinks/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('mobilelinkDelete', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async mobilelinkEdit(id, payload) {
    return this.requestService
      .put(`mobilelinks/${id}`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          _logicError('mobilelinkEdit', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async csvImport(type, payload) {
    return this.requestService
      .csvImport(type, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          _logicError('csvImport', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async progressNumbersGet(projectId) {
    return this.requestService
      .get(`projects/${projectId}/progressnumber-submissions`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          _logicError('progressnumbersGet', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async progressNumbersDelete(projectId, id) {
    return this.requestService
      .delete(`projects/${projectId}/progressnumber-submissions/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('progressNumbersDelete', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async progressNumbersAdd(projectId, payload) {
    return this.requestService
      .post(`projects/${projectId}/progressnumber-submissions`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('progressNumbersAdd', result);
        }
        return result.data;
      })
      .catch(_catcherInTheCode);
  }
  async summaryViewsGet() {
    return this.requestService
      .get(`summaryviews`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('summaryviewsGet', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async summaryViewsGetDocuments(id, fields, converterFunc, dataAttribute) {
    return this.requestService
      .get(`summaryviews/${id}/documents`)
      .then((result) => {
        if (result.error) {
          return { error: result.error };
        }
        const results = result.data.map((item, index) => {
          return converterFunc(item, id, fields, index);
        });
        return dataAttribute ? { [dataAttribute]: results } : results;
      })
      .catch(_catcherInTheCode);
  }

  async summaryViewCreate(payload) {
    return this.requestService
      .post(`summaryviews`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          _logicError('summaryViewCreate', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async summaryViewDelete(id) {
    return this.requestService
      .delete(`summaryviews/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('summaryviewDelete', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }
  async oneSummaryViewGet(id) {
    return this.requestService
      .get(`summaryviews/${id}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          _logicError('oneSummaryViewGet', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }
  async companyAttachmentDelete(companyId, id) {
    return this.requestService
      .get(`summaryviews/${id}/companies/remove/${companyId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('companyAttachmentDelete', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }
  async companyAttachmentAdd(companyId, id) {
    return this.requestService
      .get(`summaryviews/${id}/companies/add/${companyId}`)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result) {
          return _logicError('companyAttachmentAdd', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

  async userDelete(id) {
    return this.requestService.delete(`users/${id}`)
      .then((result) => {
        if (_getResultError(result)) { return _getResultError(result); }
        if (!result.data) { return _logicError('UserDelete', result); }
        return result.data;
      }).catch(_catcherInTheCode);
  }

  async summaryViewUpdateDocumentStatus(payload, summaryviewId, documentId) {
    return this.requestService
      .post(`summaryviews/${summaryviewId}/documents/${documentId}/status`, payload)
      .then((result) => {
        if (_getResultError(result)) {
          return _getResultError(result);
        }
        if (!result.data) {
          _logicError('updateDocumentStatus', result);
        }
        return result;
      })
      .catch(_catcherInTheCode);
  }

}
