// documentTable

import React, { Component } from "react";
import { Form, Button, Badge } from "react-bootstrap";
import I18n from "i18n-js";
import { arrayToTree } from "performant-array-to-tree";
import AvainiaCore from "avainia-core-api";
import { List, Table, AlignLeft, Plus } from "../../multiview/Icon/Icon.js";
import LocalStorageService from "../../../AvainiaTools/LocalStorageService";
import IconifiedDocumentGrid from "../Projects/Project/IconifiedDocumentGrid.js";
import DocumentReportDate from "../Projects/Project/DocumentReportDate.js";
import DocumentReportType from "../Projects/Project/DocumentReportType.js";
import AvainiaPermissions from "../../../AvainiaTools/AvainiaPermissions.js";
import AvainiaTable from "../../multiview/AvainiaTable/AvainiaTable.js";
import "./SummaryTable.scss";
import Error from "../../multiview/Error/Error.js";
import Loading from "../../multiview/Loading/Loading.js";

import MobileFilters from "../Projects/Project/MobileFilters.js";
import SummaryFilters from "./SummaryFilters.js";

const Modals = {
  documentAdd: 1,
  folderAdd: 2,
  folderEdit: 3,
  moveToFolder: 4,
  deleteFiles: 5,
};

class SummaryTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // activeFolder: null,
      tableMode: this.props.layout || "table",
      modal: false,
      iconified: false,
      exportIds: [],
      exportLoading: false,
      reportsDropdown: false,
      settingsDropdown: false,
      tableConfig: LocalStorageService.getTableListConfig(),
      loading: false,
      error: false,
      moveToFolder: "",
      projectType: "",
    };
  }

  componentDidMount = () => {
    const allFolders = this.getAllFolders();
    let activeFolder = null;

    //this.setState({ moveToFolder: allFolders });

    if (this.props.configuration.folderListModel === "tree") {
      activeFolder = allFolders.find((folder) => folder.id === 0);
    }

    // Get initial configuration state from instance-config and set it as starting point for tableConfig.
    let initialTableConfig = {};
    const documentConfig = this.props.configuration.document
      ? this.props.configuration.document.summaryTableSettings
      : null;

    if (documentConfig) {
      for (const [, type] of Object.entries(documentConfig)) {
        for (const setting of Object.entries(type)) {
          initialTableConfig[setting[0]] = setting[1];
        }
      }
      initialTableConfig["documentConfig"] = true;
    } else {
      initialTableConfig["documentConfig"] = false;
    }

    LocalStorageService.setTableListConfig(initialTableConfig);
    this.setState({ tableConfig: initialTableConfig, moveToFolder: allFolders, activeFolder });
  };

  setTableMode = (mode) => {
    this.props.toggleLayout(mode);
    this.setState({ tableMode: mode, reportsDropdown: false });
  };

  getAllFolders = () => {
    const allFolders = [
      ...this.props.folders,
      { id: 0, name: `${I18n.t("views.infraproject.main-folder")}`, parent_id: null },
    ];
    allFolders.forEach((folder) => {
      folder.label = folder.name;
      folder.key = folder.id;
    });

    const sortedFolders = allFolders.sort((a, b) => {
      if (a.id === b.id) {
        return 0;
      }
      if (a.id === 0) {
        return -1;
      }
      if (b.id === 0) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    const treeData = arrayToTree(sortedFolders, { parentId: "parent_id", dataField: null, childrenField: "nodes" });
    return treeData;
  };

  toggleIconified = () => {
    this.setState({ iconified: !this.state.iconified });
  };

  hideModal = () => {
    this.setState({ modal: false });
  };

  moveToFolder = () => {
    // Condo project fails to get the folder listing on load, this one gets the listing when the modal is opened.
    this.setState({
      moveToFolder: this.getAllFolders(),
      modal: Modals.moveToFolder,
    });
  };

  getDocument = (id) => {
    return this.props.documents.find((doc) => doc.id === id);
  };

  getFolderDescendents = (folderId) => {
    const children = this.props.folders.filter((x) => x.parent_id === folderId);
    let descendents = children;

    children.forEach((x) => {
      descendents = descendents.concat(this.getFolderDescendents(x.id));
    });

    return descendents;
  };

  updateTableConfig = (key) => {
    const { tableConfig } = this.state;

    tableConfig[key] = !tableConfig[key];
    LocalStorageService.setTableListConfig(tableConfig);
    this.setState({ tableConfig });
  };

  getDeletedPhaseWithName = (name) => {
    return this.props.allPhases ? this.props.allPhases.find((x) => x.name === name && x.deactivated) : null;
  };

  exportzip = () => {
    if (this.state.exportLoading) {
      return;
    }

    const documentIds = this.state.exportIds;
    if (typeof documentIds === "undefined" || documentIds.length <= 0) {
      return alert(I18n.t("views.infraproject.export-choose-one"));
    }

    this.setState({ exportLoading: true }, () => {
      const payload = {
        projectId: this.props.project.id,
        documentIds,
      };

      // TODO: Improve user feedback
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.createFileExportJob(payload).then((response) => {
        if (response.error) {
          alert(I18n.t("views.infraproject.export-error"));
        } else {
          alert(I18n.t("views.infraproject.export-success"));
        }
        this.setState({ exportLoading: false });
      });
    });
  };

  sortFolders = (a, b) => {
    if (a.id === b.id) {
      return 0;
    }
    if (a.parent_id === 0) {
      return -1;
    }
    if (b.parent_id === 0) {
      return -1;
    }
    return 0;
  };

  createParentCountTree = (folders) => {
    let folderTree = [];

    folders.forEach((folder) => {
      const parentCount = this.getParentCount(folder);
      let folderName = folder.name;

      const margin = 4 * parentCount;

      if (parentCount > 0) {
        folderName = folderName;
      }
      folderTree.push({ ...folder, name: folderName, parentCount });
    });
    return folderTree;
  };

  getParentCount = (folder, parentCount = 0) => {
    if (folder.parent_id === null) {
      return parentCount;
    }
    parentCount += 1;
    const parentFolder = this.props.folders.find((f) => f.id === folder.parent_id);
    return this.getParentCount(parentFolder, parentCount);
  };

  getKeywordColor = (state) => {
    const keywordColorId = this.props.thing.state.states.filter((x) => x.text === state);
    if(keywordColorId && keywordColorId[0] && keywordColorId[0].id){
      return this.props.thing.props.configuration.summaryviews.keywordColors[keywordColorId[0].id];
    }
  };

  makeBadges = () => {
    const badges = [];
    if (this.props.thing.state.view.activeFilters.subcontractor.length > 0) {
      this.props.thing.state.view.activeFilters.subcontractor.forEach((subcontractor) => {
        badges.push(
          <Badge variant="dark" className="rounded-pill">
            {subcontractor}
            <Plus
              id="subcontractor-filter"
              className="filter-block-button"
              onClick={this.props.thing.removeFilter}
              style={{
                fontSize: "20px",
                backgroundColor: "white",
                color: "black",
                borderRadius: "50%",
                marginLeft: "5px",
                rotate: "45deg",
              }}
            />
          </Badge>
        );
      });
    }

    if (this.props.thing.state.view.activeFilters.state.length > 0) {
      this.props.thing.state.view.activeFilters.state.forEach((state) => {
        const color = this.getKeywordColor(state) ? this.getKeywordColor(state) : 'black';
        badges.push(
          <Badge className="rounded-pill text-white" style={{ backgroundColor: color }}>
            {state}
            <Plus
              id="state-filter"
              className="filter-block-button"
              onClick={this.props.thing.removeFilter}
              style={{
                fontSize: "20px",
                backgroundColor: "white",
                color: "black",
                borderRadius: "50%",
                marginLeft: "5px",
                rotate: "45deg",
              }}
            />
          </Badge>
        );
      });
    }
    return badges;
  };

  render() {
    const config = LocalStorageService.getTableListConfig();
    const user = LocalStorageService.getUser();

    //! TODO! REFACTOR THIS PERMISSION CHECK TO ACTUALLY USE PERMISSIONS

    const currentFolderId = this.state.activeFolder ? Number(this.state.activeFolder.id) : Number(null);

    let folders = [];
    if (!this.props.configuration.folderListModel || this.props.configuration.folderListModel === "default") {
      folders = this.props.folders
        ? this.props.folders.filter((folder) => Number(folder.parent_id) === currentFolderId)
        : null;
    } else {
      const fileCount = this.props.documents.filter((doc) => doc.folder_id === "" || doc.folder_id == 0).length || 0;
      folders = this.createParentCountTree([
        ...this.props.folders,
        { id: 0, name: `${I18n.t("views.infraproject.main-folder")}`, parent_id: null, filecount: fileCount },
      ]);
    }

    const data = folders.sort(function (a, b) {
        if (a.name === "Main folder" || a.name === "Pääkansio") {
          return -1;
        }
        return a.name.localeCompare(b.name);
      }),
      tree = (function (data, root) {
        var r = [],
          o = {};
        data.forEach(function (a) {
          o[a.id] = { data: a, children: o[a.id] && o[a.id].children };
          if (a.parent_id === root) {
            r.push(o[a.id]);
          } else {
            o[a.parent_id] = o[a.parent_id] || {};
            o[a.parent_id].children = o[a.parent_id].children || [];
            o[a.parent_id].children.push(o[a.id]);
          }
        });
        return r;
      })(data, null),
      sorted = tree.reduce(function traverse(r, a) {
        return r.concat(a.data, (a.children || []).reduce(traverse, []));
      }, []);

    // Because of folder <-> phase matching, some folders will not be shown (they match a deactivated phase and they don't contain files. We need to show all files, even for deactivated phase folders!)
    const foldersToList = folders
      ? folders.filter((f) => {
          return !(f.filecount === 0 && this.getDeletedPhaseWithName(f.name)) && !f.main;
        })
      : null;

    let documentsToList = this.props.visibleDocuments;

    if (this.state.loading) {
      return <Loading inline />;
    }

    return (
      <>
        <div className="SummaryTable-topbar">
          {this.props.useFilters && <SummaryFilters thing={this.props.thing} />}
          {this.props.useFilters && <MobileFilters thing={this.props.thing} />}
          <div className="SummaryTable-controls">
            <div className="SummaryTable-controls-inner">
              {this.props.useIcons && (
                <>
                  <span
                    className={`SummaryTable-controls-modetoggle ${
                      this.state.tableMode === "table" ? "is-active" : ""
                    }`}
                    onClick={() => {
                      this.setTableMode("table");
                    }}
                  >
                    <List />
                  </span>
                  <span
                    className={`SummaryTable-controls-modetoggle ${
                      this.state.tableMode === "icons" ? "is-active" : ""
                    }`}
                    onClick={() => {
                      this.setTableMode("icons");
                    }}
                  >
                    <Table />
                  </span>
                  <span
                    className={`reports SummaryTable-controls-modetoggle ${
                      this.state.tableMode === "report-date" || this.state.tableMode === "report-type"
                        ? "is-active"
                        : ""
                    }`}
                  >
                    <span onClick={() => this.setState({ reportsDropdown: !this.state.reportsDropdown })}>
                      <AlignLeft />
                    </span>
                    {this.state.reportsDropdown && (
                      <span className="settings-dropdown">
                        <span
                          onClick={() => {
                            this.setTableMode("report-date");
                          }}
                        >
                          <input
                            defaultChecked={this.state.tableMode === "report-date"}
                            id="reporttype-1"
                            name="reporttype"
                            type="radio"
                          />
                          <label htmlFor="reporttype-1">{I18n.t("views.infraproject.sort-by-date")}</label>
                        </span>
                        <span
                          onClick={() => {
                            this.setTableMode("report-type");
                          }}
                        >
                          <input
                            defaultChecked={this.state.tableMode === "report-type"}
                            id="reporttype-2"
                            name="reporttype"
                            type="radio"
                          />
                          <label htmlFor="reporttype-2">{I18n.t("views.infraproject.sort-by-type")}</label>
                        </span>
                      </span>
                    )}
                  </span>
                </>
              )}
              <span
                className="settings SummaryTable-controls-modetoggle dropdown-toggle"
                onClick={() => this.setState({ settingsDropdown: !this.state.settingsDropdown })}
              >
                <span>{I18n.t("views.infraproject.settings")}</span>
                {this.state.settingsDropdown && (
                  <span
                    className="settings-dropdown"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Form.Check
                      type="checkbox"
                      label={I18n.t("views.infraproject.show-name")}
                      id="cfgNameText"
                      checked={this.state.tableConfig.viewNameText}
                      onChange={(e) => {
                        this.updateTableConfig("viewNameText");
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      label={I18n.t("views.infraproject.show-info")}
                      id="cfgDetailText"
                      checked={this.state.tableConfig.viewDetailText}
                      onChange={(e) => {
                        this.updateTableConfig("viewDetailText");
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      label={I18n.t("views.infraproject.show-position")}
                      id="cfgPositionText"
                      checked={this.state.tableConfig.viewPositionText}
                      onChange={(e) => {
                        this.updateTableConfig("viewPositionText");
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      label={I18n.t("views.infraproject.show-company")}
                      id="cfgAddedByCompany"
                      checked={this.state.tableConfig.viewAddedByCompany}
                      onChange={(e) => {
                        this.updateTableConfig("viewAddedByCompany");
                      }}
                    />
                    {config.showPhaseSelector ? (
                      <Form.Check
                        type="checkbox"
                        label={I18n.t("views.infraproject.show-phase")}
                        id="cfgPhaseText"
                        checked={this.state.tableConfig.viewPhaseText}
                        onChange={(e) => {
                          this.updateTableConfig("viewPhaseText");
                        }}
                      />
                    ) : null}
                    {config.showStateSelector ? (
                      <Form.Check
                        type="checkbox"
                        label={I18n.t("views.infraproject.show-subject")}
                        id="cfgSubjectText"
                        checked={this.state.tableConfig.viewSubjectText}
                        onChange={(e) => {
                          this.updateTableConfig("viewSubjectText");
                        }}
                      />
                    ) : null}
                    {config.showSubjectSelector ? (
                      <Form.Check
                        type="checkbox"
                        label={I18n.t("views.infraproject.show-state")}
                        id="cfgStateText"
                        checked={this.state.tableConfig.viewStateText}
                        onChange={(e) => {
                          this.updateTableConfig("viewStateText");
                        }}
                      />
                    ) : null}
                  </span>
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="badge-stack">{this.makeBadges()}</div>
        <div className="SummaryTable">
          {this.state.error && <Error inline error={this.state.error} />}
          {this.state.tableMode === "table" && (
            <>
              <AvainiaTable
                tableKey={this.state.key}
                condensed
                data={documentsToList}
                defaultSortedBy={[{
                  dataField: "state",
                  order: "desc"
                }]}
                keyField="counter"
                id="DocumentView"
                rowStyle={(row, rowIndex) => {
                  const style = {
                    backgroundColor: this.getKeywordColor(row.state) + "33",
                  };
                  if (this.props.documentActive && this.props.documentActive.id === row.id) {
                    style.backgroundColor = "lightgreen";
                  }
                  return style;
                }}
                rowClickHandler={(e, rowData) => {
                  this.props.documentActivate(this.getDocument(rowData.id));
                }}
                columns={[
                  {
                    dataField: "project_code",
                    text: I18n.t("views.projects.project"),
                    headerStyle: { width: "100px" },
                  },
                  {
                    dataField: "counter",
                    text: I18n.t("views.infraproject.nbr"),
                    headerStyle: { width: "60px" },
                    sort: true,
                    sortFunc: (a, b, order, dataField, rowA, rowB) => {
                      if (order === "asc") {
                        return rowA.counterNumber - rowB.counterNumber;
                      }

                      return rowB.counterNumber - rowA.counterNumber;
                    },
                  },

                  {
                    dataField: "files[0].extension",
                    text: I18n.t("views.documents.type"),
                    headerStyle: { width: "70px" },
                    sort: true,
                  },
                  {
                    dataField: "document_type.name",
                    text: I18n.t("documentType.document-type"),
                    formatter: (cell, row) => {
                      return row.document_type.is_default ? I18n.t("documentType.default") : row.document_type.name;
                    },
                    sort: true,
                  },
                  {
                    dataField: "phase",
                    text: I18n.t("views.infraproject.phase"),
                    sort: true,
                    hidden: config.documentConfig ? !config.viewPhaseText : false,
                  },
                  {
                    dataField: "subject",
                    text: I18n.t("views.infraproject.subject"),
                    sort: true,
                    hidden: config.documentConfig ? !config.viewSubjectText : false,
                  },
                  {
                    dataField: "state",
                    text: I18n.t("views.infraproject.state"),
                    sort: true,
                    hidden: config.documentConfig ? !config.viewStateText : false,
                  },
                  { dataField: "created", text: I18n.t("views.infraproject.created"), sort: true },
                  {
                    dataField: "summary_companies",
                    headerStyle: { width: "200px" },
                    text: I18n.t("views.summaryviews.subcontractor"),
                    formatter: (cell) => {
                      return (
                        <>
                          {cell.map((label) => (
                            <li style={{ listStyle: "none" }}>{label}</li>
                          ))}
                        </>
                      );
                    },
                    sort: true,
                  },
                  {
                    dataField: "detailText",
                    text: I18n.t("views.infraproject.info"),
                    hidden: !config.viewDetailText,
                    sort: true,
                  },
                  {
                    dataField: "positionText",
                    text: I18n.t("views.infraproject.position"),
                    hidden: !config.viewPositionText,
                    sort: true,
                  },
                  {
                    dataField: "addedByCompany",
                    text: I18n.t("views.infraproject.company"),
                    hidden: !config.viewAddedByCompany,
                    sort: true,
                  },
                ]}
              />
            </>
          )}

          {this.state.tableMode === "icons" && (
            <IconifiedDocumentGrid
              phases={this.props.phases}
              subjects={this.props.subjects}
              visibleDocuments={this.props.visibleDocuments}
              documentActive={this.props.documentActive}
              documentActivate={this.props.documentActivate}
            />
          )}

          {this.state.tableMode === "report-date" && (
            <DocumentReportDate
              phases={this.props.phases}
              subjects={this.props.subjects}
              visibleDocuments={this.props.visibleDocuments}
              documentActive={this.props.documentActive}
              documentActivate={this.props.documentActivate}
            />
          )}

          {this.state.tableMode === "report-type" && (
            <DocumentReportType
              phases={this.props.phases}
              subjects={this.props.subjects}
              visibleDocuments={this.props.visibleDocuments}
              documentActive={this.props.documentActive}
              documentActivate={this.props.documentActivate}
            />
          )}
        </div>
      </>
    );
  }
}

export default SummaryTable;
