import React, { Component } from 'react';
import { Button, ListGroup, InputGroup, FormControl } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import { Trash, Cog, Edit } from '../../multiview/Icon/Icon.js';
import AvainiaTable from '../../multiview/AvainiaTable/AvainiaTable.js';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import CreateProductForm from './CreateProductForm.js';
import ProductCategoryEditModal from './ProductCategoryEditModal.js';
import ProductEditModal from './ProductEditModal.js';
import { TopbarContext } from '../../../contexts/TopbarContext.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

const Modals = {
  editCategory: 1,
  editProduct: 2,
};

class Products extends Component {
  static contextType = TopbarContext;

  constructor(props) {
    super(props);

    this.state = {
      productCategories: [],
      products: [],
      activeCategory: false,
      categoryname: '',
      editing: false,
      modal: false,
      loading: true,
      secondaryloading: false,
      error: false,
      secondaryerror: false,
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.productCategoriesGet().then((productCategories) => {
      if (productCategories.error) { return this.setState({ error: productCategories.error }); }

      this.setState({ productCategories, loading: false }, this.context.resetTopbar);
    });
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  doSaveCategory = (e) => {
    this.setState({ loading: true }, () => {
      const payload = { name: this.state.categoryname };
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.productCategoriesCreate(payload).then((productCategory) => {
        if (productCategory.error) { return this.setState({ error: productCategory.error }); }
        const { productCategories } = this.state;
        productCategories.push(productCategory);
        this.setState({ productCategories, loading: false });
      });
    });
  }

  deleteCategory = (id) => {
    // TODO: implement UI
    // TODO: Confirm
    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.productCategoriesDelete(id).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }
        let { productCategories } = this.state;
        productCategories = productCategories.filter((x) => x.id !== id);
        this.setState({ productCategories, loading: false });
      });
    });
  }

  editCategory = (id) => {
    if (this.state.loading) { return; }

    const editingTarget = this.state.productCategories.find((x) => x.id === id);
    this.setState({ editing: editingTarget, modal: Modals.editCategory });
  }

  categoryEditCallback = (id, name) => {
    const { productCategories } = this.state;

    const target = productCategories.find((x) => x.id === id);
    if (target) { target.name = name; }

    this.setState({ productCategories });
  }

  productEditCallback = (product) => {
    const { products } = this.state;

    let target = products.find((x) => x.id === product.id);
    if (target) { target = product; }

    this.setState({ products });
  }

  deleteProduct = (product) => {
    // TODO: Confirm
    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.productsDelete(product.id).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }
        let { products } = this.state;
        products = products.filter((x) => x.id !== product.id);
        this.setState({ products, loading: false });
      });
    });
  }

  editProduct = (product) => {
    if (this.state.loading) { return; }

    const editingTarget = this.state.products.find((x) => x.id === product.id);
    this.setState({ editing: editingTarget, modal: Modals.editProduct });
  }

  activateCategory = (activeCategory, e) => {
    const act = document.querySelector('.pseudolink.active');
    if (act) { act.classList.toggle('active'); }
    e.target.classList.toggle('active');
    this.setState({ activeCategory, secondaryloading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.productsGet(activeCategory.id).then((products) => {
        if (products.error) { return this.setState({ secondaryerror: products.error }); }

        this.setState({ products, loading: false, secondaryloading: false });
      });
    });
  }

  productCreateCallback = (product) => {
    const { products } = this.state;
    products.push(product);
    this.setState({ products });
  }

  hideModal = () => { this.setState({ modal: false }); }

  renderActions = (cell, row) => {
    return <>
      <Trash data-todo="TODO: CONFIRM" onClick={() => { this.deleteProduct(row); }} className="clickable" />
      <Cog onClick={() => { this.editProduct(row); }} className="clickable" />
    </>;
  }

  render() {
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    return (

      <div className="App-container">
        <h1>{I18n.t('views.products.productcategories')}</h1>
        <ListGroup className="listing">
          {this.state.productCategories.map((pg) => <ListGroup.Item key={pg.id}>
            <span className="clickable" onClick={(e) => this.activateCategory(pg, e)}>{pg.name}</span>
            <Trash onClick={() => { this.deleteCategory(pg.id); }} />
            <Edit onClick={() => { this.editCategory(pg.id); }} />
          </ListGroup.Item>)}
        </ListGroup>
        {this.state.modal === Modals.editCategory &&
          <ProductCategoryEditModal productCategory={this.state.editing} onHide={this.hideModal} editCallback={this.categoryEditCallback} />
        }

        <h3>{I18n.t('views.products.create-new-product-category')}</h3>

        <InputGroup>
          <FormControl
            placeholder={I18n.t('views.products.name')}
            aria-label={I18n.t('views.products.name')}
          />
          <InputGroup.Append>
          <Button variant="primary" onClick={this.doSaveCategory}>
            {I18n.t('views.products.button-create')}
          </Button>
          </InputGroup.Append>
        </InputGroup>

        {this.state.activeCategory && <div className="categoryproducts">
          <h2>{I18n.t('views.products.products-for')} {this.state.activeCategory.name}</h2>

          { this.state.secondaryerror && <Error inline error={this.state.secondaryerror} /> }
          { this.state.secondaryloading && <Loading inline /> }

          <AvainiaTable data={this.state.products} keyField="id" columns={[
            { dataField: 'id', text: I18n.t('general.id'), headerStyle: { width: '60px' } },
            { dataField: 'manufacturer', text: I18n.t('views.products.manufacturer') },
            { dataField: 'code', text: I18n.t('views.products.code') },
            { dataField: 'name', text: I18n.t('views.products.name') },
            { dataField: 'description', text: I18n.t('views.products.description') },
            { dataField: 'price', text: I18n.t('views.products.price') },
            { dataField: 'measurements', text: I18n.t('views.products.measurements') },
            { dataField: 'external_link', text: I18n.t('views.products.external_link') },
            { dataField: 'actions', text: I18n.t('general.table-actions'), headerStyle: { width: '100px' }, formatter: this.renderActions },
          ]} />

          {this.state.modal === Modals.editProduct && <ProductEditModal product={this.state.editing} onHide={this.hideModal} editProductCallback={this.productEditCallback} />}

          <CreateProductForm
            successCallback ={this.productCreateCallback}
            productCategories={this.state.productCategories}
            selectedCategory={this.state.activeCategory}
          />
        </div>}
      </div>

    );
  }
}

export default Products;
