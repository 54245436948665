import React, { Component } from 'react';
import AvainiaCore from 'avainia-core-api';
import ModalNotificationShow from '../Modals/ModalNotificationShow.js';
import Notifications from './Notifications.js';
import '../../mainviews/Notifications/notificationsAdmin.scss';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

const Modals = {
  showNotificationModal: 1972,
};

export default class NotificationsLatest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeNotification: null,
      allNotifications: [],
      modal: null,
      moreNotifications: false,
      notifications: [],
    };
  }

  componentDidMount() {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.notificationGet().then((notifications) => {
      if (notifications && notifications.length > 0) {
        const publishedNotifications = notifications.filter((x) => x.is_published);

        api.notificationGetLatest().then((latestNotifications) => {
          if (latestNotifications && latestNotifications.length > 0) {
            const latestPublishedNotifications = latestNotifications.filter((x) => x.is_published);

            let moreNotifications = false;
            if (publishedNotifications.length > latestPublishedNotifications.length) {
              moreNotifications = true;
            }

            this.setState({
              notifications: latestPublishedNotifications,
              allNotifications: publishedNotifications,
              moreNotifications,
            });
          }
        });
      }
    });
  }

  showNotification = (activeNotification) => {
    this.setState({ modal: Modals.showNotificationModal, activeNotification });
  }

  hideModal = () => { this.setState({ modal: false }); }

  render() {
    const { activeNotification, modal } = this.state;

    return (
      <>
        <Notifications
          latestPublishedNotifications={this.state.notifications}
          publishedNotifications={this.state.allNotifications}
          showMore={this.state.moreNotifications}
          showNotification={this.showNotification}
        />
        {modal === Modals.showNotificationModal &&
          <ModalNotificationShow
            hide={this.hideModal}
            notification={activeNotification}
          />
        }
      </>
    );
  }
}
