import React, { Component } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import { Upload } from '../../multiview/Icon/Icon.js';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

const baseState = {
  manufacturer: '',
  name: '',
  code: '',
  description: '',
  price: 0,
  measurements: '',
  external_link: '',
  product_photo: undefined,
  product_context_photo: undefined,
  product_photo_preview: '',
  product_context_photo_preview: '',
  error: false,
  loading: false,
};

class CreateProductForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...baseState,
    };
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  handleImageChange = (e) => {
    if (e.target.files[0]) {
      const { name } = e.target;
      const reader = new FileReader();
      const file = e.target.files[0];

      reader.onloadend = () => {
        this.setState((prevState) => ({
          [name]: file,
          [`${name}_preview`]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  }

  save = (e) => {
    if (this.state.loading) { return; }

    this.setState({ loading: true, error: false }, () => {
      const payload = {
        product_category_id: this.props.selectedCategory.id,
        manufacturer: this.state.manufacturer,
        name: this.state.name,
        code: this.state.code,
        description: this.state.description,
        price: this.state.price,
        measurements: this.state.measurements,
        external_link: this.state.external_link,
      };

      if (this.state.product_photo) { payload.product_photo = this.state.product_photo; }
      if (this.state.product_context_photo) { payload.product_context_photo = this.state.product_context_photo; }

      const formData = new FormData();

      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });

      const api = new AvainiaCore(LocalStorageService.getToken);
      api.productsCreate(formData).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }
        return this.setState(baseState, () => this.props.successCallback(result));
      });
    });
  }

  render() {
    if (this.state.loading) { return <Loading inline />; }

    return <div>
      <h3>{I18n.t('views.products.create-new-product')}</h3>

      {this.state.error && <Error error={this.state.error} inline /> }

      <Form.Group controlId="form-manufacturer">
        <Form.Label>{I18n.t('views.products.manufacturer')}</Form.Label>
        <Form.Control type="text" name="manufacturer" value={this.state.manufacturer} onChange={this.onChange} isInvalid={!this.state.manufacturer} />
      </Form.Group>
      <Form.Group controlId="form-name">
        <Form.Label>{I18n.t('views.products.name')}</Form.Label>
        <Form.Control type="text" name="name" value={this.state.name} onChange={this.onChange} isInvalid={!this.state.name} />
      </Form.Group>
      <Form.Group controlId="form-code">
        <Form.Label>{I18n.t('views.products.code')}</Form.Label>
        <Form.Control type="text" name="code" value={this.state.code} onChange={this.onChange} isInvalid={!this.state.code} />
      </Form.Group>
      <Form.Group controlId="form-description">
        <Form.Label>{I18n.t('views.products.description')}</Form.Label>
        <Form.Control as="textarea" name="description" value={this.state.description} onChange={this.onChange} rows="3" />
      </Form.Group>
      <Form.Group controlId="form-price">
        <Form.Label>{I18n.t('views.products.price')}</Form.Label>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id="inputGroupPrepend">€</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control type="text" name="price" value={this.state.price} onChange={this.onChange} isInvalid={isNaN(this.state.price)} />
        </InputGroup>
      </Form.Group>
      <Form.Group controlId="form-measurements">
        <Form.Label>{I18n.t('views.products.measurements')}</Form.Label>
        <Form.Control type="text" name="measurements" value={this.state.measurements} onChange={this.onChange} />
      </Form.Group>
      <Form.Group controlId="form-external_link">
        <Form.Label>{I18n.t('views.products.external_link')}</Form.Label>
        <Form.Control type="text" name="external_link" value={this.state.external_link} onChange={this.onChange} />
      </Form.Group>
      <Form.Group controlId="form-category">
        <Form.Label>{I18n.t('views.products.productcategory')}</Form.Label>
        <Form.Control type="text" value={this.props.selectedCategory.name} readOnly />
      </Form.Group>
      <Form.Group>
        {/* <Form.Label>{I18n.t('views.products.product-photo-label')}</Form.Label> */}
        <Form.Control type="file" id="product_photo" className="inputfile" onChange={this.handleImageChange} name="product_photo" />
        <label htmlFor="product_photo" className="wide"><Upload /> {I18n.t('general.choose-photo')}</label>
        {this.state.product_photo_preview && <img className="product-preview-image" alt="preview" src={this.state.product_photo_preview}/> }
      </Form.Group>
      <Form.Group>
        {/* <Form.Label>{I18n.t('views.products.product-context-photo-label')}</Form.Label> */}
        <Form.Control type="file" id="product_context_photo" className="inputfile" onChange={this.handleImageChange} name="product_context_photo" />
        <label htmlFor="product_context_photo" className="wide"><Upload /> {I18n.t('general.choose-context-photo')}</label>
        {this.state.product_context_photo_preview && <img className="product-preview-image" alt="preview" src={this.state.product_context_photo_preview}/> }
      </Form.Group>

      <Button variant="success" onClick={this.save} data-todo="enable/disable based on validation">{I18n.t('views.products.button-create')}</Button>
    </div>;
  }
}

export default CreateProductForm;
