import React, { Component } from "react";
import { Form, Button, ListGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import I18n from "i18n-js";
import moment from "moment";
import AvainiaCore from "avainia-core-api";
import { Edit, Trash } from "../../../multiview/Icon/Icon.js";
import SchedulerGraph from "../../../multiview/Graph/SchedulerGraph.js";
import Error from "../../../multiview/Error/Error.js";
import Loading from "../../../multiview/Loading/Loading.js";
import ScheduleService from "../../../../AvainiaTools/ScheduleService.js";
import EditScheduleModal from "./EditScheduleModal.js";
import LocalStorageService from "../../../../AvainiaTools/LocalStorageService.js";

const Modals = {
  scheduleEdit: 1,
};

class SchedulesTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      baseKey: 0,
      completion: null,
      description: "",
      start_at: "",
      end_at: "",
      parent_id: 0,
      schedules: [],
      schedulesSorted: [],
      scheduleToEdit: false,
      modal: false,
      graphProps: false,
      error: false,
      loading: true,
    };
  }

  componentDidMount() {
    const api = new AvainiaCore(LocalStorageService.getToken);

    ScheduleService.getSchedules(this.props.project.id).then((data) => {
      const schedulesSorted = data.sorted;
      const schedules = data.unsorted;

      api.projectStatusesGet().then((projectStatuses) => {
        if (projectStatuses.error) {
          return;
        }

        this.setState({
          projectStatuses,
          schedulesSorted,
          schedules,
          loading: false,
        });
      });
    });
  }

  createSchedule = (e) => {
    const completionPercentage = parseInt(this.state.completion, 10);

    if (this.state.loading) {
      return;
    }

    if (!this.state.start_at | !this.state.end_at || !this.state.description) {
      this.setState({ error: 35 });
      return;
    }

    if (this.state.start_at.getTime() > this.state.end_at.getTime()) {
      this.setState({ error: 34 });
      return;
    }

    if (!Number.isNaN(completionPercentage)) {
      if (completionPercentage < 0 || completionPercentage > 100) {
        this.setState({ error: 37 });
        return;
      }
    }

    if (Number.isNaN(completionPercentage)) {
      if (
        (completionPercentage !== "" && !Number.isInteger(completionPercentage)) ||
        (completionPercentage !== null && !Number.isInteger(completionPercentage))
      ) {
        this.setState({ error: 38 });
        return;
      }
    }

    this.setState({ loading: true }, () => {
      const start = this.formatDateDB(this.state.start_at);
      const end = this.formatDateDB(this.state.end_at);
      const estimateStart = this.state.estimate_start_at && this.formatDateDB(this.state.estimate_start_at);
      const estimateEnd = this.state.estimate_end_at && this.formatDateDB(this.state.estimate_end_at);

      const payload = {
        description: this.state.description,
        start_at: start,
        end_at: end,
        estimate_start_at: estimateStart,
        estimate_end_at: estimateEnd,
        completion: completionPercentage,
      };

      if (this.state.parent_id) {
        payload.parent_id = this.state.parent_id;
      }

      const api = new AvainiaCore(LocalStorageService.getToken);
      api.projectSchedulesCreate(this.props.project.id, payload).then((schedule) => {
        if (schedule.error) {
          this.setState({ error: schedule.error });
        }
        this.setState({
          error: false,
          description: "",
          start_at: "",
          end_at: "",
          estimate_start_at: null,
          estimate_end_at: null,
          parent_id: 0,
          completion: null,
        });
        this.refreshView();
      });
    });
  };

  delete = (e) => {
    if (this.state.loading) {
      return;
    }

    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.projectSchedulesDelete(this.props.project.id, e.id).then((schedule) => {
        if (schedule.error) {
          this.setState({ error: schedule.error });
        }

        this.refreshView();
      });
    });
  };

  formatDateDB = (date) => {
    const d = new Date(date);
    const formatted = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;

    return formatted;
  };

  formChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({ [name]: value });
  };

  onChangeDatePicker = (date, target) => {
    this.setState({ [target]: date });
  };

  edit = (schedule) => {
    this.setState({ scheduleToEdit: schedule, modal: Modals.scheduleEdit });
  };

  hideModal = () => {
    this.setState({ modal: false });
  };

  refreshView = () => {
    this.setState({ loading: true }, () => {
      ScheduleService.getSchedules(this.props.project.id).then((data) => {
        const schedulesSorted = data.sorted;
        const schedules = data.unsorted;
        this.setState({
          schedulesSorted,
          schedules,
          loading: false,
          baseKey: this.state.baseKey + 1,
        });
      });
    });
  };

  renderScheduleChild(schedule) {
    if (schedule.children && schedule.children.length > 0) {
      return (
        <ListGroup className="listing">
          {schedule.children.map((child) => (
            <ListGroup.Item key={child.id}>
              {child.id}
              {this.getScheduleName(child)}
              {moment(child.start_at).format("DD.MM.YYYY")} - {moment(child.end_at).format("DD.MM.YYYY")}
              <Trash
                onClick={() => {
                  this.delete(child);
                }}
              />
              <Edit
                onClick={() => {
                  this.edit(child);
                }}
              />
              {this.renderScheduleChild(child)}
            </ListGroup.Item>
          ))}
        </ListGroup>
      );
    }
  }

  getScheduleName = (schedule) => {
    const defaultStatuses = ["active", "inactive", "before", "during", "after", "imported"];
    const slug = schedule.description;

    if (schedule.automatical) {
      if (schedule.description === "Projektien statukset" || schedule.description === "Project statuses") {
        return ` ${I18n.t("views.projects.project-statuses")} `;
      }
      if (defaultStatuses.includes(slug)) {
        const name = `views.projects.statuses.${[slug]}`;
        return ` ${I18n.t("views.projects.project-status-changed")}: ${I18n.t(name)} `;
      }
      return ` ${I18n.t("views.projects.project-status-changed")}: ${schedule.description} `;
    }
    return schedule.description;
  };

  render() {
    return (
      <div className="App-container schedule">
        <h1>{I18n.t("views.schedules.schedules")}</h1>

        <Error inline error={this.state.error} />
        {!this.state.error && this.state.loading && <Loading inline />}
        {!this.state.loading && (
          <>
            <ListGroup className="listing">
              {this.state.schedulesSorted.map((schedule) => (
                <ListGroup.Item key={schedule.id}>
                  {schedule.id}
                  {this.getScheduleName(schedule)}
                  {moment(schedule.start_at).format("DD.MM.YYYY")} - {moment(schedule.end_at).format("DD.MM.YYYY")}
                  <Trash
                    onClick={() => {
                      this.delete(schedule);
                    }}
                  />
                  <Edit
                    onClick={() => {
                      this.edit(schedule);
                    }}
                  />
                  {this.renderScheduleChild(schedule)}
                </ListGroup.Item>
              ))}
            </ListGroup>

            <h3>{I18n.t("views.schedules.create-schedule")}</h3>

            <Form.Group controlId="create-schedule">
              <Form.Label>{I18n.t("views.schedules.create-schedule-description")}</Form.Label>
              <Form.Control type="text" onChange={this.formChange} name="description" value={this.state.description} />

              <Form.Label>{I18n.t("views.schedules.create-schedule-start_at")}</Form.Label>
              <DatePicker
                name="start_at"
                selected={this.state.start_at}
                onChange={(date) => this.onChangeDatePicker(date, "start_at")}
                className="datepicker-input"
                dateFormat="dd.MM.yyyy"
              />

              <Form.Label>{I18n.t("views.schedules.create-schedule-end_at")}</Form.Label>
              <DatePicker
                name="end_at"
                selected={this.state.end_at}
                onChange={(date) => this.onChangeDatePicker(date, "end_at")}
                className="datepicker-input"
                dateFormat="dd.MM.yyyy"
              />

              <Form.Label>{I18n.t("views.schedules.create-schedule-estimate_start_at")}</Form.Label>
              <DatePicker
                name="estimate_start_at"
                selected={this.state.estimate_start_at}
                onChange={(date) => this.onChangeDatePicker(date, "estimate_start_at")}
                className="datepicker-input"
                dateFormat="dd.MM.yyyy"
              />

              <Form.Label>{I18n.t("views.schedules.create-schedule-estimate_end_at")}</Form.Label>
              <DatePicker
                name="estimate_end_at"
                selected={this.state.estimate_end_at}
                onChange={(date) => this.onChangeDatePicker(date, "estimate_end_at")}
                className="datepicker-input"
                dateFormat="dd.MM.yyyy"
              />

              <Form.Label>{I18n.t("views.schedules.create-schedule-parent_id")}</Form.Label>
              <Form.Control as="select" onChange={this.formChange} name="parent_id" value={this.state.parent_id}>
                <option key="0" value="0">
                  {" "}
                  -{" "}
                </option>
                {this.state.schedules.map((schedule) => (
                  <option key={schedule.id} value={schedule.id}>
                    {schedule.description}
                  </option>
                ))}
              </Form.Control>

              <Form.Label>{I18n.t("views.schedules.create-schedule-completion")}</Form.Label>
              <Form.Control type="text" onChange={this.formChange} name="completion" value={this.state.completion} />
            </Form.Group>
            <Button variant="primary" onClick={this.createSchedule}>
              {I18n.t("views.schedules.button-create")}
            </Button>
          </>
        )}

        {this.state.schedules.length > 0 && <SchedulerGraph schedules={this.state.schedules} />}

        {this.state.scheduleToEdit && (
          <EditScheduleModal
            key={this.state.baseKey}
            show={this.state.modal === Modals.scheduleEdit}
            onHide={this.hideModal}
            onRefresh={this.refreshView}
            schedule={this.state.scheduleToEdit}
            schedules={this.state.schedules}
            formatDateDB={this.formatDateDB}
            project={this.props.project}
          />
        )}
      </div>
    );
  }
}

export default SchedulesTab;
