import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Tab, Button } from 'react-bootstrap';
import { Edit, Trash } from '../../multiview/Icon/Icon.js';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import { TopbarContext } from '../../../contexts/TopbarContext.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import AvainiaTable from '../../multiview/AvainiaTable/AvainiaTable.js';
import MobilelinkEditModal from './MobilelinkEditModal.js';

class Mobilelinks extends Component {
  static contextType = TopbarContext;

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      loading: true,
      error: false,
      mobilelinks: [],
      mobilelinkEdited: null,
    };
  }

  componentDidMount() {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.mobilelinkGet().then((mobilelinks) => {
      if (mobilelinks.error) { return this.setState({ error: mobilelinks.error }); }
      mobilelinks = mobilelinks.sort((a,b) => a.order - b.order);
      this.setState({ mobilelinks, loading: false });
    });
  }

  deleteLink = (id) => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.mobilelinkDelete(id).then((mobilelink) => {
      window.location.reload();
    });
  }

  editLink = (id) => {
    let mobilelinkEdited = null;

    if(id == "new"){
      mobilelinkEdited = {id: null, title: "", link: "", order: 0};
    } else {
      mobilelinkEdited = this.state.mobilelinks.find((x) => (x.id === id));
    }
    this.setState({mobilelinkEdited});
  }

  render() {
    if (this.state.error) {
      return <Error error={this.state.error} />;
    }
    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <div className="App-container">
        <h1>{I18n.t('views.mobilelinks.page-title')}</h1>
        <Button onClick={()=>{this.editLink("new")}}>{I18n.t('views.mobilelinks.create-mobilelink')}</Button>
        <AvainiaTable
          data={this.state.mobilelinks}
          keyField="id"
          columns={[
            { dataField: 'id', text: I18n.t('general.id'), headerStyle: { width: '60px' } },
            { dataField: 'title', text: I18n.t('views.mobilelinks.title')},
            { dataField: 'link', text: I18n.t('views.mobilelinks.link')},
            { dataField: 'order', text: I18n.t('views.mobilelinks.order')},
            {
              dataField: 'id',
              text: "",
              formatter: (cell, row) => {
                return <>
                  <Edit style={{cursor:"pointer"}} onClick={() => { this.editLink(row.id); }} />
                  <Trash style={{cursor:"pointer"}} onClick={() => { this.deleteLink(row.id); }} />
                </>;
              },
            },
          ]}
        />
        {this.state.mobilelinkEdited &&
          <MobilelinkEditModal
            mobilelink={this.state.mobilelinkEdited}
            onHide={()=> {this.setState({mobilelinkEdited: null})}}
            onSave={()=> {window.location.reload()}}
          />
        }
      </div>
    );
  }
}

export default withRouter(Mobilelinks);
