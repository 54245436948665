import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Form, Button, Modal, Badge } from 'react-bootstrap';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import AvainiaCore from 'avainia-core-api';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class ApartmentMaterialselectionQuoteModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      selections: [],
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.apartment;
    // Get apartment selections for the provided materialforms

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.materialSelectionsGet(this.props.project.id, id).then((selections) => {
      if (selections.error) { return this.setState({ error: selections.error }); }

      this.setState({
        selections,
        name: this.props.quote ? this.props.quote.name : '',
        loading: false,
      });
    });
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  getSelectionForSelect = (select) => {
    const selection = this.state.selections.find((selection) => {
      return selection.material_form_select_id === select.id;
    });

    if (selection) { return selection; }

    let defaultOption = false;
    this.props.materialforms.forEach((mf) => {
      if (defaultOption) { return; }
      const opt = mf.options.find((o) => o.id === select.default_option_id);
      if (opt) {
        defaultOption = opt;
      }
    });

    if (!defaultOption) {
      console.error('Default option not found'); // TODO! real error
    }

    return {
      default: 1,
      name: defaultOption ? defaultOption.name : '-',
      price: defaultOption ? defaultOption.price : '-',
    };
  }

  createQuote = () => {
    if (this.state.loading) { return; }

    const projectId = this.props.project.id;
    const apartmentId = this.props.apartment.id;
    const payload = {
      apartment_id: apartmentId,
      name: this.state.name,
      rows: [],
    };

    // Gather all selections, explicit or implict as rows for the payload
    this.props.materialforms.forEach((materialform) => {
      materialform.selects.forEach((select) => {
        const selection = this.getSelectionForSelect(select);
        payload.rows.push({
          description: `${materialform.name}, ${selection.name}`,
          price: selection.price,
        });
      });
    });

    this.setState({ loading: 1 }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.projectMaterialquotesCreate(projectId, payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }

        alert('Success, please refresh page');// TODO! Improve, translate
        this.setState({ loading: false });
      });
    });
  }

  saveQuote = () => {
    const apartmentId = this.props.apartment.id;
    const payload = this.props.quote;

    payload.name = this.state.name;

    this.setState({ loading: 1 }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.apartmentMaterialQuoteSave(apartmentId, payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }

        alert('Success, please refresh page');// TODO! Improve, translate
        this.setState({ loading: false });
      });
    });
  }

  publishQuote = () => {
    const apartmentId = this.props.apartment.id;
    const payload = this.props.quote;

    payload.name = this.state.name;
    payload.status = 'sent';

    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.apartmentMaterialQuoteSave(apartmentId, payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }

        alert('Success, please refresh page');// TODO! Improve, translate
        this.setState({ loading: false });
      });
    });
  }

  getTotalPrice = () => {
    let total = 0;
    this.props.materialforms.forEach((materialform) => {
      materialform.selects.forEach((select) => {
        const selection = this.getSelectionForSelect(select);

        if (select.area) {
          total += Number(selection.price) * Number(select.area);
        } else {
          total += Number(selection.price);
        }
      });
    });

    return Number(total).toFixed(2);
  }

  render() {
    const { quote } = this.props;

    return <Modal show={true} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.materialforms.apartment-materialform-quote')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.error && <Error inline error={this.state.error} />}
        {!this.state.error && this.state.loading && <Loading inline />}
        {!this.state.error && !this.state.loading && <>

          <b>{I18n.t('views.materialforms.quote-name')}</b>
          <Form.Control name="name" value={this.state.name} type="text" onChange={this.onChange}></Form.Control>

          {quote && <p><Badge variant="info">{I18n.t(`views.materialforms.quote-status-${quote.status}`)}</Badge></p>}
          {!quote && <p><Badge variant="info">{I18n.t('views.materialforms.quote-status-unsaved')}</Badge></p>}

          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>{I18n.t('views.materialforms.materialform')}</th>
                <th>{I18n.t('views.materialforms.materialform-selection')}</th>
                <th>{I18n.t('general.price')}</th>
              </tr>
            </thead>
            <tbody>
              {this.props.materialforms.map((materialform) => materialform.selects.map((select) => <tr key={select.id}>
                <td>{materialform.name}</td>
                <td>
                  {this.getSelectionForSelect(select).name}
                  {this.getSelectionForSelect(select).default && <i>&nbsp; {I18n.t('views.materialforms.default-option')}</i>}
                </td>
                <td>{this.getSelectionForSelect(select).price} {select.area && <>x {select.area}</>}</td>
              </tr>))}
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td><b style={{ textDecoration: 'underline' }}>{I18n.t('general.total-price')}: {this.getTotalPrice()}</b></td>
              </tr>
            </tfoot>
          </table>

        </>}
      </Modal.Body>
      <Modal.Footer>
        {!quote && <Button variant="secondary" onClick={this.createQuote}>{I18n.t('views.materialforms.button-create-quote')}</Button>}
        {quote && quote.status === 'created' && <Button variant="secondary" onClick={this.saveQuote}>{I18n.t('views.materialforms.button-save-quote')}</Button>}
        {quote && quote.status === 'created' && <Button variant="secondary" onClick={this.publishQuote}>{I18n.t('views.materialforms.button-publish-quote')}</Button>}
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('views.materialforms.button-cancel')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default ApartmentMaterialselectionQuoteModal;
