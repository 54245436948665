import React, { useState, useEffect, useContext } from 'react';
import { Button, ButtonGroup, Container, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Document, Page } from 'react-pdf';
import I18n from 'i18n-js';
import { TopbarContext } from '../../../contexts/TopbarContext';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService';
import AvainiaCore from 'avainia-core-api';
import Loading from '../../multiview/Loading/Loading';
import Error from '../../multiview/Error/Error';
import SecureImage from '../../multiview/SecureImage/SecureImage';

const fetchDocumentBase64 = async (doc, project, callback) => {
  // `/api/v1/projects/${projectId}/documents/${item.id}/files/${item.files[0].id}
  const remoteUrl = window.Avainia.core + '/api/v1/projects/' + project.id + '/documents/' + doc.id + '/files/' + doc.files[0].id;
  const token = LocalStorageService.getToken();
  const obj = { headers: { Authorization: `Bearer ${token}` } };

  fetch(remoteUrl, obj)
    .then(r => r.blob())
    .then(blob => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result;
        const b64 = result.replace(/^data:.+;base64,/, ''); // remove; to be force-set
        const mime = doc.files[0].mime;
        callback({ base64: 'data:' + mime + ';base64,' + b64 });
      };
      reader.readAsDataURL(blob);
    });
};

const makeTextRenderer = (searchText) => {
  // https://gist.github.com/wojtekmaj/f265f55e89ccb4ce70fbd2f8c7b1d95d
  function highlightPattern(text, pattern) {
    const splitText = text.split(pattern);

    if (splitText.length <= 1) {
      return text;
    }

    // const matches = text.match(pattern);
    const matches = text.match(pattern);

    return splitText.reduce((arr, element, index) => (matches[index] ? [
      ...arr,
      element,
      <mark key={index}>
        {matches[index]}
      </mark>,
    ] : [...arr, element]), []);
  }

  return function textRenderer(textItem) {
    return highlightPattern(textItem.str, searchText);
  };
};

export default function SearchResultModal(props) {
  const { result, enrichedResult, hideModal } = props;
  const context = useContext(TopbarContext);
  const [numPages, setNumPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [doc, setDocument] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [project, setProject] = useState(null);

  const projectId = result.metadata?.project_id;

  useEffect(() => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.projectGet(projectId).then((project) => {
      if (project.error) {
        setError(doc.error);
        setLoading(false);
        return;
      }
      api.documentGet(result.id).then((doc) => {
        if (doc.error) {
          setError(doc.error);
          setLoading(false);
          return;
        }
        setProject(project);
        setDocument(doc);

        const file = doc.files[0];

        if (file.mime === "application/pdf") {
          fetchDocumentBase64(doc, project, (result) => {
            setPdfFile(result.base64);
            setLoading(false);
          });
        } else {
          setLoading(false);
        }
      });
    })
  }, [result.id, doc, projectId]);

  const download = async () => {
    try {
      const url = `/api/v1/projects/${projectId}/documents/${doc.id}/files/${doc.files[0].id}`;
      const obj = { headers: { Authorization: `Bearer ${LocalStorageService.getToken()}` } };
      const res = await fetch(process.env.REACT_APP_API_HOST + url, obj);

      if (res.ok) {
        const binary = await res.blob();

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(binary, doc.name);
        } else {
          const src = window.URL.createObjectURL(binary);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style.cssText = 'display: none';
          a.href = src;
          a.download = doc.name;
          a.click();
          window.URL.revokeObjectURL(src);
          setTimeout((x) => { document.body.removeChild(a); }, 1000);
        }
      } else if (res.status !== 410) { // 410 means dont retry
        throw new Error('failed to fetch document'); // TODO! Fatal error, do actual logging
      }
    } catch (ex) {
      console.error(ex); // TODO! Fatal error, do actual logging
    }
  }

  // console.log('Rendering result modal', { resolvedProjects, result, project, enrichedResult, doc });

  let hilightString = context.searchText;
  let page = 0;
  if (enrichedResult && enrichedResult.match?.best_matching_sentences?.content) {
    let maxSimilarity = 0;
    enrichedResult.match.best_matching_sentences.content.forEach((contentObject, index) => {
      if (contentObject.similarity > maxSimilarity) {
        page = index;
        maxSimilarity = contentObject.similarity;
        hilightString = contentObject.sentence.trim();
      }
    });
  }

  let thumb;
  if (doc && project) {
    // file = doc.files[0];
    // isImage = file.mime.indexOf("image") >= 0;
    thumb = `/api/v1/projects/${project.id}/documents/${doc.id}/thumb`;
  }

  return (
    <Modal
      show
      onHide={hideModal}
      className="search-result-modal"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('search.search-result')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loading hide={!loading} />
        <Error error={error} />

        {project && <b>{I18n.t('search.project')}: {project.name}</b>}

        {!pdfFile && <div>
          <SecureImage
            className="image-thumbnail image-thumbnail-mini result-thumb"
            src={thumb}
          />
        </div>}

        {pdfFile && <div className="search-modal-body">
          <Document file={pdfFile} onLoadSuccess={(pdf) => { setNumPages(pdf.numPages); }}>
            <Page
              pageNumber={page+1}
              customTextRenderer={makeTextRenderer(hilightString)}
            />
          </Document>
          <p>{I18n.t('search.page')} {page+1} / {numPages}</p>
        </div>}
      </Modal.Body>
      <Modal.Footer>
        <Container>
          {!loading && (
            <ButtonGroup className="d-flex">
              <Button onClick={download}>{I18n.t('search.download-file')}</Button>
              {project && (
                <Link
                  to={`/project/${project.id}/${result.id}`}
                  className="btn btn-primary"
                >
                  {I18n.t('search.move-to-project')}
                </Link>
              )}
              <Button onClick={hideModal} variant="secondary">
                {I18n.t('search.close')}
              </Button>
            </ButtonGroup>
          )}
        </Container>
      </Modal.Footer>
    </Modal>
  );
}
