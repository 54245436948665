import React, { Component } from 'react';
import I18n from 'i18n-js';
import Logger from 'js-logger';
import { Redirect } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './AvainiaTable.scss';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

/* This is a small wrapper for react-boostrap-table-next to future proof and set default always-on props */
class AvainiaTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navigate: false,
      key: '',
    };
  }

  componentDidMount = () => {
    this.setState({ key: this.props.tableKey });
  }

  clack = (e, row, rowIndex) => this.setState({ navigate: row.id });

  render() {
    if (this.state.navigate) {
      return <Redirect to={`/${this.props.rowClickIdRedirect}/${this.state.navigate}`} push={true} />;
    }

    const options = this.props.options || {};
    options.noDataText = I18n.t('general.avainiatable-no-data-text');
    delete (this.props.options);

    let onClick = () => {};

    let classes = '';
    if (this.props.rowClickIdRedirect) {
      onClick = this.clack;
      classes = 'clickable';
    } else if (this.props.rowClickHandler) {
      onClick = this.props.rowClickHandler;
      classes = 'clickable';
    }

    const rowEvents = { onClick };

    if (this.props.search) {
      Logger.error('Search is not currently supported');
    }

    let stripedValue = true;
    if (typeof this.props.striped !== 'undefined') {
      stripedValue = this.props.striped;
    }

    let defaultSorted = this.props.defaultSortedBy ? this.props.defaultSortedBy : null;

    // TODO: onTableChange ?
    // https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/table-props.html#ontablechange-function
    return <BootstrapTable
      remote={{filter: true}}
      noDataIndication={I18n.t('general.no-results')}
      {...this.props}
      striped={stripedValue}
      hover
      classes={`AvainiaTable ${classes}`}
      rowEvents={ rowEvents }
      // options={options}
      bootstrap4
      filter={ filterFactory() }
      defaultSorted={defaultSorted}
    />;
  }
}

export default AvainiaTable;
