export default class NewRequestService {
  constructor(token) {
    this.token = token;
  }

  static rejectWrapper(thing, reject) {
    console.log('RejectWrapper is firing for', thing);
    if (thing instanceof TypeError) {
      // Most likely the core is down or unreachable -- check locally by simply shutting down the core while using the app

      // Show an error to the user - this is completely outside of react, defined in index.html
      // TODO: const e = document.getElementById("connection-issue-modal");
      // TODO: e.classList.add("active");
      // TODO: Add loop to retry connection and show the user that this happens

      console.error('TypeError when contacting the core  - core unavailable?');
      reject(thing);
      return;
    }

    console.error('Unknown error when contacting core', thing);
    reject(thing);
  }

  async post(type, data) {
    const BaseURL = `${process.env.REACT_APP_API_HOST}/api/v1/`;
    const Payload = data instanceof FormData ? data : JSON.stringify(data);

    return new Promise((resolve, reject) => {
      const payload = {
        method: 'POST',
        body: Payload,
        headers: {
          Accept: 'application/json',
        },
      };

      if (!(data instanceof FormData)) { payload.headers['Content-Type'] = 'application/json'; }

      if (this.token) {
        payload.headers.Authorization = `Bearer ${this.token}`;
      }

      fetch(BaseURL + type, payload)
        .then(async (response) => {
          const error = (response.status > 299 || response.status < 199);
          let json;
          try {
            json = response.json ? await response.json() : null;
          } catch (e) {
            console.warn('Unable to parse JSON from API response', e);
          }
          if (error) {
            return reject({
              error: 'Request error',
              status: response.status,
              statusText: response.statusText,
              errorCode: json && json.errors ? json.errors : null,
            });
          }
          return resolve(json);
        }).catch((error) => { NewRequestService.rejectWrapper(error, reject); });
    });
  }

  async rawGet(url, settings) {
    const BaseURL = `${process.env.REACT_APP_API_HOST}/`;

    settings = settings || {};

    return new Promise((resolve, reject) => {
      const payload = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      if (settings.tokenify) {
        if (this.token) {
          payload.headers.Authorization = `Bearer ${this.token}`;
        }
      }

      fetch(BaseURL + url, payload)
        .then((response) => {
          if (response.status > 299 || response.status < 199) {
            return reject({
              error: 'Request error',
              status: response.status,
              statusText: response.statusText,
            });
          }

          if (settings.text) {
            response = response.text();
          } else {
            response = response.json();
          }

          return resolve(response);
        }).catch((error) => { NewRequestService.rejectWrapper(error, reject); });
    });
  }

  async get(type) {
    const BaseURL = `${process.env.REACT_APP_API_HOST}/api/v1/`;

    return new Promise((resolve, reject) => {
      const payload = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      if (this.token) {
        payload.headers.Authorization = `Bearer ${this.token}`;
      }

      fetch(BaseURL + type, payload)
        .then(async (response) => {
          const error = (response.status > 299 || response.status < 199);
          let json;
          try {
            json = response.json ? await response.json() : null;
          } catch (e) {
            console.warn('Unable to parse JSON from API response', e);
          }
          if (error) {
            return reject({
              error: 'Request error',
              status: response.status,
              statusText: response.statusText,
              errorCode: json && json.errors ? json.errors : null,
            });
          }
          return resolve(json);
        }).catch((error) => { NewRequestService.rejectWrapper(error, reject); });
    });
  }

  async delete(type, data) {
    const BaseURL = `${process.env.REACT_APP_API_HOST}/api/v1/`;
    const Payload = data instanceof FormData ? data : JSON.stringify(data);

    return new Promise((resolve, reject) => {
      const payload = {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: Payload,
      };

      if (this.token) {
        payload.headers.Authorization = `Bearer ${this.token}`;
      }

      fetch(BaseURL + type, payload)
        .then(async (response) => {
          const error = (response.status > 299 || response.status < 199);
          let json;
          try {
            json = response.json ? await response.json() : null;
          } catch (e) {
            console.warn('Unable to parse JSON from API response', e);
          }
          if (error) {
            return reject({
              error: 'Request error',
              status: response.status,
              statusText: response.statusText,
              errorCode: json && json.errors ? json.errors : null,
            });
          }
          return resolve(json);
        }).catch((error) => { NewRequestService.rejectWrapper(error, reject); });
    });
  }

  async updateFolder(urlEndPoint, folderId, projectId = null) {
    const BaseURL = `${process.env.REACT_APP_API_HOST}/api/v1/`;
    return new Promise((resolve, reject) => {
      const payload = {
        method: 'PUT',
        body: JSON.stringify({
          folder_id: folderId,
          project_id: projectId,
        }),
        headers: {
          Accept: 'application/json',
        },
      };

      payload.headers['Content-Type'] = 'application/json';

      if (this.token) {
        payload.headers.Authorization = `Bearer ${this.token}`;
      }

      const fetchUrl = BaseURL + urlEndPoint;

      fetch(fetchUrl, payload)
        .then(async (response) => {
          const error = (response.status > 299 || response.status < 199);
          let json;
          try {
            json = response.json ? await response.json() : null;
          } catch (e) {
            console.warn('Unable to parse JSON from API response', e);
          }
          if (error) {
            return reject({
              error: 'Request error',
              status: response.status,
              statusText: response.statusText,
              errorCode: json && json.errors ? json.errors : null,
            });
          }
          if (typeof response.length !== 'undefined') {
            response = response.length > 0 ? response = json : response;
            return resolve(response);
          }
          if (json) {
            response = json;
            return resolve(response);
          }
          console.log('Unexpected response state after POST!!');

          return resolve();
        }).catch((error) => { NewRequestService.rejectWrapper(error, reject); });
    });
  }

  async put(type, data, useFormData = false) {
    const BaseURL = `${process.env.REACT_APP_API_HOST}/api/v1/`;

    const formData = new FormData();

    if (useFormData) {
      for (const key in data) {
        formData.append(key, data[key]);
      }
    }

    return new Promise((resolve, reject) => {
      const payload = {
        method: useFormData ? 'POST' : 'PUT',
        body: useFormData ? formData : JSON.stringify(data),
        headers: {
          Accept: 'application/json',
        },
      };

      if (!useFormData) { payload.headers['Content-Type'] = 'application/json'; }

      const token = data.token || this.token;
      if (token) {
        payload.headers.Authorization = `Bearer ${token}`;
      }

      let fetchUrl = BaseURL + type;
      if (useFormData) { fetchUrl += '?_method=PUT'; }

      fetch(fetchUrl, payload)
        .then(async (response) => {
          const error = (response.status > 299 || response.status < 199);
          let json;
          try {
            json = response.json ? await response.json() : null;
          } catch (e) {
            console.warn('Unable to parse JSON from API response', e);
          }
          if (error) {
            return reject({
              error: 'Request error',
              status: response.status,
              statusText: response.statusText,
              errorCode: json && json.errors ? json.errors : null,
            });
          }
          if (typeof response.length !== 'undefined') {
            response = response.length > 0 ? response = json : response;
            return resolve(response);
          }
          if (json) {
            response = json;
            return resolve(response);
          }
          console.log('Unexpected response state after POST!!');
          return resolve();
        }).catch((error) => { NewRequestService.rejectWrapper(error, reject); });
    });
  }

  async getImage(type) {
    const BaseURL = `${process.env.REACT_APP_API_HOST}/api/v1/`;

    return new Promise((resolve, reject) => {
      const payload = {
        method: 'GET',
        headers: {
          Accept: 'image/*',
          'Content-Type': 'image/*',
        },
      };

      if (this.token) {
        payload.headers.Authorization = `Bearer ${this.token}`;
      }

      fetch(BaseURL + type, payload)
        .then((response) => {
          if (response.status > 299 || response.status < 199) {
            return reject({
              error: 'Request error',
              status: response.status,
              statusText: response.statusText,
            });
          }
          return resolve(response.blob());
        }).catch((error) => { NewRequestService.rejectWrapper(error, reject); });
    });
  }

  async postUserPhoto(type, photo) {
    const BaseURL = `${process.env.REACT_APP_API_HOST}/api/v1/`;
    const EndURL = '?_method=PUT';

    return new Promise((resolve, reject) => {
      const payload = {
        method: 'POST',
        headers: {
          accept: '*/*',
        },
        body: photo,
      };

      if (this.token) {
        payload.headers.Authorization = `Bearer ${this.token}`;
      }

      fetch(BaseURL + type + EndURL, payload)
        .then((response) => {
          if (response.status > 299 || response.status < 199) {
            return reject({
              error: 'Request error',
              status: response.status,
              statusText: response.statusText,
            });
          }
          return resolve(response);
        }).catch((error) => { NewRequestService.rejectWrapper(error, reject); });
    });
  }

  async postPhoto(type, photo) {
    const BaseURL = `${process.env.REACT_APP_API_HOST}/api/v1/`;
    const EndURL = '?_method=PUT';

    return new Promise((resolve, reject) => {
      const payload = {
        method: 'POST',
        headers: {
          accept: '*/*',
        },
        body: photo,
      };

      if (this.token) {
        payload.headers.Authorization = `Bearer ${this.token}`;
      }

      fetch(BaseURL + type + EndURL, payload)
        .then((response) => {
          if (response.status > 299 || response.status < 199) {
            return reject({
              error: 'Request error',
              status: response.status,
              statusText: response.statusText,
            });
          }
          return resolve(response);
        }).catch((error) => { NewRequestService.rejectWrapper(error, reject); });
    });
  }

  async csvImport(type, data) {
    const BaseURL = `${process.env.REACT_APP_API_HOST}/api/v1/`;
    const EndURL = '?_method=PUT';

    return new Promise((resolve, reject) => {
      const payload = {
        method: 'POST',
        headers: {
          accept: '*/*',
        },
        body: data,
      };

      if (this.token) {
        payload.headers.Authorization = `Bearer ${this.token}`;
      }

      fetch(BaseURL + "import/csv/" + type + EndURL, payload)
        .then(async (response) => {
          if (response.status > 299 || response.status < 199) {
            return reject({
              error: 'Request error',
              status: response.status,
              statusText: response.statusText,
            });
          }

          let json;
          try {
            json = response.json ? await response.json() : null;
          } catch (e) {
            console.warn('Unable to parse JSON from API response', e);
          }
          if (json) {
            response = json;
            return resolve(response);
          }

          return resolve(response);
        }).catch((error) => { NewRequestService.rejectWrapper(error, reject); });
    });
  }
}
