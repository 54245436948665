import React from 'react';
import I18n from 'i18n-js';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Notifications = (props) => {
  const { latestPublishedNotifications, publishedNotifications, showMore, mainView } = props;

  let notificationsToShow = [];
  if (showMore) {
    notificationsToShow = latestPublishedNotifications;
  } else {
    notificationsToShow = publishedNotifications;
  }

  if (!notificationsToShow.length) { return null; }

  notificationsToShow.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  return (
    <div className={`notifications ${mainView ? 'App-container' : ''}`}>
      {showMore ? <h4>{I18n.t('views.notifications.newest-notifications')}</h4> : <h4>{I18n.t('views.notifications.notifications')}</h4>}
      {notificationsToShow.map((notification) => (
        <div key={notification.id} className="notification-info">
          <p className="notification-info-heading">
            {moment(notification.created_at).format('DD.MM.YYYY')} {notification.title}
          </p>
          <p className="notification-excerpt">
            {notification.body.substring(0, 172)}...
          </p>
          <div className="notification-read-more clickable" onClick={() => props.showNotification(notification)}>
            {I18n.t('views.notifications.read-more')}
          </div>
        </div>
      ))}

      {notificationsToShow.length > 0 && showMore && (
        <Link to="/view-notifications" >
          <Button>
            {I18n.t('views.notifications.show-all-notifications')}
          </Button>
        </Link>
      )}
    </div>
  );
};

export default Notifications;
