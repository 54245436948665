import React, { Component } from 'react';
import AvainiaCore from 'avainia-core-api';
import I18n from 'i18n-js';
import Loading from '../../multiview/Loading/Loading.js';
import Error from '../../multiview/Error/Error.js';
import { TopbarContext } from '../../../contexts/TopbarContext.js';

import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import SearchResult from './SearchResult.js';

import './SearchResults.scss';

const perPage = 5;

export default class SearchResults extends Component {
  static contextType = TopbarContext;

  state = {
    page: 1,
    project: false,
    document: false,
    toggleDocsResultsOn: true,
    toggleProjecttsResultsOn: true,
    searchResponse: null,
    results: [],
    resolvedProjects: [],
    loading: false,
    error: false,
  };

  componentDidMount() {
    this.context.setTopbar(
      [],
      null,
      false,
      () => {},
      this.searchTextChaged,
    );
  }

  searchTextChaged = () => {
    // This is the actual search handler!
    // The logic is as follows:
    // 1. Get a set of search results and then get detailed
    // 2. Render each result in separate component, that component gets the details
    // TODO: Paginate initial results, show only 5 at a time
    // TODO: Disable detailed results; alternatively show a direct results list
    // TODO: ProjectId filtering
    // TODO: DocumentType filtering
    // TODO: Testing with images, docx, xlsx and zip

    if (this.state.loading) { return; }

    const api = new AvainiaCore(LocalStorageService.getToken);
    const { searchText, documentTypeId } = this.context;
    const payload = {
      query: searchText,
      documentTypeId,
      page: this.state.page,
      perPage,
    };

    if (!searchText) {
      return this.setState({ error: 39 }); // TODO: Improve error system
    }

    this.setState({ loading: true, error: false }, () => {
      api.search(payload).then((data) => {
        if (data.error) { return this.setState({ error: data.error, loading: false }); }

        const { results } = data;

        // Grab all unique projectIds from results and resolve their relevant projects
        const projectIds = results
          .map((result) => result.metadata?.project_id)
          .filter((value, index, array) => array.indexOf(value) === index);

        this.resolveProjects(projectIds);

        this.setState({
          searchResponse: data,
          results,
          loading: false,
          error: false,
        });
      });
    });
  };

  loadMoreResults = () => {
    if (this.state.loading) { return; }

    const api = new AvainiaCore(LocalStorageService.getToken);
    const query = this.context.searchText;
    const newPage = this.state.page + 1;
    const payload = { query, page: newPage, perPage };

    this.setState({ loading: true, newPage }, () => {
      api.search(payload).then((data) => {
        if (data.error) { return this.setState({ error: data.error, loading: false }); }

        const { results } = data;

        // Grab all unique projectIds from results and resolve their relevant projects
        const projectIds = results
          .map((result) => result.metadata?.project_id)
          .filter((value, index, array) => array.indexOf(value) === index);

        this.resolveProjects(projectIds);

        this.setState((prevState) => {
          return {
            searchResponse: data,
            results: prevState.results.concat(results),
            loading: false,
            error: false,
          };
        });
      });
    });
  }

  showResultModal = (result, projectId) => {
    this.setState({ result }, () => {
      if (!projectId) { return; }
      const api = new AvainiaCore(LocalStorageService.getToken);

      // TODO: Get document details

      api.getProject(projectId).then((project) => {
        if (project.error) { return; }
        this.setState({ project });
      });
    });
  }

  // This might be overcomplicated
  resolveProjects = (projectIds) => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    // ? For some completely mysterious reason this doesn't work when inlined O_o
    const promises = projectIds.map((projectId) => {
      return api.projectGet(projectId);
    });

    Promise.all(promises).then((projects) => {
      const resolvedProjects = [];
      projects.forEach((project) => {
        if (project.error) {
          return console.error({ errorWhenResolvingProject: project.error });
        }

        resolvedProjects.push(project);
      });

      this.setState((prevState) => {
        const unsaved = resolvedProjects.filter((p) => {
          if (prevState.resolvedProjects.find((r) => r.id === p.id)) {
            return false;
          }
          return true;
        });

        return {
          resolvedProjects: prevState.resolvedProjects.concat(unsaved),
        };
      });
    });
  }

  render() {
    const { loadingCtx, searchTriggered, searchText } = this.context;
    const { loading, error, results, key, resolvedProjects, searchResponse } = this.state;

    const showResults = searchTriggered && !loadingCtx && results.length !== 0;

    return (
      <div className="App-container" key={key}>
        {searchText && <h3 style={{ marginBottom: 20 }}>
          {I18n.t('search.search-results-for')}: <b>{searchText}</b>
        </h3>}

        <Loading inline hide={!loading} />
        <Error inline error={error} />

        {/* TODO: Translation */}
        {searchTriggered && !loadingCtx && !loading && !results.length && <div>
          {I18n.t('search.no-results')}
        </div>}

        {showResults && (
          <table className="search-results">
            <tbody>
              {results.map((result) => <SearchResult
                result={result}
                key={result.id}
                query={searchText}
                showResultModal={this.showResultModal}
                resolvedProjects={resolvedProjects}
                resolveProject={this.resolveProject}
              />)}
            </tbody>
          </table>
        )}

        {/* TODO: Translation */}
        {searchResponse?.more_available && <button onClick={this.loadMoreResults}>
          Lataa lisää tuloksia
        </button>}

      </div>
    );
  }
}
