
import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Button, Badge } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import { File } from '../../multiview/Icon/Icon.js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import ProductReportModal from '../Modals/ProductReportModal.js';
import ApartmentMaterialselectionQuoteModal from '../Modals/ApartmentMaterialselectionQuoteModal.js';
import AvainiaPanel from './AvainiaPanel.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

const Modals = {
  quote: 1,
  productReport: 2,
};

class MaterialformQuotesPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      materialQuotes: [],
      modal: false,
      reportData: false,
      targetApartment: false,
      targetQuote: false,
      modalKeyHack: 1,
      loading: false,
      error: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.project;

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.projectMaterialquotesGet(id).then((materialQuotes) => {
      if (materialQuotes.error) { return this.setState({ error: materialQuotes.error }); }

      this.setState({
        materialQuotes,
        loading: false,
      });
    });
  }

  hideModal = (e) => { this.setState({ modal: false }); }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  printAptMaterialQuoteBadge = (apt) => {
    const quote = this.apartmentHasMaterialQuote(apt);
    if (quote) {
      if (quote.status === 'approved') {
        return <Badge variant="success" className="clickable" onClick={() => { this.openQuoteForApartment(apt); }}>
            {quote.name} ({I18n.t(`views.materialforms.quote-status-${quote.status}`)})
          </Badge>;
      }
      return <Badge variant="primary" className="clickable" onClick={() => { this.openQuoteForApartment(apt); }}>
            {quote.name} ({I18n.t(`views.materialforms.quote-status-${quote.status}`)})
          </Badge>;
    }
    return <Badge variant="info" className="clickable" onClick={() => { this.openQuoteForApartment(apt); }}>
        {I18n.t('views.materialforms.button-quote-create')}
      </Badge>;
  }

  showProductReport = (stairwell) => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.reportForProducts(this.props.project.id, stairwell).then((reportData) => {
      if (reportData.error) { return this.setState({ error: reportData.error }); }

      this.setState({ reportData, modal: Modals.productReport });
    });
  }

  openQuoteForApartment = (targetApartment) => {
    const quote = this.apartmentHasMaterialQuote(targetApartment);
    this.setState({ targetApartment, targetQuote: quote, modal: Modals.quote });
  }

  apartmentHasMaterialQuote = (apt) => {
    return this.state.materialQuotes.find((quote) => quote.apartment_id === apt.id);
  }

  render() {
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    const stairwells = [];
    this.props.apartments.forEach((apt) => { if (stairwells.indexOf(apt.stairwell) === -1) { stairwells.push(apt.stairwell); } });

    return <AvainiaPanel double={false} icon={<File />} heading={I18n.t('views.materialforms.quotes-heading')}>

      {stairwells.map((s) => <div key={s} className="stairwell" style={{ marginBottom: '15px' }}>
        <h5 style={{ textAlign: 'center' }}>{I18n.t('views.condominiums.stairwell')} {s}</h5>
        <table style={{ width: '100%' }}>
          <tbody>
            {this.props.apartments.filter((apt) => apt.stairwell === s).map((apt) => <tr key={apt.id}>
                <td>{apt.stairwell}{apt.apartment_number}</td>
                <td>{this.printAptMaterialQuoteBadge(apt)}</td>
                <td>{apt.selections && <>{I18n.t('views.materialforms.selections-done')}</>}</td>
              </tr>)}
          </tbody>
        </table>
        <Button size="sm" style={{ width: '100%' }} onClick={() => { this.showProductReport(s); }}>
          {I18n.t('views.materialforms.button-show-product-report')}
        </Button>
      </div>)}

      {this.state.modal === Modals.quote &&
        <ApartmentMaterialselectionQuoteModal
          key={this.state.modalKeyHack}
          onHide={this.hideModal}
          apartment={this.state.targetApartment}
          project={this.props.project}
          materialforms={this.props.materialforms}
          quote={this.state.targetQuote}
        />
      }

      {this.state.modal === Modals.productReport &&
        <ProductReportModal
          data={this.state.reportData}
          onHide={this.hideModal}
          key={this.state.modalKeyHack}
        />
      }
    </AvainiaPanel>;
  }
}

export default MaterialformQuotesPanel;
