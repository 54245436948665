import React, { Component } from 'react';
import AvainiaCore from 'avainia-core-api';
import ModalNotificationShow from '../Modals/ModalNotificationShow.js';
import Notifications from './Notifications.js';

import '../../mainviews/Notifications/notificationsAdmin.scss';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

const Modals = {
  showNotificationModal: 1972,
};

export default class NotificationsArchive extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
      activeNotification: null,
      modal: null,
      latestNotifications: [],
      allNotifications: [],
    };
  }

  componentDidMount() {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.notificationGet().then((notifications) => {
      if (notifications && notifications.length > 0) {
        const publishedNotifications = notifications.filter((notification) => notification.is_published);

        this.setState({ notifications: publishedNotifications });
      }
    });
  }

  showNotification = (activeNotification) => {
    this.setState({ modal: Modals.showNotificationModal, activeNotification });
  }

  hideModal = () => { this.setState({ modal: false }); }

  render() {
    const { activeNotification, modal } = this.state;

    return (
      <>
        <Notifications
          mainView
          latestPublishedNotifications={this.state.allNotifications}
          publishedNotifications={this.state.notifications}
          showMore={this.state.moreNotifications}
          showNotification={this.showNotification}
        />

        {modal === Modals.showNotificationModal &&
          <ModalNotificationShow
            hide={this.hideModal}
            notification={activeNotification}
          />
        }
      </>
    );
  }
}
