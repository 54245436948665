import React, { Component } from 'react';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import MaterialformDeadlinesPanel from '../../../../multiview/Panels/MaterialformDeadlinesPanel.js';
import MaterialformQuotesPanel from '../../../../multiview/Panels/MaterialformQuotesPanel.js';
import MaterialformAdminPanel from '../../../../multiview/Panels/MaterialformAdminPanel.js';
import Error from '../../../../multiview/Error/Error.js';
import Loading from '../../../../multiview/Loading/Loading.js';
import LocalStorageService from '../../../../../AvainiaTools/LocalStorageService.js';

class MaterialformsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apartments: [],
      apartmentTemplates: [],
      materialforms: [],
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.project;
    const api = new AvainiaCore(LocalStorageService.getToken);

    // TODO: Refactor this, at least combine the two Promise.alls to run parallel
    api.projectMaterialFormsGet(id).then((materialforms) => {
      if (materialforms.error) { return this.setState({ error: materialforms.error }); }

      // Get condominium apartments
      api.condominiumApartmentsGet(this.props.condominiumId).then((apartments) => {
        if (apartments.error) { return this.setState({ error: apartments.error }); }

        // Get condominium apartment templates
        api.condominiumApartmentTemplatesGet(this.props.condominiumId).then((apartmentTemplates) => {
          if (apartmentTemplates.error) { return this.setState({ error: apartmentTemplates.error }); }

          // Map condominium apartment templates to materialforms
          const promises = apartmentTemplates.map((t) => api.condominiumApartmentTemplateMaterialformsGet(t.id));

          Promise.all(promises).then((data) => {
            let error = false;
            let i = 0;

            data.forEach((mfs) => {
              if (error) { return; }
              if (mfs.error) { error = true; return; }
              apartmentTemplates[i].materialforms = mfs; // TODO: Check if this logic is correct
              i++;
            });

            if (error) { return this.setState({ error }); }

            // Get materialselections for each apartment. TODO: This is just to check if selections have been made, a performance boost would be to create an endpoint for this
            const apartmentPromises = apartments.map((a) => api.materialSelectionsGet(id, a.id));

            Promise.all(apartmentPromises).then((result) => {
              result.forEach((selections) => {
                if (error) { return; }
                if (selections.error) { error = true; return; }

                if (selections.length) {
                  const targetApt = apartments.find((apt) => apt.id === selections[0].apartment_id);
                  targetApt.selections = selections;
                }
              });

              if (error) { return this.setState({ error }); }

              this.setState({
                apartmentTemplates,
                apartments,
                materialforms,
                loading: false,
              });
            });
          });
        });
      });
    });
  }

  render() {
    return <div className="App-container materialforms materialselections">
      <div className="AvainiaPanel">
        <div className="AvainiaPanel-panel AvainiaPanel-panel--header">
          <div className="AvainiaPanel-content">
            <div className="text">
              <h2>{I18n.t('views.materialforms.materialforms')}</h2>

              {this.state.error && <Error inline error={this.state.error} />}
              {!this.state.error && this.state.loading && <Loading inline />}
            </div>
          </div>
        </div>

        {!this.state.error && !this.state.loading && <>
          <MaterialformAdminPanel double materialforms={this.state.materialforms} apartmentTemplates={this.state.apartmentTemplates} project={this.props.project} />
          <MaterialformQuotesPanel apartments={this.state.apartments} materialforms={this.state.materialforms} project={this.props.project} />
          <MaterialformDeadlinesPanel double project={this.props.project} />
        </>}
      </div>
    </div>;
  }
}

export default MaterialformsTab;
