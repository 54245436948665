import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import I18n from 'i18n-js';
import { Upload } from '../Icon/Icon.js';
import AvainiaCore from 'avainia-core-api';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class ModalChangeUserPhoto extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photo: '',
      imagePreviewName: '',
      imagePreviewUrl: '',
      notPhoto: false,
      loading: false,
      error: false,
    };
  }

  handleSubmit = () => {
    if (this.state.photo === '') {
      return;
    }

    const formData = new FormData();

    formData.append('user_photo', this.state.photo);

    const user = LocalStorageService.getUser();

    this.setState({ loading: 1, error: false }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.userPhotoDelete(user.id).then((res) => {
        if (res.error) { return this.setState({ error: res.error, loading: false }); }

        api.userPhotoCreate(user.id, formData).then((res) => {
          if (res.error) { return this.setState({ error: res.error, loading: false }); }

          this.props.userphotocallback(this.state.imagePreviewUrl);

          return this.handleModalExit();
        });
      });
    });
  }

  handleImageChange = (e) => {
    this.setState({
      photo: '',
      imagePreviewName: '',
      imagePreviewUrl: '',
      notPhoto: false,
    });

    if (e.target.files[0] && e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
      const reader = new FileReader();
      const file = e.target.files[0];

      this.setState({ imagePreviewName: e.target.files[0].name });

      reader.onloadend = () => {
        this.setState({
          photo: file,
          imagePreviewUrl: reader.result,
        });
      };

      reader.readAsDataURL(file);
    } else {
      this.setState({ notPhoto: 24 });
    }
  }

  handleModalExit = () => {
    this.setState({
      imagePreviewName: '',
      imagePreviewUrl: '',
      photo: '',
      notPhoto: false,
      loading: false,
      error: false,
    });

    this.props.onHide();
  }

  render() {
    const { imagePreviewUrl } = this.state;
    let imagePreview = null;

    if (imagePreviewUrl) {
      imagePreview = <img src={imagePreviewUrl} id="user-photo-modal-preview" alt={I18n.t('views.sidebar.userphotomodal.photo-preview')} />;
    }

    return <Modal show={this.props.show} onHide={this.props.onHide} onExit={this.handleModalExit} id="user-photo-modal">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.sidebar.userphotomodal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.error && <Error inline error={this.state.error} />}
        {!this.state.error && this.state.loading && <Loading inline />}
        {!this.state.error && !this.state.loading && <div>
          <form className="col-sm-12">
            {<input type="file" id="file" onChange={this.handleImageChange} accept="image/*" className="inputfile" />}
            <label htmlFor="file" style={{ maxWidth: '100%', display: 'block' }}><Upload /> {I18n.t('general.choose-photo')}</label>
          </form>
          <div className="col-sm-12">{imagePreview}</div>
          {this.state.notPhoto && <Error inline error={this.state.notPhoto} />}
        </div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" disabled={this.state.loading} onClick={this.handleSubmit}>{I18n.t('views.sidebar.userphotomodal.button')}</Button>
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('general.modal-close')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default ModalChangeUserPhoto;
