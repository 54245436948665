import React, { Component } from 'react';
import { Button, Modal, InputGroup, FormControl } from 'react-bootstrap';
import I18n from 'i18n-js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import AvainiaCore from 'avainia-core-api';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import { Check, Close } from '../Icon/Icon.js';

export default class ModalProjectStatusAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payload: {
        name: '',
        is_active: true,
        parent_id: '',
      },
      loading: false,
      error: false,
    };
  }

  componentDidMount = () => {
    let unavailableStatuses = [];
    /* eslint-disable-next-line array-callback-return */
    this.props.projectStatuses.map((status) => {
      const statusName = status.name.toLowerCase();
      unavailableStatuses.push(statusName)
    })

    this.setState({unavailableStatuses});
  }

  onChange = (e) => {
    let value = e.target.value;
    if (e.target.name === "parent_id") {
      parseInt(value);
    }

    this.setState({
      payload:  {...this.state.payload, [e.target.name]: value },
    })
  }

  toggleActiveStatus = (value) => {
    this.setState({
      payload:  {...this.state.payload, is_active: value },
    })
  }

  save = () => {
    if (this.state.loading) { return; }

    const payload = this.state.payload;
    if (!payload.name) {
      this.setState({ error: 35 });
      return;
    }

    if (this.validateName(payload.name)) {
      this.setState({ loading: true }, () => {
        const api = new AvainiaCore(LocalStorageService.getToken);
        api.projectStatusAdd(payload).then((result) => {
          if (result.error) { return result.errorCode ? this.setState({ error: result.errorCode['code'] }) : result.error; }
          this.props.projectStatusCallback(result);
        }, this.setState({ loading: false }));
      });
    } else {
        this.setState({ error: 40 });
        return;
      }
  }

  validateName = (value) => {
    const valueName = value.toLowerCase();
    if (!this.state.unavailableStatuses.includes(valueName)) {
      return true;
    } return false;
  }

  render() {
    const { error, loading, payload } = this.state;
    const { onHide, show, projectStatuses } = this.props;

    return (
      <Modal className="modal-project-statuses" show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('projectStatuses.create-new-status')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Error inline error={error} />
          <Loading inline loading={loading} />
          {!loading && (
            <>
              <label htmlFor="name" className="status-label">{I18n.t('projectStatuses.status-name')}</label>
              <InputGroup>
                <FormControl
                  type="text"
                  onChange={this.onChange}
                  name="name"
                  placeholder={I18n.t('projectStatuses.status-name')}
                  value={payload.name}
                />
              </InputGroup>
              <label htmlFor="parent_id" className="status-label">{I18n.t('projectStatuses.status-parent')}</label>
              <InputGroup>
                <FormControl
                  as="select"
                  onChange={this.onChange}
                  name="parent_id"
                  placeholder={I18n.t('projectStatuses.status-parent')}
                  value={payload.parent_id}
                >
                <option key="0" value="0"> - </option>
                { projectStatuses && projectStatuses.map((parent) => {
                  return <option key={parent.id} value={parent.id}> {parent.name} </option>;
                })}
                </FormControl>
              </InputGroup>
              <InputGroup>

              <label htmlFor="is_active" className="status-label">{I18n.t('projectStatuses.status-active')}</label>
              {payload.is_active && (
                <Check
                  className="project-status clickable"
                  onClick={() => this.toggleActiveStatus(0)}
                  title={I18n.t('projectStatuses.click-to-deactivate')}
                />
              )}
              {!payload.is_active && (
                <Close
                  className="role-not-assigned clickable"
                  onClick={() => this.toggleActiveStatus(1)}
                  title={I18n.t('projectStatuses.click-to-activate')}
                />
              )}
              </InputGroup>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" disabled={loading} onClick={this.save}>{I18n.t('general.modal-form-save')}</Button>
          <Button variant="secondary" onClick={onHide}>{I18n.t('general.modal-close')}</Button>
        </Modal.Footer>
      </Modal>
    );
    }
  }