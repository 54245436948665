import React, { Component } from 'react';
import I18n from 'i18n-js';
import {
  Modal,
  Toast,
  Button,
  Row,
  Col,
  Container,
  ButtonGroup,
  Form,
  FormControl,
} from 'react-bootstrap';
import moment from 'moment';
import AvainiaCore from 'avainia-core-api';
import SecureImage from '../../SecureImage/SecureImage.js';
import AvainiaPermissions from '../../../../AvainiaTools/AvainiaPermissions.js';
import LocalStorageService from '../../../../AvainiaTools/LocalStorageService.js';
import EditableField from './Components/EditableField.js';
import ShowFields from './Components/ShowFields.js';
import ModalDocumentReplace from './ModalDocumentReplace.js';

import './ModalViewDocument.scss';

//! TODO: IMPLEMENT LOADING!

class ModalViewDocument extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: [],
      fieldData: [],
      newPosition: false,
      editCoords: false,
      newFields: [],
      replaceImageMode: false,
      loading: false,
      error: false,
      groupName: this.props.documentActive.group,
    };
  }

  updateFieldData = (fieldId, fieldValue) => {
    this.setState((prevState) => ({
      fieldData: {
        ...prevState.fieldData,
        [fieldId]: fieldValue,
      },
    }));
  }

  updateDateFieldData = (fieldId, fieldValue) => {
    this.setState((prevState) => ({
      fieldData: {
        ...prevState.fieldData,
        [fieldId]: fieldValue,
      },
    }));
  }

  handleGroupChange = (groupName) => {
    this.setState({
      groupName,
    });
  }


  deactivateEditMode = (e) => {
    this.props.deactivateEditMode();
    this.setState({
      editCoords: false,
    }, () => {
      window.editmap = null;
    });
  }

  activateEditMode = (e, id) => {
    this.props.activateEditMode(id);
    this.setState({
      editCoords: false,
    });
  }

  editCoords = () => {
    this.setState({ editCoords: true });
  }

  save = () => {
    const { documentActive, project } = this.props;

    const payload = {
      fields: [],
      keywords: [],
      group: this.state.groupName,
    };

    documentActive.document_type.fields.forEach((field) => {
      if (field.is_automatic && !field.key === 'gps_coordinates') {
        return;
      }

      const value = this.state.fieldData[field.id];

      if (value || value === '') {
        // 1. If field value changed, get new value
        if (field.type === 'keywords') {
          const keyword = field.keywords.find((kw) => kw.text === value);
          if (keyword) {
            payload.keywords.push({
              field_id: field.id,
              id: keyword.id,
              text: value,
            });
          }
        } else if (field.type === 'date') {
          if (value === "") {
            payload.fields.push({
              field_id: field.id,
              date: null,
            });
          } else {
            payload.fields.push({
              field_id: field.id,
              date: value,
            });
          }
        } else {
          payload.fields.push({
            field_id: field.id,
            text: value,
          });
        }
        return;
      }

      // 2. If field has old value, show that one
      if (field.type === 'keywords') {
        const oldValue = documentActive.keywords.find((keyword) => keyword.field_id === field.id);
        if (oldValue) {
          payload.keywords.push({
            field_id: field.id,
            id: oldValue.id,
            text: oldValue.text,
          });
        }
      } else if (field.type === 'date') {
        const oldValue = documentActive.fields.find((oldFieldValue) => oldFieldValue.field_id === field.id);
        if (oldValue && oldValue.date !== null) {
          const formattedOldValue = moment(oldValue.date).format('YYYY-MM-DD HH:mm:ss');

          payload.fields.push({
            field_id: field.id,
            date: formattedOldValue,
          });
        }
      } else {
        const oldValue = documentActive.fields.find((oldFieldValue) => oldFieldValue.field_id === field.id);
        if (oldValue) {
          payload.fields.push({
            field_id: field.id,
            text: oldValue.text,
          });
        }
      }
    });

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.doSaveEditedImage(project, documentActive.id, payload).then((result) => {
      if (!result || result.error) { return this.setState({ error: 4220 }); }
      this.props.documentEditedCallback(result);
    });
  }

  download = async (e) => {
    const targetDocument = this.props.documentActive;

    try {
      const obj = { headers: { Authorization: `Bearer ${LocalStorageService.getToken()}` } };
      const res = await fetch(process.env.REACT_APP_API_HOST + targetDocument.url, obj);

      if (res.ok) {
        const binary = await res.blob();

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(binary, targetDocument.files[0].full_name);
        } else {
          const src = window.URL.createObjectURL(binary);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style.cssText = 'display: none';
          a.href = src;
          a.download = targetDocument.files[0].full_name;
          a.click();
          window.URL.revokeObjectURL(src);
          setTimeout((x) => { document.body.removeChild(a); }, 1000);
        }
      } else if (res.status !== 410) { // 410 means dont retry
        throw new Error('failed to fetch document'); // TODO! Fatal error, do actual logging
      }
    } catch (ex) {
      console.error(ex); // TODO! Fatal error, do actual logging
    }
  }

  delete = (e) => {
    if (!window.confirm(I18n.t('views.infraproject.confirm-document-delete'))) { return; }

    const targetDocument = this.props.documentActive;

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.doDeleteDocument(this.props.project, targetDocument).then((result) => {
      if (result.error) { this.setState({ error: result.error }); }

      this.props.documentDeletedCallback();
    });
  }

  initializeEditMap = () => {
    const activeDocument = this.props.documentActive;
    const defaultCoords = { lat: window.Avainia.config.coordinates.lat, lng: window.Avainia.config.coordinates.lng };
    const center = activeDocument.coordinates || defaultCoords;
    const options = { // TODO: DEDUPE, Map.js and below
      center,
      zoom: 10,
      fullscreenControl: false,
      zoomControl: true,
      scaleControl: true,
      mapTypeControl: true,
    };

    window.editmap = new window.google.maps.Map(document.getElementById('editCoordsModalMap'), options);

    if (window.editmarker) { window.editmarker.setMap(null); }

    // It is possible that an document has no coordinates if it was added from the browser TODO: What to do in this case
    if (activeDocument.coordinates) {
      window.editmarker = new window.google.maps.Marker({
        position: activeDocument.coordinates,
        map: window.editmap,
      });
    }

    window.editmap.addListener('click', (e) => {
      if (window.editmarker) {
        window.editmarker.setMap(null);
      }

      const newPosition = e.latLng;

      window.editmarker = new window.google.maps.Marker({
        position: newPosition,
        map: window.editmap,
      });

      window.editmap.panTo(newPosition);

      const gpsField = this.state.fields.find((field) => field.key === 'gps_coordinates');
      const gpsAccurary = this.state.fields.find((field) => field.key === 'gps_accuracy');

      const fixedNewPosition = `${newPosition}`.replace(' ', '').replace('(', '').replace(')', '');

      this.updateFieldData(gpsField.id, fixedNewPosition);
      this.updateFieldData(gpsAccurary.id, '0');

      this.setState({ newPosition: fixedNewPosition });
    });
  }

  componentDidMount = () => {
    // This might be obsolete
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.fieldsGet().then((fields) => {
      if (fields.error) {
        return this.setState({ error: fields.error });
      }
      this.setState({ fields });
    });
  };

  componentDidUpdate = () => {
    if (!this.state.editCoords) { return; }
    if (window.editmap) { return; }

    if (window.google) {
      return this.initializeEditMap();
    }

    const scriptNode = document.createElement('script');
    scriptNode.type = 'text/javascript';
    scriptNode.id = 'googleMapsInsert';
    scriptNode.src = 'https://maps.google.com/maps/api/js?key=AIzaSyCn6T7i_CYc8AFNuT3idK-M0PjLG07anN4'; // TODO! DEDUPLICATE

    document.head.appendChild(scriptNode);

    // Wait for map to load
    scriptNode.addEventListener('load', this.initializeEditMap);
  }

  addNewField = (field) => {
    this.setState({ newFields: [...this.state.newFields, field] });
  }

  sortFields = (a, b) => {
    if (a.is_default > b.is_default) {
      return -1;
    }
    if (b.is_default > a.is_default) {
      return 1;
    }
    if (a.is_default && b.is_default) {
      if (a.order < b.order) {
        return -1;
      }
      return 1;
    }
    if (a.key < b.key) {
      return -1;
    }
    return 1;
  }

  replaceImageModal = () => {
    this.setState({
      replaceImageMode: true,
    });
  }

  hideImageReplaceModal = () => {
    this.setState({
      replaceImageMode: false,
    });
  }

  render() {
    const { configuration, documentActive, disableEdit, disableDelete, show, project, isGroup } = this.props;
    const excludedFieldKeys = configuration.document && configuration.document.documentAddFieldExceptions ? Object.keys(configuration.document.documentAddFieldExceptions) : [];
    const visibleFields = documentActive.document_type.fields.filter(function(el) { return !excludedFieldKeys.includes(el.key) });

    if (!documentActive || !show) { return null; }

    const { newPosition, replaceImageMode } = this.state;
    const editMode = this.props.editMode;

    const user = LocalStorageService.getUser();
    const canEdit = disableEdit ? false : user.hasPermission(AvainiaPermissions.DocumentEdit);
    const canDelete = disableDelete ? false : user.hasPermission(AvainiaPermissions.DocumentsDelete);

    const isMobile = this.props.isMobile;

    if (replaceImageMode) {
      return <ModalDocumentReplace document={documentActive} onHide={this.hideImageReplaceModal} project={project} />
    }

    const modalButtons = (
       <ButtonGroup className={`d-flex modal-buttons ${this.state.width < 400 && 'small'}`}>
        {editMode && <Button onClick={this.save}>{I18n.t('general.save')}</Button>}
        {editMode && <Button onClick={this.deactivateEditMode}>{I18n.t('general.cancel')}</Button>}
        {!editMode && canEdit && <Button onClick={(e) => this.activateEditMode(e, documentActive.id)}>{I18n.t('views.infraproject.edit')}</Button>}
        {!editMode && <Button onClick={this.download}>{I18n.t('views.infraproject.download')}</Button>}
        {!editMode && canDelete && <Button onClick={this.delete}>{I18n.t('views.infraproject.delete')}</Button>}
        <Button onClick={(e) => { this.props.hideModal(); }}>{I18n.t('general.modal-close')}</Button>
        {!editMode && canEdit && <Button style={{width: '100%'}} onClick={this.replaceImageModal}>{I18n.t('views.documents.replace-file')}</Button>}
      </ButtonGroup>
    );

    const documentPreviewAndDataRendered = (
      <Toast
        style={{
          width: '100%',
          maxWidth: '100%',
          height: isGroup ? '780px' : '100%',
          overflowY: 'auto',
        }}
        onClose={editMode ? this.deactivateEditMode : (e) => { this.props.hideModal(); }}
        animation={false}
      >
        <Toast.Header closeButton={isGroup ? false : true}>
          <strong className="mr-auto">{documentActive.counter} - {I18n.t('views.infraproject.info')}</strong>
        </Toast.Header>
        <Toast.Body>
          <SecureImage className="document-thumbnail" src={documentActive.thumb} />
          <Container style={{ marginBottom: isMobile ? '20px' : '125px' }}>
            <Row>
              <Col>
                {editMode && visibleFields.sort(this.sortFields).map((field) => <EditableField
                  key={field.id}
                  field={field}
                  updateFieldData={this.updateFieldData}
                  updateDateFieldData={this.updateDateFieldData}
                  editCoords={this.editCoords}
                  newPosition={newPosition}
                  isEditCoords={this.state.editCoords}
                  document={documentActive}
                />)}
                {editMode && <Form.Group>
                  <Form.Label>{I18n.t('views.documents.group-name')}</Form.Label>
                  <FormControl
                    type="text"
                    value={this.state.groupName}
                    readOnly={false}
                    onChange={(e) => {
                      this.handleGroupChange(e.target.value);
                    }}
                  />
                </Form.Group>}
                {!editMode && <ShowFields
                  doc={documentActive}
                  fields={documentActive.document_type ? visibleFields: this.props.fields}
                  excludedFieldKeys={excludedFieldKeys}
                />}
              </Col>
            </Row>
          </Container>
          {isMobile && <div className="modal-document-buttons">
            {modalButtons}
          </div>}
          {editMode && <Container>{modalButtons}</Container>}
        </Toast.Body>
        </Toast>
    );

    if (editMode) {
      return <Modal show={true} size="lg" onHide={()=>{ /* Intentionally disabled */ }}>
        <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
          <Toast
          style={{
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            position: 'relative',
          }}
          onClose={editMode ? this.deactivateEditMode : (e) => { this.props.hideModal()}}
          animation={false}
          >
            <Toast.Header>
              <strong className="mr-auto">{documentActive.counter} - {I18n.t('views.infraproject.info')}</strong>
            </Toast.Header>
            <Toast.Body>
              <SecureImage className="document-thumbnail" src={documentActive.thumb} />
              <Container style={{ marginBottom: '125px' }}>
                <Row>
                  <Col>
                    {editMode && visibleFields.sort(this.sortFields).map((field) => <EditableField
                      key={field.id}
                      field={field}
                      updateFieldData={this.updateFieldData}
                      updateDateFieldData={this.updateDateFieldData}
                      editCoords={this.editCoords}
                      newPosition={newPosition}
                      isEditCoords={this.state.editCoords}
                      document={documentActive}
                    />)}
                    {editMode && <Form.Group>
                      <Form.Label>{I18n.t('views.documents.group-name')}</Form.Label>
                      <FormControl
                        type="text"
                        value={this.state.groupName}
                        readOnly={false}
                        onChange={(e) => {
                          this.handleGroupChange(e.target.value);
                        }}
                      />
                    </Form.Group>}
                    {!editMode && <ShowFields
                      doc={documentActive}
                      fields={documentActive.document_type ? visibleFields: this.props.fields}
                      excludedFieldKeys={excludedFieldKeys}
                    />}
                  </Col>
                </Row>
              </Container>
              <hr/>
              {editMode && <Container>{modalButtons}</Container>}
            </Toast.Body>
          </Toast>
        </div>
      </Modal>;
    }

    /*
     * The RndWrapper acts as a zero point and provides a fixed position to which the Rnd can be relative to the
     * This looks hacky but is needed because Rnd uses a transform(x,y) to position its element.
     */
    if (isMobile && !editMode) {
      return (
        <div className="toast-wrapper">
        <Modal.Body>
            {documentPreviewAndDataRendered}
        </Modal.Body>
        </div>);
    } else {
    return (
      <div className="toast-wrapper">
      {documentPreviewAndDataRendered}
      <Modal.Footer className="modal-document-footer">
        <div className="modal-document-buttons">
          {modalButtons}
        </div>
      </Modal.Footer>
      </div>);
    }
  }
}

export default ModalViewDocument;
