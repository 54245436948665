import React, { Component } from "react";
import "./SummaryCard.scss";
import I18n, { placeholder } from "i18n-js";

class SummaryCard extends Component {
  render() {
    return (
      <div className="summary-card">
        <span className="summary-card-title">{this.props.heading}</span>
        <div>
          <b className="summary-card-content">{this.props.value}</b>
          <span className="summary-card-footer">{I18n.t("views.progressnumbers." + this.props.unit)}</span>
        </div>
      </div>
    );
  }
}

export default SummaryCard;
