import React, { Component } from 'react';
import { Button, Modal, Row, Form, Col, InputGroup } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import { Upload, Trash } from '../../multiview/Icon/Icon.js';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class MobilelinkEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      title: null,
      link: null,
      order: null,
      loading: true
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      id: this.props.mobilelink.id,
      title: this.props.mobilelink.title,
      link: this.props.mobilelink.link,
      order: this.props.mobilelink.order,
      loading: false
    });
  }

  save = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    const payload = {
      id: this.state.id,
      title: this.state.title,
      link: this.state.link,
      order: this.state.order
    }

    if(payload.id){
      api.mobilelinkEdit(payload.id, payload).then((mobilelink) => {
        this.props.onSave();
      });
    } else {
      api.mobilelinkCreate(payload).then((mobilelink) => {
        this.props.onSave();
      });
    }
  }

  onChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    this.setState((prevState) => ({
      [name] : value
    }));
  }

  render() {
    return <Modal show={true} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.mobilelinks.edit-mobilelink')}}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.error && <Error inline error={this.state.error} />}
        {!this.state.error && this.state.loading && <Loading inline />}
        {!this.state.error && !this.state.loading &&
          <Form.Group as={Row} id="edit-product-form">
            <Form.Label column sm="2">{I18n.t('views.mobilelinks.title')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.onChange} name="title" value={this.state.title} /></Col>
            <Form.Label column sm="2">{I18n.t('views.mobilelinks.link')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.onChange} name="link" value={this.state.link} /></Col>
            <Form.Label column sm="2">{I18n.t('views.mobilelinks.order')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.onChange} name="order" value={this.state.order} /></Col>
          </Form.Group>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" disabled={this.state.loading} onClick={this.save}>{I18n.t('general.modal-form-save')}</Button>
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('general.modal-close')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default MobilelinkEditModal;
