import React, { Component } from 'react';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import { TopbarContext } from '../../../contexts/TopbarContext.js';
import { Button, Row, Form, Col, InputGroup } from 'react-bootstrap';
import { Upload, Trash } from '../../multiview/Icon/Icon.js';
import './Import.scss';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

import project_csv from '../../../importExamples/projects.csv';
import users_csv from '../../../importExamples/users.csv';

class Import extends Component {
  static contextType = TopbarContext;

  constructor(props) {
    super(props);

    this.state = {
      projectCSV: null,
      usersCSV: null,
      error: null
    };
  }

  componentDidMount() {

  }

  projectCSVChange = (e) => {
    this.setState({ projectCSV: null });
    if (e.target.files[0] && e.target.files[0].name.match(/.(csv)$/i)) {
      const reader = new FileReader();
      const file = e.target.files[0];

      reader.onloadend = () => {
        this.setState({
          projectCSV: file
        });
      };

      reader.readAsDataURL(file);
    }
  }

  userCSVChange = (e) => {
    this.setState({ usersCSV: null });
    if (e.target.files[0] && e.target.files[0].name.match(/.(csv)$/i)) {
      const reader = new FileReader();
      const file = e.target.files[0];

      reader.onloadend = () => {
        this.setState({
          usersCSV: file
        });
      };

      reader.readAsDataURL(file);
    }
  }

  uploadCSV = (type) => {
    const formData = new FormData();
    let data = type == "projects" ? this.state.projectCSV : this.state.usersCSV;
    formData.append('csv_data', data);
    let _this = this;

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.csvImport(type, formData).then((res) => {
      console.log(res);
      if(res.code === 200){
        let url = type == 'projects' ? '/projects' : '/users';
        window.location = url;
      } else {
        _this.setState({error: 'import-error'});
      }
    });

  }

  render() {

    return (
      <div className="App-container Import">
        <h1>{I18n.t('views.import.title')}</h1>
        {this.state.error && <Error inline error={this.state.error} />}
        <Form.Group className="project-import">
          <h4>{I18n.t('views.import.import-projects')}</h4>
          <span>{I18n.t('views.import.import-projects-description')} </span><a className="link" href={project_csv}>{I18n.t('views.import.here')}</a>
          <Row style={{alignItems:'center'}}>
            <Form.Control
              type="file"
              id="import_projects"
              className="upload-button"
              onChange={this.projectCSVChange}
              name="import_projects"
              ref={(ref) => this.import_projects = ref}
            />
            {!this.state.projectCSV && <label className="upload-label" htmlFor="import_projects"><Upload /> {I18n.t('views.import.upload-csv')}</label>}
            {this.state.projectCSV && <button className="upload" onClick={() => this.uploadCSV("projects")}>{I18n.t('views.import.confirm')}</button>}

            {this.state.projectCSV && <>
               <span>{this.state.projectCSV.name}</span>
               <button className="cancel" onClick={() => this.setState({projectCSV: null})}><Trash/></button>
            </>}
          </Row>
        </Form.Group>
        <Form.Group className="user-import" style={{marginTop:"30px"}}>
          <h4>{I18n.t('views.import.import-users')}</h4>
          <span>{I18n.t('views.import.import-users-description')} </span><a className="link" href={users_csv}>{I18n.t('views.import.here')}</a>
          <Row style={{alignItems:'center'}}>
            <Form.Control
              type="file"
              id="import_users"
              className="upload-button"
              onChange={this.userCSVChange}
              name="import_users"
              ref={(ref) => this.import_users = ref}
            />
            {!this.state.usersCSV && <label className="upload-label" htmlFor="import_users"><Upload /> {I18n.t('views.import.upload-csv')}</label>}
            {this.state.usersCSV && <button className="upload" onClick={() => this.uploadCSV("users")}>{I18n.t('views.import.confirm')}</button>}

            {this.state.usersCSV && <>
              <span>{this.state.usersCSV.name}</span>
              <button className="cancel" onClick={() => this.setState({usersCSV: null})}><Trash/></button>
            </>}
          </Row>
        </Form.Group>
      </div>
    );
  }
}

export default Import;
