import React, { Component } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import { Edit, Trash } from '../../multiview/Icon/Icon.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import AvainiaTableHeading from '../../multiview/AvainiaTable/AvainiaTableHeading.js';
import ModalNotificationCreate from '../../multiview/Modals/ModalNotificationCreate.js';
import ModalNotificationEdit from '../../multiview/Modals/ModalNotificationEdit.js';
import { TopbarContext } from '../../../contexts/TopbarContext.js';
// import AvainiaPermissions from '../../../AvainiaTools/AvainiaPermissions.js';
import './notificationsAdmin.scss';

const Modals = {
  notificationCreate: 1,
  notificationEdit: 2,
};

class NotificationsAdmin extends Component {
  static contextType = TopbarContext;

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      editing: false,
      notifications: [],
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    this.context.resetTopbar();

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.notificationGet().then((notifications) => {
      if (notifications.error) {
        return this.setState({ loading: false, error: notifications.error });
      }

      this.setState({ notifications, loading: false });
    });
  }

  hideModal = (e) => { this.setState({ modal: false, editing: false }); }

  getRequiredFieldHasNoErrors = (data) => {
    if (!data.title || !data.body) {
      return false;
    }
    return true;
  }

  notificationCallback = () => {
    window.location.reload(); // TODO: Improve
  }

  createNotification = () => {
    this.setState({ modal: Modals.notificationCreate });
  }

  editNotification = (editing) => {
    this.setState({ editing, modal: Modals.notificationEdit });
  }

  deleteNotification = (notification) => {
    if (!window.confirm(I18n.t('views.projects.confirm-delete'))) { return; }

    this.setState({ loading: true, error: false }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.notificationDelete(notification.id).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }
        window.location.reload();
      });
    });
  }

  downloadAttachment = async (notification) => {
    try {
      const obj = { headers: { Authorization: `Bearer ${LocalStorageService.getToken()}`} };
      const res = await fetch(process.env.REACT_APP_API_HOST + "/api/v1/notifications/" + notification.id + "/download", obj);
      const fileName = notification.attachment.name;

      if (res.ok) {
        const binary = await res.blob();

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(binary, fileName);
        } else {
          const src = window.URL.createObjectURL(binary);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style.cssText = 'display: none';
          a.href = src;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(src);
          setTimeout((x) => { document.body.removeChild(a); }, 1000);
        }
      } else if (res.status !== 410) { // 410 means dont retry
        throw new Error('failed to fetch document'); // TODO! Fatal error, do actual logging
      }
    } catch (ex) {
      console.error(ex); // TODO! Fatal error, do actual logging
    }
  }

  render() {
    const user = LocalStorageService.getUser();
    if (!user) { return; }
    // Disabled until notifications permissions rework
    // const notificationsCreate = user.hasPermission(AvainiaPermissions.NotificationsCreate);
    // const notificationsEdit = user.hasPermission(AvainiaPermissions.NotificationsEdit);
    // const notificationsDelete = user.hasPermission(AvainiaPermissions.NotificationsDelete);
    const ownerManager = user && user.isOwnerManager();


    return <div className="App-container">
      <AvainiaTableHeading title={I18n.t('views.notifications.notifications')} />

      <Row style={{ fontWeight: 'bold', borderBottom: '2px solid #000' }}>
        <Col xs={2}>{I18n.t('views.notifications.title')}</Col>
        <Col xs={3}>{I18n.t('views.notifications.body')}</Col>
        <Col xs={2}>{I18n.t('views.notifications.attachment')}</Col>
        <Col xs={1}>{I18n.t('views.notifications.created-by')}</Col>
        <Col xs={1}>{I18n.t('views.notifications.edited-by')}</Col>
        <Col xs={1}>{I18n.t('views.notifications.is-published')}</Col>

      </Row>
      {this.state.notifications.map((notification) => {
        return (
          <Row style={{ borderBottom: '1px solid #f9f9f9' }} key={notification.id}>
            <Col xs={2}>{notification.title}</Col>
            <Col xs={3}>{notification.body.substring(0, 50)}...</Col>
            <Col xs={2}>{notification.attachment ? notification.attachment.name : null}</Col>
            <Col xs={1}>{notification.creator.name}</Col>
            <Col xs={1}>{notification.editor ? notification.editor.name : ''}</Col>
            <Col xs={1}>{notification.is_published ? I18n.t('views.notifications.yes') : I18n.t('views.notifications.no')}</Col>
            <Col xs={2}>
              { ownerManager &&
                <Edit
                  className="clickable"
                  onClick={(e) => this.editNotification(notification) }
                />
              }
              { ownerManager &&
                <Trash
                className="clickable"
                onClick={(e) => this.deleteNotification(notification) }
                />
              }
            </Col>
          </Row>
        );
      })}

      { ownerManager &&
      <Button variant="primary" onClick={this.createNotification}>
        {I18n.t('views.notifications.add-notification')}
      </Button>
      }

      {!this.state.loading && this.state.modal === Modals.notificationCreate &&
        <ModalNotificationCreate
          onHide={this.hideModal}
          notificationCallback={this.notificationCallback}
          requiredFieldsCheck={this.getRequiredFieldHasNoErrors}
        />
      }

      {!this.state.loading && this.state.modal === Modals.notificationEdit &&
        <ModalNotificationEdit
          onHide={this.hideModal}
          notificationCallback={this.notificationCallback}
          notification={this.state.editing}
          requiredFieldsCheck={this.getRequiredFieldHasNoErrors}
          downloadAttachment={this.downloadAttachment}
        />
      }
    </div>;
  }
}

export default NotificationsAdmin;
