import React, { Component } from 'react';
import I18n from 'i18n-js';
import {
  Modal,
  Toast,
  Button,
  Row,
  Col,
  Container,
  ButtonGroup,
  Form,
  FormControl,
  Dropdown,
  DropdownButton
} from 'react-bootstrap';
import moment from 'moment';
import AvainiaCore from 'avainia-core-api';
import SecureImage from '../../multiview/SecureImage/SecureImage.js';
import AvainiaPermissions from '../../../AvainiaTools/AvainiaPermissions.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import ShowFields from '../../multiview/Modals/ModalViewDocument/Components/ShowFields.js';
import './ModalViewDocument.scss';

class SummaryModalViewDocument extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: [],
      fieldData: [],
      newPosition: false,
      editCoords: false,
      newFields: [],
      replaceImageMode: false,
      loading: false,
      error: false,
      groupName: this.props.documentActive.group,
    };
  }

  changeStatus = (keywordId) => {
    const targetDocument = this.props.documentActive.id;
    const targetSummaryview = this.props.summaryView;

    console.log(keywordId);
    console.log(targetDocument);
    console.log(targetSummaryview);

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.summaryViewUpdateDocumentStatus({"keyword_id": keywordId}, targetSummaryview, targetDocument).then((result) => {
      if (!result || result.error) { return this.setState({ error: 4220 }); }
      this.props.documentEditedCallback(result);
    });

  }

  download = async (e) => {
    const targetDocument = this.props.documentActive;

    try {
      const obj = { headers: { Authorization: `Bearer ${LocalStorageService.getToken()}` } };
      const res = await fetch(process.env.REACT_APP_API_HOST + targetDocument.url, obj);

      if (res.ok) {
        const binary = await res.blob();

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(binary, targetDocument.files[0].full_name);
        } else {
          const src = window.URL.createObjectURL(binary);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style.cssText = 'display: none';
          a.href = src;
          a.download = targetDocument.files[0].full_name;
          a.click();
          window.URL.revokeObjectURL(src);
          setTimeout((x) => { document.body.removeChild(a); }, 1000);
        }
      } else if (res.status !== 410) { // 410 means dont retry
        throw new Error('failed to fetch document'); // TODO! Fatal error, do actual logging
      }
    } catch (ex) {
      console.error(ex); // TODO! Fatal error, do actual logging
    }
  }

  componentDidMount = () => {
    // This might be obsolete
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.fieldsGet().then((fields) => {
      if (fields.error) {
        return this.setState({ error: fields.error });
      }
      this.setState({ fields });
    });
  };

  componentDidUpdate = () => {
    if (!this.state.editCoords) { return; }
    if (window.editmap) { return; }

    if (window.google) {
      return this.initializeEditMap();
    }

    const scriptNode = document.createElement('script');
    scriptNode.type = 'text/javascript';
    scriptNode.id = 'googleMapsInsert';
    scriptNode.src = 'https://maps.google.com/maps/api/js?key=AIzaSyCn6T7i_CYc8AFNuT3idK-M0PjLG07anN4'; // TODO! DEDUPLICATE

    document.head.appendChild(scriptNode);

    // Wait for map to load
    scriptNode.addEventListener('load', this.initializeEditMap);
  }

  addNewField = (field) => {
    this.setState({ newFields: [...this.state.newFields, field] });
  }

  sortFields = (a, b) => {
    if (a.is_default > b.is_default) {
      return -1;
    }
    if (b.is_default > a.is_default) {
      return 1;
    }
    if (a.is_default && b.is_default) {
      if (a.order < b.order) {
        return -1;
      }
      return 1;
    }
    if (a.key < b.key) {
      return -1;
    }
    return 1;
  }

  render() {
    const { configuration, documentActive, disableEdit, disableDelete, show, project, isGroup } = this.props;
    const excludedFieldKeys = configuration.document && configuration.document.documentAddFieldExceptions ? Object.keys(configuration.document.documentAddFieldExceptions) : [];
    const visibleFields = documentActive.document_type.fields.filter(function(el) { return !excludedFieldKeys.includes(el.key) });

    if (!documentActive || !show) { return null; }

    const editMode = this.props.editMode;

    const user = LocalStorageService.getUser();

    const isMobile = this.props.isMobile;

    const modalButtons = (
      <>
      <DropdownButton
        alignRight
        size="sm"
        drop="top"
        variant="primary"
        onSelect={this.changeStatus}
        title={I18n.t('views.summaryviews.changestatus')}
        style={{margin:0, width:"100%"}}
      >
        {this.props.states.map((s) => <Dropdown.Item style={{margin:0, width:"100%"}} key={s.id} eventKey={s.id}>{s.text}</Dropdown.Item>)}
      </DropdownButton> 
      <ButtonGroup className={`d-flex modal-buttons ${this.state.width < 400 && 'small'}`}>
        {editMode && <Button onClick={this.deactivateEditMode}>{I18n.t('general.cancel')}</Button>}
        {!editMode && <Button onClick={this.download}>{I18n.t('views.infraproject.download')}</Button>}
        <Button onClick={(e) => { this.props.hideModal(); }}>{I18n.t('general.modal-close')}</Button>
      </ButtonGroup>
      </>
    );

    const documentPreviewAndDataRendered = (
      <Toast
        style={{
          width: '100%',
          maxWidth: '100%',
          height: isGroup ? '780px' : '100%',
          overflowY: 'auto',
        }}
        onClose={editMode ? this.deactivateEditMode : (e) => { this.props.hideModal(); }}
        animation={false}
      >
        <Toast.Header closeButton={isGroup ? false : true}>
          <strong className="mr-auto">{documentActive.counter} - {I18n.t('views.infraproject.info')}</strong>
        </Toast.Header>
        <Toast.Body>
          <SecureImage className="document-thumbnail" src={documentActive.thumb} />
          <Container style={{ marginBottom: isMobile ? '20px' : '125px' }}>
            <Row>
              <Col>
                {editMode && <Form.Group>
                  <Form.Label>{I18n.t('views.documents.group-name')}</Form.Label>
                  <FormControl
                    type="text"
                    value={this.state.groupName}
                    readOnly={false}
                    onChange={(e) => {
                      this.handleGroupChange(e.target.value);
                    }}
                  />
                </Form.Group>}
                <ShowFields
                  doc={documentActive}
                  fields={documentActive.document_type ? visibleFields: this.props.fields}
                  excludedFieldKeys={excludedFieldKeys}
                />
              </Col>
            </Row>
          </Container>
          {isMobile && <div className="modal-document-buttons">
            {modalButtons}
          </div>}
          <Container>
            {modalButtons}
          </Container>
        </Toast.Body>
        </Toast>
    );

    /*
     * The RndWrapper acts as a zero point and provides a fixed position to which the Rnd can be relative to the
     * This looks hacky but is needed because Rnd uses a transform(x,y) to position its element.
     */
    if (isMobile && !editMode) {
      return (
        <div className="toast-wrapper">
        <Modal.Body>
            {documentPreviewAndDataRendered}
        </Modal.Body>
        </div>);
    } else {
    return (
      <div className="toast-wrapper">
      {documentPreviewAndDataRendered}
      <Modal.Footer style={{width:"100%"}} className="modal-document-footer">
        <div className="modal-document-buttons">
          {modalButtons}
        </div>
      </Modal.Footer>
      </div>);
    }
  }
}

export default SummaryModalViewDocument;
