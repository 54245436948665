import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService';

class ApartmentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }

  save = (e) => {
    const { id } = this.state.condominium;

    const api = new AvainiaCore(LocalStorageService.getToken);
    if (id) {
      // Update
      this.setState({ loading: 1, error: false }, () => {
        api.condominiumEdit(this.state.condominium).then((result) => {
          if (result.error) { return this.setState({ error: result.error, loading: false }); }
          return this.setState({ loading: false, error: false }, () => this.props.successCallback(result));
        });
      });
    } else {
      // Create
      this.setState({ loading: 1, error: false }, () => {
        api.condominiumCreate(this.state.condominium).then((result) => {
          if (result.error) { return this.setState({ error: result.error, loading: false }); }
          return this.setState({ loading: false, error: false }, () => this.props.successCallback(result));
        });
      });
    }
  }

  onChange = (e) => {
    const condominium = { ...this.state.condominium };
    condominium[e.target.name] = e.target.value;
    this.setState({ condominium });
  }

  render() {
    const { apartment } = this.props;

    return <Modal show={this.props.show} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('general.apartment')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <h2>{apartment.stairwell} {apartment.apartment_number}</h2>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('general.modal-close')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default ApartmentModal;
