import React from "react";
import "./Icon.scss";
import { ReactComponent as ShieldIcon } from "../../../images/shield-alt-solid.svg";
import { ReactComponent as ProjectIcon } from "../../../images/project-diagram-solid.svg";
import { ReactComponent as CityIcon } from "../../../images/city-solid.svg";
import { ReactComponent as BoxIcon } from "../../../images/box-solid.svg";
import { ReactComponent as UserIcon } from "../../../images/user-solid.svg";
import { ReactComponent as UsersIcon } from "../../../images/user-friends-solid.svg";
import { ReactComponent as UsersCogIcon } from "../../../images/users-cog-solid.svg";
import { ReactComponent as SignOutIcon } from "../../../images/sign-out-alt-solid.svg";
import { ReactComponent as CogIcon } from "../../../images/cog-solid.svg";
import { ReactComponent as LockIcon } from "../../../images/lock-solid.svg";
import { ReactComponent as LanguageIcon } from "../../../images/language-solid.svg";
import { ReactComponent as SearchIcon } from "../../../images/search-solid.svg";
import { ReactComponent as AngleIconLeft } from "../../../images/angle-left-solid.svg";
import { ReactComponent as AngleIconUp } from "../../../images/angle-up-solid.svg";
import { ReactComponent as AngleIconRight } from "../../../images/angle-right-solid.svg";
import { ReactComponent as AngleIconDown } from "../../../images/angle-down-solid.svg";
import { ReactComponent as EditIcon } from "../../../images/edit-solid.svg";
import { ReactComponent as TrashIcon } from "../../../images/trash-alt-solid.svg";
import { ReactComponent as EnvelopeIcon } from "../../../images/envelope-solid.svg";
import { ReactComponent as UploadIcon } from "../../../images/upload-solid.svg";
import { ReactComponent as CloseIcon } from "../../../images/times-circle.svg";
import { ReactComponent as CheckIcon } from "../../../images/check-circle.svg";
import { ReactComponent as LinkIcon } from "../../../images/link-solid.svg";
import { ReactComponent as ClockIcon } from "../../../images/clock.svg";
import { ReactComponent as FileIcon } from "../../../images/file-alt-solid.svg";
import { ReactComponent as FolderIcon } from "../../../images/folder-solid.svg";
import { ReactComponent as FolderPlusIcon } from "../../../images/folder-plus-solid.svg";
import { ReactComponent as FolderMinusIcon } from "../../../images/folder-minus-solid.svg";
import { ReactComponent as ListIcon } from "../../../images/th-list-solid.svg";
import { ReactComponent as TableIcon } from "../../../images/table-solid.svg";
import { ReactComponent as CalendarIcon } from "../../../images/calendar.svg";
import { ReactComponent as MapMarkerIcon } from "../../../images/map-solid.svg";
import { ReactComponent as FilterIcon } from "../../../images/filter-solid.svg";
import { ReactComponent as PlusIcon } from "../../../images/plus-solid.svg";
import { ReactComponent as KeyIcon } from "../../../images/key-solid.svg";
import { ReactComponent as AlignLeftIcon } from "../../../images/align-left-solid.svg";
import { ReactComponent as UndoIcon } from "../../../images/undo-solid.svg";
import { ReactComponent as NotificationIcon } from "../../../images/bell.svg";
import { ReactComponent as DownloadIcon } from "../../../images/download-solid.svg";
import { ReactComponent as InfoCircleIcon } from "../../../images/info-circle-solid.svg";
import { ReactComponent as MarkerColorByIcon } from "../../../images/map-marked-solid.svg";
import { ReactComponent as EnlargeIcon } from "../../../images/enlarge.svg";
import { ReactComponent as ShrinkIcon } from "../../../images/shrink.svg";
import { ReactComponent as ProcessIcon } from "../../../images/process-solid.svg";

const Shield = (props) => <ShieldIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Project = (props) => <ProjectIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const City = (props) => <CityIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Box = (props) => <BoxIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const User = (props) => <UserIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Users = (props) => <UsersIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const UsersCog = (props) => <UsersCogIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const SignOut = (props) => <SignOutIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Cog = (props) => <CogIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Lock = (props) => <LockIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Language = (props) => <LanguageIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Search = (props) => <SearchIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const AngleLeft = (props) => <AngleIconLeft {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const AngleRight = (props) => (
  <AngleIconRight {...props} className={`Icon ${props.className ? props.className : ""}`} />
);
const AngleUp = (props) => <AngleIconUp {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const AngleDown = (props) => <AngleIconDown {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Edit = (props) => <EditIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Trash = (props) => <TrashIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Envelope = (props) => <EnvelopeIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Upload = (props) => <UploadIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Close = (props) => <CloseIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Check = (props) => <CheckIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Link = (props) => <LinkIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Clock = (props) => <ClockIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const File = (props) => <FileIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Folder = (props) => <FolderIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const FolderPlus = (props) => (
  <FolderPlusIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />
);
const FolderMinus = (props) => (
  <FolderMinusIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />
);
const List = (props) => <ListIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Table = (props) => <TableIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Calendar = (props) => <CalendarIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const MapMarker = (props) => <MapMarkerIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Filter = (props) => <FilterIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Plus = (props) => <PlusIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Key = (props) => <KeyIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const AlignLeft = (props) => <AlignLeftIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Undo = (props) => <UndoIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Notification = (props) => (
  <NotificationIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />
);
const Download = (props) => <DownloadIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const InfoCircle = (props) => (
  <InfoCircleIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />
);
const MarkerColorBy = (props) => (
  <MarkerColorByIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />
);
const Shrink = (props) => <ShrinkIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Enlarge = (props) => <EnlargeIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;
const Process = (props) => <ProcessIcon {...props} className={`Icon ${props.className ? props.className : ""}`} />;

export {
  Shield,
  Project,
  City,
  Box,
  User,
  Users,
  UsersCog,
  SignOut,
  Cog,
  Lock,
  Language,
  Search,
  AngleLeft,
  AngleRight,
  AngleUp,
  AngleDown,
  Edit,
  Trash,
  Envelope,
  Upload,
  Close,
  Check,
  Link,
  Clock,
  File,
  Folder,
  FolderPlus,
  FolderMinus,
  List,
  Table,
  Calendar,
  MapMarker,
  Filter,
  Plus,
  Key,
  AlignLeft,
  Undo,
  Notification,
  Download,
  InfoCircle,
  MarkerColorBy,
  Shrink,
  Enlarge,
  Process,
};
