import React, { Component } from "react";
import AvainiaPanel from "../../../multiview/Panels/AvainiaPanel";
import { AngleDown, Process, Trash } from "../../../multiview/Icon/Icon";
import I18n, { placeholder } from "i18n-js";
import SummaryCard from "../../../multiview/Card/SummaryCard";
import "./ProgressNumbersTab.scss";
import { Collapse, ListGroup, Button, DropdownButton } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import AvainiaCore from "avainia-core-api";
import Loading from "../../../multiview/Loading/Loading";
import Error from "../../../multiview/Error/Error";
import LocalStorageService from "../../../../AvainiaTools/LocalStorageService.js";
import ModalProgressReportAdd from "../../../multiview/Modals/ModalProgressReportAdd.js";

const Modals = {
  progressReportAdd: 1,
};
class ProgressNumbersTab extends Component {
  constructor() {
    super();

    this.state = {
      progressNumbers: [],
      filteredNumbers: [],
      summedValues: [],
      error: false,
      loading: true,
      open: false,
      setOpen: false,
      start_at: "",
      end_at: "",
      modal: false,
    };
  }

  componentDidMount = () => {
    this.getProgressNumbers();
  };

  getProgressNumbers = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.progressNumbersGet(this.props.project.id).then((progressNumbers) => {
      if (progressNumbers.error) {
        return this.setState({ error: progressNumbers.error });
      }

      this.setState({ progressNumbers: progressNumbers.data, loading: false });
    });
  };

  delete = (e) => {
    if (this.state.loading) {
      return;
    }

    if (!window.confirm(I18n.t("views.progressnumbers.confirmreportdelete"))) {
      return;
    }

    this.setState({ loading: true, error: false }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.progressNumbersDelete(this.props.project.id, e.id).then((progressNumber) => {
        if (progressNumber.error) {
          this.setState({ error: progressNumber.error, loading: false });
        }

        const progressNumbers = this.state.progressNumbers.filter((x) => x.id !== progressNumber.id);
        this.setState({ progressNumbers, loading: false });
        this.refreshView();
      });
    });
  };

  filterNumbers = (progressNumbers, start_at, end_at) => {
    let filteredNumbers = [];
    let compare = 0;

    if (!start_at && !end_at) return progressNumbers;

    if (!start_at) {
      start_at = 0;
    }

    if (!end_at) {
      end_at = 64074879674;
    }

    progressNumbers.map((filteredNumber) => {
      compare = moment(filteredNumber.date, "DD.MM.YYYY hh:mm").unix();
      if (compare >= start_at && compare <= end_at + 86400) {
        filteredNumbers.push(filteredNumber);
      }
    });

    return filteredNumbers;
  };

  // Function to sum the values and delete non-unique slugs
  sumValues = (progressNumbers) => {
    let summedValues = [];

    progressNumbers.forEach((progressNumber) => {
      progressNumber.numbers.forEach((value) => {
        let found = false;
        summedValues.forEach((summedValue) => {
          if (summedValue.slug === value.slug) {
            found = true;
            summedValue.value += parseInt(value.value);
          }
        });

        if (!found) {
          summedValues.push({ slug: value.slug, value: parseInt(value.value), unit: value.unit });
        }
      });
    });

    return summedValues;
  };

  onChangeDatePicker = (date, target) => {
    this.setState({ [target]: date });
  };

  handleCollapseClick = (id) => {
    if (!this.state.open || id !== this.state.open) {
      this.setState({
        open: id,
      });
    } else if (this.state.open === id) {
      this.setState({ open: false });
    }
  };

  refreshView = () => {
    this.getProgressNumbers();
  };

  addProgressReport = () => {
    this.setState({ modal: Modals.progressReportAdd });
  };

  hideModal = (e) => {
    this.setState({ modal: false });
  };

  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    let numbersFiltered = this.filterNumbers(
      this.state.progressNumbers,
      moment(this.state.start_at, "DD.MM.YYYY").unix(),
      moment(this.state.end_at, "DD.MM.YYYY").unix()
    );
    let summedNumbers = this.sumValues(numbersFiltered);
    let startDate = moment(this.state.start_at).format("DD.MM.YYYY");
    let endDate = moment(this.state.end_at).format("DD.MM.YYYY");

    return (
      <div className="App-container">
        <AvainiaPanel
          className="avainiaPanel"
          heading={I18n.t("views.progressnumbers.progressnumbers")}
          icon={<Process />}
        >
          <DropdownButton className="dropdown-button" title={I18n.t("views.progressnumbers.timeperiod")}>
            <DatePicker
              name="start_at"
              selected={this.state.start_at}
              onChange={(date) => this.onChangeDatePicker(date, "start_at")}
              isClearable
              className="datepicker-input"
              dateFormat="dd.MM.yyyy"
              popperPlacement="auto-left"
            />
            <strong>–</strong>
            <DatePicker
              name="end_at"
              selected={this.state.end_at}
              onChange={(date) => this.onChangeDatePicker(date, "end_at")}
              isClearable
              className="datepicker-input"
              dateFormat="dd.MM.yyyy"
              popperPlacement="auto-left"
            />
          </DropdownButton>

          <div className="time-container">
            <div className="time-between">
              {startDate !== "Invalid date" && startDate + " - "}
              {endDate !== "Invalid date" && endDate}
            </div>
          </div>
          <div className="cardgrid">
            {summedNumbers &&
              summedNumbers.map((summedValue) => (
                <SummaryCard
                  heading={I18n.t("views.progressnumbers." + summedValue.slug)}
                  value={summedValue.value}
                  key={summedValue.slug}
                  unit={summedValue.unit}
                ></SummaryCard>
              ))}
          </div>
          <div className="progressreport-div">
            <b className="progressreport">{I18n.t("views.progressnumbers.progressreports")}</b>
            <Button variant="primary" className="report-button" onClick={this.addProgressReport}>
              {I18n.t("views.progressnumbers.addreport")}
            </Button>
          </div>

          <ListGroup className="document-list">
            {numbersFiltered &&
              numbersFiltered.map((progressNumber) => (
                <ListGroup.Item
                  key={progressNumber.id}
                  className="document-list-item"
                  onClick={() => this.handleCollapseClick(progressNumber.id)}
                >
                  <span
                    className="document-list-item-title"
                    aria-controls={progressNumber.id}
                    aria-expanded={this.state.open}
                  >
                    <b className="document-list-item-id">{progressNumber.id}. </b>
                    <span className="document-list-date">{progressNumber.date}</span> <span>{progressNumber.user}, {progressNumber.company}</span>
                  </span>
                  <AngleDown
                    className="angle-button document-list-collapse"
                    onClick={() => {
                      this.handleCollapseClick(progressNumber.id);
                    }}
                  />
                  <Trash
                    className="trash-button"
                    onClick={() => {
                      this.delete(progressNumber);
                    }}
                  />
                  <Collapse in={this.state.open === progressNumber.id}>
                    <div id={progressNumber.id} className="collapse-grid">
                      {progressNumber.numbers.map((value) => (
                        <div class="listItem" key={value.slug}>
                          {I18n.t(`views.progressnumbers.${value.slug}`)}
                          <div className="listItem-value" style={{ float: "right" }}>
                            <b style={{ float: "right" }}>
                              {value.value} {value.unit}{" "}
                            </b>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Collapse>
                </ListGroup.Item>
              ))}
          </ListGroup>

          <ModalProgressReportAdd
            show={this.state.modal === Modals.progressReportAdd}
            onHide={this.hideModal}
            onRefresh={this.refreshView}
            project={this.props.project}
          />
        </AvainiaPanel>
      </div>
    );
  }
}

export default ProgressNumbersTab;
