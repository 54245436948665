import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Edit, Trash } from '../../multiview/Icon/Icon.js';
import { Form, Button } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import ApartmentModal from './ApartmentModal.js';
import AvainiaTable from '../../multiview/AvainiaTable/AvainiaTable.js';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

const Modals = {
  apartment: 1,
};

class CondominiumApartmentsTab extends Component {
  constructor(props) {
    super(props);

    let error = false;
    const t = this.props.apartmenttemplates;
    if (typeof t === 'undefined' || !t.length) {
      error = 16;
    }

    this.state = {
      apartments: [],
      stairwell: '',
      apartment_number: '',
      apartment_template_id: error ? -1 : t[0].id,
      redirect: false,
      modalize: false,
      modal: false,
      secondaryLoading: false,
      secondaryError: false,
      loading: true,
      error,
    };
  }

  componentDidMount() {
    const { id } = this.props.condominium;
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.condominiumApartmentsGet(id).then((apartments) => {
      if (apartments.error) { return this.setState({ error: apartments.error }); }

      this.setState({ apartments, loading: false });
    });
  }

  create = () => {
    if (this.state.loading) { return; }

    const payload = {
      stairwell: this.state.stairwell,
      apartment_number: this.state.apartment_number,
      apartment_template_id: this.state.apartment_template_id,
    };

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.condominiumApartmentCreate(this.props.condominium.id, payload).then((apartment) => {
      if (apartment.error) { return this.setState({ error: apartment.error }); }

      const { apartments } = this.state;
      apartments.push(apartment);

      this.setState({ apartments, loading: false });
    });
  }

  delete = (targetId) => {
    if (this.state.loading) { return; }
    // TODO: Confirm
    this.setState({ loading: 1, error: false }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.condominiumApartmentDelete(this.props.condominium.id, targetId).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  hideModal = (e) => { this.setState({ modal: false }); }

  activateAptModal = (e) => { this.setState({ modal: Modals.apartments }); }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  modalize = (apartment) => { this.setState({ modal: Modals.apartment, modalize: apartment }); }

  getApartmentTemplateNameById = (id) => {
    // eslint-disable-next-line eqeqeq
    const target = this.props.apartmenttemplates.find((x) => x.id == id);
    return target ? target.name : '';
  }

  renderActions = (cell, row) => {
    return <>
      <Trash data-todo="TODO: CONFIRM" onClick={() => { this.delete(row.id); }} />
      <Edit onClick={() => { this.modalize(row.id); }} />
    </>;
  }

  render() {
    if (this.state.error) { return <Error inline error={this.state.error} />; }
    if (this.state.loading) { return <Loading inline />; }
    if (this.state.navigate) { return <Redirect to={`/condominium/${this.props.condominium.id }/apartments/${this.state.navigate}`} push={true} />; }

    return <div className="App-container">
      <h1>{I18n.t('views.condominiums.tab-apartments')}</h1>

      <AvainiaTable data={this.state.apartments} keyField="id" rowClickHandler={(e, rowData) => { this.setState({ navigate: rowData.id }); }} columns={[
        { dataField: 'id', text: I18n.t('general.id'), headerStyle: { width: '60px' } },
        { dataField: 'stairwell', text: I18n.t('views.condominiums.apartments.stairwell') },
        { dataField: 'apartment_number', text: I18n.t('views.condominiums.apartments.apartment_number') },
        { dataField: 'apartment_template_id', text: I18n.t('views.condominiums.apartments.apartment_template_id'), formatter: (cell, row) => <>{this.getApartmentTemplateNameById(row.apartment_template_id)}</> },
        { dataField: 'actions', text: I18n.t('general.table-actions'), headerStyle: { width: '100px' }, formatter: this.renderActions },
      ]} />

      <b>{I18n.t('views.condominiums.apartments.stairwell')}</b>
      <Form.Control type="text" onChange={this.onChange} name="stairwell" value={this.state.stairwell} />

      <b>{I18n.t('views.condominiums.apartments.apartment_number')}</b>
      <Form.Control type="text" onChange={this.onChange} name="apartment_number" value={this.state.apartment_number} />

      <b>{I18n.t('views.condominiums.apartments.apartment_template_id')}</b>
      <Form.Control as="select" onChange={this.onChange} name="apartment_template_id">
        {this.props.apartmenttemplates.map((x) => <option value={x.id} key={x.id}>{x.name}</option>)}
      </Form.Control>

      <div className="actions largebuffer">
        <Button
          className="right"
          size="sm"
          data-todo="TODO: permissions"
          onClick={this.create}
        >
          {I18n.t('views.condominiums.apartments.button-create')}
        </Button>
      </div>

      <ApartmentModal
        apartment={this.state.modalize}
        show={this.state.modal === Modals.apartment}
        onHide={this.hideModal}
        successCallback={this.successCallback}
        condominium={this.props.condominium}
      />
    </div>;
  }
}

export default CondominiumApartmentsTab;
