import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import I18n from 'i18n-js';

// Based on https://cuneyt.aliustaoglu.biz/en/using-google-maps-in-react-without-custom-libraries/

class ProjectsMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mapHidden: true,
      mapExpanded: false,
      key: Math.random()
    };
  }

  MapSettings = {
    apikey: 'AIzaSyCn6T7i_CYc8AFNuT3idK-M0PjLG07anN4',
    defaultCenter: { lat: window.Avainia.config.coordinates.lat ? window.Avainia.config.coordinates.lat : '0', lng: window.Avainia.config.coordinates.lng ? window.Avainia.config.coordinates.lng : '0' },
    zoom: 6,
    unsetZoom: 15,
    spidefierOptions: {
      maxZoom: 18,
      legWeight: 3,
      keepSpiderfied: 1,
      circleFootSeparation: 30,
      markersWontMove: true,
      markersWontHide: true,
    },
  };

  refreshMap() {
    this.setState({
      key: Math.random()
    });
  }

  onGoogleApiLoaded = (config) => {
    const { maps, map } = config;
    window._map = map; // This is necessary! TODO: refactor

    const OverlappingMarkerSpiderfier = require('overlapping-marker-spiderfier');
    const MarkerClusterer = require('node-js-marker-clusterer');
    const oms = new OverlappingMarkerSpiderfier(map, this.MapSettings.spidefierOptions);

    const markers = this.props.projects.filter((x) => x.coordinates).map((project) => {

      var coordinates = project.coordinates.split(',');
      coordinates = {lat: parseFloat(coordinates[0]), lng: parseFloat(coordinates[1])};

      const marker = new maps.Marker({
        position: coordinates,
        map,
        label: project.code,
        icon: {
          labelOrigin: new maps.Point(14, 14),
          path: "M12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
          fillColor: "red",
          fillOpacity: 1,
          strokeWeight: 1,
          strokeColor: "white",
          rotation: 0,
          scale: 2,
          labelOrigin: new window.google.maps.Point(12, 10)
        },
      });

      var contentString = `<a href="/project/${project.id}"><h6>${project.code} - ${project.name}</h6></a>`;

      project.companies.map((company) => {
        contentString += `${company.name},`;
      });
      contentString = contentString.slice(0, -1);
      contentString += `<br> ${I18n.t('folders.filecount')}: ${project.document_count}`;
      contentString += `<br> ${I18n.t('folders.mostRecentDocument')}: ${project.mostRecentDocument}`;

      const infowindow = new maps.InfoWindow({
        content: contentString,
      });

      marker.addListener("click", () => {
        infowindow.open({
          anchor: marker,
          map,
          shouldFocus: false,
        });
      });

      oms.addMarker(marker);

      return marker;
    });
    window._oms = oms;
    window._markers = markers;

    const closeFullscreen = document.createElement('div');
    closeFullscreen.className = 'closeFullscreen';
    closeFullscreen.style.top = '10px';
    closeFullscreen.style.marginRight = '3em';
    closeFullscreen.style.cursor = 'pointer';
    closeFullscreen.setAttribute('id', 'closeFullcreen');
    closeFullscreen.addEventListener('click', () => {
      document.getElementById('root').classList.remove('mobile-map-fullscreen');
    });

    map.controls[maps.ControlPosition.RIGHT_TOP].push(closeFullscreen);
    window._mc = new MarkerClusterer(map, markers, {
      imagePath: '/m/m',
      maxZoom: 16,
    });
    this.getDefaultCenter();
  }

  getDefaultCenter = () => {
    return (`lat: ${window.Avainia.config.coordinates.lat}, lng: ${window.Avainia.config.coordinates.lng}`);
  }

  getCenter = () => {

    var coordinates = null;

    this.props.allProjects.map((x) => {
      if(x.coordinates){
        coordinates = x.coordinates.split(',');
        coordinates = {lat: parseFloat(coordinates[0]), lng: parseFloat(coordinates[1])};
      }
    });

    if(coordinates){
      return coordinates;
    }

    if (this.props.coordinates) {
      const coords = this.props.coordinates.split(',');
      const mapCoords = {lat: parseFloat(coords[0]), lng: parseFloat(coords[1])}
      return mapCoords;
    }
    return this.MapSettings.defaultCenter;
  }

  toggleMapExpand = () => {
    this.setState((prevState) => ({ mapExpanded: !prevState.mapExpanded }));
  }

  render() {
    if (this.props.hideableMap) {
      if (this.props.mapHidden) { return false; }
    }

    const { mapExpanded } = this.state;

    return <>
      <div className={`projects-map map-wrapper ${mapExpanded ? 'expand' : ''}`}>
        <div className="map-container">
          <GoogleMapReact
            key={this.state.key}
            bootstrapURLKeys={{ key: this.MapSettings.apikey }}
            defaultCenter={this.getCenter()}
            defaultZoom={this.MapSettings.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={this.onGoogleApiLoaded}
            options={{
              fullscreenControl: false,
              zoomControl: true,
              scaleControl: true,
              mapTypeControl: true,
              streetViewControl: true,
            }}
          />
        </div>
      </div>
    </>;
  }
}

export default ProjectsMap;
