import React, { Component } from "react";
import I18n from "i18n-js";
import { Button, InputGroup, Form, Row, Col } from "react-bootstrap";
import AvainiaCore from "avainia-core-api";
import { Check, Close, Key } from "../../../multiview/Icon/Icon.js";
import Loading from "../../../multiview/Loading/Loading.js";
import AvainiaTable from "../../../multiview/AvainiaTable/AvainiaTable.js";
import CustomSelect from "../../../multiview/Select/CustomSelect.js";
import CompaniesTabModal from "./CompaniesTabModal.js";
import LocalStorageService from "../../../../AvainiaTools/LocalStorageService.js";

const Modals = {
  editCompany: 1,
};

class CompaniesTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      search: "",
      selectedCompany: false,
      companyToEdit: false,
      modal: false,
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.companiesGet().then((companies) => {
      if (companies.error) {
        return this.setState({ loading: false, error: companies.error });
      }

      this.setState({
        companies,
        loading: false,
      });
    });
  }

  search = (e) => {
    this.setState({ search: e.target.value });
  };

  getCompaniesAddedToProjects = () => {
    const term = this.state.search;
    const searchable = ["name", "code", "type"];

    return this.props.project.companies.filter((obj) =>
      Object.keys(obj)
        .filter((key) => searchable.includes(key))
        .some((key) => {
          return obj[key].toLowerCase().includes(term.toLowerCase());
        })
    );
  };

  getCompaniesToShowInTable = () => {
    return this.getCompaniesAddedToProjects();
  };

  getCompanyRolesToShowInTable = () => {
    // TODO: Get existing permissions somehow
    if (!this.state.selectedCompany) {
      return [];
    }

    return this.state.selectedCompany.roles;
  };

  closeModal = (e) => {
    this.setState({ modal: false, companyToEdit: false });
  };

  addCallback = (company) => {
    window.location.reload(); // TODO: Improve
  };

  removeCallback = (id) => {
    window.location.reload(); // TODO: Improve
  };

  attachCompany = () => {
    this.setState({ modal: Modals.editCompany });
  };

  handleCompanySelectChange = (name, value) => {
    const company = this.state.companies.find((x) => Number(x.id) === Number(value.value)); // Hurr durr typing is hard
    this.setState({ selectedCompany: company });
  };

  contentPermissionCreate = (role, folder) => {
    // TODO! Hardcoding the contentPermission type, should it be done?
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.contentPermissionAdd(role, "App\\Folder", folder.id).then((result) => {
      if (result.error) {
        return this.setState({ error: result.error });
      }

      window.location.reload(); // TODO: Improve
    });
  };

  contentPermissionRemove = (contentPermission) => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.contentPermissionRemove(contentPermission).then((result) => {
      if (result.error) {
        return this.setState({ error: result.error });
      }

      window.location.reload(); // TODO: Improve
    });
  };

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <div className="App-container">
        <Row className="align-items-center mb-5">
          <Col xs={6}>
            <h1 className="App-pageTitle mb-0">{I18n.t("views.companies.companies")}</h1>
          </Col>
          <Col xs={4}>
            <InputGroup>
              <Form.Control
                placeholder={I18n.t("views.companies.search")}
                name="Search"
                type="text"
                onChange={this.search}
                value={this.state.search}
              />
            </InputGroup>
          </Col>
          <Col xs={2}>
            <Button block variant="primary" onClick={this.attachCompany} style={{ marginBottom: "0" }}>
              {I18n.t("views.companies.attach-company")}
            </Button>
          </Col>
        </Row>

        <AvainiaTable
          keyField="id"
          data={this.getCompaniesToShowInTable()}
          rowClickHandler={(e, rowData) => {
            this.setState({
              companyToEdit: this.state.companies.find((x) => x.id === rowData.id),
              modal: Modals.editCompany,
            });
          }}
          columns={[
            { dataField: "name", text: I18n.t("views.companies.name") },
            {
              dataField: "type",
              text: I18n.t("views.companies.type"),
              formatter: (cell, row) => I18n.t(`constants.companyTypes.${cell}`),
            },
          ]}
        />

        {this.props.project.type === "infrastructure" && (
          <>
            <h1>{I18n.t("folders.folders")}</h1>
            <div className="companies-buttons" style={{ width: 300, padding: 10 }}>
              {this.state.companies.length > 0 && (
                <CustomSelect
                  name="company"
                  placeholder={""}
                  handleChange={this.handleCompanySelectChange}
                  selectOptions={this.getCompaniesToShowInTable().map((c) => {
                    return { label: c.name, value: c.id };
                  })}
                  isSearchable={true}
                />
              )}
            </div>

            <p>{I18n.t("folders.warning-visibility")}</p>

            {!this.state.selectedCompany && I18n.t("folders.please-select-company")}
            {this.state.selectedCompany && (
              <AvainiaTable
                keyField="id"
                data={this.props.folders.sort((a, b) => {
                  return a.name > b.name ? 1 : -1;
                })}
                columns={[
                  {
                    dataField: "name",
                    text: "-",
                    formatter: (cell, folder) => {
                      if (folder.default) {
                        return (
                          <>
                            <Key /> {cell}
                          </>
                        );
                      }
                      return cell;
                    },
                  },
                ].concat(
                  this.getCompanyRolesToShowInTable().map((role) => {
                    return {
                      dataField: `${role.id}`,
                      text: I18n.t(`constants.userTypes.${role.display_name}`),
                      formatter: (cell, folder) => {
                        if (folder.default) {
                          return "-";
                        }

                        // TODO: If "employee" has a folder-contentpermission, it automatically means "manager" and "editor" users implicitly have the permission too as they are always employees
                        if (folder.contentPermissions) {
                          const contentPermission = folder.contentPermissions.find((p) => p.role_id === role.id);
                          if (contentPermission) {
                            return (
                              <Check
                                onClick={() => this.contentPermissionRemove(contentPermission)}
                                title={I18n.t("folders.click-to-revoke-permission")}
                              />
                            );
                          }
                        }
                        return (
                          <Close
                            onClick={() => this.contentPermissionCreate(role, folder)}
                            title={I18n.t("folders.click-to-grant-permission")}
                          />
                        );
                      },
                    };
                  })
                )}
              />
            )}
          </>
        )}

        {this.state.modal === Modals.editCompany && (
          <CompaniesTabModal
            project={this.props.project}
            onHide={this.closeModal}
            addCallback={this.addCallback}
            removeCallback={this.removeCallback}
            companiesSelected={this.state.companiesSelected}
            companies={this.state.companies}
            companyToEdit={this.state.companyToEdit}
          />
        )}
      </div>
    );
  }
}

export default CompaniesTab;
