import React, { Component } from 'react';
import { Button, Modal, Row, Form, Col, InputGroup } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import { Upload, Trash } from '../../multiview/Icon/Icon.js';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class ProductEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product: this.props.product,
      product_photo: this.props.product.product_photo,
      product_context_photo: this.props.product.product_context_photo,
      loading: false,
      error: false,
    };

    this.onChange = this.onChange.bind(this);
  }

  save = () => {
    const productId = this.props.product.id;
    const { product } = this.state;

    // If null, signal core to delete images
    if (product.product_context_photo === null) {
      product.delete_product_context_photo = 1;
      delete product.product_context_photo;
    }

    if (product.product_photo === null) {
      product.delete_product_photo = 1;
      delete product.product_photo;
    }

    // Don't send urls back
    if (typeof product.product_photo === 'string') { delete product.product_photo; }
    if (typeof product.product_context_photo === 'string') { delete product.product_context_photo; }

    this.setState({ loading: 1, error: false }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.productsEdit(productId, product).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        return this.setState({ loading: false, error: false }, () => {
          this.props.editProductCallback(product);
          this.props.onHide();
        });
      });
    });
  }

  onChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;

    this.setState((prevState) => ({
      product: {
        ...prevState.product,
        [name]: value,
      },
    }));
  }

  onRemoveImage = (e) => {
    const name = e.target.getAttribute('data-name');
    this[`fileinput_${name}`].value = null;

    this.setState((prevState) => ({
      [name]: null,
      product: {
        ...prevState.product,
        [name]: null,
      },
    }));
  }

  handleImageChange = (e) => {
    const { name } = e.target;
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      this.setState((prevState) => ({
        [name]: reader.result,
        product: {
          ...prevState.product,
          [name]: file,
        },
      }));
    };

    reader.readAsDataURL(file);
  }

  findPhotoColSize = () => {
    if (this.state.product_photo) {
      return '6';
    }

    return '10';
  }

  render() {
    return <Modal show={true} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.products.edit-product')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.error && <Error inline error={this.state.error} />}
        {!this.state.error && this.state.loading && <Loading inline />}
        {!this.state.error && !this.state.loading && <div>
          <Form.Group as={Row} id="edit-product-form">
            <Form.Label column sm="2">{I18n.t('views.products.productname-label')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.onChange} name="name" value={this.state.product.name || ''} isInvalid={!this.state.product.name} /></Col>
            <Form.Label column sm="2">{I18n.t('views.products.productmanufacturer-label')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.onChange} name="manufacturer" value={this.state.product.manufacturer || ''} isInvalid={!this.state.product.manufacturer} /></Col>
            <Form.Label column sm="2">{I18n.t('views.products.productcode-label')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.onChange} name="code" value={this.state.product.code || ''} isInvalid={!this.state.product.code} /></Col>
            <Form.Label column sm="2">{I18n.t('views.products.productdescription-label')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.onChange} name="description" value={this.state.product.description || ''} /></Col>
            <Form.Label column sm="2">{I18n.t('views.products.productprice-label')}</Form.Label>
            <Col sm="10">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">€</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control type="text" onChange={this.onChange} name="price" value={this.state.product.price || ''} isInvalid={isNaN(this.state.product.price)} />
              </InputGroup>
            </Col>
            <Form.Label column sm="2">{I18n.t('views.products.productmeasurements-label')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.onChange} name="measurements" value={this.state.product.measurements || ''} /></Col>
            <Form.Label column sm="2">{I18n.t('views.products.productexternallink-label')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.onChange} name="external_link" value={this.state.product.external_link || ''} /></Col>
            <Form.Label column sm="2">{I18n.t('views.products.product-photo-label')}</Form.Label>
            {this.state.product_photo && <Col sm="4"><img alt="product preview" className="product-preview-image" src={this.state.product_photo}/></Col>}
            <Col sm={this.findPhotoColSize()}>
              {this.state.product_photo && <Button variant="danger" className="remove-photo-button" onClick={this.onRemoveImage} data-name="product_photo"><Trash />{I18n.t('general.remove-photo')}</Button> }
              <Form.Control type="file" id="modal_product_photo" className="inputfile" onChange={this.handleImageChange} name="product_photo" ref={(ref) => this.fileinput_product_photo = ref}/>
              <label htmlFor="modal_product_photo"><Upload /> {I18n.t('general.choose-photo')}</label>
            </Col>
            <Form.Label column sm="2">{I18n.t('views.products.product-context-photo-label')}</Form.Label>
            {this.state.product_context_photo && <Col sm="4"><img alt="product context preview" className="product-preview-image" src={this.state.product_context_photo}/></Col>}
            <Col sm="6">
              {this.state.product_context_photo && <Button variant="danger" className="remove-photo-button" onClick={this.onRemoveImage} data-name="product_context_photo"><Trash />{I18n.t('general.remove-photo')}</Button> }
              <Form.Control type="file" id="modal_product_context_photo" className="inputfile" onChange={this.handleImageChange} name="product_context_photo" ref={(ref) => this.fileinput_product_context_photo = ref}/>
              <label htmlFor="modal_product_context_photo"><Upload /> {I18n.t('general.choose-photo')}</label>
            </Col>
          </Form.Group>
        </div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" disabled={this.state.loading} onClick={this.save}>{I18n.t('general.modal-form-save')}</Button>
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('general.modal-close')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default ProductEditModal;
