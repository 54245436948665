import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Button } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import AvainiaPanel from './AvainiaPanel.js';
import { Cog, Trash } from '../Icon/Icon.js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import ModalUsercategoryCreate from '../Modals/ModalUsercategoryCreate.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

const Modals = {
  createUsercategory: 1,
};

export default class PanelUserCategories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userCategories: [],
      modal: false,
      loading: true,
      error: false,
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.userCategoriesGet().then((userCategories) => {
      if (userCategories.error) { return this.setState({ error: userCategories.error }); }

      this.setState({
        userCategories,
        loading: false,
      });
    });
  }

  showCreateModal = () => {
    this.setState({ modal: Modals.createUsercategory });
  }

  hideModal = () => { this.setState({ modal: false }); }

  deleteUserCategory = (userCategory) => {
    if (this.state.loading) { return; }

    if (!window.confirm(I18n.t('usercategories.confirm-delete'))) { return; }

    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.userCategoryDelete(userCategory.id).then((result) => {
        if (result.error) {
          return this.setState({
            error: result.error === -3 ? 28 : result.error, // Joel, your code system Sucks.
            loading: false,
          });
        }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  render() {
    return <AvainiaPanel heading={I18n.t('usercategories.heading')} icon={<Cog />}>
      {this.state.error && <Error inline error={this.state.error} />}
      {this.state.loading && <Loading inline />}

      <ul>
        {this.state.userCategories.map((uc) => (
          <li key={uc.id}>
            {uc.name}
            {!!uc.default && ` (${I18n.t('usercategories.default')})`}
            &nbsp;
            {!uc.default && <Trash
              className="clickable"
              onClick={(e) => this.deleteUserCategory(uc)}
              style={{ color: 'red' }}
            />}
          </li>
        ))}
      </ul>

      <Button onClick={this.showCreateModal}>
        {I18n.t('usercategories.button-create-new')}
      </Button>

      <ModalUsercategoryCreate
        show={this.state.modal === Modals.createUsercategory}
        onHide={this.hideModal}
      />
    </AvainiaPanel>;
  }
}
