import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { Form } from 'react-bootstrap';
import { MarkerColorBy } from '../../multiview/Icon/Icon.js'
import I18n from 'i18n-js';

// Based on https://cuneyt.aliustaoglu.biz/en/using-google-maps-in-react-without-custom-libraries/

class SummaryMap extends Component {
  constructor(props) {
    super(props);
    this.escFunction = this.escFunction.bind(this);

    this.state = {
      mapHidden: true,
      mapExpanded: false,
      mapFullScreen: false,
    };
  }

  MapSettings = {
    apikey: 'AIzaSyCn6T7i_CYc8AFNuT3idK-M0PjLG07anN4',
    defaultCenter: { lat: window.Avainia.config.coordinates.lat ? window.Avainia.config.coordinates.lat : '0', lng: window.Avainia.config.coordinates.lng ? window.Avainia.config.coordinates.lng : '0' },
    zoom: 10,
    unsetZoom: 15,
    spidefierOptions: {
      maxZoom: 18,
      legWeight: 3,
      keepSpiderfied: 1,
      circleFootSeparation: 30,
      markersWontMove: true,
      markersWontHide: true,
    },
  };

  onGoogleApiLoaded = (config) => {
    const { maps, map } = config;
    window._map = map; // This is necessary! TODO: refactor

    const OverlappingMarkerSpiderfier = require('overlapping-marker-spiderfier');
    const MarkerClusterer = require('node-js-marker-clusterer');
    const oms = new OverlappingMarkerSpiderfier(
      map,
      this.MapSettings.spidefierOptions
    );

    const markers = [];

    const images = this.props.imagesByGroups;

    images.forEach(group => {
      group.images.map((image) => {
        
        let colorIndex;
        image.keywords.map((word) => {
          if(word.text === image.state){
            colorIndex = word.id;
          }
        });

        let customColor = this.props.configuration.summaryviews.keywordColors[colorIndex] ? this.props.configuration.summaryviews.keywordColors[colorIndex] : null;

        const marker = new maps.Marker({
          position: image.coordinates,
          map,
          label: image.counter,
          icon: {
            path: 'M12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z',
            fillColor: customColor ?? '#ea4335',
            fillOpacity: 1,
            strokeWeight: 1,
            strokeColor: 'white',
            rotation: 0,
            scale: 2,
            labelOrigin: new maps.Point(12, 10),
          },
        });

        marker.addListener('click', (e) => {
          this.props.activateImage(image, { dontCenter: true });
        });

        oms.addMarker(marker);
        markers.push(marker);
      });
    });
    window._oms = oms;
    window._markers = markers;

    const closeFullscreen = document.createElement('div');
    closeFullscreen.className = 'closeFullscreen';
    closeFullscreen.style.top = '10px';
    closeFullscreen.style.marginRight = '3em';
    closeFullscreen.style.cursor = 'pointer';
    closeFullscreen.setAttribute('id', 'closeFullcreen');
    closeFullscreen.addEventListener('click', () => {
      document.getElementById('root').classList.remove('mobile-map-fullscreen');
    });

    map.controls[maps.ControlPosition.RIGHT_TOP].push(closeFullscreen);
    window._mc = new MarkerClusterer(map, markers, {
      imagePath: '/m/m',
      maxZoom: 16,
    });
    this.getDefaultCenter();
  };

  getDefaultCenter = () => {
    return (`lat: ${window.Avainia.config.coordinates.lat}, lng: ${window.Avainia.config.coordinates.lng}`);
  }

  getCenter = () => {
    if (this.props.allImages.length > 0) {
      const firstCoords = this.props.allImages.find((x) => x.coordinates);

      if (firstCoords) {
        return firstCoords.coordinates;
      }
    }

    if (this.props.coordinates) {
      const coords = this.props.coordinates.split(',');
      const mapCoords = {lat: parseFloat(coords[0]), lng: parseFloat(coords[1])}
      return mapCoords;
    }
    return this.MapSettings.defaultCenter;
  }

  toggleMapExpand = () => {
    this.setState((prevState) => ({ mapExpanded: !prevState.mapExpanded }));
  }

  toggleMapFullScreen = () => {
    this.setState((prevState) => ({ mapFullScreen: !prevState.mapFullScreen }));
  }

  // exit pseudo full-screen by pressing escape
  componentDidMount(){
    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction(event){
    if (event.key === "Escape") {
      this.setState({ mapFullScreen: false })
    }
  }

  render() {
    if (this.props.hideableMap) {
      if (this.props.mapHidden) { return false; }
    }

    const { mapExpanded, mapFullScreen } = this.state;

    return <>
      <div className={`map-wrapper ${mapExpanded ? 'expand' : ''} ${mapFullScreen ? 'full-screen' : ''}`}>
        <div className="button-container">
          <button className="map-expand-button btn" onClick={this.toggleMapExpand}>
            {I18n.t(`views.infraproject.${mapExpanded ? 'collapse' : 'expand'}-map`)}
          </button>
          <button className="map-full-screen-button btn" onClick={this.toggleMapFullScreen}>
            {I18n.t(`views.infraproject.${mapFullScreen ? 'collapse-full-screen' : 'full-screen'}-map`)}
          </button>
        </div>
        <div className="map-container">
          <GoogleMapReact
            bootstrapURLKeys={{ key: this.MapSettings.apikey }}
            defaultCenter={this.getCenter()}
            defaultZoom={this.MapSettings.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={this.onGoogleApiLoaded}
            options={{
              fullscreenControl: false,
              zoomControl: true,
              scaleControl: true,
              mapTypeControl: true,
              streetViewControl: true,
            }}
          />
        </div>
      </div>
    </>;
  }
}

export default SummaryMap;
