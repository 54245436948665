import React, { Component } from 'react';
import { Button, Modal, Row, Form, Col } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

// TODO! Is this used anywhere???
// TODO! THIS NEEDS TO BE FIXED!
class ApartmentTemplateEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      materialforms: [],
      loading: true,
      error: false,
    };
  }

  componentDidMount = () => {
    const { id } = this.props.apartmentTemplate;
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.condominiumApartmentTemplateMaterialformsGet(id).then((materialforms) => {
      if (materialforms.error) { return this.setState({ error: materialforms.error }); }

      this.setState({
        loading: false,
        name: this.props.apartmentTemplate.name,
        materialforms });
    });
  }

  save = () => {
    const apartmentId = this.props.apartmentTemplate.id;
    const condoId = this.props.apartmentTemplate.condominium_id;
    const payload = {
      name: this.state.name,
    };

    this.setState({ loading: 1, error: false }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.condominiumApartmentTemplateEdit(condoId, apartmentId, payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        return this.setState({ loading: false, error: false }, () => {
          this.props.editCallback(apartmentId, payload.name);
          this.props.onHide();
        });
      });
    });
  }

  onChange = (e) => {
    const name = e.target.value;
    this.setState({ name });
  }

  render() {
    return <Modal show={true} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.condominiums.edit-apartmenttemplate')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.error && <Error inline error={this.state.error} />}
        {!this.state.error && this.state.loading && <Loading inline />}
        {!this.state.error && !this.state.loading && <div>
        <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.condominiums.apartmenttemplate-name')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.onChange} name="name" value={this.state.name} /></Col>
          </Form.Group>
        <ul>
          {this.state.materialforms.map((mf) => <li key={mf.id}>{mf.name}</li>)}
        </ul>
      </div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" disabled={this.state.loading} onClick={this.save}>{I18n.t('general.modal-form-save')}</Button>
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('general.modal-close')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default ApartmentTemplateEditModal;
