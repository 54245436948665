import React from "react";
import I18n from "i18n-js";
import { NavLink } from "react-router-dom";
import LocalStorageService from "../../../AvainiaTools/LocalStorageService.js";
import AvainiaPermissions from "../../../AvainiaTools/AvainiaPermissions.js";
import AvainiaCore from "avainia-core-api";
import {
  Shield,
  Project,
  Box,
  Users,
  UsersCog,
  City,
  Language,
  Lock,
  File,
  Notification,
  Link,
  Folder,
  Cog,
  List,
  AlignLeft,
} from "../Icon/Icon.js";

const AvainiaLinks = (props) => {
  const { cfg } = props;
  const [summaryViews, SetSummaryViews] = React.useState([]);
  //! NOTE: This needs to be in sync with App.js
  const user = LocalStorageService.getUser();
  const productsManage = cfg.productsAndMaterialselection && user.hasPermission(AvainiaPermissions.ProductsManage);
  // const companiesManage = user.hasPermission(AvainiaPermissions.CompaniesManage); // TODO! Fix after ownerManager
  const myCompany = user && (user.hasRole("manager") || user.hasRole("super"));
  const company = user.companies[0]; // TODO! This ASSUMES the user has exactly one company
  const admin = user && (user.hasRole("owner") || user.hasRole("super"));
  const ownerManager = user && user.isOwnerManager();
  //const notificationsManager = user.hasPermission(AvainiaPermissions.NotificationsAccess);

  React.useEffect(() => {
    if (ownerManager) {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.summaryViewsGet().then((summaryViews) => {
        if (summaryViews.error) {
          return console.error("Error getting summary views!", summaryViews.error);
        }
        SetSummaryViews(summaryViews);
        //console.log("Summary views", summaryViews);
      });
    }
  }, []);

  return (
    <>
      {cfg.projects && (
        <NavLink to="/projects">
          <Project />
          {I18n.t("views.sidebar.link-projects")}
        </NavLink>
      )}
      {cfg.projects && (
        <NavLink to="/project-archive">
          <Folder />
          {I18n.t("views.sidebar.link-projects-inactive")}
        </NavLink>
      )}

      {ownerManager && (
        <NavLink to="/defaultfolders">
          {/* Hurr durr I totally made a new icon */}
          <Project style={{ transform: "scale(-1,-1)" }} />
          {I18n.t("views.sidebar.link-projects-admin")}
        </NavLink>
      )}

      {cfg.condominiums && (
        <NavLink to="/condominiums">
          <City />
          {I18n.t("views.sidebar.link-condominiums")}
        </NavLink>
      )}

      {productsManage && (
        <NavLink to="/products">
          <Box />
          {I18n.t("views.sidebar.link-products")}
        </NavLink>
      )}

      <NavLink to="/privacy">
        <Shield />
        {I18n.t("views.sidebar.link-privacy")}
      </NavLink>

      {ownerManager && (
        <NavLink to="/companies">
          <Users />
          {I18n.t("views.sidebar.link-companies")}
        </NavLink>
      )}

      {myCompany && (
        <NavLink to={`/company-management/${company.id}`}>
          <UsersCog />
          {I18n.t("views.sidebar.link-company-management")}
        </NavLink>
      )}

      {admin && false && (
        <NavLink to="/translations">
          <Language />
          {I18n.t("views.sidebar.link-translations")}
        </NavLink>
      )}

      {ownerManager && (
        <NavLink to="/document-types">
          <File />
          {I18n.t("views.sidebar.document-types")}
        </NavLink>
      )}

      {ownerManager && (
        <NavLink to="/permissions">
          <Lock />
          {I18n.t("views.sidebar.link-permissions")}
        </NavLink>
      )}

      {ownerManager && (
        <NavLink to="/notifications">
          <Notification />
          {I18n.t("views.sidebar.notifications")}
        </NavLink>
      )}

      {ownerManager && (
        <NavLink to="/mobilelinks">
          <Link />
          {I18n.t("views.sidebar.mobilelinks")}
        </NavLink>
      )}

      {(ownerManager || summaryViews.length > 0) && (
        <NavLink to="/summaryviews" className="sidebar-summaryviews">
          {I18n.t("views.sidebar.sharedsummaries")}
          <Cog style={{ marginRight: "0", marginLeft: "10px", height: "26px", width: "26px" }} />
        </NavLink>
      )}

      {ownerManager &&
        summaryViews.map((view) => (
          <NavLink key={view.id} to={`/summaryview/${view.id}#base`} className="sidebar-summaryviewlist">
            {view.name}
          </NavLink>
        ))}
    </>
  );
};

export default AvainiaLinks;
