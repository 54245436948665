// documentTable

import React, { Component } from 'react';
import { Form, Button, Badge, Spinner } from 'react-bootstrap';
import I18n from 'i18n-js';
import { arrayToTree } from 'performant-array-to-tree';
import AvainiaCore from 'avainia-core-api';
import { List, Table, AlignLeft } from '../../../multiview/Icon/Icon.js';
import LocalStorageService from '../../../../AvainiaTools/LocalStorageService.js';
import IconifiedDocumentGrid from './IconifiedDocumentGrid.js';
import DocumentReportDate from './DocumentReportDate.js';
import DocumentReportType from './DocumentReportType.js';
import ModalDocumentAdd from '../../../multiview/Modals/ModalDocumentAdd.js';
import ModalFolderAdd from '../../../multiview/Modals/ModalFolderAdd.js';
import ModalFolderEdit from '../../../multiview/Modals/ModalFolderEdit.js';
import ModalConfirmDelete from '../../../multiview/Modals/ModalConfirmDelete.js';
import ModalMoveToFolder from '../../../multiview/Modals/ModalMoveToFolder.js';
import AvainiaTable from '../../../multiview/AvainiaTable/AvainiaTable.js';
import './DocumentTable.scss';
import Error from '../../../multiview/Error/Error.js';
import Loading from '../../../multiview/Loading/Loading.js';
import DesktopFilters from './DesktopFilters.js';
import MobileFilters from './MobileFilters.js';
import { Folder } from '../../../multiview/Icon/Icon.js';
import AvainiaPermissions from '../../../../AvainiaTools/AvainiaPermissions.js';

const Modals = {
  documentAdd: 1,
  folderAdd: 2,
  folderEdit: 3,
  moveToFolder: 4,
  deleteFiles: 5,
};

class DocumentTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeFolder: null,
      flatMode: this.props.flatMode || false,
      tableMode: this.props.layout || 'table',
      modal: false,
      iconified: false,
      exportIds: [],
      exportLoading: false,
      reportsDropdown: false,
      settingsDropdown: false,
      tableConfig: LocalStorageService.getTableListConfig(),
      loading: false,
      error: false,
      moveToFolder: '',
      projectType: '',
    };
  }

  componentDidMount = () => {
    const allFolders = this.getAllFolders();
    let activeFolder = null;

    //this.setState({ moveToFolder: allFolders });

    if (this.props.configuration.folderListModel === 'tree') {
      activeFolder = allFolders.find((folder) => folder.id === 0);
    }

    // Get initial configuration state from instance-config and set it as starting point for tableConfig.
    let initialTableConfig = {};
    const documentConfig = this.props.configuration.document ? this.props.configuration.document.documentTableSettings: null;

    if (documentConfig) {
      for (const [ , type] of Object.entries(documentConfig)) {
        for (const setting of Object.entries(type)) {
           initialTableConfig[setting[0]] = setting[1];
        }
      }
      initialTableConfig["documentConfig"] = true;
    } else {
      initialTableConfig["documentConfig"] = false;
    }

    LocalStorageService.setTableListConfig(initialTableConfig);
    this.setState({ tableConfig: initialTableConfig, moveToFolder: allFolders, activeFolder });
  }

  setTableMode = (mode) => {
    this.props.toggleLayout(mode);
    this.setState({ tableMode: mode, reportsDropdown: false });
  }

  getAllFolders = () => {
    const allFolders = [...this.props.folders, { id: 0, name: `${I18n.t('views.infraproject.main-folder')}`, parent_id: null }];
    allFolders.forEach((folder) => {
      folder.label = folder.name;
      folder.key = folder.id;
    });

    const sortedFolders = allFolders.sort((a, b) => {
      if (a.id === b.id) { return 0; }
      if (a.id === 0) { return -1; }
      if (b.id === 0) { return 1; }
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    });

    const treeData = (arrayToTree(sortedFolders, { parentId: 'parent_id', dataField: null, childrenField: 'nodes' }));
    return treeData;
  }

  toggleIconified = () => { this.setState({ iconified: !this.state.iconified }); }

  hideModal = () => { this.setState({ modal: false }); }

  documentAdd = () => { this.setState({ modal: Modals.documentAdd }); }

  folderAdd = () => { this.setState({ modal: Modals.folderAdd }); }

  folderEdit = () => { this.setState({ modal: Modals.folderEdit }); }

  deleteFiles = () => { this.setState({ modal: Modals.deleteFiles }); }

  moveToFolder = () => {
    // Condo project fails to get the folder listing on load, this one gets the listing when the modal is opened.
    this.setState({
      moveToFolder: this.getAllFolders(),
      modal: Modals.moveToFolder,
    });
  }

  getDocument = (id) => {
    return this.props.documents.find((doc) => doc.id === id);
  }

  renderFolderThing = () => {
    if (!this.state.activeFolder) { return <></>; }

    const folders = (!this.props.configuration.folderListModel || this.props.configuration.folderListModel === 'default') ? this.props.folders : [...this.props.folders, { id: 0, name: `${I18n.t('views.infraproject.main-folder')}`, parent_id: null }]
    const rootFolder = this.props.configuration.folderListModel === 'tree' ? folders.find((folder) => folder.id === 0) : null;
    const parents = [];
    let parent = folders.find((f) => f.id === Number(this.state.activeFolder.id));

    parents.push(parent);

    while (parent.parent_id) {
      // "It's safe" -Joel 2020
      // eslint-disable-next-line no-loop-func
      parent = this.props.folders.find((f) => f.id === Number(parent.parent_id));
      parents.push(parent);
    }

    const showDelete = !this.state.activeFolder.default
                       && !this.state.activeFolder.filecount
                       && !this.state.activeFolder.subfoldercount
                       && this.state.activeFolder.id !== 0;

    const showEdit = !this.state.activeFolder.default && this.state.activeFolder.id !== 0;

    return <span>
        &nbsp;/&nbsp;
        <span
          onClick={() => { this.setState({ activeFolder: rootFolder }); }}
          className="clickable"
          style={{ textDecoration: 'underline' }}
        >
          {this.props.project.name}
        </span>
      {parents.reverse().map((folder) => <span key={folder.id}>
        &nbsp;/&nbsp;
        <span
          onClick={() => { this.setState({ activeFolder: folder }); }}
          className="clickable"
          style={{ textDecoration: 'underline' }}
        >
          {folder.name}
        </span>
      </span>)}
      &nbsp;

      {showDelete &&
        <Badge variant="warning" className="clickable" onClick={this.deleteFolder}>
          {I18n.t('general.delete')}
        </Badge>
      }
      {showEdit &&
        <Badge variant="primary" className="clickable" onClick={this.folderEdit}>
          {I18n.t('general.edit')}
        </Badge>
      }
    </span>;
  }

  deleteFolder = () => {
    if (!window.confirm(I18n.t('folders.confirm-delete'))) { return; }

    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.projectFolderDelete(this.state.activeFolder).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        this.setState({ loading: false, activeFolder: null }, () => {
          window.location.reload();
        });
      });
    });
  }

  getFolderDescendents = (folderId) => {
    const children = this.props.folders.filter((x) => x.parent_id === folderId);
    let descendents = children;

    children.forEach((x) => {
      descendents = descendents.concat(this.getFolderDescendents(x.id));
    });

    return descendents;
  }

  updateTableConfig = (key) => {
    const { tableConfig } = this.state;

    tableConfig[key] = !tableConfig[key];
    LocalStorageService.setTableListConfig(tableConfig);
    this.setState({ tableConfig });
  }

  getDeletedPhaseWithName = (name) => {
    return this.props.allPhases ? this.props.allPhases.find((x) => x.name === name && x.deactivated) : null;
  }

  toggleFlatmode = () => {
    this.setState((prevState) => ({
      flatMode: !prevState.flatMode,
      activeFolder: false,
    }), () => this.props.toggleFlatMode(this.state.flatMode));
  }

  exportzip = () => {
    if (this.state.exportLoading) { return; }

    const documentIds = this.state.exportIds;
    if (typeof documentIds === 'undefined' || documentIds.length <= 0) {
      return alert(I18n.t('views.infraproject.export-choose-one'));
    }

    this.setState({ exportLoading: true }, () => {
      const payload = {
        projectId: this.props.project.id,
        documentIds,
      };

      // TODO: Improve user feedback
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.createFileExportJob(payload).then((response) => {
        if (response.error) {
          alert(I18n.t('views.infraproject.export-error'));
        } else {
          alert(I18n.t('views.infraproject.export-success'));
        }
        this.setState({ exportLoading: false });
      });
    });
  }

  sortFolders = (a, b) => {
    if (a.id === b.id) { return 0; }
    if (a.parent_id === 0) { return -1; }
    if (b.parent_id === 0) { return -1; }
    return 0;
  }

  createParentCountTree = (folders) => {
    let folderTree = [];

    folders.forEach(folder => {
      const parentCount = this.getParentCount(folder)
      let folderName = folder.name;

      const margin = 4*parentCount;

      if (parentCount > 0) {
        folderName = folderName;
      }
      folderTree.push({...folder, name: folderName, parentCount})

    });
    return folderTree;
  }

  getParentCount = (folder, parentCount = 0) => {
    if (folder.parent_id === null) { return parentCount}
    parentCount += 1;
    const parentFolder = this.props.folders.find(f => f.id === folder.parent_id)
    return this.getParentCount(parentFolder, parentCount)
  }

  render() {
    const config = LocalStorageService.getTableListConfig();
    const user = LocalStorageService.getUser();

    //! TODO! REFACTOR THIS PERMISSION CHECK TO ACTUALLY USE PERMISSIONS
    const canDelete = user.hasRole('Super') ? true : user.hasPermission(AvainiaPermissions.DocumentsDelete);
    const userCanAddDocument = user.hasRole('Super') || !!user.companies.find((x) => x.type === 'owner' || x.type === 'subcontractor');
    const currentFolderId = this.state.activeFolder ? Number(this.state.activeFolder.id) : Number(null);

    let folders = [];
    if (!this.props.configuration.folderListModel || this.props.configuration.folderListModel === 'default') {
      folders = this.props.folders ? this.props.folders.filter((folder) => Number(folder.parent_id) === currentFolderId) : null;
    } else {
      const fileCount = this.props.documents.filter((doc) => doc.folder_id === "" || doc.folder_id == 0).length || 0;
      folders = this.createParentCountTree([...this.props.folders, { id: 0, name: `${I18n.t('views.infraproject.main-folder')}`, parent_id: null, filecount: fileCount }]);
    }

    const data = folders
    .sort(function (a, b) {
      if (a.name ===  'Main folder' || a.name === 'Pääkansio') {
        return -1;
      }
        return a.name.localeCompare(b.name);
    }),
    tree = function (data, root) {
        var r = [], o = {};
        data.forEach(function (a) {
            o[a.id] = { data: a, children: o[a.id] && o[a.id].children };
            if (a.parent_id === root) {
                r.push(o[a.id]);
            } else {
                o[a.parent_id] = o[a.parent_id] || {};
                o[a.parent_id].children = o[a.parent_id].children || [];
                o[a.parent_id].children.push(o[a.id]);
            }
        });
        return r;
    }(data, null),
    sorted = tree.reduce(function traverse(r, a) {
        return r.concat(a.data, (a.children || []).reduce(traverse, []));
    }, [])

    // Because of folder <-> phase matching, some folders will not be shown (they match a deactivated phase and they don't contain files. We need to show all files, even for deactivated phase folders!)
    const foldersToList = folders ? folders.filter((f) => {
      return !(f.filecount === 0 && this.getDeletedPhaseWithName(f.name)) && !f.main;
    }) : null;

    let documentsToList = this.props.visibleDocuments;

    if (!this.state.flatMode) {
      documentsToList = documentsToList.filter((document) => {
        const folderId = Number(document.folder_id);

        if (folderId === currentFolderId) { return true; }

        /*
        TODO! The bit below was disabled because the E customer requested it; it was too confusing.
        TODO! Because this is disabled, some users might not see all of their own files!!
        if (this.props.folders) {
          const targetFolder = this.props.folders.find((folder) => Number(folder.id) === folderId);

          if (!targetFolder) {
            // It would appear that this document is in a folder that the user can't access.
            // When the user sees a document but not the folder, it is highly likely that
            // access has been removed or it was uploaded directly into an inaccessible folder
            // using the mobile app. The user can see the file almost certainly because they
            // are the owner of the file. SO; list this file in the listing.
            // TODO: Detect this situation automatically and add a metadata marker to the document and to the document listing
            return true;
          }
        }
        */

        return false;
      });
    }

    if (this.state.loading) { return <Loading inline />; }

    return <>
    <div className="DocumentTable-topbar">

      {this.props.useFilters && <DesktopFilters thing={this.props.thing} toggleFlatmode={this.toggleFlatmode} flatMode={this.state.flatMode} />}
      {this.props.useFilters && <MobileFilters thing={this.props.thing} />}
      <div className="DocumentTable-controls">
        <div className="DocumentTable-controls-inner">
          {this.props.useIcons && <>
          <span className={`DocumentTable-controls-modetoggle ${this.state.tableMode === 'table' ? 'is-active' : ''}`} onClick={() => { this.setTableMode('table'); } }>
            <List />
          </span>
          <span className={`DocumentTable-controls-modetoggle ${this.state.tableMode === 'icons' ? 'is-active' : ''}`} onClick={() => { this.setTableMode('icons'); } }>
            <Table />
          </span>
          <span className={`reports DocumentTable-controls-modetoggle ${this.state.tableMode === 'report-date' || this.state.tableMode === 'report-type' ? 'is-active' : ''}`}>
            <span onClick={() => this.setState({ reportsDropdown: !this.state.reportsDropdown })}><AlignLeft /></span>
              {this.state.reportsDropdown && <span className="settings-dropdown">
                <span onClick={() => { this.setTableMode('report-date'); } }>
                  <input defaultChecked={this.state.tableMode === 'report-date'} id="reporttype-1" name="reporttype" type="radio" />
                  <label htmlFor="reporttype-1">
                    {I18n.t('views.infraproject.sort-by-date')}
                  </label>
                </span>
                <span onClick={() => { this.setTableMode('report-type'); } }>
                  <input defaultChecked={this.state.tableMode === 'report-type'} id="reporttype-2" name="reporttype" type="radio" />
                  <label htmlFor="reporttype-2">
                    {I18n.t('views.infraproject.sort-by-type')}
                  </label>
                </span>
              </span>}
            </span>
            </>}
          <span className="settings DocumentTable-controls-modetoggle dropdown-toggle" onClick={() => this.setState({ settingsDropdown: !this.state.settingsDropdown })}>
            <span>{I18n.t('views.infraproject.settings')}</span>
              {this.state.settingsDropdown && <span className="settings-dropdown" onClick={(e) => { e.stopPropagation(); }}>
                <Form.Check type="checkbox" label={I18n.t('views.infraproject.show-name')} id="cfgNameText" checked={this.state.tableConfig.viewNameText} onChange={(e) => { this.updateTableConfig('viewNameText'); }} />
                <Form.Check type="checkbox" label={I18n.t('views.infraproject.show-info')} id="cfgDetailText" checked={this.state.tableConfig.viewDetailText} onChange={(e) => { this.updateTableConfig('viewDetailText'); }} />
                <Form.Check type="checkbox" label={I18n.t('views.infraproject.show-position')} id="cfgPositionText" checked={this.state.tableConfig.viewPositionText} onChange={(e) => { this.updateTableConfig('viewPositionText'); }} />
                <Form.Check type="checkbox" label={I18n.t('views.infraproject.show-company')} id="cfgAddedByCompany" checked={this.state.tableConfig.viewAddedByCompany} onChange={(e) => { this.updateTableConfig('viewAddedByCompany'); }} />
                { config.showPhaseSelector ? <Form.Check type="checkbox" label={I18n.t('views.infraproject.show-phase')} id="cfgPhaseText" checked={this.state.tableConfig.viewPhaseText} onChange={(e) => { this.updateTableConfig('viewPhaseText'); }} /> : null }
                { config.showStateSelector ? <Form.Check type="checkbox" label={I18n.t('views.infraproject.show-subject')} id="cfgSubjectText" checked={this.state.tableConfig.viewSubjectText} onChange={(e) => { this.updateTableConfig('viewSubjectText'); }} /> : null }
                { config.showSubjectSelector ? <Form.Check type="checkbox" label={I18n.t('views.infraproject.show-state')} id="cfgStateText" checked={this.state.tableConfig.viewStateText} onChange={(e) => { this.updateTableConfig('viewStateText'); }} /> : null }

              </span>}
          </span>
        </div>
      </div>
    </div>
    <div className="DocumentTable">
      {this.state.error && <Error inline error={this.state.error} />}
      {this.state.tableMode === 'table' && <>
        {!this.state.flatMode && this.props.folders.length > 0 && <>
          {this.renderFolderThing()}
          { (!this.props.configuration.folderListModel || this.props.configuration.folderListModel === 'default') &&
            <AvainiaTable
              noDataIndication={I18n.t('general.no-folders')}
              wrapperClasses="table-folders-wrapper"
              condensed
              keyField="id"
              defaultSorted={[{ dataField: 'name', order: 'asc' }]}
              data={foldersToList}
              rowClickHandler={(e, rowData) => { this.setState({ activeFolder: rowData }); }}
              columns={[
                // { dataField: 'id', text: I18n.t('general.id'), headerStyle: { width: '60px' } },
                { dataField: 'name', text: I18n.t('folders.folder'), sort: true },
                { dataField: 'filecount', text: I18n.t('folders.filecount'), sort: true },
                { dataField: 'subfoldercount', text: I18n.t('folders.subfolders'), sort: true },
                {
                  dataField: 'foo',
                  text: I18n.t('folders.files-in-subfolders'),
                  formatter: (cell, row) => {
                    // TODO: Precalculate
                    const descendents = this.getFolderDescendents(row.id);
                    return descendents.reduce((accumulator, folder) => { return accumulator + folder.filecount; }, 0);
                  },
                  sort: true,
                },
              ]}
            />
          }
          {
            this.props.configuration.folderListModel === 'tree' &&
              <AvainiaTable
                noDataIndication={I18n.t('general.no-folders')}
                wrapperClasses="table-folders-wrapper table-tree"
                condensed
                keyField="id"
                striped={false}
                data={sorted}
                rowClickHandler={(e, rowData) => { this.setState({ activeFolder: rowData }) }}
                rowClasses={(row, rowIndex) => {
                  if (currentFolderId === row.id) return 'tree-model-row-active';
                }}
                rowStyle={ { border: '1px solid lightgrey' } }
                columns={[
                  { dataField: 'name', text: I18n.t('folders.folder'), headerStyle: { borderBottom: '2px solid', fontWeight: 'bold' }, sort: false, formatter: (value, row) => (
                    <span>
                      {row.parentCount > 0 ?
                        <span style={{ marginLeft: 4*row.parentCount +'rem' }}>
                          <Folder style={{color: '#4a78ef'}}/>
                          {value}
                        </span>
                        :
                        <span>
                          <Folder style={{color: '#4a78ef'}}/>
                          {value}
                        </span>
                      }
                    </span>
                  ) },
                  { dataField: 'filecount', text: I18n.t('folders.filecount'), headerStyle: { borderBottom: '2px solid', fontWeight: 'bold' }, sort: false, },
                ]}
              />
          }
        </>}
        <AvainiaTable
          tableKey={this.state.key}
          condensed
          data={documentsToList}
          keyField="counter"
          id="DocumentView"
          selectRow={{
            mode: 'checkbox',
            clickToSelect: false,
            selectColumnStyle: { textAlign: 'center' },
            headerColumnStyle: { textAlign: 'center' },
            onSelect: (row, isSelect, rowIndex, e) => {
              if (isSelect) {
                this.setState({ exportIds: [...this.state.exportIds, row.id] });
              } else {
                this.setState({ exportIds: this.state.exportIds.filter((id) => id !== row.id) });
              }
            },
            onSelectAll: (isSelect, rows, e) => {
              if (isSelect) {
                this.setState({ exportIds: rows.map((row) => row.id) });
              } else {
                this.setState({ exportIds: [] });
              }
            },
          }}
          rowStyle={(row, rowIndex) => {
            const style = {};

            if (this.props.documentActive && this.props.documentActive.id === row.id) {
              style.backgroundColor = 'lightgreen';
            }

            return style;
          }}
          rowClickHandler={(e, rowData) => {
            this.props.documentActivate(this.getDocument(rowData.id));
          }}
          columns={[
            {
              dataField: 'counter',
              text: I18n.t('views.infraproject.nbr'),
              headerStyle: { width: '60px' },
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) => {
                if (order === 'asc') {
                  return rowA.counterNumber - rowB.counterNumber;
                }

                return rowB.counterNumber - rowA.counterNumber;
              },
            },
            {
              dataField: 'files[0].full_name',
              text: I18n.t('documentType.name'),
              hidden: !config.viewNameText,
              sort: false,
              formatter: (cell, row) => {
                if(!cell){return '-'}
                return cell.length > 20 ? <span title={cell}>{cell.slice(0,17)}...</span> : <span>{cell}</span>;
              }
            },
            { dataField: 'files[0].extension', text: I18n.t('views.documents.type'), headerStyle: { width: '60px' }, sort: true },
            {
              dataField: 'folder_id',
              text: I18n.t('folders.folder'),
              formatter: (cell, row) => {
                // TODO: Precalulate
                if (!cell) { return ''; }

                let parent = this.props.folders.find((f) => f.id === Number(cell));
                if (!parent) { return '/'; }

                let text = `${parent.name}/`;

                while (parent.parent_id) {
                  // "It's safe" -Joel 2020
                  // eslint-disable-next-line no-loop-func
                  parent = this.props.folders.find((f) => f.id === Number(parent.parent_id));
                  text = `${parent.name}/${text}`;
                }

                return text;
              },
              hidden: !this.state.flatMode,
              sort: true,
            },
            {
              dataField: 'document_type.name',
              text: I18n.t('documentType.document-type'),
              formatter: (cell, row) => {
                return row.document_type.is_default ? I18n.t('documentType.default') : row.document_type.name;
              },
              sort: true,
            },
            { dataField: 'phase', text: I18n.t('views.infraproject.phase'), sort: true, hidden: config.documentConfig ? !config.viewPhaseText : false },
            { dataField: 'subject', text: I18n.t('views.infraproject.subject'), sort: true, hidden: config.documentConfig ? !config.viewSubjectText : false },
            { dataField: 'state', text: I18n.t('views.infraproject.state'), sort: true, hidden: config.documentConfig ? !config.viewStateText : false },
            { dataField: 'created', text: I18n.t('views.infraproject.created'), sort: true },
            { dataField: 'detailText', text: I18n.t('views.infraproject.info'), hidden: !config.viewDetailText, sort: true },
            { dataField: 'positionText', text: I18n.t('views.infraproject.position'), hidden: !config.viewPositionText, sort: true },
            { dataField: 'addedByCompany', text: I18n.t('views.infraproject.company'), hidden: !config.viewAddedByCompany, sort: true },
          ]}
        />
        <div className="DocumentTable-bottomPadding" />
      </>}

      {this.state.tableMode === 'icons' &&
        <IconifiedDocumentGrid
          phases={this.props.phases}
          subjects={this.props.subjects}
          visibleDocuments={this.props.visibleDocuments}
          documentActive={this.props.documentActive}
          documentActivate={this.props.documentActivate}
        />
      }

      {this.state.tableMode === 'report-date' &&
        <DocumentReportDate
          phases={this.props.phases}
          subjects={this.props.subjects}
          visibleDocuments={this.props.visibleDocuments}
          documentActive={this.props.documentActive}
          documentActivate={this.props.documentActivate}
        />
      }

      {this.state.tableMode === 'report-type' &&
        <DocumentReportType
          phases={this.props.phases}
          subjects={this.props.subjects}
          visibleDocuments={this.props.visibleDocuments}
          documentActive={this.props.documentActive}
          documentActivate={this.props.documentActivate}
        />
      }

      <div className="operations">
        {userCanAddDocument && <>
          <Button onClick={this.documentAdd}>{I18n.t('views.infraproject.button-add-document')}</Button>
          <Button onClick={this.folderAdd}>{I18n.t('views.infraproject.button-add-folder')}</Button>
          <Button onClick={this.moveToFolder} className={ this.state.exportIds.length ? '' : 'd-none' }>{I18n.t('views.infraproject.move-to-folder')}</Button>
          {canDelete && <Button onClick={this.deleteFiles} className={ this.state.exportIds.length ? '' : 'd-none' }>
            {I18n.t('views.infraproject.delete-selected-files')}
          </Button>}
        </>}
        {this.props.configuration.documentExporting &&
          <Button onClick={this.exportzip} className={ this.state.exportIds.length ? '' : 'd-none' }>
            {this.state.exportLoading && <> <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp; {I18n.t('general.loading')} </>}
            {!this.state.exportLoading && <> {I18n.t('views.infraproject.export-selected-documents')} </>}
          </Button>
        }
      </div>

      {this.state.modal === Modals.documentAdd &&
        <ModalDocumentAdd
          parentFolder={this.state.activeFolder}
          project={this.props.project}
          phases={this.props.phases}
          subjects={this.props.subjects}
          hideModal={this.hideModal}
          configuration={this.props.configuration}
        />
      }

      {this.state.modal === Modals.folderAdd &&
        <ModalFolderAdd
          parentFolder={this.state.activeFolder}
          project={this.props.project}
          hideModal={this.hideModal}
        />
      }

      {this.state.modal === Modals.folderEdit &&
        <ModalFolderEdit
          folder={this.state.activeFolder}
          hideModal={this.hideModal}
        />
      }

      {this.state.modal === Modals.moveToFolder &&
        <ModalMoveToFolder
          folders={this.state.moveToFolder}
          project={this.props.project}
          files={this.state.exportIds}
          hideModal={this.hideModal}
          documents={this.props.documents}
          documentEditedCallback={() => { document.location.reload(); }}
        />
      }

      {this.state.modal === Modals.deleteFiles &&
        <ModalConfirmDelete
          ids={this.state.exportIds}
          hideModal={this.hideModal}
          projectId={this.props.project.id}
        />
      }
    </div>
  </>;
  }
}

export default DocumentTable;
