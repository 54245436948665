import React from 'react';
import I18n from 'i18n-js';
import {  Alert, OverlayTrigger } from 'react-bootstrap';
import { InfoCircle } from '../Icon/Icon.js';

export default function Error(props) {
  if (!props.error) {
    return null;
  }

  if (props.inline) {
    return (
      <Alert variant="danger">
        {I18n.t(`errors.code-${props.error}`)}
        {props.info && <OverlayTrigger placement="right" overlay={props.popoverInfo}>
          <InfoCircle />
        </OverlayTrigger>}
      </Alert>
    );
  }

  return (
    <div className="App-main">
      <Alert variant="danger" className="error">
        {I18n.t(`errors.code-${props.error}`)}
        {props.info && <OverlayTrigger placement="right" overlay={props.popoverInfo}>
          <InfoCircle />
        </OverlayTrigger>}
      </Alert>
    </div>
  );
};
