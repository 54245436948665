import React, { Component } from 'react';
import { Tab, Form, Button, Badge } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import I18n from 'i18n-js';
import Error from '../../../../multiview/Error/Error.js';
import Loading from '../../../../multiview/Loading/Loading.js';
import AvainiaPanel from '../../../../multiview/Panels/AvainiaPanel.js';
import CondominiumPanel from '../../../../multiview/Panels/CondominiumPanel.js';
import ContactPanel from '../../../../multiview/Panels/ContactPanel.js';
import SchedulePanel from '../../../../multiview/Panels/SchedulePanel.js';
import AvainiaCore from 'avainia-core-api';
import MaterialformsTab from './MaterialformsTab.js';
import CondominiumDocumentsPanel from './CondominiumDocumentsPanel.js';
import ContactTab from '../ContactTab.js';
import CompaniesTab from '../CompaniesTab.js';
import SchedulesTab from '../SchedulesTab.js';
import { TopbarContext } from '../../../../../contexts/TopbarContext.js';
import LocalStorageService from '../../../../../AvainiaTools/LocalStorageService.js';
import AvainiaPermissions from '../../../../../AvainiaTools/AvainiaPermissions.js';

const Tabs = {
  base: 'base',
  materialforms: 'materialforms',
  contact: 'contact',
  schedules: 'schedules',
  companies: 'companies',
};

class CondominiumProject extends Component {
  static contextType = TopbarContext;

  constructor(props) {
    super(props);

    this.state = {
      allCondominiums: [],
      apartments: [],
      contacts: [],
      schedules: [],
      tab: Tabs.base,
      selectedCondominium: false,
      redirect: false,
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.project;
    const api = new AvainiaCore(LocalStorageService.getToken);

    const promises = [
      // TODO: Go through all of them and add the data attribute
      api.projectContactsGet(id, 'contacts'),
      api.projectCondominiumsGet(id, 'condominiums'),
      api.projectSchedulesGet(id, 'schedules'),
      api.condominiumsGet('allCondominiums'), // TODO: Improvement - Performance - this is only used in edge cases
    ];

    Promise.all(promises).then((data) => {
      let error = false;
      let allCondominiums = [];
      let apartments;
      let contacts;
      let schedules;

      data.forEach((x) => {
        if (error) { return; }
        if (x.error) { error = x.error; return; }
        if (x.contacts) { contacts = x.contacts; }
        if (x.schedules) { schedules = x.schedules; }
        if (x.allCondominiums) { allCondominiums = x.allCondominiums; } // TODO: Performance improvement: we only need this information if the project doesn't have a condominium set!!
      });

      if (error) { return this.setState({ error }); }

      const user = LocalStorageService.getUser();
      const canManage = user && user.hasPermission(AvainiaPermissions.ProjectsManage);
      const userManagementTabKey = canManage ? Tabs.companies : false;

      this.setState({
        allCondominiums,
        apartments,
        contacts,
        schedules,
        loading: false,
      }, () => {
        this.context.setTopbar([
          <Tab key={Tabs.base} eventKey={Tabs.base} title={I18n.t('general.basicdata')}></Tab>,
          <Tab key={Tabs.materialforms} eventKey={Tabs.materialforms} title={I18n.t('views.materialforms.materialforms')}></Tab>,
          <Tab key={Tabs.contact} eventKey={Tabs.contact} title={I18n.t('views.contacts.contacts')}></Tab>,
          <Tab key={Tabs.schedules} eventKey={Tabs.schedules} title={I18n.t('views.schedules.schedules')}></Tab>,
        ], userManagementTabKey);
      });
    });
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  saveCondoSelectionForProject = (e) => {
    if (!this.state.selectedCondominium) { return alert(I18n.t('views.project.warn-select-option-first')); }

    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.projectCondominiumSet(this.props.project.id, this.state.selectedCondominium).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  render() {
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    // Make sure this Project has a condominium
    if (!this.props.project.condominiumProject) {
      return <>
        <div className="App-container">
          <h1>{I18n.t('views.projects.condominiumproject')}</h1>
          <Badge variant="warning" className="error">{I18n.t('views.projects.condominiumproject-needs-project')}</Badge>

          <h2>{I18n.t('views.condominiums.condominiums')}</h2>
          <Form.Control as="select" onChange={this.onChange} name="selectedCondominium">
            <option value="">-</option>
            {this.state.allCondominiums.map((x) => <option value={x.id} key={x.id}>{x.name}</option>)}
          </Form.Control>

          <div className="actions largebuffer">
            <Button size="sm" onClick={this.saveCondoSelectionForProject}>{I18n.t('general.modal-form-save')}</Button>
          </div>

        </div>
      </>;
    }

    return (

      <div className="App-container">
        {this.context.tab === Tabs.base && <div className="AvainiaPanel">
          <AvainiaPanel header>
            <div className="text">
              <h2>{this.props.project.code} - {this.props.project.name}</h2>
            </div>
          </AvainiaPanel>
          <CondominiumPanel condominium={this.props.project.condominiumProject.condominium_id} />
          <ContactPanel project={this.props.project} />
          <SchedulePanel double project={this.props.project} />
          <CondominiumDocumentsPanel double project={this.props.project} configuration={this.props.configuration} />
        </div>}

        {this.context.tab === Tabs.materialforms && <MaterialformsTab condominiumId={this.props.project.condominiumProject.condominium_id} project={this.props.project} />}
        {this.context.tab === Tabs.contact && <ContactTab project={this.props.project} />}
        {this.context.tab === Tabs.schedules && <SchedulesTab project={this.props.project} />}
        {this.context.tab === Tabs.companies && <CompaniesTab project={this.props.project} />}
      </div>

    );
  }
}

export default withRouter(CondominiumProject);
