import React from 'react';
import I18n from 'i18n-js';
import './Loading.scss';

export default function Loading(props) {
  if (props.hide) { return <></>; }
  if (props.loading === false) { return <></>; }

  let className = 'Loading';
  if (props.inline) { className += ' Loading--inline'; }

  return (
    <div className={className}>
      <p className="Loading-text">{I18n.t('general.loading')}...</p>
      <div className="Loading-icon">
        <div className="Loading-inner"></div>
        <div className="Loading-inner"></div>
      </div>
    </div>
  );
};
