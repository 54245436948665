import React, { useState } from 'react';
import I18n from 'i18n-js';
import { Button, Modal } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import Error from '../Error/Error.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

const ModalConfirmDelete = (props) => {
  const { ids, hideModal, projectId } = props;

  const [error, setError] = useState(false);

  const deleteFiles = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.deleteDocuments(ids, projectId).then((res) => {
      if (res.error) {
        setError(res.error);
      } else {
        window.location.reload();
      }
    });
  };

  return (
    <Modal show={true} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('general.confirm')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Error inline error={error} />
        {I18n.t('views.infraproject.confirm-documents-delete')}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hideModal}>
          {I18n.t('general.modal-close')}
        </Button>
        <Button variant="danger" onClick={deleteFiles}>
          {I18n.t('views.infraproject.delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfirmDelete;
