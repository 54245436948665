import AvainiaCore from 'avainia-core-api';
import LocalStorageService from './LocalStorageService.js';

/**
 * This acts as a getter for all the globally shared data
 */
export default class GlobalsService {
  static getGlobals = async () => {
    const user = LocalStorageService.getUser();
    if (!user) { return { error: "no-user" }; }

    if (window.Avainia.Globals && !window.Avainia.Globals.error) { return window.Avainia.Globals; }

    const api = new AvainiaCore(LocalStorageService.getToken);
    const promises = [
      api.fieldsGet('Fields'),
      api.keywordsGet('Keywords'),
      api.userTypesGet('UserTypes'),
      api.companyTypesGet('CompanyTypes'),
    ];

    await Promise.all(promises).then(async (data) => {
      const Globals = {
        Fields: null,
        Keywords: null,
        UserTypes: null,
        CompanyTypes: null,
        loading: true,
      };

      data.forEach((x) => {
        if (x.error) { Globals.error = x.error; return; }
        if (x.Fields) { Globals.Fields = x.Fields; }
        if (x.Keywords) { Globals.Keywords = x.Keywords; }
        if (x.UserTypes) { Globals.UserTypes = x.UserTypes; }
        if (x.CompanyTypes) { Globals.CompanyTypes = x.CompanyTypes; }
      });

      if (Globals.error) {
        window.Avainia.Globals = Globals;
        return console.error('Globals error!', Globals.error);
      }

      Globals.Fields = Globals.Fields.map((field) => {
        if (field.type !== 'keywords') { return field; }
        return {
          ...field,
          keywords: Globals.Keywords.filter((keyword) => keyword.field_id === field.id),
        };
      });

      window.Avainia.Globals = Globals;

      // Invalidate the globals cache once per minute when they are needed
      if (window.Avainia.GlobalsRefreshTimer) {
        return;
      }

      let timeout = 3 * 60 * 1000; // Timeout increased to 3 minutes because some requests are very slow
      window.Avainia.GlobalsRefreshTimer = setTimeout(GlobalsService.invalidateGlobals, timeout);
    });

    return window.Avainia.Globals;
  };

  static invalidateGlobals = async () => {
    window.Avainia.Globals = null;
  }
}
