import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import './AvainiaTagInput.scss';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService';


class AvainiaTagInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      keywords: [],
      editMode: false,
      targetIndex: false,
      newText: '',
      deactivated: false,
      changed: false,
    };
  }

  componentDidMount = () => {
    const { keywords } = this.props;

    if (keywords) {
      const keywordValues = keywords.map((keyword) => keyword.text);
      this.setState({ tags: keywordValues, keywords });
    }
  }

  addTags = (event) => {
    if (event.target.value !== '') {
      this.setState({ tags: [ ...this.state.tags, event.target.value ] });
      this.props.addKeyword(event.target.value);
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  editKeyword = (text, index) => {
    const target = this.props.keywords.find((propKeyword) => propKeyword.text === text);
    this.setState({
      editMode: target,
      targetIndex: index,
      deactivated: target.deactivated,
    });
  }

  onChange = (e) => {
    this.setState({ changed: true, [e.target.name]: e.target.value });
  }

  saveEditedKeyword = () => {
    // Keeping this here for future use (after getting rid of the window.location.reload)
    // const tags = this.state.tags.map((tag) => (tag === this.state.editMode.text ? this.state.newText : tag));
    const payload = {
      keyword: this.state.editMode.id,
      text: this.state.newText ? this.state.newText : this.state.editMode.text,
      deactivated: this.state.deactivated,
    };

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.keywordUpdate(payload).then((response) => {
      if (response.error) {
        console.log(response.error); // TODO: Improve
      }
      window.location.reload();
    });
  }

  deleteKeyword = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.keywordDelete(this.state.editMode.id).then((response) => {
      if (response.error) {
        console.log(response.error); // TODO: Improve
      }

      window.location.reload();
    });
  }

  render() {
    let inputValue = this.state.newText;

    if (!inputValue && this.state.editMode.text && !this.state.changed) {
      inputValue = this.state.editMode.text;
    }

    return this.state.editMode
      ? <React.Fragment>
          <Form.Group>
            <Form.Label>{I18n.t('tagInput.editing-keyword')} "{ this.state.editMode.text }"...</Form.Label>
            <Form.Control
              type="text"
              name="newText"
              placeholder={this.state.editMode.text}
              value={inputValue}
              onChange={this.onChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check 
              type="checkbox" 
              label={I18n.t('tagInput.disabled')} 
              checked={this.state.deactivated} 
              onChange={(e) => this.setState({ deactivated: e.target.checked })}
            />
          </Form.Group>
          <Form.Group>
            <Button style={{ marginRight: '10px' }} onClick={this.saveEditedKeyword}>
              {I18n.t('tagInput.update-keyword')}
            </Button>
            {!this.props.targetField.is_default &&
              <Button
                style={{ marginRight: '10px' }}
                variant="danger"
                onClick={this.deleteKeyword}
              >
                {I18n.t('tagInput.delete-keyword')}
              </Button>
            }
          <Button variant="secondary" onClick={() => this.setState({ editMode: false })}>
            {I18n.t('general.cancel')}
          </Button>
        </Form.Group>
      </React.Fragment>
      : <div className="tags-input">
        <ul id="tags">
          {this.state.tags.map((tag, index) => {
            const keyword = this.state.keywords.find((kw) => kw.text === tag);
            if (!keyword) {
              return <li key={index} className="tag newtag">
                <span className="tag-title">{tag}</span>
              </li>;
            }
            return <li key={index} className={`tag ${keyword.deactivated ? 'deactivated' : ''}`}>
              <span
                className="tag-title"
                onClick={this.props.editKeyword ? () => this.editKeyword(keyword.text, index) : null}
              >
                {keyword.text}
              </span>
            </li>;
          })}
        </ul>
      <Form.Control
        type="text"
        onKeyUp={(event) => (event.keyCode === 13 ? this.addTags(event) : null)}
        placeholder={I18n.t('documentType.enter-keyword')}
        style={{ margin: 'auto' }}
      />
      </div>;
  }
}

export default AvainiaTagInput;
