import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AvainiaCore from 'avainia-core-api';
import Error from '../../../multiview/Error/Error.js';
import Loading from '../../../multiview/Loading/Loading.js';
import CondominiumProject from './CondominiumProject/CondominiumProject.js';
import InfraMarineProject from './InfraMarineProject/InfraMarineProject.js';
import { TopbarContext } from '../../../../contexts/TopbarContext.js';
import LocalStorageService from '../../../../AvainiaTools/LocalStorageService.js';

class Project extends Component {
  static contextType = TopbarContext;

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      project: { id },
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    const { id } = this.state.project;

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.projectGet(id).then((project) => {
      if (project.error) { return this.setState({ error: project.error }); }
      this.setState({ project, loading: false }, this.context.resetTopbar);
    });
  }

  render() {
    const { error, loading, project } = this.state;
    const { configuration } = this.props;

    if (error) { return <Error error={error} />; }
    if (loading) { return <Loading />; }

    switch (project.type) {
      case 'infrastructure':
      case 'marine':
        return <InfraMarineProject configuration={configuration} project={project}/>;
      case 'condominium':
        return <CondominiumProject configuration={configuration} project={project} />;
      default:
        return null;
    }
  }
}

export default withRouter(Project);
