import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Button, Modal, FormControl, Dropdown, DropdownButton } from 'react-bootstrap';
import TreeMenu from 'react-simple-tree-menu';
import AvainiaCore from 'avainia-core-api';
import Error from '../Error/Error.js';
import CustomSelect from '../../multiview/Select/CustomSelect.js';

import './ModalMoveToFolder.scss';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class ModalMoveToFolder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      foldername: '',
      parentId: null,
      loading: false,
      error: false, // TODO: Implement
      exportIds: this.props.files,
      value: null,
      noFiles: false,
      projects: [],
      selectedProject: null,
      projectSelectOptions: [],
      foreignProjectFolders: []
    };
  }

  componentDidMount = () => {

    if (this.state.exportIds.length === 0) {
      this.setState({ noFiles: true, error: 26 });
    } else {
      this.setState({ noFiles: false });

    document.querySelector('#tree > div > input').placeholder = I18n.t('views.infraproject.search-folders');

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.projectsGet().then((projects) => {
      if (projects.error) { return this.setState({ error: projects.error }); }

        let projectSelectOptions = [
          {
            key: 0,
            label: this.props.project.name,
            value: null
          }
        ];

        projects.map((project) => {
          if(project.deleted_at){return false;}
          if(project.status.slug === 'inactive'){return false;}
          if(project.id === this.props.project.id){return false;}
          projectSelectOptions.push({label: project.name, value: project.id});
        });

        this.setState({
          projects,
          projectSelectOptions
        });
      });
    }
  };

  moveToFolder = (newFolder) => {
    if (newFolder === '/') {
      newFolder = '';
    }

    let newProject = this.state.selectedProject;

    // Check that any chosen documents actually exist
    const documentIds = this.state.exportIds;

    if (typeof documentIds === 'undefined' || documentIds.length <= 0) {
      this.setState({ noFiles: true });
      return alert(I18n.t('views.infraproject.export-choose-one'));
    }

    // Loop through all files to be moved to another folder
    documentIds.forEach((id) => {
      // Find the file in turn
      const fileToMove = this.props.documents.find((file) => file.id === id);
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.doUpdateFolder(this.props.project, fileToMove.id, newFolder, newProject).then((result) => {
        if (!result || result.error) {
          return this.setState({ error: 4220 });
        }
        this.props.documentEditedCallback(result);
        this.setState({ value: null, exportIds: null });
      });
    });
  };

  selectFolder = (folder) => {
    this.setState({ value: folder.id });
  };

  chooseProject = (name, option) => {
    let project = option.value;

    if(option.value === null){
      this.setState({
        selectedProject: null,
        foreignProjectFolders: null
      });
    } else {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.projectFoldersGet(project, 'folders').then((result) => {
        if (!result || result.error) {
          return this.setState({ error: 4220 });
        }

        let foreignProjectFolders = [{
          id: null,
          key: null,
          label: I18n.t('views.infraproject.main-folder'),
          name: I18n.t('views.infraproject.main-folder')
        }];

        result.folders.map((project) => {
          foreignProjectFolders.push({
            label: project.name,
            key: project.id,
            ...project
          });
        });

        this.setState({
          selectedProject: project,
          foreignProjectFolders
        });
      });
    }
  };

  render() {
    var folders = this.state.selectedProject ? this.state.foreignProjectFolders : {... this.props.folders};

    return (
      <Modal show={true} onHide={this.props.hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {I18n.t('views.infraproject.move-to-folder')}
          </Modal.Title>
        </Modal.Header>
        {this.state.error && <Error inline error={this.state.error} />}
        <Modal.Body>

          <CustomSelect
            name="type"
            placeholder={I18n.t('views.infraproject.move-to-project')}
            handleChange={this.chooseProject}
            selectOptions={this.state.projectSelectOptions}
          />

          <h5>{I18n.t('views.infraproject.available-folders')}</h5>
          <hr />
          <div id='tree'>
            {!this.state.noFiles && (
                <TreeMenu
                  data={folders}
                  initialActiveKey='none'
                  initialFocusKey='none'
                  debounceTime={125}
                  disableKeyboard={false}
                  hasSearch
                  onClickItem={(folder) => this.selectFolder(folder)}
                  resetOpenNodesOnDataUpdate={false}
                />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {!this.state.loading && (
            <>
              <Button variant='secondary' onClick={this.props.hideModal}>
                {I18n.t('general.cancel')}
              </Button>
              {!this.state.error && (
                <Button
                  variant='primary'
                  onClick={() => this.moveToFolder(this.state.value)}>
                  {I18n.t('views.infraproject.move-selected-documents')}
                </Button>
              )}
            </>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalMoveToFolder;
