import React, { Component } from 'react';
import { Button, Row, Modal, Form, Col } from 'react-bootstrap';
import I18n from 'i18n-js';
import Loading from '../Loading/Loading.js';
import Error from '../Error/Error.js';
import AvainiaCore from 'avainia-core-api';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

const defaultCondominium = {
  name: '',
  street_address: '',
  postcode: '',
  post_office: '',
};

class UpcertCondominiumModal extends Component {
  constructor(props) {
    super(props);

    const condominium = this.props.hasOwnProperty('condominium') ? this.props.condominium : defaultCondominium;

    this.state = {
      condominium,
      loading: false,
      error: false,
    };
  }

  save = () => {
    if (this.state.loading) { return; }

    const { id } = this.state.condominium;
    const api = new AvainiaCore(LocalStorageService.getToken);

    if (id) {
      // Update
      this.setState({ loading: 1, error: false }, () => {
        api.condominiumEdit(this.state.condominium).then((result) => {
          if (result.error) { return this.setState({ error: result.error, loading: false }); }

          this.setState({ loading: false, error: false }, () => this.props.successCallback(result));
        });
      });
    } else {
      // Create
      this.setState({ loading: 1, error: false }, () => {
        api.condominiumCreate(this.state.condominium).then((result) => {
          if (result.error) { return this.setState({ error: result.error, loading: false }); }

          this.setState({ loading: false, error: false }, () => this.props.successCallback(result));
        });
      });
    }
  }

  onChange = (e) => {
    const condominium = { ...this.state.condominium };
    condominium[e.target.name] = e.target.value;
    this.setState({ condominium });
  }

  render() {
    const { condominium } = this.state;
    const { id } = condominium;
    const title = id ? I18n.t('views.condominiums.button-edit') : I18n.t('views.condominiums.button-create-new');

    return <Modal show={this.props.show} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.error && <Error inline error={this.state.error} /> }
        {this.state.loading && <Loading inline /> }
        {!this.state.loading && <>
          <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.condominiums.name')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.onChange} name="name" value={condominium.name} /></Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.condominiums.street_address')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.onChange} name="street_address" value={condominium.street_address} /></Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.condominiums.postcode')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.onChange} name="postcode" value={condominium.postcode} /></Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.condominiums.post_office')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.onChange} name="post_office" value={condominium.post_office} /></Col>
          </Form.Group>
        </>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" disabled={this.state.loading} onClick={this.save}>{I18n.t('general.modal-form-save')}</Button>
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('general.modal-close')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default UpcertCondominiumModal;
