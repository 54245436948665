import AvainiaCore from 'avainia-core-api';
import React, { useState, useEffect } from 'react';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService';
import SecureImage from '../../multiview/SecureImage/SecureImage';
import SearchResultModal from './SearchResultModal';

function getTitle(enriched, result) {
  let title = enriched?.texts?.title;
  title = title ? title[0] : '';

  if (!title) { title = result.metadata.title; }
  if (Array.isArray(title)) { [title] = title; }

  return title;
}

function getBestMatch(enriched) {
  let best = { similarity: null, sentence: null, page: null };

  const contentMatches = enriched?.match?.best_matching_sentences?.content;
  if (contentMatches) {
    contentMatches.forEach((match, index) => {
      if (match.similarity > best.similarity) {
        best = match;
        best.page = index;
      }
    });
  }

  return best;
}

export default function SearchResult(props) {
  const [enriched, setEnriched] = useState(false);
  const [error, setError] = useState(false);
  const [activeResult, setActiveResult] = useState(false);

  const { result, query, resolvedProjects } = props;
  const projectId = result.metadata ? result.metadata.project_id : false;

  useEffect(() => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.searchResultDetails(result.id, query).then((enrichedResult) => {
      if (enrichedResult.error) { return setError(enrichedResult.error); }
      setEnriched(enrichedResult);
    });
  }, [query, result.id]);

  if (error) {
    console.error({ errorDuringSearchEnrichment: error });
  }

  const title = getTitle(enriched, result);
  const bestMatch = getBestMatch(enriched);
  const thumb = projectId ? `/api/v1/projects/${projectId}/documents/${result.id}/thumb` : '';
  const project = resolvedProjects.find((p) => p.id === Number(projectId));

  return (
    <>
      <tr className="result-row clickable" onClick={(e) => { !error && setActiveResult(result); }}>
        <td style={{ width: 180, padding: 5 }}>
          <SecureImage
            className="image-thumbnail image-thumbnail-mini result-thumb"
            src={thumb}
          />
        </td>
        <td>
          {project && <p><i>{project.name}</i></p>}
          <p title={result.id}><b>{title}</b></p>
          <p>{bestMatch.sentence}</p>
          {/* Disabled for now. Are they useful?
          {result.summary.keywords.map((kw) => (
            <Badge key={kw} variant="info" style={{ marginLeft: 3 }}>{kw}</Badge>
          ))}
          <br />
          <i>{bestMatch.similarity}</i>
          */}
        </td>
      </tr>
      {activeResult && <tr>
        <td>
          <SearchResultModal
            result={result}
            enrichedResult={enriched}
            resolvedProjects={resolvedProjects}
            hideModal={(e) => { setActiveResult(false); }}
          />
        </td>
        <td></td>
      </tr>}
    </>
  );
}
