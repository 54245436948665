import React, { Component, Fragment } from 'react';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import AvainiaTable from '../../AvainiaTable/AvainiaTable.js';
import { Check, Close } from '../../Icon/Icon.js';
import Error from '../../Error/Error.js';
import './DocumentTypeManageModal.scss';
import LocalStorageService from '../../../../AvainiaTools/LocalStorageService.js';

class DocumentTypesManageModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documentTypeName: '',
      isActive: false,
      fields: false,
      loading: true,
      documentTypeFieldIds: [],
      showFields: false,
      error: false,
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.fieldsGet().then((fields) => {
      if (fields.error) { return this.setState({ error: fields.error }); }
      this.setState({ fields, loading: false, });
    });
  }

  // TODO: Find a better alternative
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedDocumentType.is_active !== this.state.isActive) {
      this.setState({
        isActive: nextProps.selectedDocumentType.is_active,
        documentTypeName: nextProps.selectedDocumentType.name,
      });
    }
  }

  updateDocumentTypeName = () => {
    const payload = {
      name: this.state.documentTypeName,
      is_immutable: false, // TODO
      is_default: false, // TODO
      is_active: this.state.isActive,
    };

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.documentTypesUpdate(this.props.selectedDocumentType.id, payload).then((result) => {
      if (!result.error) {
        this.props.editCallback(result.data);
        window.location.reload(); // TODO: Improve...
      }
    });
  }

  updateDocumentTypeFieldIds = (id) => {
    const exists = this.state.documentTypeFieldIds.includes(id);
    if (exists) {
      this.setState({
        documentTypeFieldIds: this.state.documentTypeFieldIds.filter((fieldId) => fieldId !== id),
      });
    } else {
      this.setState((prevState) => ({
        documentTypeFieldIds: [...prevState.documentTypeFieldIds, id],
      }));
    }
  }

  renderActions = (cell, row) => {
    if (!this.state.documentTypeFieldIds) {
      return;
    }

    if (row.is_default) {
      return <Check style={{ width: '100%', cursor: 'not-allowed', opacity: '0.6' }} />;
    }

    const enabled = this.state.documentTypeFieldIds.includes(row.id);

    return <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      { enabled
        ? <Check className="clickable" onClick={() => this.updateDocumentTypeFieldIds(row.id)} disabled={row.is_default} />
        : <Close className="clickable" onClick={() => this.updateDocumentTypeFieldIds(row.id)} disabled={row.is_default}/>
      }
    </div>;
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  changeActive = (e) => {
    this.setState({ isActive: e.target.checked });
  }

  showFields = () => {
    const documentTypeFieldIds = this.props.selectedDocumentType.fields.map((field) => field.id);

    this.setState({
      showFields: true,
      documentTypeFieldIds,
    });
  }

  updateDocumentTypeFields = () => {
    this.setState({loading: true}, () => {
      const payload = {
        fieldIds: this.state.documentTypeFieldIds,
      };
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.documentTypeFieldsUpdate(this.props.selectedDocumentType.id, payload).then((result) => {
        if (result.error) {
          this.setState({ error: result.error });
        }
        this.setState({loading: false});
      });
    });
  }

  modalOnExit = () => {
    this.setState({ showFields: false });
  }

  render() {
    const { selectedDocumentType, show, hideModal } = this.props;

    if (this.state.error) { return <Error error={this.state.error} />; }

    return <Modal size="lg" show={show} onHide={hideModal} onExit={this.modalOnExit}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('documentType.manageTitle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>{I18n.t('documentType.name')}</Form.Label>
            <Form.Control type="text" name="documentTypeName" defaultValue={selectedDocumentType.name} onChange={this.onChange}/>
          </Form.Group>
          <Form.Group>
            <Form.Check
              type="checkbox"
              label={I18n.t('documentType.active')}
              onChange={(e) => this.changeActive(e)}
              checked={this.state.isActive}
              disabled={selectedDocumentType.is_default}
            />
          </Form.Group>
          {!selectedDocumentType.is_default && <Button onClick={this.updateDocumentTypeName}>{I18n.t('documentType.update')}</Button>}
        </Form>

        <hr/>

        <h5>{I18n.t('documentType.enable-disable-fields')}</h5>
          {this.state.showFields ?
            <Fragment>
              <AvainiaTable
                keyField='id'
                data={this.state.fields}
                rowClickHandler={null}
                columns={[
                  {
                    dataField: 'key',
                    text: I18n.t('documentType.field-name'),
                    formatter: (cell, row) => {
                      return I18n.t(`fields.${cell}`, { defaultValue: cell });
                    },
                  },
                  {
                    dataField: 'type',
                    text: I18n.t('documentType.field-type'),
                    formatter: (cell, row) => {
                      return I18n.t(`documentType.${cell}`);
                    },
                  },
                  {
                    dataField: 'is_default',
                    text: I18n.t('documentType.default'),
                    formatter: (cell, row) => {
                      if (cell) {
                        return I18n.t('documentType.true');
                      }
                      return I18n.t('documentType.false');
                    },
                  },
                  {
                    dataField: 'actions',
                    text: I18n.t('documentType.enabled'),
                    headerStyle: { width: '90px' },
                    formatter: this.renderActions,
                    headerAlign: 'center',
                    formatExtraData: this.state.documentTypeFieldIds,
                  },
                ]}
              />
              <Button
                style={{width: 132}}
                onClick={this.updateDocumentTypeFields}
              >
                {this.state.loading ?
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  :
                  I18n.t('documentType.update-fields')
                }
              </Button>
            </Fragment>
            : <Button onClick={this.showFields}>{I18n.t('documentType.load-fields')}</Button>
          }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.hideModal}>{I18n.t('documentType.return')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default DocumentTypesManageModal;
