import React, { Component } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import I18n from "i18n-js";
import AvainiaCore from "avainia-core-api";
import LocalStorageService from "../../../AvainiaTools/LocalStorageService.js";

class ModalsummaryViewAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultFolders: this.props.defaultFolders,
      selectedFolder: "1",
      name: "",
    };
  }

  onSelectedViewChange = (e) => {
    this.setState({ selectedFolder: e.target.value });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  addSummaryViewPage = () => {
    const payload = {
      name: this.state.name,
      defaultfolder_id: this.state.selectedFolder,
    };
    if (!payload.name) {
      window.confirm("Täytä kaikki kentät");
      return;
    }
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.summaryViewCreate(payload).then((result) => {
      if (result.error) {
        return this.setState({ error: result.error });
      }
      this.props.onHide();

      this.setState({ selectedFolder: "1", name: "" });
      window.location.reload();
    });
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header>
          <h2>{I18n.t("views.summaryviews.addsummaryview")}</h2>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>{I18n.t("documentType.name")}</Form.Label>
          <Form.Control type="text" onChange={this.onChange} name="name" value={this.state.name} />
          <Form.Label>{I18n.t("folders.folder")}</Form.Label>
          <Form.Control as="select" onChange={this.onSelectedViewChange}>
            {this.state.defaultFolders.map((task) => (
              <option key={task.id} value={task.id}>
                {task.name}
              </option>
            ))}
          </Form.Control>
          <Button onClick={this.addSummaryViewPage} style={{ float: "right" }}>
            {I18n.t("views.summaryviews.addsummaryview")}
          </Button>
        </Modal.Body>
      </Modal>
    );
  }
}
export default ModalsummaryViewAdd;
