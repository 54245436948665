import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Tab } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import ModalCompanyEdit from '../../multiview/Modals/ModalCompanyEdit.js';
import { TopbarContext } from '../../../contexts/TopbarContext.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

const Tabs = {
  base: 'base',
};

const Modals = {
  editCompany: 'edit-company',
};

class CompanyManagement extends Component {
  static contextType = TopbarContext;

  constructor(props) {
    super(props);

    this.state = {
      company: {},
      userCategories: [],
      modal: false,
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    if (!id) {
      return this.setState({ error: 2 });
    }

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.userCategoriesGet().then((userCategories) => {
      if (userCategories.error) { return this.setState({ error: userCategories.error }); }

      const api = new AvainiaCore(LocalStorageService.getToken);
      api.companyGet(id).then((company) => {
        if (company.error) {
          return this.setState({ error: company.error });
        }

        this.setState({
          userCategories,
          company,
          loading: false,
        }, () => {
          const tabs = [
            <Tab key={Tabs.base} eventKey={Tabs.base} title={I18n.t('general.basicdata')} />,
          ];
          this.context.setTopbar(tabs, false);
        });
      });
    });
  }

  editCompanyCallback = (company) => {
    window.location.reload(); // TODO: Improve
  };

  editCompany = (e) => {
    this.setState({ modal: Modals.editCompany });
  };

  hideModal = (e) => {
    this.setState({ modal: false });
  };

  render() {
    if (this.state.error) {
      return <Error error={this.state.error} />;
    }
    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <div className="App-container">
        {this.context.tab === Tabs.base && (
          <div className="text">
            <h2>{this.state.company.name}</h2>

            <button
              onClick={this.editCompany}
              className="btn btn-primary"
            >
              {I18n.t('views.companies.manage-company')}
            </button>

            {this.state.modal === Modals.editCompany && (
              <ModalCompanyEdit
                disableRemoval
                userCategories={this.state.userCategories}
                editCompanyCallback={this.editCompanyCallback}
                companyToEdit={this.state.company}
                onHide={this.hideModal}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(CompanyManagement);
