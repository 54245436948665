import React, { Component } from 'react';
import { Form, InputGroup, Tabs, Button, Dropdown, ButtonGroup, DropdownButton, Spinner } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import { Search, UsersCog } from '../Icon/Icon.js';
import { TopbarContext } from '../../../contexts/TopbarContext.js';
import './Topbar.scss';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

/*
  SEARCH:
  OK! 1. Toggle search on

  2. Advanced search -> initialize -> show advanced search
  3. Search term changed -> switch view to search results -> trigger prop onSearch
  4. Advanced search term changed and view is results -> trigger prop onSearch
  5. Advanced search off -> hide advanced search
  6. enter in input -> trigger search
  7. search button pressed -> do nothing
*/

// TODO: Convert userManagementTabKey to a custom property on a TAB that makes it not render normally

class Topbar extends Component {
  static contextType = TopbarContext;

  constructor(props) {
    super(props);

    this.state = {
      documentTypes: [],
      searchActive: false,
      advancedSearch: false,
      initialized: false,
      loading: false,
      error: false,
    };
  }

  toggleSearch = () => { this.setState({ searchActive: !this.state.searchActive }); }

  initialize = (callback) => {
    if (this.state.initialized) { return; }

    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.documentTypesGet().then((documentTypes) => {
        if (documentTypes.error) { return this.setState({ error: documentTypes.error }); }

        this.setState({
          documentTypes,
          loading: false,
          initialized: true,
        }, callback);
      });
    });
  }

  toggleAdvancedSearch = () => {
    if (!this.state.initialized) {
      return this.initialize(() => {
        this.setState((prevState) => ({
          advancedSearch: !prevState.advancedSearch,
        }));
      });
    }

    this.setState((prevState) => ({
      advancedSearch: !prevState.advancedSearch,
    }));
  }

  documentTypeSelected = (documentTypeId) => {
    // documentTypeId will be a numeric string, such as "3"
    this.context.searchDocumentTypeHandler(documentTypeId);
  }

  searchResultTypeSelected = (eventKey) => {
    // EventKey will be "user", "document" or "project"
    console.log(eventKey);
  }

  doSearch = () => {
    this.context.searchChangeHandler(this.context.searchText);
  }

  render() {
    const { searchActive } = this.state;
    const config = this.props.configuration;
    const loading = this.state.loading || this.context.loading;

    const user = LocalStorageService.getUser();
    const ownerManager = user && user.isOwnerManager();
    const enableSearch = config.search && ownerManager;

    let dropdownButtonSuffix = "";
    if (this.context.documentTypeId) {
      /* eslint-disable-next-line */
      const dt = this.state.documentTypes.find((dt) => dt.id == this.context.documentTypeId);
      if (dt) {
        const name = dt.name === 'document_type_default' ? I18n.t('documentType.defaultDocumentType') : dt.name;
        dropdownButtonSuffix = ": " + name;
      }
    }

    return <div className={`
      Topbar ${(enableSearch && searchActive) ? 'is-active' : ''}
      ${enableSearch ? 'has-search' : ''}
    `}>
      <div className="Topbar-inner">
        <div className="Topbar-content">
          {enableSearch && <div className="Topbar-search">
            <InputGroup size="sm">
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend" onClick={this.toggleSearch} >
                  {!loading && <Search />}
                  {loading && <Spinner animation="border" size="sm" role="status" aria-hidden="true" /> }
                </InputGroup.Text>
              </InputGroup.Prepend>
              {searchActive && <>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="search"
                  value={this.context.searchText}
                  onChange={(e) => {
                    this.context.searchChangeHandler(e.target.value);
                  }}
                  autoFocus
                />
                <InputGroup.Append>
                  <Button variant="primary" onClick={this.doSearch}>{I18n.t('search.search')}</Button>
                  <Button variant="secondary" onClick={this.toggleAdvancedSearch}>{I18n.t('search.advanced-search')}</Button>
                </InputGroup.Append>
              </>}
            </InputGroup>

            {searchActive && this.state.advancedSearch && <div className="search-advanced">
              <DropdownButton
                title={I18n.t('documentType.document-type') + dropdownButtonSuffix}
                disabled={!this.state.initialized}
                onSelect={this.documentTypeSelected}
              >
                {this.state.documentTypes.map((dt) => <Dropdown.Item key={dt.id} eventKey={dt.id}>
                  {dt.name === 'document_type_default' ? I18n.t('documentType.defaultDocumentType') : dt.name}
                </Dropdown.Item>)}
              </DropdownButton>
              {/* <DropdownButton
                title={I18n.t('views.documents.type')}
                disabled={!this.state.initialized}
                onSelect={this.searchResultTypeSelected}
              >
                <Dropdown.Item eventKey="project">{I18n.t('views.projects.project')}</Dropdown.Item>
                <Dropdown.Item eventKey="document">{I18n.t('general.document')}</Dropdown.Item>
                <Dropdown.Item eventKey="user">{I18n.t('general.user')}</Dropdown.Item>
              </DropdownButton> */}
            </div>}
          </div>}

          <div className="Topbar-tabs">
            {this.context.tabs && <Tabs activeKey={this.context.tab} onSelect={this.context.setTab}>
              {this.context.tabs}
            </Tabs>}
          </div>

          <div className="Topbar-actions">
            {this.context.mapViewToggleEnabled && <>
              <Dropdown alignRight as={ButtonGroup}>
                <Dropdown.Toggle className="topbar-map-dropdown">
                  <i className="las la-map"/>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="1" onClick={this.context.mapViewToggle}>
                    {this.context.mapViewActive && I18n.t('views.infraproject.hide-map')}
                    {!this.context.mapViewActive && I18n.t('views.infraproject.show-map')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>}

            {this.context.userManagementTabKey && <InputGroup size="sm">
              <InputGroup.Append>
                <InputGroup.Text onClick={this.context.showUserManagement}>
                  <UsersCog />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>}
          </div>
        </div>
      </div>
    </div>;
  }
}

export default Topbar;
