import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import { Check, Close, Trash } from '../Icon/Icon.js';
import './ModalDefaultfolderManage.scss';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

export default class ModalDefaultfolderManage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      editName: this.props.activeDefaultfolder.name,
      loading: false,
      error: false,
    };
  }

  renderDefaultFolderCompanies = (df) => {
    const relevantPermissions = this.props.contentPermissions.filter((cp) => {
      return cp.content_id === df.id;
    });

    const companies = this.props.companies
      .map((company) => {
        const attachedRoles = company.roles.filter((role) => {
          return relevantPermissions.find((rp) => rp.role_id === role.id);
        });
        return { ...company, attachedRoles };
      })
      .sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });

    return (
      <div className='permissions-for-company defaultfolders'>
        <table className='informational-grid'>
          <thead>
            <tr>
              <td></td>
              <td>{I18n.t('constants.userTypes.employee')}</td>
              <td>{I18n.t('constants.userTypes.editor')}</td>
              <td>{I18n.t('constants.userTypes.manager')}</td>
            </tr>
          </thead>
          <tbody>
            {companies.map((company) => {
              const roleEmployee = company.roles.find(
                (x) => x.display_name === 'employee',
              );
              const roleEditor = company.roles.find(
                (x) => x.display_name === 'editor',
              );
              const roleManager = company.roles.find(
                (x) => x.display_name === 'manager',
              );

              const roleEmployeeAssigned = relevantPermissions.find(
                (rp) => rp.role_id === roleEmployee.id,
              );
              const roleEditorAssigned = relevantPermissions.find(
                (rp) => rp.role_id === roleEditor.id,
              );
              const roleManagerAssigned = relevantPermissions.find(
                (rp) => rp.role_id === roleManager.id,
              );

              return (
                <tr key={company.id}>
                  <td>{company.name}</td>
                  <td>
                    {roleEmployeeAssigned && (
                      <Check
                        className="role-assigned clickable"
                        onClick={() => this.contentPermissionRemove(roleEmployeeAssigned)}
                        title={I18n.t('folders.click-to-revoke-permission')}
                      />
                    )}
                    {!roleEmployeeAssigned && (
                      <Close
                        className="role-not-assigned clickable"
                        onClick={() => this.contentPermissionCreate(roleEmployee, df)}
                        title={I18n.t('folders.click-to-grant-permission')}
                      />
                    )}
                  </td>
                  <td>
                    {roleEditorAssigned && (
                      <Check
                        className="role-assigned clickable"
                        onClick={() => this.contentPermissionRemove(roleEditorAssigned)}
                        title={I18n.t('folders.click-to-revoke-permission')}
                      />
                    )}
                    {!roleEditorAssigned && (
                      <Close
                        className="role-not-assigned clickable"
                        onClick={() => this.contentPermissionCreate(roleEditor, df)}
                        title={I18n.t('folders.click-to-grant-permission')}
                      />
                    )}
                  </td>
                  <td>
                    {roleManagerAssigned && (
                      <Check
                        className="role-assigned clickable"
                        onClick={() => this.contentPermissionRemove(roleManagerAssigned)}
                        title={I18n.t('folders.click-to-revoke-permission')}
                      />
                    )}
                    {!roleManagerAssigned && (
                      <Close
                        className="role-not-assigned clickable"
                        onClick={() => this.contentPermissionCreate(roleManager, df)}
                        title={I18n.t('folders.click-to-grant-permission')}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  // ? note: this is copied from App\\Folder -- dedupe?
  contentPermissionCreate = (role, folder) => {
    // TODO! Hardcoding the contentPermission type, should it be done?
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.contentPermissionAdd(role, 'App\\Defaultfolder', folder.id).then(
      (result) => {
        if (result.error) {
          return this.setState({ error: result.error });
        }
        this.props.updatePermissionsCallback();
      },
    );
  };

  // ? note: this is copied from App\\Folder -- dedupe?
  contentPermissionRemove = (contentPermission) => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.contentPermissionRemove(contentPermission).then((result) => {
      if (result.error) {
        return this.setState({ error: result.error });
      }
      this.props.updatePermissionsCallback();
    });
  };

  deleteDefaultfolder = (df) => {
    if (this.state.loading) {
      return;
    }

    if (!window.confirm(I18n.t('defaultfolders.confirm-delete'))) {
      return;
    }

    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.defaultFoldersDelete(df.id).then((result) => {
        if (result.error) {
          return this.setState({
            error: result.error === -3 ? 28 : result.error, // Joel, your code system Sucks.
            loading: false,
          });
        }

        window.location.reload(); // TODO: Improve
      });
    });
  };

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  create = () => {
    if (this.state.loading) { return; }

    // This is only relevant when creating new subfolders.
    // Change logic when changing permissions doesn't automatically reload window between each permission check
    if (!this.state.name) {
      window.location.reload(); // TODO: Improve
      return;
    }

    this.setState({ loading: true }, () => {
      const payload = {
        name: this.state.name,
      };

      payload.parent_id = this.props.activeDefaultfolder.id;
      payload.project_subtype_id = this.props.projectSubtype.id;

      const api = new AvainiaCore(LocalStorageService.getToken);
      api.defaultFoldersCreate(payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  save = () => {
    if (this.state.loading) { return; }

    this.setState({ loading: true }, () => {
      const payload = {
        id: this.props.activeDefaultfolder.id,
        name: this.state.editName,
      };

      const api = new AvainiaCore(LocalStorageService.getToken);
      api.defaultFoldersEdit(payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  render() {
    return <Modal className="modal-default-folders" show={this.props.show} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('defaultfolders.manage-defaultfolder')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.error && <Error error={this.state.error} inline />}
        {this.state.loading && <Loading inline />}
        {!this.state.loading && <>
          <h5>{I18n.t('defaultfolders.edit-defaultfolder-name')}</h5>
          <InputGroup className="mb-3">
            <FormControl
              type="text"
              onChange={this.onChange}
              name="editName"
              value={this.state.editName}
            />
            <InputGroup.Append>
              <Button variant="primary" onClick={this.save}>{I18n.t('general.save')}</Button>
            </InputGroup.Append>
          </InputGroup>

          <h5>{I18n.t('defaultfolders.edit-defaultfolder-permissions')}</h5>
          {this.renderDefaultFolderCompanies(this.props.activeDefaultfolder)}

          <div className="modal-default-folders-childbox">
            <h5>{I18n.t('defaultfolders.create-new-child')}</h5>
            <Form.Group>
              <Form.Label>{I18n.t('defaultfolders.parent')}</Form.Label>
              <Form.Control type="text" value={this.props.activeDefaultfolder.name} readOnly />
            </Form.Group>

            <Form.Group>
              <Form.Label>{I18n.t('defaultfolders.name')}</Form.Label>
              <Form.Control type="text" onChange={this.onChange} name="name" value={this.state.name} />
            </Form.Group>
            <Button variant="primary" onClick={this.create}>
              {I18n.t('general.save')}
            </Button>
          </div>
        </>}

      </Modal.Body>
      <Modal.Footer>
        <Button className="trash" variant="danger" onClick={(e) => this.deleteDefaultfolder(this.props.activeDefaultfolder)}>
          <Trash />
        </Button>
        <Button variant="secondary" onClick={this.props.onHide}>
          {I18n.t('views.materialforms.button-cancel')}
        </Button>
      </Modal.Footer>
    </Modal>;
  }
}
