import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Badge, Button, Modal, Form } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class ModalContactAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: '',
      last_name: '',
      title: '',
      phone: '',
      email: '',
      contact_id: false,
      requiredError: false,
      loading: false,
      error: false,
    };
  }

  getRequiredFieldHasNoErrors = () => {
    if (!this.state.first_name || !this.state.last_name || !this.state.title) {
      return false;
    }
    return true;
  }

  createContact = (e) => {
    if (this.state.loading) { return; }

    if (this.getRequiredFieldHasNoErrors()) {
      this.setState({ requiredError: false, loading: true, error: false }, () => {
        const payload = {
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          title: this.state.title,
          phone: this.state.phone,
          email: this.state.email,
        };

        const api = new AvainiaCore(LocalStorageService.getToken);
        api.projectContactsCreate(this.props.project.id, payload).then((contact) => {
          if (contact.error) { return this.setState({ error: contact.error, loading: false }); }

          this.setState({
            first_name: '',
            last_name: '',
            title: '',
            phone: '',
            email: '',
            loading: false,
          });
          this.props.onHide();
          this.props.onRefresh();
        });
      });
    } else {
      this.setState({ requiredError: I18n.t('general.required') });
    }
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  render() {
    return <Modal show={this.props.show} onHide={this.props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.contacts.edit-contact')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.error && <Error error={this.state.error} inline />}
        {!this.state.error && this.state.loading && <Loading inline />}
        {!this.state.error && !this.state.loading && <>

          <Form.Group controlId="create-contact-firstname">
            <Form.Label>{I18n.t('views.contacts.create-contact-first_name')}</Form.Label>
            <Form.Control type="text" onChange={this.onChange} name="first_name" value={this.state.first_name} />
            {this.state.requiredError && <div><Badge variant="danger">{this.state.requiredError}</Badge></div>}
          </Form.Group>

          <Form.Group controlId="create-contact-lastname">
            <Form.Label>{I18n.t('views.contacts.create-contact-last_name')}</Form.Label>
            <Form.Control type="text" onChange={this.onChange} name="last_name" value={this.state.last_name} />
            {this.state.requiredError && <div><Badge variant="danger">{this.state.requiredError}</Badge></div>}
          </Form.Group>

          <Form.Group controlId="create-contact-title">
            <Form.Label>{I18n.t('views.contacts.create-contact-title')}</Form.Label>
            <Form.Control type="text" onChange={this.onChange} name="title" value={this.state.title} />
            {this.state.requiredError && <div><Badge variant="danger">{this.state.requiredError}</Badge></div>}
          </Form.Group>

          <Form.Group controlId="create-contact-phone">
            <Form.Label>{I18n.t('views.contacts.create-contact-phone')}</Form.Label>
            <Form.Control type="text" onChange={this.onChange} name="phone" value={this.state.phone} />
          </Form.Group>

          <Form.Group controlId="create-contact-email">
            <Form.Label>{I18n.t('general.email')}</Form.Label>
            <Form.Control type="text" onChange={this.onChange} name="email" value={this.state.email} />
          </Form.Group>

        </>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('views.materialforms.button-cancel')}</Button>
        <Button variant="primary" onClick={this.createContact}>{I18n.t('views.contacts.button-create')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default ModalContactAdd;
