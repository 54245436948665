import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Button, Badge, Modal } from 'react-bootstrap';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import AvainiaCore from 'avainia-core-api';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class QuoteModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }

  getTotalPrice = () => {
    let total = 0;
    this.props.quote.rows.forEach((row) => {
      total += Number(row.price);
    });

    return Number(total).toFixed(2);
  }

  accept = () => {
    const { id } = this.props.quote;
    const payload = {};
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.apartmentMaterialquoteApprove(id, payload).then((result) => {
      if (result.error) { return this.setState({ error: result.error }); }
    });
  }

  render() {
    const { quote } = this.props;

    return <Modal show={true} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.materialforms.apartment-materialform-quote')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

      {this.state.error && <Error inline error={this.state.error} />}
      {!this.state.error && this.state.loading && <Loading inline />}
      {!this.state.error && !this.state.loading && <>
        <h3>{quote.name}</h3>
        <p><Badge variant="info">{I18n.t(`views.materialforms.quote-status-${quote.status}`)}</Badge></p>

        <table style={{ width: '100%' }}>
          <tbody>
            {quote.rows.map((row) => <tr key={row.id}>
                <td>{row.description}</td>
                <td>{row.price}</td>
              </tr>)}
            <tr>
              <td></td>
              <td><b>{this.getTotalPrice()}</b></td>
            </tr>
          </tbody>
        </table>
      </>}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.accept}>{I18n.t('views.materialforms.button-approve-quote')}</Button>
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('views.materialforms.button-cancel')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}
export default QuoteModal;
