import React, { Component } from 'react';
import I18n from 'i18n-js';
import moment from 'moment';
import { ListGroup } from 'react-bootstrap';

class ShowFields extends Component {
  renderField = (fieldName) => {
    let field = this.props.fields.find((x) => x.key === fieldName);

    if (field) {
      const fieldId = field.id;

      field = this.props.doc.fields.find((x) => x.field_id === fieldId);

      if (field) {
        return field.date
          ? moment(field.date).format('YYYY.MM.DD HH:mm')
          : field.text;
      }

      field = this.props.doc.keywords.find((x) => x.field_id === fieldId);

      if (field) {
        return field.text;
      }

      return <span className='field-not-found' />;
    }

    return <span className='error-field-not-found' />;
  };

  sortFields = (a, b) => {
    if (a.is_default > b.is_default) {
      return -1;
    }
    if (b.is_default > a.is_default) {
      return 1;
    }
    if (a.is_default && b.is_default) {
      if (a.order < b.order) {
        return -1;
      }
      return 1;
    }
    if (a.key < b.key) {
      return -1;
    }
    return 1;
  };

  validateDate = (fieldSet) => {
    let fieldValue = '';
    if (fieldSet && fieldSet !== '') {
      fieldValue = moment(fieldSet).format('YYYY.MM.DD HH:mm');
    } else {
      fieldValue = '';
    }
    return fieldValue;
  };

  render() {
    const { doc, fields, excludedFieldKeys } = this.props;

    if (!doc || !fields || !fields.length) {
      return false;
    }

    const fieldSets = [];

    fields.forEach((field) => {
      let fieldValue = '';

      const savedData = doc.fields.find((f) => f.field_id === field.id);

      if (savedData) {
        if (field.type === 'text') {
          fieldValue = savedData.text;
        } else if (field.type === 'date') {
          fieldValue = savedData.date;
        }
      }
      if (!field.is_default) {
        fieldSets.push({
          key: field.key,
          type: field.type,
          value: fieldValue,
        });
      }
    });

    return (
      <ListGroup variant='flush'>

        { !excludedFieldKeys.includes('phase') &&
          <ListGroup.Item style={{ padding: '0' }}>
            {I18n.t('fields.phase')}: {this.renderField('phase')}
          </ListGroup.Item>
        }

        { !excludedFieldKeys.includes('subject') &&
          <ListGroup.Item style={{ padding: '0' }}>
            {I18n.t('fields.subject')}: {this.renderField('subject')}
          </ListGroup.Item>
        }

        { !excludedFieldKeys.includes('state') &&
          <ListGroup.Item style={{ padding: '0' }}>
            {I18n.t('fields.state')}: {this.renderField('state')}
          </ListGroup.Item>
        }

        { !excludedFieldKeys.includes('creation_date') &&
          <ListGroup.Item style={{ padding: '0' }}>
            {I18n.t('fields.creation_date')}: {this.renderField('creation_date')}
          </ListGroup.Item>
        }

        { !excludedFieldKeys.includes('info') &&
          <ListGroup.Item style={{ padding: '0' }}>
            {I18n.t('fields.info')}: {this.renderField('info')}
          </ListGroup.Item>
        }

        { !excludedFieldKeys.includes('position') &&
          <ListGroup.Item style={{ padding: '0' }}>
            {I18n.t('fields.position')}: {this.renderField('position')}
          </ListGroup.Item>
        }

        <ListGroup.Item style={{ padding: '0' }}>
          {I18n.t('views.documents.group-name')}: {doc.group}
        </ListGroup.Item>

        <ListGroup.Item style={{ padding: '0' }}>
          {I18n.t('views.infraproject.addedByCompany')}: {doc.addedByCompany}
        </ListGroup.Item>

        <ListGroup.Item style={{ padding: '0' }}>
          {I18n.t('fields.gps_coordinates')}:{' '}
          {this.renderField('gps_coordinates')}
        </ListGroup.Item>

        <ListGroup.Item style={{ padding: '0' }}>
          {I18n.t('fields.gps_accuracy')}: {this.renderField('gps_accuracy')}
        </ListGroup.Item>

        {fieldSets.sort(this.sortFields).map((fieldSet) => {
          return (
            <ListGroup.Item key={fieldSet.key} style={{ padding: '0' }}>
              {I18n.t(`fields.${fieldSet.key}`, { defaultValue: fieldSet.key })}
              :{' '}
              {fieldSet.type === 'date'
                ? this.validateDate(fieldSet.value)
                : fieldSet.value}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  }
}

export default ShowFields;
