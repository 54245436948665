import React, { Component } from 'react';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import AvainiaPanel from './AvainiaPanel.js';
import { Users } from '../Icon/Icon.js';
import AvainiaList from '../AvainiaList/AvainiaList.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class ContactPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contacts: [],
      loading: true,
      error: false,
    };
  }

  componentDidMount = () => {
    if (!this.props.project) { return this.setState({ error: 17 }); }

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.projectContactsGet(this.props.project.id).then((contacts) => {
      if (contacts.error) { return this.setState({ error: contacts.error }); }

      this.setState({ contacts, loading: false });
    });
  }

  render() {
    return <AvainiaPanel heading={I18n.t('views.contacts.contacts')} icon={<Users />}>
      {this.state.error && <Error inline error={this.state.error} /> }
      {!this.state.error && this.state.loading && <Loading inline /> }
      {!this.state.error && !this.state.loading && this.state.contacts.map((contact, index) => <AvainiaList
        title={`${contact.title} ${contact.first_name} ${contact.last_name}`}
        key={`contact-${index}`}
      >
        <li>
          <div> {contact.phone} </div>
          <div> {contact.email} </div>
        </li>
      </AvainiaList>)}
    </AvainiaPanel>;
  }
}

export default ContactPanel;
