import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import I18n from 'i18n-js';
import { Dropdown, DropdownButton, Badge } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import Loading from '../../multiview/Loading/Loading.js';
import Error from '../../multiview/Error/Error.js';
import TermsModal from '../../multiview/Modals/TermsModal.js';
import PrivacyPolicyModal from '../../multiview/Modals/PrivacyPolicyModal.js';
import TimeoutToast from '../../multiview/Toasts/TimeoutToast.js';
import logoAvainia from './Logo-Avainia-negative.png';
import './Login.scss';

const Modals = {
  termsModal: 1,
  privacyPolicyModal: 2,
};

const languagesDictionary = { // TODO: Refactor this somewhere, translations.js? Translations.js?
  en: 'English',
  fi: 'Suomeksi',
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: '',
      email: '',
      password: '',
      password_confirmation: '',
      terms_accepted: false,
      user: false,
      modal: false,
      loading: false,
      error: false,
      passwordError: '',
    };
  }

  componentDidMount = () => {
    if (!this.props.match.params.email) { return; }
    this.setState({
      token: this.props.match.params.token,
      email: decodeURIComponent(this.props.match.params.email),
    });
  }

  doLogin = (e) => {
    const api = new AvainiaCore();
    this.setState({ loading: true }, () => {
      api.login(this.state.email, this.state.password)
        .then((result) => {
          if (result.error) { return this.setState({ loading: false, error: 8 }); }

          if (result.terms_accepted) {
            LocalStorageService.setUser(result);
            window.location.reload();
          } else {
            this.setState({ modal: Modals.termsModal, user: result, loading: false });
          }
        })
        .catch((error) => {
          console.error('Error during login', error); // TODO! Fatal error, actual logging
        });
    });
  }

  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (this.props.forgot) {
        return this.saveNewPassword();
      }

      if (this.state.forgotten) {
        return this.sendPasswordResetEmail();
      }

      return this.doLogin();
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  validateForm = () => {
    const valid = this.state.email.length > 0 && this.state.password.length > 0;
    return valid;
  }

  termsModal = () => {
    this.setState({ modal: Modals.termsModal });
  }

  privacyModal = () => {
    this.setState({ modal: Modals.privacyPolicyModal });
  }

  termsAccepted = () => {
    if (this.state.loading) { return; }

    LocalStorageService.setUser(this.state.user);

    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.acceptTerms()
        .then((result) => {
          if (!result || result.error) { return this.setState({ loading: false, error: 8 }); }
          window.location.reload();
        })
        .catch((error) => {
          return this.setState({ loading: false, error: 22 });
        });
    });
  }

  hideModal = () => {
    this.setState({ modal: false });
  }

  languageSelectHandler = (lang) => {
    LocalStorageService.setLang(lang);
    window.location.reload();
  }

  forgottenPassword = (e) => {
    return this.setState({ forgotten: true });
  }

  forgottenPasswordNot = (e) => {
    return this.setState({ forgotten: false });
  }

  saveNewPassword = (e) => {
    if (this.state.password !== this.state.password_confirmation) {
      this.setState({ error: 1 });
    }

    this.setState({ loading: true, error: false }, () => {
      const payload = {
        token: this.state.token,
        email: this.state.email,
        password: this.state.password,
        password_confirmation: this.state.password_confirmation,
      };
      const api = new AvainiaCore();
      api.userPasswordChange(payload).then((result) => {
        if (result.error) {
          if (result.error === 14) {
            this.setState({ passwordError: I18n.t('general.password-reset-match-error') });
          } else if (result.error === 6) {
            this.setState({ passwordError: I18n.t('general.password-reset-invalid') });
          }
          return this.setState({ error: 2, loading: false });
        }
        alert(I18n.t('views.passwordset.message-success'));
        document.location.replace('/');
      });
    });
  }

  wrap = (body) => {
    return (
      <div className="Login" style={{ backgroundImage: `url(${this.props.configuration.login.Background})` }}>
        <div className="Login-card card">
          <div className="card-body">
            <div className="Login-logo"><img src={this.props.configuration.login.Logo} alt="Logo" /></div>
            {this.state.loading && <Loading inline />}
            {!this.state.loading &&
            <>
              {this.state.error && <Error error={this.state.error} inline /> }
              {body}
            </>}
          </div>
          <div className="card-footer">
            {!this.state.loading &&
            <>
              <div className="row align-items-center">
                <div className="col-6">
                  <div className="Login-terms">
                    {I18n.t('views.login.privacy-policy-1')}&nbsp;
                    <a href="#showPrivacyPolicy" onClick={this.privacyModal}>{I18n.t('views.login.privacy-policy-2')}</a>
                  </div>
                </div>
                <div className="col-6">
                  <DropdownButton
                    alignRight
                    drop="up"
                    size="sm"
                    variant="light"
                    onSelect={this.languageSelectHandler}
                    title={languagesDictionary[I18n.currentLocale()]}
                  >
                    {Object.keys(languagesDictionary).map((key) => <Dropdown.Item key={key} eventKey={key}>{languagesDictionary[key]}</Dropdown.Item>)}
                  </DropdownButton>
                </div>
              </div>
            </>}
          </div>
        </div>

        {this.props.timeout && <TimeoutToast />}

        <TermsModal
          show={this.state.modal === Modals.termsModal}
          onHide={this.hideModal}
          onAccept={this.termsAccepted}
          configuration={this.props.configuration}
        />

        <PrivacyPolicyModal
          show={this.state.modal === Modals.privacyPolicyModal}
          onHide={this.hideModal}
          configuration={this.props.configuration} />

        <div className="Login-footer">
          <img src={logoAvainia} alt="Avainia" />
        </div>
      </div>
    );
  }

  sendPasswordResetEmail = (e) => {
    if (this.state.loading) { return; }

    this.setState({ loading: true }, () => {
      const api = new AvainiaCore();
      api.userPasswordReset(this.state.email).then(() => {
        this.setState({ loading: false, forgotten: false }, () => {
          return alert(I18n.t('views.companies.reset-password-email-sent'));
        });
      });
    });
  }

  render() {
    if (this.props.forgot || this.props.activate) {
      return this.wrap(<>
        <p>{I18n.t('views.login.please-enter-new-password')}</p>

        <input
          className="form-control"
          type="text"
          disabled
          value={this.state.email}
        />

        <input
          className="form-control"
          name="password"
          type="password"
          value={this.state.password}
          placeholder={I18n.t('general.password')}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
        />

        <input
          className="form-control mb-3"
          name="password_confirmation"
          type="password"
          value={this.state.password_confirmation}
          placeholder={I18n.t('general.password_confirmation')}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
        />

        <div style={{ marginBottom: '20px', fontSize: '14px' }}>
          {this.state.error ? <Badge variant="danger" >{(this.state.passwordError)}</Badge> : ''}
        </div>

        <button className="btn btn-primary mr-1" onClick={this.saveNewPassword}>{I18n.t('general.save')}</button>
        <button className="btn btn-light cancel" onClick={() => { window.location.replace('/'); }}>{I18n.t('general.cancel')}</button>
      </>);
    }

    if (this.state.forgotten) {
      return this.wrap(<>
        <p>{I18n.t('views.login.enter-email-for-reset')}</p>

        <input
          className="form-control mb-3"
          name="email"
          type="text"
          placeholder={I18n.t('general.email')}
          autoComplete="email"
          value={this.state.email}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          autoFocus
        />

        <button className="btn btn-primary mr-1" onClick={this.sendPasswordResetEmail}>{I18n.t('general.save')}</button>
        <button className="btn btn-light cancel" onClick={this.forgottenPasswordNot}>{I18n.t('general.cancel')}</button>
      </>);
    }

    return this.wrap(<>
      <input
        className="Login-email form-control"
        name="email"
        type="email"
        placeholder={I18n.t('general.email')}
        autoComplete="email"
        value={this.state.email}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        autoFocus
      />

      <input
        className="Login-password form-control mb-3"
        name="password"
        type="password"
        value={this.state.password}
        placeholder={I18n.t('general.password')}
        autoComplete="password"
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
      />

      <button className="btn btn-primary btn-block mb-3" onClick={this.doLogin} disabled={this.state.loading}>{I18n.t('views.login.login-button')}</button>

      <div className="Login-forgotPassword">
        {I18n.t('views.login.forgot-password')}&nbsp;
        <a href="#forgottenPassword" onClick={this.forgottenPassword}>{I18n.t('views.login.click-here')}</a>
      </div>
    </>);
  }
}

export default withRouter(Login);
