import React, { Component } from "react";
import { TopbarContext } from "../../../contexts/TopbarContext";
import LocalStorageService from "../../../AvainiaTools/LocalStorageService";
import AvainiaPermissions from "../../../AvainiaTools/AvainiaPermissions";
import AvainiaCore from "avainia-core-api";
import Loading from "../../multiview/Loading/Loading.js";
import I18n from "i18n-js";
import AvainiaTable from "../../multiview/AvainiaTable/AvainiaTable.js";
import { Row, Col, InputGroup, Form, Button } from "react-bootstrap";
import { Trash } from "../../multiview/Icon/Icon.js";

class SummaryCompanies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      summaryCompanies: [],
      selectCompanies: [],
      selectedCompany: "1",
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.oneSummaryViewGet(this.props.project.id).then((summaryCompanies) => {
      if (summaryCompanies.error) {
        return this.setState({ error: summaryCompanies.error });
      }
      api.companiesGet().then((companies) => {
        if (companies.error) {
          return this.setState({ error: companies.error });
        }

        this.setState({
          summaryCompanies: summaryCompanies.companies,
          selectCompanies: companies,
          loading: false,
        });
      });
    });
  }

  attachCompany = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    const id = this.props.project.id;
    const companyId = this.state.selectedCompany;
    api.companyAttachmentAdd(companyId, id).then((response) => {
      if (response.error) {
        return this.setState({ error: response.error });
      }
      const summaryCompanies = this.state.summaryCompanies.filter((company) => company.id !== id);
      this.setState({ summaryCompanies });
      window.location.reload();
    });
  };

  deleteCompanyAttachment = (id) => {
    if (!window.confirm(I18n.t("views.progressnumbers.confirmreportdelete"))) {
      return;
    }
    const api = new AvainiaCore(LocalStorageService.getToken);

    const companyId = this.props.project.id;
    api.companyAttachmentDelete(id, companyId).then((response) => {
      if (response.error) {
        return this.setState({ error: response.error });
      }
      const summaryCompanies = this.state.summaryCompanies.filter((company) => company.id !== id);
      this.setState({ summaryCompanies });
    });
  };
  onSelectedTaskChange = (e) => {
    this.setState({ selectedCompany: e.target.value });
  };

  renderActions = (cell, row) => {
    return (
      <div className="documenttype-actions">
        <Trash onClick={() => this.deleteCompanyAttachment(row.id)} className="clickable" />
      </div>
    );
  };

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <div className="App-container">
        <Row className="align-items-center mb-5">
          <Col xs={6}>
            <h1 className="App-pageTitle mb-0">{I18n.t("views.companies.companies")}</h1>
          </Col>
          <Col xs={4}>
            <InputGroup>
              <Form.Control name="select" as="select" onChange={this.onSelectedTaskChange}>
                {this.state.selectCompanies.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </Form.Control>
            </InputGroup>
          </Col>
          <Col xs={2}>
            <Button block variant="primary" onClick={this.attachCompany} style={{ marginBottom: "0" }}>
              {I18n.t("views.companies.attach-company")}
            </Button>
          </Col>
        </Row>

        <AvainiaTable
          keyField="id"
          data={this.state.summaryCompanies}
          columns={[
            { dataField: "name", text: I18n.t("views.companies.name") },
            {
              dataField: "type",
              text: I18n.t("views.companies.type"),
              formatter: (cell, row) => I18n.t(`constants.companyTypes.${cell}`),
            },
            {
              dataField: "actions",
              text: I18n.t("general.table-actions"),
              headerStyle: { width: "100px" },
              formatter: this.renderActions,
            },
          ]}
        />
      </div>
    );
  }
}
export default SummaryCompanies;
