import React, { Component } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import moment from 'moment';
import { Edit, Cog } from '../../multiview/Icon/Icon.js';
import AvainiaTable from '../../multiview/AvainiaTable/AvainiaTable.js';
import NotificationsLatest from '../../multiview/Notifications/NotificationsLatest.js';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import ModalConfigProjectsTable from '../../multiview/Modals/ModalConfigProjectsTable.js';
import ModalProjectEdit from '../../multiview/Modals/ModalProjectEdit.js';
import AvainiaPermissions from '../../../AvainiaTools/AvainiaPermissions.js';
import AvainiaTableHeading from '../../multiview/AvainiaTable/AvainiaTableHeading.js';
import ModalProjectCreate from '../../multiview/Modals/ModalProjectCreate.js';
import { TopbarContext } from '../../../contexts/TopbarContext.js';
import ProjectsMap from './ProjectsMap.js';

import "./Projects.scss";

const Modals = {
  editProjectModal: 1,
  tableConfigModal: 2,
};

class Projects extends Component {
  static contextType = TopbarContext;

  constructor(props) {
    super(props);

    //! TODO: Hardcoding is evil (types)
    let type = 'marine';

    if (this.props.configuration.condominiums) {
      type = 'condominium';
    }

    if (this.props.configuration.infraprojects) {
      type = 'infrastructure';
    }

    this.state = {
      projects: [],
      mapViewOpen: this.props.configuration.projectsMapViewOpenAsDefault ? true : false,
      visibleProjects: [],
      projectSubtypes: [],
      filter: '',
      filters: {},
      editing: false,
      key: 1,
      type,
      tableConfig: [],
      modal: false,
      loading: true,
      backgroundLoading: false,
      error: false
    };
  }

  componentDidMount() {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.projectSubtypesGet().then((projectSubtypes) => {
      if (projectSubtypes.error) { return this.setState({ error: projectSubtypes.error }); }

      // ? Useful for performance tuning const t0 = performance.now();
      let only_active = this.props.inactive == 1 ? null : 1;
      let only_inactive = this.props.inactive == 1 ? 1 : null;
      let limit = this.props.inactive == 1 ? null : 50;

      api.projectsGet(null, limit, only_active, only_inactive).then((projects) => {
        // ? Useful for performance tuning const t1 = performance.now();
        // ? Useful for performance tuning console.log(`Call to doSomething took ${t1 - t0} milliseconds.`);

        if (projects.error) { return this.setState({ error: projects.error }); }

        api.projectStatusesGet().then((projectStatuses) => {
          if (projectStatuses.error) { return; }

        // TODO: Refactor this to Inside getAllProjects!
          const openingCounts = LocalStorageService.getProjectOpeningCounts();
          const projectsWithOpeningCounts = projects.map((project) => {
            return {
              ...project,
              boost: openingCounts ? openingCounts[project.code] : 0,
            };
          });

          this.setState({
            projectSubtypes,
            projectStatuses,
            projects: projectsWithOpeningCounts,
            visibleProjects: this.getvisibleProjects(projects, false),
            loading: false,
            backgroundLoading: this.props.inactive == 1 ? false : true,
          }, this.context.resetTopbar);

          if(this.props.inactive != 1){
            this.getAllProjects();
          }
        });
      });
    });
  }

  getAllProjects = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    let only_active = this.props.inactive == 1 ? null : 1;
    let only_inactive = this.props.inactive == 1 ? 1 : null;

    api.projectsGet(null, null, only_active, only_inactive).then((projects) => {
      if (projects.error) { return this.setState({ error: projects.error }); }
      const openingCounts = LocalStorageService.getProjectOpeningCounts();
      const projectsWithOpeningCounts = projects.map((project) => {
        return {
          ...project,
          boost: openingCounts ? openingCounts[project.code] : 0,
        };
      });
      this.setState({
        projects: projectsWithOpeningCounts,
        visibleProjects: this.getvisibleProjects(projects, false),
        backgroundLoading: false
      }, this.context.resetTopbar);

    });
  }

  match = (a, b) => {
    if (!a) { return false; }
    if (!Array.isArray(a)) { a = a.toLowerCase(); }
    return a.indexOf(b) !== -1;
  }

  getvisibleProjects = (projects, filterValue) => {
    projects.sort((a, b) => {
      if (a.boost === b.boost) return 0;
      if (a.boost > b.boost) return -1;
      return 1;
    });

    if (!filterValue) { return projects; }

    const lowcaseFilter = filterValue.toLowerCase();
    const results = projects.filter((project) => {
      // Filter with the search term, match id
      if (this.match(project.code, lowcaseFilter)) {
        return true;
      }

      // Match status / type
      if (project.status && this.match(I18n.t(`views.projects.statuses.${project.status}`).toLowerCase(), lowcaseFilter)) {
        return true;
      }

      // Match name
      if (this.match(project.name, lowcaseFilter)) {
        return true;
      }

      // Match companies -- TODO: The data doesn't contain companies!
      return !!project.companies.find((company) => { return this.match(company.name, lowcaseFilter) || this.match(company.code, lowcaseFilter); });
    });
    return results;
  }

  onSearchTermChange = (e) => {
    // Small throttling of input updates
    const { value } = e.target;

    this.setState({ filter: value }, () => {
      // Small throttling of input updates
      if (window._timeout) { clearTimeout(window._timeout); }
      window._timeout = setTimeout(() => {
        window._timeout = false;
        const visibleProjects = this.getvisibleProjects(this.state.projects, value);
        this.setState({ visibleProjects });
      }, 200);
    });
  }

  getAllowedProjectTypes = () => {
    const allowedProjectTypes = [];

    if (this.props.configuration.condominiums) { allowedProjectTypes.push("condominiums"); }
    if (this.props.configuration.infraprojects) { allowedProjectTypes.push("infraprojects"); }
    if (this.props.configuration.marineprojects) { allowedProjectTypes.push("marineprojects"); }

    return allowedProjectTypes;
  }

  getTypeFor = (cell, row) => {
    const subtype = this.state.projectSubtypes.find(x => x.id === row.project_subtype_id);

    const projectTypes = this.getAllowedProjectTypes();

    if (projectTypes.length > 1) {
      return <span>{subtype.name} <em>{I18n.t(`views.projects.${row.type}`)}</em></span>;
    }

    return subtype.name;
  }

  getStatusFor = (cell, row) => {
    const status = this.state.projectStatuses.find(x => x.id === row.project_status_id);

    if (status) {
      if (status.is_default) {
        return I18n.t(`views.projects.statuses.${status.slug}`)
      } else {
        return status.name;
      }
    }
  }

  editProject = (e, row) => {
    if (this.state.loading) { return; }

    e.stopPropagation();

    const editing = this.state.projects.find((x) => x.id === row.id);

    this.setState({ editing, modal: Modals.editProjectModal });
  }

  addProject = () => {
    this.setState({ modal: Modals.addProjectModal });
  }

  projectEditCallback = (id, payload) => {
    const { projects } = this.state;
    const updated = projects.map((target) => {
      if (target.id === id) {
        return {
          ...target,
          code: payload.code,
          name: payload.name,
          status: payload.status,
          type: payload.type,
          coordinates: payload.coordinates,
        };
      }

      return target;
    });

    window.location.reload();
  }

  projectDeleteCallback = (id) => {
    const { projects } = this.state;
    const filtered = projects.filter((x) => x.id !== id);
    const visibleProjects = this.getvisibleProjects(filtered, this.state.filter);

    this.setState({
      visibleProjects,
      projects: filtered,
      key: Math.random(),
    });
  }

  hideModal = () => { this.setState({ modal: false }); }

  renderCompanies = (companies) => {
    if (!companies) { return null; }

    return companies.map((company) =>
      <div key={company.id} style={{ borderBottom: '1px solid' }}>{company.name}</div>
    );
  }

  renderActions = (cell, row) => {
    return (
      <Edit onClick={(e) => { this.editProject(e, row); }} className="clickable" />
    );
  }

  showTableConfig = () => {
    this.setState({ modal: Modals.tableConfigModal });
  }

  refreshTable = () => {
    this.setState({ key: Math.random() });
  }

  projectAddedCallback = (project) => {
    const { projects } = this.state;

    projects.push(project);

    this.setState({
      projects,
      visibleProjects: this.getvisibleProjects(projects, this.state.filter),
      loading: false,
      modal: false,
    }, this.refreshTable);
  }

  getProjectsToList = (config) => {
    /*
    NOTE: Inactive projects have their own pages now, commented this out
    if (!config.showInactiveProjects) {
      // TODO! Hardcoding is evil
      return this.state.visibleProjects.filter((project) => project.status.slug !== 'inactive');
    }*/

    return this.state.visibleProjects;
  }

  onSelectChange = (event) => {
    if (event.target.value === "all") {
      this.setState({
        filters: {}
      })
    } else {
      this.setState({
        filters: { status: { filterVal: event.target.value, filterType: "SELECT", comparator: "=", caseSensitive: false }}
      });
    }
  };

  getStatusName = (type) => {
    let name = '';

    this.state.projectStatuses.find(x => {
      if (x.id === type.id) {
        if (x.is_default) {
          name =  I18n.t(`views.projects.statuses.${x.slug}`);
        } else {
          name = x.name;
        }
      }
    })
    return name;
  }

  render() {
    const user = LocalStorageService.getUser();
    if (!user) { return; }

    const { loading, error, modal, key, filters } = this.state;
    const config = LocalStorageService.getProjectsTableConfig();

    const projects = this.getProjectsToList(config);
    const onlyCondominiumOrOnlyInfra = this.props.configuration.condominiums !== this.props.configuration.infraprojects;
    const ownerManager = user.isOwnerManager(); // TODO: Replace with real checks
    const projectManager = user.hasPermission(AvainiaPermissions.ProjectsManage);
    const hideDeletedProjects = this.props.configuration.hideDeletedProjects ? this.props.configuration.hideDeletedProjects : false;
    const selectOptions = [];
    let projectData = hideDeletedProjects ? projects.filter(project => !project.deleted_at) : projects;
    const statusFilterActive = this.props.configuration.statusFilter;

    if (statusFilterActive) {
      if (this.state.projectStatuses) {
        this.state.projectStatuses.forEach(el => {
          selectOptions.push({id: el.id, name: el.name})
        })
      }
      const statusFilter = !filters ? null : filters.status ? filters.status.filterVal : null;

      if (statusFilter) {
        // eslint-disable-next-line
        projectData = projectData.filter((item) => item.status.id == statusFilter);
      }
    }

    return (<>

      {!loading && this.state.mapViewOpen && <>
          {this.state.backgroundLoading && <ProjectsMap projects={this.state.projects} allProjects={this.state.visibleProjects} coordinates={null} />}
          {!this.state.backgroundLoading && <ProjectsMap projects={this.state.projects} allProjects={this.state.visibleProjects} coordinates={null} />}
      </>}

      <div className="App-container" key={key}>

        <NotificationsLatest />

        <AvainiaTableHeading
          title={ this.props.inactive == 1 ? I18n.t('views.projects.projects-archive') : I18n.t('views.projects.projects')}
          placeholder={I18n.t('views.projects.search-projects')}
          onChange={this.onSearchTermChange}
          button={
            <>
              <Button onClick={this.addProject} size="m" style={{ marginBottom: 5 }}>
                {I18n.t('views.projects.button-create')}
              </Button>
              <Button variant="secondary" size="m" onClick={this.showTableConfig} style={{ marginLeft: 5, marginBottom: 5 }}>
                <Cog style={{ height: '1rem' }} />
              </Button>
              {this.props.configuration.projectsMapView && <Button variant="secondary" size="m" onClick={() => {this.setState({mapViewOpen: !this.state.mapViewOpen})}} style={{ marginLeft: 5, marginBottom: 5 }}>
                <i style={{ height: '1rem' }} className="las la-map"/>
              </Button>}
            </>
          }
          secondRow={
            statusFilterActive ? <div className="projectStatusFilter">
              <Form.Control as="select" name="selectByStatus"  onChange={this.onSelectChange}>
                <option value="all">{I18n.t('projectStatuses.select-by-status')}</option>
                {selectOptions.map((type) => <option value={type.id} key={type.id} name={type.name}>
                  {this.getStatusName(type)}
                </option>)}
              </Form.Control>
            </div> : null
          }
        />

        <Error error={error} inline />
        {!loading &&
            <AvainiaTable
              data={projectData}
              keyField="id"
              rowClickIdRedirect="project"
              defaultSorted={[{ dataField: 'mostRecentDocument', order: 'desc' }]}
              rowStyle={(row) => { if (row.deleted_at) { return { color: "#880000" }; } else { return {}; } }}
              columns={[
                {
                  dataField: 'id',
                  text: I18n.t('general.id'),
                  headerStyle: { width: '60px' },
                  hidden: !config.id,
                },
                {
                  dataField: 'code',
                  text: I18n.t('views.projects.project-code'),
                  hidden: !config.code,
                  formatter: (cell, row) => {
                    return <a href={`/project/${row.id}`} className="clickable">
                      {cell}
                    </a>;
                  },
                  sort: true,
                },
                {
                  dataField: 'name',
                  text: I18n.t('views.projects.project-name'),
                  formatter: (cell, row) => {
                    return <span>{cell} {row.deleted_at ? <b title={I18n.t('general.deleted') + " " + moment(row.deleted_at).format('YYYY.MM.DD HH:mm')}>*</b> : ""}</span>;
                  },
                  hidden: !config.name,
                  sort: true
                },
                { dataField: 'filecount', text: I18n.t('folders.filecount'), sort: true },
                { dataField: 'mostRecentDocument', text: I18n.t('folders.mostRecentDocument'), sort: true },
                {
                  dataField: 'status',
                  text: I18n.t('views.projects.status'),
                  formatter: this.getStatusFor,
                  hidden: !config.status,
                  sort: true,
                  sortValue: this.getStatusFor,
                },
                {
                  dataField: 'companies',
                  text: I18n.t('general.companies'),
                  formatter: this.renderCompanies,
                  hidden: !config.companies,
                },
                {
                  dataField: 'type',
                  text: I18n.t('views.projects.type'),
                  formatter: this.getTypeFor,
                  hidden: !config.type || onlyCondominiumOrOnlyInfra,
                },
                {
                  dataField: 'actions',
                  text: I18n.t('general.table-actions'),
                  headerStyle: { width: '100px' },
                  formatter: this.renderActions,
                  hidden: !user.hasPermission(AvainiaPermissions.ProjectsManage) || !config.actions,
                },
              ]}
            />
        }

        <div style={{display: "flex", justifyContent: "end"}}>
          <Button onClick={() => {window.location = '/import'}} variant="primary">
            {I18n.t('views.projects.csv-import')}
          </Button>
        </div>

        <Error error={error} inline />
        {!error && loading && <Loading inline />}

        {modal === Modals.editProjectModal &&
          <ModalProjectEdit
            disableType={onlyCondominiumOrOnlyInfra}
            project={this.state.editing}
            onHide={this.hideModal}
            projectDeleteCallback={this.projectDeleteCallback}
            editCallback={this.projectEditCallback}
            configuration={this.props.configuration}
          />
        }

        <ModalConfigProjectsTable
          disableType={onlyCondominiumOrOnlyInfra}
          callBack={this.refreshTable}
          show={modal === Modals.tableConfigModal}
          onHide={this.hideModal}
        />

        {modal === Modals.addProjectModal && projectManager && ownerManager && !loading &&
          <ModalProjectCreate
            projectSubtypes={this.state.projectSubtypes}
            projectAddedCallback={this.projectAddedCallback}
            onHide={this.hideModal}
            configuration={this.props.configuration}
          />
        }

        {this.state.backgroundLoading &&
          <div className="background-loading">
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            <strong>{I18n.t('views.projects.loading') + "..."}</strong>
          </div>
        }

      </div>
    </>);
  }
}

export default Projects;
