import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Button, Modal, Form, Badge } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import '../../mainviews/Notifications/notificationsAdmin.scss';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class ModalNotificationEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      body: '',
      attachment: null,
      creator_user_id: '',
      editor_user_id: '',
      published: false,
      loading: true,
      error: false,
      requiredError: false,
      hasAttachment: null,
    };
  }

  componentDidMount = () => {
    const { notification } = this.props;

    const hasAttachment = notification.attachment !== null;

    this.setState({
      hasAttachment,
      loading: false,
      title: notification.title,
      body: notification.body,
      attachment: notification.attachment,
      creator_user_id: notification.creator_user_id,
      editor_user_id: notification.editor_user_id,
      published: notification.is_published,
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleFileChange = (e) => {
    if (e.target.files[0]) {
      const { name } = e.target;
      const reader = new FileReader();
      const file = e.target.files[0];

      reader.onload = () => {
        this.setState({
          [name]: file,
        });
      };

      reader.readAsDataURL(file);
    }
  }

  removeOldFile = () => {
    this.setState({ attachment: null, attachmentRemoved: true });
  }

  handlePublishChange = (e) => {
    this.setState({ published: e.target.checked });
  }

  updateNotification = (e) => {
    if (this.state.loading) {
      return;
    }

    if (this.props.requiredFieldsCheck(this.state)) {
      this.setState(
        { requiredError: false, loading: true, error: false },
        () => {
          const payload = {
            title: this.state.title,
            body: this.state.body,
            is_published: this.state.published,
            is_edited: true,
          };

          // This indicates a file that has been changed
          if (this.state.attachment && !this.state.attachment.filename) {
            payload.attachment = this.state.attachment;
            payload.attachmentChanged = true;
            payload.attachmentRemoved = false;
          }

          if (this.state.attachmentRemoved) {
            payload.attachmentRemoved = true;
          }

          const api = new AvainiaCore(LocalStorageService.getToken);
          api.notificationEdit(payload, this.props.notification.id).then(
            (notification) => {
              if (notification.errorCode) { return this.setState({ error: notification.errorCode.code, loading: false }); }

              this.setState({
                title: '',
                body: '',
                attachment: null,
                published: false,
                loading: false,
              });
              this.props.onHide();
              this.props.notificationCallback();
            },
          );
        },
      );
    } else {
      this.setState({ requiredError: I18n.t('general.required') });
    }
  };

  render() {
    return (
      <Modal show={true} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('views.notifications.edit-notification')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.error && <Error error={this.state.error} inline />}
          {this.state.loading && <Loading inline />}
          {!this.state.loading && (
            <>
              <Form.Group controlId='edit-modal-title'>
                <Form.Label>{I18n.t('views.notifications.title')}</Form.Label>
                <Form.Control
                  type='text'
                  onChange={this.onChange}
                  name='title'
                  value={this.state.title}
                />
                <Badge variant='danger'>{this.state.requiredError}</Badge>
              </Form.Group>
              <Form.Group controlId='edit-modal-body'>
                <Form.Label>{I18n.t('views.notifications.body')}</Form.Label>
                <Form.Control
                  as="textarea" rows="5"
                  onChange={this.onChange}
                  name='body'
                  value={this.state.body}
                />
                <Badge variant='danger'>{this.state.requiredError}</Badge>
              </Form.Group>
              <Form.Group controlId="publish-checkbox">
                <Form.Check
                  type='checkbox'
                  label={I18n.t('views.notifications.publish')}
                  checked={this.state.published}
                  onChange={this.handlePublishChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{I18n.t('views.notifications.attachment')}</Form.Label><br/>
                  {/* If no file has been added previously */}
                  {!this.state.attachment && <>
                    <Form.Control
                      type="file"
                      id="inputfile"
                      className="inputfile"
                      onChange={this.handleFileChange}
                      name="attachment"
                    />
                    <label className="label-select-file" htmlFor="inputfile">
                      {I18n.t('views.notifications.select-file')}
                    </label>
                  </>}
                  {this.state.attachment ? <p>{this.state.attachment.name}
                    <button className="notification-button" onClick={() => this.removeOldFile()}>
                      {I18n.t('general.delete')}
                    </button>
                    {this.state.hasAttachment &&
                    <button className="notification-button" onClick={() => this.props.downloadAttachment(this.props.notification)}>
                      {I18n.t('views.notifications.download-file')}
                    </button>}
                  </p> : null}
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={this.props.onHide}>
            {I18n.t('views.notifications.button-cancel')}
          </Button>
          <Button variant='primary' onClick={this.updateNotification}>
            {I18n.t('views.notifications.button-save')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalNotificationEdit;
