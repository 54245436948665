import React, { Component } from 'react';
import I18n from 'i18n-js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import ScheduleService from '../../../AvainiaTools/ScheduleService.js';
import SchedulerGraph from '../Graph/SchedulerGraph.js';
import AvainiaPanel from './AvainiaPanel.js';
import { Calendar } from '../Icon/Icon.js';

class SchedulePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedulesSorted: [],
      schedules: [],
      error: false,
      loading: true,
    };
  }

  componentDidMount = () => {
    this.setState({ loading: true }, () => {
      ScheduleService.getSchedules(this.props.project.id).then((data) => {
        const schedulesSorted = data.sorted;
        const schedules = data.unsorted;
        this.setState({ schedulesSorted, schedules, loading: false });
      });
    });
  }

  render() {
    return <AvainiaPanel heading={I18n.t('views.schedules.schedules')} icon={<Calendar />} double={this.props.double}>
      {this.state.error && <Error inline error={this.state.error} /> }
      {!this.state.error && this.state.loading && <Loading inline /> }
      {!this.state.error && !this.state.loading && <>
        <SchedulerGraph schedules={this.state.schedules} />
      </>}
    </AvainiaPanel>;
  }
}

export default SchedulePanel;
